import  React  from "react";
import { Helmet } from 'react-helmet';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-awesome-slider/dist/styles.css';
import Footer from "./Footer";
import Header from "./Header";
import axios from 'axios';
import Swal from "sweetalert2";
import ScrollAnimation from 'react-animate-on-scroll';

class contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
            isActive: false,
        }
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this);
    }
    componentDidMount() {
  
        axios.get("https://www.oengines.com/careers")
            .then(response => {
                console.log("Data is::::::::",response.data);               
                this.setState({Job: response.data});
                this.setState({Description: response.data[0].description});
                
            })
            .catch(function (error) {
                console.log(error);
            })

    }
    onClickShowdetails(job){       
        console.log("job is:::::::",job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';    
    }  
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    maxlengthnumber(evt) {
        if (evt.target.value.length === 11) {
            return false;
        }
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};
       
        if (this.state.name === '' || this.state.name === null) {
            console.log("Nmae")
            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            console.log("mail")
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            console.log("subject")
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            console.log("number")
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.message === '' || this.state.message === null) {
            console.log("message")
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }else{

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,
                
            };
            console.log("formObject::::::::::;",formObject);
            axios.post("https://www.oengines.com/contactus", formObject)
            .then((res) => {
                console.log("Response is:::::::::",res);
                if(res.data !== 'undefined'){
                    Swal.fire({
                        title: "Thank you!",
                        text: "Your request has been assigned successfully.",
                        icon: "success",
                    }).then(function () {
                        window.location.reload();
                    });
                }else{
                    Swal.fire({
                        title: "Oops...!",
                        text: "Oh! Something went wrong, please try again!",
                        icon: "error",
                    }).then(function () {
                        window.location.reload();
                    });
                }
               
            });
        }                  
    }
    render() {

        const myStyleone = {
            backgroundImage:
                "url('../assets/img/bg/choose-us-bg-2.jpg')",
            width: "100%",
            zIndex: 2,
        };
        const mystyletow = {
        backgroundColor: "#FFF"
        };
        const { nameError, emailError, subjectError, numberError, messageError } = this.state.formErrors;

        var Information;
        if(this.state.Job.length){
        Information = this.state.Job.map((job,Index)=>       
            <>
                
                <li key={Index}>
                    <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                </li>                                          
            </>
        )
        }else{
            Information = <div><h4 style={{color: "Red", fontSize: "20px"}}>No Record Found !</h4></div>;
        }
        return (
            <div>
                <Helmet>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/jquery-datetimepicker/2.5.20/jquery.datetimepicker.min.css"/>
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />                    
                    <script src="https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js"></script>
                    <script src="../assets/js/script.js" type="Javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-datetimepicker/2.5.20/jquery.datetimepicker.full.min.js"></script>                    
                </Helmet>
                {/* <div className="loader" ><span>Loading...</span></div> */}
                <Header />
                <div className="breadcumb-wrapper" style={myStyleone}>
                    <div className="container">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title mb-20">Get In Touch</h1>
                            <p className="text-white">Get a quote, or speak with an expert? Let us know what you are looking for and we’ll get back to you right away</p>                           
                        </div>
                    </div>
                </div>
               
                <div className="bg-smoke" style={mystyletow}>
                    <div className="container-fluied">
                        <div className="row">
                            <div className="col-xl-6 ctn-from">
                                <div className="title-area mb-35 text-xl-start text-center">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <span className="sub-title"><img
                                    src="../assets/img/theme-img/title_shape_2.svg" alt="shape" />contact with us!</span>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <h2 className="sec-title">Drop Us a Line</h2>
                                </ScrollAnimation>  
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <p className="sec-text">Reach Out to us from our Contact form and we will get back to you shortly.</p>
                                </ScrollAnimation>
                                </div>
                                <form  onSubmit={this.onSubmit} method="post" className="contact-forms ajax-contact">
                                    <div className="row">
                                        <div className="form-group col-md-6" >
                                            <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" style={{backgroundColor: "#FFFFFF"}}/> 
                                            <i className="fal fa-user"></i>   
                                            {nameError &&
                                            <div className="invalid-feedback" >{nameError}</div>
                                            }                                                     
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} style={{backgroundColor: "#FFFFFF"}} autoComplete="Off"/> 
                                            <i className="fal fa-envelope"></i>
                                            {emailError &&
                                                <div className="invalid-feedback">{emailError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">                                        
                                            <input type="text" id="subject"  className={subjectError ? "form-control is-invalid" : "form-control"} ref="subjectfield" name="subject" placeholder="Subject" onChange={this.handleChange} style={{backgroundColor: "#FFFFFF"}} autoComplete="Off" />               
                                            <i className="fal fa-note-sticky"></i>                              
                                            {subjectError &&
                                                <div className="invalid-feedback">{subjectError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} onKeyDown={(evt) =>   ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} name="number" value={this.state.number} ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off"   onChange={this.maxlengthnumber}/>
                                            <i className="fal fa-phone"></i>
                                            {numberError &&
                                                <div className="invalid-feedback">{numberError}</div>
                                            }
                                        </div>                                                                           
                                        <div className="form-group col-12">
                                            <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                            className={messageError ? "form-control is-invalid" : "form-control"}  placeholder="Your Message" onChange={this.handleChange} style={{backgroundColor: "#FFFFFF"}} autoComplete="Off">
                                            </textarea> 
                                            <i className="fal fa-comment"></i>
                                            {messageError &&
                                                <div className="invalid-feedback">{messageError}</div>
                                            }
                                        </div>
                                        <div className="form-btn text-xl-start text-center col-12 cont-btn">
                                            <button type="submit" className="as-btn style3">Send Details
                                                <i className="fa-regular fa-arrow-right ms-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3"></p>
                                </form>
                            </div>
                            <div className="col-xl-6 ps-0">
                            <div className="map-sec" >
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d276.2593411090248!2d72.85847216407258!3d21.235272708671435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f153fc63919%3A0x825cef8b1bee1265!2sOENGINES%20GAMES%20LLP!5e0!3m2!1sen!2sin!4v1679640277224!5m2!1sen!2sin" allowFullScreen="" loading="lazy"></iframe></div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
               
            </div>
        )
    }
}

export default contact;
    