import  React  from "react";
import { Helmet } from 'react-helmet';
import '../../src/assets/css/app.min.css';
import '../../src/assets/css/style.css';
import '../../src/assets/css/fontawesome.min.css';
import OwlCarousel from 'react-owl-carousel';
import 'react-awesome-slider/dist/styles.css';
import '../../src/assets/css/responsive.css';
import axios from 'axios';
import Swal from "sweetalert2";
import Footer from "../Footer";
import Header from "../Header";
import ScrollAnimation from 'react-animate-on-scroll';
import 'react-circular-progressbar/dist/styles.css';
import { Modal } from 'react-bootstrap';

class Codelnitorpage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
            // isActive: false,
            // isOpen: false
        }
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
    }
    componentDidMount() {

        axios.get("https://www.oengines.com/careers")
            .then(response => {
                console.log("Data is::::::::", response.data);
                this.setState({ Job: response.data });
                this.setState({ Description: response.data[0].description });

            })
            .catch(function (error) {
                console.log(error);
            })

    }
    // openModal = (e) =>{
        
    //     this.setState({ isOpen: !this.state.isOpen });
    //     console.log("enter in open modal",this.state.isOpen);
    // }
    // handleClickInsideModal = (event) => {
    //     event.stopPropagation();
    // }
    // handlevideopopup(e) {
    //     console.log("::::::add on this:");
    //     this.setState({ isOpen: !this.state.isOpen });
    // }
    onClickShowdetails(job) {
        console.log("job is:::::::", job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    handleChangedatepicker = (date) => {
        this.setState({
            selectedDate: date,
        });
    };
    maxlengthnumber(evt) {
        if (evt.target.value.length === 11) {
            return false;
        }
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};
        console.log("Enter in::::::::::::");
        if (this.state.name === '' || this.state.name === null) {

            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }else if (this.state.message === '' || this.state.message === null) {
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }else {

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: 'codelgnitor'+ '-' + this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,

            };
            console.log("formObject::::::::::;", formObject);
            axios.post("https://www.oengines.com/contactus", formObject)
                .then((res) => {
                    console.log("Response is:::::::::", res);
                    if (res.data !== 'undefined') {
                        Swal.fire({
                            title: "Thank you!",
                            text: "Your request has been assigned successfully.",
                            icon: "success",
                        }).then(function () {
                            window.location.reload();
                        });
                    } else {
                        Swal.fire({
                            title: "Oops...!",
                            text: "Oh! Something went wrong, please try again!",
                            icon: "error",
                        }).then(function () {
                            window.location.reload();
                        });
                    }

                });
        }


        this.setState({
            name: '',
            email: '',
            subject: '',
            number: '',
            message: ''

        })

    }

    render() {
        var settings = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 3,
                    nav: true,
                    loop: true
                }

            }
        };        
        const mystyletow = {
            backgroundImage:
                "url('../assets/img/bg/counter_bg_1.png')",
        };
     
        var Information;
        if (this.state.Job.length) {
            Information = this.state.Job.map((job) =>
                <>
                    <li>
                        <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>
                </>
            )
        } else {
            Information = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }

        var careersec;
        if (this.state.Job.length) {
            this.state.Job.map((job) =>
                careersec = this.state.Job.map((job) => {
                    if (job.job === 'Codelgnitor Development') {
                        return <>
                            <section className="conversational-area pb-100 mt-10">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="conversational-content">
                                                <span>CAREER AT OENGINES</span>
                                                <h2>Join Our Amazing Team Now!</h2>

                                                <ul className="list">
                                                    <li>
                                                        <i className='fa fa-check'></i>
                                                        We are passionate developers. If you are too, this is the right place for you.
                                                    </li>
                                                    <li>
                                                        <i className='fa fa-check'></i>
                                                        A good place to explore your potential and continue to learn and grow.
                                                    </li>
                                                    <li>
                                                        <i className='fa fa-check'></i>
                                                        A Professional Environment to collaborate with teammates! Brainstorming & exploring various perspectives
                                                    </li>
                                                    <li>
                                                        <i className='fa fa-check'></i>
                                                        We welcome those with leadership skills to contribute to the team and explore their potential.
                                                    </li>
                                                </ul>
                                                <a className="conversational-btn">
                                                    Apply For Job
                                                    <i className='bx bx-right-arrow-alt'></i>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="conversational-image">
                                                <img src="assets/img/GameDevelopment/react/hero-banner-1.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="shape">
                                    <img src="assets/img/shape/white-shape1.png" alt="image" />
                                </div>
                            </section>
                        </>
                    } else {
                        return null;
                    }
                }
                )
            )
        } else {
            careersec = '';
        }

        const { nameError, emailError, subjectError, numberError, toDateError, messageError } = this.state.formErrors;
        return (
            <>
                <Helmet>

                    <link href="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/css/coreui.min.css" rel="stylesheet" integrity="sha384-UkVD+zxJKGsZP3s/JuRzapi4dQrDDuEf/kHphzg8P3v8wuQ6m9RLjTkPGeFcglQU" crossorigin="anonymous" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                    <script src="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/js/coreui.bundle.min.js" integrity="sha384-n0qOYeB4ohUPebL1M9qb/hfYkTp4lvnZM6U6phkRofqsMzK29IdkBJPegsyfj/r4" crossorigin="anonymous"></script>                   
                    <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js" type="text/javascript"></script>                    
                    <script src="../assets/js/mian.js" type="Javascript"></script>
                    <script src="../assets/js/slick-min.js"></script>
                    <script src="../assets/js/script.js" type="text/Javascript"></script>                    
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-circle-progress/1.1.3/circle-progress.min.js"></script>
                </Helmet>
                <div className="loader" id="playerContainer"><span>Loading...</span></div>
                {/* <Modal size="lg" show={this.state.isOpen} onClick={this.handleClickInsideModal} className="modal_contect video-effect-modal modalvideos" centered>
                    <Modal.Header closeButton className="modal_header p-0 border-none" style={{ border: "none" }} onHide={() => this.handlevideopopup()} >
                    </Modal.Header>
                    <Modal.Body>
                        <iframe width="960" height="415" src="https://www.youtube.com/embed/9xwazD5SyVg?autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>                        
                    </Modal.Body>
                </Modal>   */}
                <Header />
                <div className="as-hero-wrapper hero-4 codelgnitor-hero" id="hero">
                    <div className="body-particle" id="body-particle"></div>
                    <div className="hero-img tilt-active mt-80"><img src="assets/img/GameDevelopment/react/codeignitor-banner-new.png" alt="Hero Image" /></div>
                    <div className="container">
                        <div className="hero-style4 hero-style-new">
                            <div className="ripple-shape"><span className="ripple-1"></span><span className="ripple-2"></span><span
                                className="ripple-3"></span><span className="ripple-4"></span><span className="ripple-5"></span><span
                                    className="ripple-6"></span></div><span className="hero-subtitle">We offer you the best Service of</span>
                            <h1 className="hero-title">CodeIgniter Development</h1>
                            <p className="hero-text">Hire the top CodeIgniter framework development company to create fully-featured and functionally-rich custom web applications.</p>
                            <div className="btn-group"><a href="tel:+02614054978" className="as-btn style3">Call Now<i
                                className="fa-regular fa-arrow-right ms-2"></i></a>
                                {/* <div className="call-btn"><a href="#!"
                                    className="play-btn popup-video codeigniter-play"><i className="fas fa-play"></i></a>
                                    <div className="media-body"><a href="#!" onClick={(e) => this.openModal(e)}
                                        className="btn-title popup-video">Watch Our Video</a> <span className="btn-text">Subscribe
                                            Now</span></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="triangle-1"></div>
                    <div className="triangle-2"></div>
                    <div className="hero-shape2"><img src="assets/img/hero/hero_shape_2_2.png" alt="shape" /></div>
                    <div className="hero-shape3"><img src="assets/img/hero/hero_shape_2_3.png" alt="shape" /></div>
                </div>
                <section className="why-choose-puzzle pt-100 pb-100">
					<div className="container">
						<div className="row">
							<div className="col-md-12 col-lg-6 col-xl-6">
								<div className="why-puzzle-text mt-0">
									<h2 className="text-white">More Than 6+ Years Codeigniter Experience Team</h2>
									<p className="text-white">As a leading website development company, we believe that your website should support your business goals. </p>
									<p className="text-white"> It takes more than just technical skills to create a digital product that becomes an asset to you.</p>
								</div>	
							</div>
							<div className="col-md-12 col-lg-6 col-xl-6">
								<div className="row">
									<div className="col-md-6 mt-20 mt-5">
										<div className="grd-border codelgnitor-grid text-center">
											<h1 className="text-white">20 +</h1>											
											<p className="text-white">Team of Experts</p>
										</div>
									</div>
									<div className="col-md-6 mt-20 mt-5">
										<div className="grd-border codelgnitor-grid text-center">											
											<h1 className="text-white">6 +</h1>										
											<p className="text-white">Years of Experience</p>										
										</div>
									</div>															
								</div>
							</div>
						</div>
					</div>
				</section>              
                <section className="space">
                    <div className="container">
                         <div className="title-codle">
                            <h2 className="text-center mb-40">CodeIgniter Development Services</h2>    
                            <p className="pt-20">
                                OEngines has excellence in Codeigniter development services. We have experience with 7 + years in web development. Oengines is a Codeigniter Development company that offers various Codeigniter development services.
                            </p>    
                         </div>
                         <div className="codel-services row">
                            <ul className="col-xl-4 col-md-6 col-sm-6 col-auto">
                                <li className="code-serv-list">Attendance Management System</li>
                                <li className="code-serv-list">A Trusted Choice</li>
                                <li className="code-serv-list">Access a World of Information</li>
                                <li className="code-serv-list">Airline Reservation System</li>
                                <li className="code-serv-list">An Ultimate Web Site for Job Seekers</li>
                                <li className="code-serv-list">AP Genco Mailing System</li>
                                <li className="code-serv-list">Automobile Workshop System</li>
                                <li className="code-serv-list">Blood Bank Management System</li>
                                <li className="code-serv-list">Blood Donation Agent System</li>
                                <li className="code-serv-list">Boob Tube Flourish</li>
                                <li className="code-serv-list">Budget Planner</li>
                                <li className="code-serv-list">Bug Tracker Management System</li>
                                <li className="code-serv-list">Bug Tracking System</li>
                                <li className="code-serv-list">Bus Transport Management System</li>
                                <li className="code-serv-list">Call Support System</li>
                                <li className="code-serv-list">Campus Selection Process</li>
                                <li className="code-serv-list">Cargo Logistics</li>
                                <li className="code-serv-list">Carrier Path System</li>
                                <li className="code-serv-list">CD Library Management System</li>
                                <li className="code-serv-list">Chat application widget</li>
                                <li className="code-serv-list">Citizen Card System</li>
                                <li className="code-serv-list">Client Server Based Live Meeting</li>
                                <li className="code-serv-list">Collage feedback system</li>
                                <li className="code-serv-list">College Mailing System</li>
                                <li className="code-serv-list">College Management System</li>
                                <li className="code-serv-list">Comparative entity mining</li>
                                <li className="code-serv-list">Cool Cab Services</li>
                                <li className="code-serv-list">Corporate Training</li>
                                <li className="code-serv-list">Courier Information System</li>
                                <li className="code-serv-list">Crime Reporting System</li>
                                <li className="code-serv-list">Customer Query Track</li>
                                <li className="code-serv-list">Customer Query Tracking System</li>
                                <li className="code-serv-list">Customer Service Desk</li>
                                <li className="code-serv-list">Customer Service Desk</li>
                                <li className="code-serv-list">Cyber Hotelier</li>
                                <li className="code-serv-list">Defect Tracking system</li>
                                <li className="code-serv-list">Detecting duplicate record in network</li>
                                <li className="code-serv-list">Developer Activity Monitor</li>
                                <li className="code-serv-list">Digital EYE Ad Designer Services</li>
                                <li className="code-serv-list">Distance Erudition (Learning) System</li>
                                <li className="code-serv-list">Distributed Computing for E-Learning</li>
                                <li className="code-serv-list">Dynamic database creation</li>
                                <li className="code-serv-list">Dynamic Query Forms for Database Queries</li>
                                <li className="code-serv-list">Dynamic Survey Management System</li>
                                <li className="code-serv-list">E- Voting</li>
                                <li className="code-serv-list">E-Banking System</li>
                                <li className="code-serv-list">E-Complaints (Police)</li>
                                <li className="code-serv-list">E-Greeting Threshold</li>
                                <li className="code-serv-list">E-Handbook</li>
                                <li className="code-serv-list">E-Post Office</li>
                                <li className="code-serv-list">E-Postal Service Network</li>
                                <li className="code-serv-list">E-Voting For Association</li>
                                <li className="code-serv-list">Easy Leave</li>
                            </ul>      
                            <ul className="col-xl-4 col-md-6 col-sm-6 col-auto">                                                              
                                <li className="code-serv-list">Easy Leave Management Tool</li>
                                <li className="code-serv-list">Education Loan Management System</li>
                                <li className="code-serv-list">Efficient two server password authentication key exchange</li>
                                <li className="code-serv-list">Email Relay Services</li>
                                <li className="code-serv-list">Email System</li>
                                <li className="code-serv-list">Employee Monitoring System</li>
                                <li className="code-serv-list">Encrypted Register Form</li>
                                <li className="code-serv-list">Eye Tracking System (Window Application)</li>
                                <li className="code-serv-list">Faculty Voting /feedback by students</li>
                                <li className="code-serv-list">File Transfer Using Cryptographic Techniques</li>
                                <li className="code-serv-list">File transfers using cryptography method</li>
                                <li className="code-serv-list">Fin Corp -Cash Controller</li>
                                <li className="code-serv-list">Financial Forecast System</li>
                                <li className="code-serv-list">Find My Buddy</li>
                                <li className="code-serv-list">Flaw Tracking System</li>
                                <li className="code-serv-list">Food Ordering System</li>
                                <li className="code-serv-list">Franchisee Management System</li>
                                <li className="code-serv-list">FriendsworldCoIn</li>
                                <li className="code-serv-list">Fully anonymous profile matching</li>
                                <li className="code-serv-list">Hospital management System</li>
                                <li className="code-serv-list">Hospital management System</li>
                                <li className="code-serv-list">Hotel Administration & Management</li>
                                <li className="code-serv-list">Human Resource Management System</li>
                                <li className="code-serv-list">Image Transfer using cryptography key</li>
                                <li className="code-serv-list">Insurance on Internet</li>
                                <li className="code-serv-list">Job Portal System</li>
                                <li className="code-serv-list">Jobbing Portcullis</li>
                                <li className="code-serv-list">Key Management for Content Integration</li>
                                <li className="code-serv-list">library management system</li>
                                <li className="code-serv-list">Local Disk on Web</li>
                                <li className="code-serv-list">Mail Client</li>
                                <li className="code-serv-list">Medical Care Management System</li>
                                <li className="code-serv-list">Mingle Box</li>
                                <li className="code-serv-list">Minimizing File Downloading using File Splitting</li>
                                <li className="code-serv-list">Moto Trader Auto Classified System</li>
                                <li className="code-serv-list">Multi storage files with different database</li>
                                <li className="code-serv-list">Multiparty access control of social network</li>
                                <li className="code-serv-list">Online Counseling</li>
                                <li className="code-serv-list">Online Crime Report</li>
                                <li className="code-serv-list">Online Examination System</li>
                                <li className="code-serv-list">Online Medicine Description/Information</li>
                                <li className="code-serv-list">Online No Due System</li>
                                <li className="code-serv-list">Online Personal diary</li>
                                <li className="code-serv-list">Online Property buys and sells</li>
                                <li className="code-serv-list">Online Recruitment Process</li>
                                <li className="code-serv-list">Online Recruitment System</li>
                                <li className="code-serv-list">Online Secure File Transfer System</li>
                                <li className="code-serv-list">Online Shopping Cart</li>
                                <li className="code-serv-list">Online shopping Cart System</li>
                                <li className="code-serv-list">Online shopping minor project</li>
                                <li className="code-serv-list">Online Shopping System</li>
                               
                            </ul>     
                            <ul className="col-xl-4 col-md-6 col-sm-6 col-auto">                                                        
                                <li className="code-serv-list">Online Social Networking Site</li>
                                <li className="code-serv-list">Online Tourist management system</li>
                                <li className="code-serv-list">Patient Management System</li>
                                <li className="code-serv-list">Personal Assistant</li>
                                <li className="code-serv-list">Pharmacy management System</li>
                                <li className="code-serv-list">Phishing website</li>
                                <li className="code-serv-list">Pre-Leave Management System</li>
                                <li className="code-serv-list">Privacy Preserving Data Sharing With Anonymous ID Assignment</li>
                                <li className="code-serv-list">Project Controller</li>
                                <li className="code-serv-list">Project Scheduler</li>
                                <li className="code-serv-list">Property Search</li>
                                <li className="code-serv-list">Providing security to the user data by using encryption technique</li>
                                <li className="code-serv-list">Question Answering System for an Effective Collaborative Learning</li>
                                <li className="code-serv-list">Random Password Generation System with mailing</li>
                                <li className="code-serv-list">Recognition Handwriting Character System (Window Application)</li>
                                <li className="code-serv-list">Recruitment Processing System</li>
                                <li className="code-serv-list">Remote Networking Service</li>
                                <li className="code-serv-list">Reservation rent Car</li>
                                <li className="code-serv-list">Revisiting defense against large scale online password attaches</li>
                                <li className="code-serv-list">School Management System</li>
                                <li className="code-serv-list">Searching a image from websites</li>
                                <li className="code-serv-list">Secure online Banking using Antiphishing</li>
                                <li className="code-serv-list">Sensitive label of privacy protection on cloud storage</li>
                                <li className="code-serv-list">Shopping Cart</li>
                                <li className="code-serv-list">Single sign in technique in network</li>
                                <li className="code-serv-list">SMS Sender via connected mobile phone (Window Application)</li>
                                <li className="code-serv-list">SMTP Mail Server</li>
                                <li className="code-serv-list">Statistical Entity Extraction from Web</li>                                
                                <li className="code-serv-list">Student Information System</li>
                                <li className="code-serv-list">Student Management System (SMS)</li>
                                <li className="code-serv-list">Students secure file storagem</li>
                                <li className="code-serv-list">Supporting search-as-you-type using sql in databases</li>
                                <li className="code-serv-list">Sybille detection of social networking</li>
                                <li className="code-serv-list">CODEIGNITER Project on Tax Information system</li>
                                <li className="code-serv-list">Tax summary or Tax Returns System</li>
                                <li className="code-serv-list">Training Portal Application</li>
                                <li className="code-serv-list">TV Broadcasting (Window Application)</li>
                                <li className="code-serv-list">Two Tail of privacy preserving on online social network</li>
                                <li className="code-serv-list">Vehicle Hiring System</li>
                                <li className="code-serv-list">Warehouse Management System</li>
                                <li className="code-serv-list">Web based Defect Tracking System</li>
                                <li className="code-serv-list">Web Based Mail Service</li>
                                <li className="code-serv-list">Website Ranking System</li>
                                <li className="code-serv-list">Xml data migration</li>                                
                            </ul>        
                         </div>
                    </div>
                </section>     

                <div className="bg-smoke" style={mystyletow}>
                    <div className="container-fluied">
                        <div className="row ctn-from">
                            <div className="col-xl-6">
                                <div className="title-area mb-35 text-xl-start text-center">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <span className="sub-title"><img
                                    src="../assets/img/theme-img/title_shape_2.svg" alt="shape" />contact with us!</span>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <h2 className="sec-title">Drop Us a Line</h2>
                                </ScrollAnimation>  
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <p className="sec-text">Reach Out to us from our Contact form and we will get back to you shortly.</p>
                                </ScrollAnimation>
                                </div>
                                <form  onSubmit={this.onSubmit} method="post" className="contact-forms ajax-contact">
                                    <div className="row">
                                        <div className="form-group col-md-6" >
                                            <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" style={{backgroundColor: "#FFFFFF"}}/> 
                                            <i className="fal fa-user"></i>   
                                            {nameError &&
                                            <div className="invalid-feedback" >{nameError}</div>
                                            }                                                     
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} style={{backgroundColor: "#FFFFFF"}} autoComplete="Off"/> 
                                            <i className="fal fa-envelope"></i>
                                            {emailError &&
                                                <div className="invalid-feedback">{emailError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">                                        
                                            <input type="text" id="subject"  className={subjectError ? "form-control is-invalid" : "form-control"} ref="subjectfield" name="subject" placeholder="Subject" onChange={this.handleChange} style={{backgroundColor: "#FFFFFF"}} autoComplete="Off"/>               
                                            <i className="fal fa-note-sticky"></i>                              
                                            {subjectError &&
                                                <div className="invalid-feedback">{subjectError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} onKeyDown={(evt) =>   ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} name="number" value={this.state.number} ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off"   onChange={this.maxlengthnumber}/>
                                            <i className="fal fa-phone"></i>
                                            {numberError &&
                                                <div className="invalid-feedback">{numberError}</div>
                                            }
                                        </div>                                                                           
                                        <div className="form-group col-12">
                                            <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                            className={messageError ? "form-control is-invalid" : "form-control"}  placeholder="Your Message" onChange={this.handleChange} style={{backgroundColor: "#FFFFFF"}} autoComplete="Off">
                                            </textarea> 
                                            <i className="fal fa-comment"></i>
                                            {messageError &&
                                                <div className="invalid-feedback">{messageError}</div>
                                            }
                                        </div>
                                        <div className="form-btn text-xl-start text-center col-12 cont-btn">
                                            <button type="submit" className="as-btn style3">Send Details
                                                <i className="fa-regular fa-arrow-right ms-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3"></p>
                                </form>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <section className="app-service-box artificial-solutions">
                    <div className="container wb-tab-title">
                        <div className="row">
                            <div className="banner-cont cmn-title">
                                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                    <h2 className="heading-2">Our Range CodeIgniter Website Development Services</h2>
                                    <p>Oengines Games is a renowned Codeigniter development company offering unprecedented Codeigniter framework development and design services to help you build the perfect online business platform most efficiently. We have proven experience in using CodeIgniter web development services to provide innovative web solutions.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <div className="services_list">
                                    <div className="circul-box color-icon-box-main"> <span className="color-icon-box bg-blue"><i className="wds-spt wds-icon10"></i></span> </div>
                                    <h3 className="heading-3">Customization Services</h3>
                                    <div className="services_list_content">
                                        <p>We build highly functional and customer applications with a suite of features such as custom templates, plugins, and modules to amplify the functionalities of the application.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <div className="services_list">
                                    <div className="circul-box color-icon-box-main"> <span className="color-icon-box bg-green"><i className="wds-spt wds-icon11"></i></span> </div>
                                    <h3 className="heading-3">Web Development</h3>
                                    <div className="services_list_content">
                                        <p>Expertise in creating high-quality CodeIgniter web applications and advanced portals that are fully functional and add value to a business enterprise.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <div className="services_list">
                                    <div className="circul-box color-icon-box-main"> <span className="color-icon-box bg-purple"><i className="wds-spt wds-icon12"></i></span> </div>
                                    <h3 className="heading-3">eCommerce Development</h3>
                                    <div className="services_list_content">
                                        <p>Creating a secure and flexible CodeIgniter eCommerce open-source website with browsing, checkout, secure payments, shipping integration, and more.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <div className="services_list">
                                    <div className="circul-box color-icon-box-main"> <span className="color-icon-box bg-blue"><i className="wds-spt wds-icon13"></i></span> </div>
                                    <h3 className="heading-3">Migration Solutions</h3>
                                    <div className="services_list_content">
                                        <p>Smooth and hassle-free Codeigniter app migration services within minimum time and the powerful attributes of the Codeigniter framework.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <div className="services_list">
                                    <div className="circul-box color-icon-box-main"> <span className="color-icon-box bg-green"><i className="wds-spt wds-icon14"></i></span> </div>
                                    <h3 className="heading-3">Upgrade Services</h3>
                                    <div className="services_list_content">
                                        <p>We offer CodeIgniter upgrade services the involving installation of the latest version to modification of the plugins to ensure your web app functions robustly.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <div className="services_list">
                                    <div className="circul-box color-icon-box-main"> <span className="color-icon-box bg-purple"><i className="wds-spt wds-icon15"></i></span> </div>
                                    <h3 className="heading-3">Module &amp; Plugin <br /> Development</h3>
                                    <div className="services_list_content">
                                        <p>Create dynamic, interactive, and custom modules and plugins for clients across diverse industry verticals to improve web apps and increase user experiences.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="platform-area ptb-100">
                    <div className="container-fluid">   
                        <div className="section-title">
                            <span>HOW IT WORKS</span>
                            <h2>Codeigniter Developement Cycle</h2>
                            <p>Have a Look at our seamless Implementation Process!</p>
                        </div>

                        <div className="tab platform-list-tab">
                            <div className="row align-items-center">
                                <div className="col-lg-3">
                                    <ul className="tabs">
                                        <li>
                                            <a href="#" className="bg-f12711">
                                                <i className='bx bx-right-arrow-alt'></i>
                                                1.Requirement Process
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="bg-0067ff">
                                                <i className='bx bx-right-arrow-alt'></i>
                                                2.Planning Process
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="bg-1b2b37">
                                                <i className='bx bx-right-arrow-alt'></i>
                                                3.Designing
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="bg-f12711">
                                                <i className='bx bx-right-arrow-alt'></i>
                                                4.Development Process
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="bg-0067ff">
                                                <i className='bx bx-right-arrow-alt'></i>
                                                5.Testing
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="bg-1b2b37">
                                                <i className='bx bx-right-arrow-alt'></i>
                                                6.Release & Evolution
                                            </a>
                                        </li>
                                        
                                    </ul>
                                </div>

                                <div className="col-lg-9">
                                    <div className="tab_content">
                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image1.jpg" alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Requirement Process</h2>

                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>                                                                    
                                                                    <p>Firstly, we study your app idea, your services, and your target audience. In this phase, we collect a variety of data to make the perfect application for you.</p>

                                                                    <a href="#" className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                        
                                                                    </a>
                                                                </div>
                                                            </div>

                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image2.jpg" alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Planning Process</h2>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>                                                                   
                                                                    <p>Puting all plan together based on the requirements gathered and finalizing it.</p>
                                                                    <a href="#" className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                        
                                                                    </a>
                                                                </div>
                                                            </div>                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image3.jpg" alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Designing</h2>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>                                                                   
                                                                    <p>Our adept developers will design and create the app, employing techniques to ensure usability all the while.</p>
                                                                    <a href="#" className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                        
                                                                    </a>
                                                                </div>
                                                            </div>                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image5.jpg" alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Development Process</h2>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>                                                                   
                                                                    <p>After the design is finalized, our experts will dedicatedly work towards the development of the web app.</p>
                                                                    <a href="#" className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                    </a>
                                                                </div>
                                                            </div>                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image4.jpg" alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Testing</h2>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>                                                                   
                                                                    <p>Before launch, our team employs the best testing process to ensure positive feedback with a plethora of testing techniques.</p>
                                                                    <a href="#" className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                    </a>
                                                                </div>
                                                            </div>                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                       
                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image4.jpg" alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Release & Evolution</h2>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>                                                                   
                                                                    <p>After completing all task and testing we Release the responsive creation to the world.</p>
                                                                    <a href="#" className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                    </a>
                                                                </div>
                                                            </div>                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
               

              

                <section className="sales-area pt-100 pb-70">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="sales-content">
                                    <span>Company Benefits</span>
                                    <h2>Get Top-Notch Codeigniter Development Services</h2>
                                    <p>OEngines games is a fastest growing Web and Mobile Development Company based in India, since 2017. We are professional, experienced and forward thinking, and our great strength is decoding our client’s needs into effective solutions.</p>

                                    <ul className="list">
                                        <li>
                                            <i className="fa-solid fa-arrow-right"></i>
                                            Proficient team
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-arrow-right"></i>
                                            High-performance and intuitive design
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-arrow-right"></i>
                                            Streamlined development process
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-arrow-right"></i>
                                            Keeping the user in mind
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="sales-box">
                                            <div className="image">
                                                <img src="assets/img/icon/shopping.png" alt="image" />
                                            </div>
                                            <h3>Managed Team Extensions</h3>
                                            <p>Our managed team extensions offer complete transparency and follow specially designed processes inspired by proven methodologies for improved project delivery.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="sales-box">
                                            <div className="image">
                                                <img src="assets/img/icon/numbered.png" alt="image" />
                                            </div>
                                            <h3>Digital Product Development</h3>
                                            <p>We don't just develop IT solutions; We engineer complex digital products that foster innovation and support transformative change.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="sales-box">
                                            <div className="image">
                                                <img src="assets/img/icon/collaboration.png" alt="image" />
                                            </div>
                                            <h3>Tech-Driven Business Transformation</h3>
                                            <p>We understand your unique needs and guide you to transform your business using the latest technologies to help you adapt and overcome the dynamic business environment.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="shape">
                        <img src="assets/img/shape/white-shape2.png" alt="image" />
                    </div>
                </section>

                <section className="space" id="project-card">
                    <div className="container">
                        <div className="title-area text-center">
                            <div className="shadow-title">PROJECTS</div><span className="sub-title"><img
                                src="assets/img/theme-img/title_shape_2.svg" alt="shape" />LATEST PROJECTS</span>
                            <h2 className="sec-title">Our Recent Latest <span className="text-theme">Projects</span></h2>
                        </div>
                        <div className="row slider-shadow as-carousel" id="projectSlide2" data-slide-show="3" data-lg-slide-show="3"
                            data-md-slide-show="2" data-sm-slide-show="1" data-arrows="true">
                            <OwlCarousel items={3}
                                className="owl-theme"
                                loop
                                autoplay={true}
                                margin={2} {...settings}>
                                <div className="">
                                    <div className="project-grid">
                                        <div className="project-grid_img"><img src="https://blog.gamedev.tv/content/images/size/w1000/2022/12/pexels-max-deroin-51415.jpg" alt="project image" />
                                            <a href="https://blog.gamedev.tv/content/images/size/w1000/2022/12/pexels-max-deroin-51415.jpg" className="play-btn style3 popup-image"><i
                                                className="far fa-plus"></i></a></div>
                                        <div className="project-grid_content">
                                            <h3 className="box-title"><a>What Game Should I Make First?</a></h3>
                                            <p className="project-grid_text">Distinctively enable premium expertise for multifunctional
                                                action items. Energistically benchmark worldwide communities.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="project-grid">
                                        <div className="project-grid_img"><img src="https://blog.gamedev.tv/content/images/size/w2000/2022/11/pixel-art-course-image-without-logo.jpg" alt="project image" />
                                            <a href="https://blog.gamedev.tv/content/images/size/w2000/2022/11/pixel-art-course-image-without-logo.jpg" className="play-btn style3 popup-image"><i
                                                className="far fa-plus"></i></a></div>
                                        <div className="project-grid_content">
                                            <h3 className="box-title"><a>How To Draw Pixel Art</a></h3>
                                            <p className="project-grid_text">Distinctively enable premium expertise for multifunctional
                                                action items. Energistically benchmark worldwide communities.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="project-grid">
                                        <div className="project-grid_img"><img src="https://blog.gamedev.tv/content/images/size/w1000/2022/12/pexels-max-deroin-51415.jpg" alt="project image" />
                                            <a href="https://blog.gamedev.tv/content/images/size/w1000/2022/12/pexels-max-deroin-51415.jpg" className="play-btn style3 popup-image"><i
                                                className="far fa-plus"></i></a></div>
                                        <div className="project-grid_content">
                                            <h3 className="box-title"><a>Node js1</a></h3>
                                            <p className="project-grid_text">Distinctively enable premium expertise for multifunctional
                                                action items. Energistically benchmark worldwide communities.</p>
                                        </div>
                                    </div>
                                </div>

                            </OwlCarousel>
                        </div>
                    </div>
                </section>

                {careersec}
                           
                <div _ngcontent-mfs-c21="" className="header-lines-decoration">
                    <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                    <span _ngcontent-mfs-c21="" className="bg-blue"></span>
                    <span _ngcontent-mfs-c21="" className="bg-white-light"></span>
                    <span _ngcontent-mfs-c21="" className="bg-orange-light"></span>
                    <span _ngcontent-mfs-c21="" className="bg-red"></span>
                    <span _ngcontent-mfs-c21="" className="bg-green"></span>
                    <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                </div>
               
                <Footer />

            </>
        )
    }
}

export default Codelnitorpage;

