import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import Header from "../../GameServiceHeader";
import Footer from '../../over_games/Footer';
import '../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import config from '../../config.json';
import OwlCarousel from 'react-owl-carousel';


var mystyleone = {
	backgroundImage:
	"url('../assets/img/bg/cta_bg_2.jpg')"
};

export default function CardGame() {
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {

		var str = window.location.pathname;
		let Pathname = str.substring(1);
		console.log("pathname is:::::::::::::::::", Pathname);

		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);	
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  

	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Card'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};



	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }
	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />

			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
						
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>

			<section id="hero" className="bg-scroll-card hero-section division">
				<div className="container">
					<div className="row card-her pt-100 pb-100">
						<div className="col-md-6 col-lg-6 arcade-col">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Card Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color max-conent">Oengines is a leading card game development company that has expertise in building next-gen applications for Android, iOS, Mac, Windows, and all the popular
										platform.
									</p>
									<p className="p-xl white-color max-conent">Discuss your goal with our dedicated resources and get to start your gaming business now.
									</p>
								</ScrollAnimation>
							</div>
							<button className="theme-button mt-4"><a href="tel:+02614054978" ><strong>Call Now</strong></a></button>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="hero-17-img video-preview">
								<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
									<img className="img-fluid card-img" src="../assets/img/GamesImg/Rummy_images/dashboard-04.png" alt="hero-image" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>			
			</section>
			<section className="cardgame-content mt-80 mb-90">
				<div className="container">
					<div className="cardgame-main-content">
						<h2 className="text-center mb-20">Card Game</h2>
						<p className="mt-30 text"><span className="startuptitle-first-letter">P</span>laying cards are used around the world in games that range from simple
							children's activities to high stakes gambling in casino games, and world
							championships in forms such as bridge. Playing cards can be regarded as
							randomization devices, similar to dice or roulette wheels, in that they are
							shuffled (randomized) prior to their distribution to players. A few simple card
							games involve pure chance, with winning and losing depending on who gets the
							"best" and "worst" collection of cards at distribution, while most card games
							involve strategy in that players can choose to add cards to their hands, discard
							some and add others, or make some other wise attempt to improve their odds
							of winning through decision making.</p>
					</div>
				</div>
			</section>

			<section className="Solutions pb-50 pt-50">
				<div className="container">
					<div className="cardgame-title pt-50">
						<h2 className="text-white text-center">Our Card Game Development Services</h2>
						<p className="text-white">Our professional developer's team integrates multi-functional features and other required functionalities into the games.

Utilizing state of the art technology and methodologies help us to ace the user market of online card gaming. 
Our team has been updating every function and feature with the client symmetrical in the open market to enhance the business lifecycle.</p>
					</div>
					<div className="row mt-50 cardgame-main">
						<div className="col-md-4 col-sm-6 col-xl-4 col-lg-4">
							<div className="d-flex">
								<img src="../assets/img/GameDevelopment/poker-game-card-de.svg" alt="img not found" />
								<h3 className="text-white ml-20">Online Multiplayer Game</h3>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-xl-4 col-lg-4">
							<div className="d-flex">
								<img src="../assets/img/GameDevelopment/poker-game-card-de.svg" alt="img not found" />
								<h3 className="text-white ml-20">Casino Card Game</h3>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-xl-4 col-lg-4">
							<div className="d-flex">
								<img src="../assets/img/GameDevelopment/poker-game-card-de.svg" alt="img not found" />
								<h3 className="text-white ml-20">Skill Based Card Games</h3>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-xl-4 col-lg-4">
							<div className="d-flex">
								<img src="../assets/img/GameDevelopment/poker-game-card-de.svg" alt="img not found" />
								<h3 className="text-white ml-20">Payment Security</h3>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-xl-4 col-lg-4">
							<div className="d-flex">
								<img src="../assets/img/GameDevelopment/poker-game-card-de.svg" alt="img not found" />
								<h3 className="text-white ml-20">Risk Management</h3>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-xl-4 col-lg-4">
							<div className="d-flex">
								<img src="../assets/img/GameDevelopment/poker-game-card-de.svg" alt="img not found" />
								<h3 className="text-white ml-20">Multi-Language</h3>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-xl-4 col-lg-4">
							<div className="d-flex">
								<img src="../assets/img/GameDevelopment/poker-game-card-de.svg" alt="img not found" />
								<h3 className="text-white ml-20">Back Office & CRM / Dashboard</h3>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-xl-4 col-lg-4">
							<div className="d-flex">
								<img src="../assets/img/GameDevelopment/poker-game-card-de.svg" alt="img not found" />
								<h3 className="text-white ml-20">Rewards & Loyalty</h3>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-xl-4 col-lg-4">
							<div className="d-flex">
								<img src="../assets/img/GameDevelopment/poker-game-card-de.svg" alt="img not found" />
								<h3 className="text-white ml-20">Anti Fraud System</h3>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-xl-4 col-lg-4">
							<div className="d-flex">
								<img src="../assets/img/GameDevelopment/poker-game-card-de.svg" alt="img not found" />
								<h3 className="text-white ml-20">Responsible Gaming</h3>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="cardgame_modes pb-100">
				<div className="container">
					<div className="cardgame-title pt-50">
						<h2 className="text-center">Card Game Modes</h2>
						<p className="mt-20">Our team is ready to deliver an amazing card gaming experience to your user in various modes. Leveraging some of the most advanced and secure tech-stacks that helps us elevate the gaming performance and engagement quality of our products</p>
					</div>
					<div className="row">
						<div className="col-md-4 col-xl-4 col-xxl-3 col-lg-4 col-sm-6">
							<div className="cardmode mt-50">
								<div className="cradmode-img">
									<img src="../assets/img/GameDevelopment/computer-Mode-card.svg" alt="img" />
								</div>
								<div className="crd-text">
									<h3>Computer Mode</h3>
									<p>Computer BOTs to train help you grind hard and make the ends meet, increase your experience through highly
									randomized BOTs in the computer mode.</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-xl-4 col-xxl-3 col-lg-4 col-sm-6">
							<div className="cardmode mt-50">
								<div className="cradmode-img">
									<img src="../assets/img/GameDevelopment/local-mode-card.svg" alt="img" />
								</div>
								<div className="crd-text">
									<h3>Local Mode</h3>
									<p>Local Mode for faster and safer gaming experience. Allowing your users to create a local room on a local server with a smoother data processing network</p>
								</div>
							</div>	
						</div>
						<div className="col-md-4 col-xl-4 col-xxl-3 col-lg-4 col-sm-6">
							<div className="cardmode mt-50">
								<div className="cradmode-img">
									<img src="../assets/img/GameDevelopment/private-mode-card.svg" alt="img" />
								</div>
								<div className="crd-text">
									<h3>Private Mode</h3>
									<p>A private room to keep your wins to yourself. Let your family and friends play on a network that eliminates the
									possibility of data-threats.</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-xl-4 col-xxl-3 col-lg-4 col-sm-6">
							<div className="cardmode mt-50">
								<div className="cradmode-img">
									<img src="../assets/img/GameDevelopment/online-multiplayer-card.svg" alt="img" />
								</div>
								<div className="crd-text">
									<h3>Online Multiplayer</h3>
									<p>Reach the leaderboard to win exciting prizes and participate online to explore events. Make friends, play, and
									earn with our multiplayer game.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="chs_oeg mt-80 mb-4">
				<div className="container">
					<div className="Oeg_main">
						<div className="row">
							<div className="col-md-6">
								<div className="Oeg_img">
									<img src="../assets/img/GameDevelopment/card-game1.png" alt="img not found" />
								</div>
							</div>
							<div className="col-md-6">
								<div className="Oeg_text">
									<h2>Why Choose Oengines</h2>
									<div className="elementor-divider style-left">
										<span className="elementor-divider-separator">
										</span>
									</div>
									<p className="mt-30 text">Oengines is a trustworthy Card Game Development Company specializing in developing a variety of card games. Our highly skilled Card Game Software Developers have delivered some of the most impressive card games including rummy games, casino games, Teen Patti, and several others. Nonetheless, we stand among those companies which offer high-quality gaming experiences to their user and are known for delivering services on time. Not only, we are experts in developing mobile card games but we also develop games for web applications.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="position-relative space">
				<div className="as-bg-img" style={mystyleone}>
					<img src="assets/img/bg/bg_overlay_1.png" alt="overlay"/>
				</div>
				<div className="container z-index-common">
					<div className="row justify-content-center">
						<div className="col-xl-6 col-lg-7 col-md-9 text-center">
							<div className="title-area mb-35"><span className="sub-title">
								<img src="assets/img/theme-img/title_shape_2.svg" alt="shape"/>CONTACT US</span>
								<h2 className="sec-title text-white">WANT TO HIRE OUR CARD<span
									className="text-theme fw-normal"> GAME DEVELOPERS?</span></h2>
							</div><a className="as-btn" onClick={handleShow}>GET IN TOUCH WITH US</a>
						</div>
					</div>
				</div>
			</section>

			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}
			<Footer />
		</div>

	);
}
