import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';



export default function Casual() {
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
	
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Casual'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};
	const handleChange = (event) => {

	};


	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }

	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>
			
			<Header />


			<section id="hero" className="casul-section hero-section division">
				<div className="container">
					<div className="row card-her pt-100 pb-100 text-center">
						<div className="col-md-12 col-lg-6">

						</div>
						<div className="col-md-12 col-lg-6 text-lg-end text-center text-sm-center text-md-center">
							<div className="hero-17-title mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl text-lg-end text-center text-sm-center text-md-center">Casual Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt action-hero-17-txt pe-0">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl max-conent m-auto mt-40 text-end">OEngines is the best casual game development company in India. We can offer simplistic gameplay mechanics and minimalist graphics, designed for a wide audience. Our games undergo thorough testing to ensure ease of use and a seamless experience.</p>
								</ScrollAnimation>
							</div>
							<a href="tel:+02614054978" ><button className="theme-button casual-theme-btn mt-50">Call Now</button></a>
						</div>
				
					</div>
				</div>
			</section>
        
			<section id="choose_poker" className="why-poker pt-80 pb-100">
				<div className="container">
					<div className="poker-choose-descs mb-50">
						<h2 className="text-center mb-35">Casual Game</h2>
						<div className="elementor-divider">
							<span className="elementor-divider-separator">
							</span>
						</div>	
						<p className="text-center mt-30 text">
                        Casual games are a mobile game genre that is designed for everyone and anyone and have simple rules, shorter
						sessions, and a low barrier to entry. Casual games employ a number of monetization strategies; however, they tend to rely on a free-to-play model that monetizes through in-app purchases and in-game ads. </p>
						<ul className="list-ul">							
							<h3>Advantages of Casual Game</h3>
							<li>Help to relieve stress</li>
							<li>Improve awareness of your surroundings</li>
							<li>Give you the chance to keep on learning</li>							
						</ul>
					</div>					
				</div>
			</section>

			<section className="service-uniq pb-100">
				<div className="container">
					<div className="service-uniq-title">
						<h2 className="text-center">Our Casual Game Development Services</h2>
					</div>

					<div className="row mt-40">
						<div className="col-md-6">
							<div className="service-uniq-box d-flex mt-35">
								<div className="word-icon">
									<img src="../assets/img/GameDevelopment/Seamless-Performing-Apps.png" alt="img" />
								</div>
								<div className="word-serv-text">
									<h3>Full-Cycle Hyper Casual Game Development</h3>
									<p>Super simple in terms of gameplay, but incredibly addictive, engaging and unputdownable games: this is what we propose when we talk about full cycle hyper casual games development. A wide range of game mechanics, bright styles, approaches and types of monetization – how would you like to see your future incredibly successful game?</p>
								</div>
							</div>
							<div className="service-uniq-box d-flex mt-35">
								<div className="word-icon">
									<img src="../assets/img/GameDevelopment/End-to-End-Support.png" alt="img" />
								</div>
								<div className="word-serv-text">
									<h3>Future-Proof Architecture</h3>
									<p>Our experts create a game architecture that allows content to scale. By conducting post-launch analytics and evaluating the viability of hyper casual mobile games, we ensure that game content is regularly updated to keep the player engaged by creating additional challenges, characters and more.</p>
								</div>
							</div>
							<div className="service-uniq-box d-flex mt-35">
								<div className="word-icon">
									<img src="../assets/img/GameDevelopment/Detailed-Analysis.png" alt="img" />
								</div>
								<div className="word-serv-text">
									<h3>Post Release Support</h3>
									<p>We continue to provide support for the game after its release. This is done by tracking game behavior, monitoring reviews, fixing bugs, functions of game elements that are not suitable for players, redesigning individual elements, improving gameplay based on feedback from players and customers.</p>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="service-uniq-box d-flex mt-35">
								<div className="word-icon">
									<img src="../assets/img/GameDevelopment/Detailed-Analysis.png" alt="img" />
								</div>
								<div className="word-serv-text">
									<h3>Hyper Casual Game Art Asset Creation</h3>
									<p>The creative team at OEngines Games takes a unique approach to each project in order to develop vibrant graphics, lively animation and engaging gameplay elements that will hold the player's attention until the end of the game session. All hyper casual game elements are superbly optimized for maximum performance.</p>
								</div>
							</div>
							<div className="service-uniq-box d-flex mt-35">
								<div className="word-icon">
									<img src="../assets/img/GameDevelopment/Immense-Experience.png" alt="img" />
								</div>
								<div className="word-serv-text">
									<h3>Testing</h3>
									<p>Our testers provide accurate and effective quality control of the game and align its parameters with the development goals. Performance, gameplay, UX/UI, device compatibility, social integration and other aspects are under our complete control.</p>
								</div>
							</div>						
						</div>
					</div>	 			
				</div>
			</section>				

			<section className="custom_website_com_pad partner_with_best pt-80 pb-80">
				<div className="container">
					<div className="partner_with_best_sec row">
						<div className="partner_written_sec col-md-6">
							<h2 className="custom_website_heading_2 custom_website_app_heading_2">Why Choose OEngines</h2>
							<p className="custom_website__paragraph mt-30 text">OEngines Games has a qualified team of Casual game developers who have expertise in developing dynamic and diverse Casual games that will attract a large audience.</p>
							<p className="custom_website__paragraph mt-30 text">We use the best mechanics of the game, and we merge them into 3D graphics to provide the best quality game experience. With various achievements, simple gameplay, and addictive experience, our games provide the ultimate Casual experience for the users..</p>
							<h3 className="mt-20 mb-20">Types of Casual Games</h3>		
							<div className="d-flex">
								<ul className="custom_website_unordered_list partner_list">
									<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2">Arcade</p></li>
									<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2">Action</p></li>
									<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2">Card and board game</p></li>
									<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2">Word and trivia</p></li>
									<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2">Hidden object</p></li>
								</ul>
								<ul className="ml-40">
									<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2">Adventure</p></li>
									<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2"> Education</p></li>
									<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2">Puzzle</p></li>
									<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2">Search Games</p></li>
									<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2">Simulators</p></li>
								</ul>
							</div>												
						</div>
						<div className="partner_img_sec col-md-6">
							<img decoding="async" src="https://artoonsolutions.com/wp-content/themes/artoon/images/lp/custom_website_development_page/partner_img.webp" alt="partner-img" />
						</div>
					</div>
				</div>
			</section>

			<section className="home_services updated_services grey_bg common_spacing">
				<div className="container ">
					<div className="row">
						<div className="col-lg-12 col-sm-12">
							<div className="chose_rht title_h2_new wow fadeInUp" data-wow-delay="0.2s" style={{visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp"}}>
								<h2 className="text-center">Advantages of creating Casual games with us</h2>
							</div>
						</div>
						<div className="col-lg-12 col-sm-12">
							<div className="chose_left pt-md-5 pt-4">
								<div className="row justify-content-center">
									<div className="col-lg-4 col-md-4 col-sm-6 col-12 col-xl-3">
										<div className="service_cols wow fadeInUp" data-wow-delay="0.2s" style={{visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp"}}>
											<a href="javascript;">
												<i><img src="https://assets.chicmic.in/wp-content/uploads/2023/04/Tap-Timing.svg" alt="Tap/Timing" /></i>
												<h3 className="d-flex align-items-center justify-content-between h5 pt-15">
													We are dedicated
												</h3>
												<p></p><p>We are a dedicated team of professionals with a lot of experience in development, trends analysis, 3D Art, game design, project management, and a big passion to create mobile game hits. We walked a hundred times through the game creation and publishing flow, that isn’t an issue for us.</p>
												<p></p>
											</a>
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-6 col-12 col-xl-3">
										<div className="service_cols wow fadeInUp" data-wow-delay="0.2s" style={{visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp"}}>
											<a href="javascript;">
												<i><img src="https://assets.chicmic.in/wp-content/uploads/2023/03/puzzleGame-1.svg" alt="Puzzle" /></i>
												<h3 className="d-flex align-items-center justify-content-between h5 pt-15">
													We are fast
												</h3>
												<p></p><p>Time is money. We appreciate our partner's time and would like to get results as fast as possible. So that we always do our best to reduce delivery times. Minimum time-wasting and no bullshit.</p>
												<p></p>
											</a>
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-6 col-12 col-xl-3">
										<div className="service_cols wow fadeInUp" data-wow-delay="0.2s" style={{visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp"}}>
											<a href="javascript;">
												<i><img src="https://assets.chicmic.in/wp-content/uploads/2023/04/swerve.svg" alt="Swerve" /></i>
												<h3 className="d-flex align-items-center justify-content-between h5 pt-15">
													We know what you need
												</h3>
												<p></p><p>We know how busy you are. That's why we are here. Our expertise allows us to analyze mobile users' needs and trends to create competitive market games. All processes in our studio aimed to get the best CTR, CPI, playtime, and retention in prototypes we produced.</p>
												<p></p>
											</a>
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-6 col-12 col-xl-3">
										<div className="service_cols wow fadeInUp" data-wow-delay="0.2s" style={{visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp"}}>
											<a href="javascript;">
												<i><img src="https://assets.chicmic.in/wp-content/uploads/2023/04/rythm.svg" alt="Rhythm-based" /></i>
												<h3 className="d-flex align-items-center justify-content-between h5 pt-15">
													We are flexible
												</h3>
												<p></p><p>We are always open to the partner’s requests and circumstances. There is no problem for us to work overtime or make advances for the partner to build long-term partnership and rapport.</p>
												<p></p>
											</a>
										</div>
									</div>
									
								</div>
							</div>
						</div>
					</div>				
				</div>
			</section>

			<section className="casual-contect pt-70 pb-70">
				<div className="container  pt-60 pb-60">
                    <div className="row">
                        <div className="hire-our-action-text racing-contect-mian text-center">
							<h4 className="text-center text-white m-auto">Hire Us</h4>
                            <h2 className="text-white text-center pt-20">Let’s develop your next Casual game hit jointly</h2>
                           <button className="theme-button mt-30" onClick={handleShow}>Contact Us</button>
                        </div>	
                    </div>
				</div>
			</section>
			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}
            
			<Footer />
		</div>

	);
}
