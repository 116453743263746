import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';

var settings = {
	dots: false,
	slidestoshow: 3,
	slidestoscroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
	initialslide: 0,
	responsiveClass: true,
	responsive: {
		0: {
			items: 1,
			nav: true
		},
		767: {
			items: 1,
			nav: true
		},
		1000: {
			items: 2,
			nav: true,
			loop: true
		}

	}
}

export default function Educational() {
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  	
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Educational'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};

	
	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }
	
	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>
			<Header />
		   
			<section id="hero" className="edu-section hero-section division">
				<div className="container">
					<div className="row card-her pt-100 pb-100 text-start">
						<div className="col-md-6 col-lg-6 edu-main">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl edu-color">Educational Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt action-hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color max-conent m-auto mt-40 edu-color mt-30 text">We are an educational game development company that creates exciting educational products for different audiences.With state-of-the-art technology tools, in-depth knowledge, and a proven track record, our team carefully analyzes the requirements for each project and applies an individual approach to achieve your goals and create content that enriches
										players with knowledge in various fields.</p>
								</ScrollAnimation>
							</div>
							<a href="tel:+02614054978" className=""><button className="btn-custom mt-50 edu-color edu-border">Call Now</button></a>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="edu-img">
								<img src="../assets/img/GameDevelopment/img-104.png" alt="img" />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="racing-game pt-80 pb-60">
				<div className="container">
					<div className="row">
						<div className="action-heaing col-md-7">
							<h2 className="text-start">Education Game</h2>
							<div className="elementor-divider racing-elementor-divider edu-elements">
								<span className="elementor-divider-separator">
								</span>
							</div>
							<p className="pt-10 mt-30 text text-start">Studying can often be a boring and mundane task for students and when they don’t have the motivation to study, their grades and test scores often suffer. Incorporating games into educational curriculum makes students more motivated and engaged in the lesson. Educational games are games explicitly designed for educational purposes, or which have incidental or secondary educational value. All types of games may be used in an educational environment, however educational games are games that are designed to help people learn about certain subjects, expand concepts, reinforce development, understand a historical event or culture, or assist them in learning a skill as they play. Game types include board, card, and video games.</p>
						</div>
						<div className="col-md-5">
							<div className="edu-game-image">
								<img src="../assets/img/GameDevelopment/img-10.svg" alt="img not found" />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="">
				<div className="services-2d">
					<div className="container">
						<div className="services-2d__head">
							<h2 className="services-2d__title text-center"><span>Our Educational </span> Game Development Services</h2>
							<p className="services-2d__text text-center m-auto">
								As an experienced educational game development company, we offer a full range of game development and post-release support services.<br />

							</p>
						</div>
						<div className="services-2d__list">
							<div className="row">
								<div className="col-12 col-md-6 col-lg-6 col-xl-4 col-sm-12 service-2d-wrapper">
									<a href="" className="service-2d">
										<div className="service-2d__bg">
											<div className="service-2d__icon"></div>
										</div>
										<h3 className="service-2d__title">
											Full-Cycle Educational Game Development
											<div className="service-2d__icon"></div>
										</h3>
										<p className="service-2d__text">Our team uses the most advanced tools and the most effective mechanics for educational game development: we are ready to create a game from scratch according to your concept or develop a concept together.
										</p>
									</a>
								</div>
								<div className="col-12 col-md-6 col-lg-6 col-xl-4 col-sm-12 service-2d-wrapper">
									<a href="" className="service-2d">
										<div className="service-2d__bg">
											<div className="service-2d__icon"></div>
										</div>
										<h3 className="service-2d__title">
											Educational Game Art and Animation Creation
											<div className="service-2d__icon"></div>
										</h3>
										<p className="service-2d__text">When creating art for educational games, we start from the chosen and approved style and create the most attractive characters and living environments for maximum player involvement and fun gameplay.
										</p>
									</a>
								</div>
								<div className="col-12 col-md-6 col-lg-6 col-xl-4 col-sm-12 service-2d-wrapper">
									<a href="" className="service-2d">
										<div className="service-2d__bg">
											<div className="service-2d__icon"></div>
										</div>
										<h3 className="service-2d__title">
											Educational Game Post-Release Support
											<div className="service-2d__icon"></div>
										</h3>
										<p className="service-2d__text">We continue to support and update the game after its release: this is done by tracking feedback, fixing bugs, implementing add-ons, and improving gameplay based on the information collected about the functioning of the game.
										</p>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="edu-game pt-30 pb-60">
				<div className="container">
					<div className="row">
						<div className="action-heaing col-md-7">
							<h2 className="text-start">Here are some benefits of Education Gaming</h2>

							<ul className="mt-40">
								<li>Increased concentration from playing first-person shooters like archery.</li>
								<li>Faster perception through observation.</li>
								<li>It helps improve memory capacity.</li>
								<li>Helping individuals who lack attention.</li>
								<li>Drastic changes in the hand-eye coordination.</li>
								<li>Improves problem-solving capacity</li>
							</ul>
						</div>
						<div className="col-md-5">
							<div className="edu-game-image">
								<img src="../assets/img/GameDevelopment/landing_service_edu_game_dev_1.jpg" alt="img not found" />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section>
				<div className="container">
					<div className="order-now-box">
						<div>
							<p className="small">Contact Us!</p>
							<h3>WANT TO HIRE OUR<span className="text-green"> EDUCATIONAL GAME DEVELOPERS?</span></h3>
						</div>
						<a onClick={handleShow} className="btn btn-contact-us scrollToBlock" attr-target="tell-us-form">GET IN TOUCH WITH US<i className=""><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="ico-flight-arrow">
							<g className="fa fa-paper-plane">
								<path d="M7 17L17 7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
								<path d="M7 7H17V17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
							</g>
							<g className="ico-flight-arrow-down">
								<path d="M7 17L17 7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
								<path d="M7 7H17V17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
							</g>
						</svg>
						</i></a>
					</div>
				</div>
			</section>

			<section className="ripenapps-fit-your-travel-app paddingBottom pt-80 pb-80">				
				<div className="container">
					<div className="fit-your-travels-inr-wrapper row">
						<div className="fyt-left-part col-md-4 col-sm-12 col-lg-6 col-xl-4">
							<div className="why-ripen-fit-apps aos-init aos-animate" data-aos="fade-right">
								<div className="ripen-logo-img aos-init aos-animate" data-aos="fade-down">
									<img src="../assets/img/GamesImg/logo-01.png" width="210px" height="46px" alt="ripenapps_logo" />
								</div>
								<div className="why-ripen-title">
									<h2><span>OEngines</span>  Games Advantages</h2>
									<p className="global_p">for Educational Game Development</p>
								</div>
								<div className="airoplane-img aos-init" data-aos="fade-up-right">
									<img src="https://ripenapps.com/assets/image/education.webp" width="179px" height="179px" alt="app_icon" />
								</div>
							</div>
						</div>
						<div className="fyt-right-part col-md-8 col-sm-12 col-lg-6 col-xl-8">
							<p className="global_p aos-init aos-animate" data-aos="fade-left">OEngines Games is a reputed Educational Game Development Company. We incorporate the best features and graphics in our game to increase the fun of the game, and to provide the best gaming experience to the users. Our team of expert developers uses the most advanced and robust tools, technologies, and engines to develop the most trendy and effective games for our clients. Here are some advantages of OEngines Educational Game Development </p>
							<div className="fit-app-reason aos-init aos-animate" data-aos="fade-up">
								<ul>
									<li>Educational Anti-Fraud System</li>
									<li>Smooth and Scalable Gameplay</li>
									<li>Easy to install and play Educational Game</li>
									<li>Support Cross-Platform Functionality</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>		

			<section className="why-us-sec">				
				<div className=" why-us">
					<div className="container">
						<div className="section-header">
							<h1 cla><span>Why</span> Choose Us ?</h1>
							<p>OEngines Game delivers robust and highly scalable web and mobile app development solutions leveraging benefits to its
							customers. We have good experience in developing educational apps on various platforms like Windows, Android, and iOS.</p>
						</div>
						<div className="section-body" data-aos="fade-up">
							<div className="" id="why-choose-slider">
								<div className="">
									<div className="row justify-content-center" style={{ transform: "translate3d(0px, 0px, 0px)"}}>
										<div className="col-xxl-2 col-lg-3 col-sm-6 col-md-4 mt-3">
											<div className="item">
												<div className="why-box">
													<div className="icons">
														<img src="../assets/img/GameDevelopment/honesty.svg" loading="lazy" width="60" height="60" alt="Well Trusted Tech Organization" title="Well Trusted Tech Organization" />
													</div>
													<h4>Well Trusted Tech Organization</h4>
												</div>
											</div>
										</div>
										<div className="col-xxl-2 col-lg-3 col-sm-6 col-md-4 mt-3">
											<div className="item">
												<div className="why-box">
													<div className="icons">
														<img src="../assets/img/GameDevelopment/customer-service.svg" loading="lazy" width="60" height="60" alt="Extra Ordinary Customer Service" title="Extra Ordinary Customer Service" />
													</div>
													<h4>
														Extra Ordinary Customer Service
													</h4>
												</div>
											</div>
										</div>
										<div className="col-xxl-2 col-lg-3 col-sm-6 col-md-4 mt-3">
											<div className="item">
												<div className="why-box">
													<div className="icons">
														<img src="../assets/img/GameDevelopment/project-management.svg" loading="lazy" width="60" height="60" alt="Proactive Approach" title="Proactive Approach" />
													</div>
													<h4>
														Proactive
													</h4>
												</div>
											</div>
										</div>
										<div className="col-xxl-2 col-lg-3 col-sm-6 col-md-4 mt-3">
											<div className="item">
											<div className="why-box">
												<div className="icons">
													<img src="../assets/img/GameDevelopment/online-shop.svg" loading="lazy" width="60" height="60" alt="One Stop Shop" title="One Stop Shop" />
												</div>
												<h4>
													One Stop <br />Shop
												</h4>
											</div>
										</div>
										</div>
										<div className="col-xxl-2 col-lg-3 col-sm-6 col-md-4 mt-3">
											<div className="item">
											<div className="why-box">
												<div className="icons">
													<img src="../assets/img/GameDevelopment/chat.svg" loading="lazy" width="60" height="60" alt="Respond Quickly" title="Respond Quickly" />
												</div>
												<h4>
													Respond <br />Quickly
												</h4>
											</div>
										</div>
										</div>
										<div className="col-xxl-2 col-lg-3 col-sm-6 col-md-4 mt-3">
											<div className="item">
											<div className="why-box">
												<div className="icons">
													<img src="../assets/img/GameDevelopment/web-development.svg" loading="lazy" width="60" height="60" alt="Dedicated Developers" title="Dedicated Developers" />
												</div>
												<h4>Dedicated <br />Developers</h4>
											</div>
										</div>
										</div>
								</div>
								</div>
								<div className="owl-nav disabled">
									<div className="owl-prev">
										<i className="fal fa-angle-left"></i>
									</div>
									<div className="owl-n	ext">
										<i className="fal fa-angle-right"></i>
									</div>
								</div>
								<div className="owl-dots disabled"></div>
							</div>
						</div>
						<div className="key-mainbox row mb-4">
							<div className="col-sm-4 key-bg-1">
								<div className="key-point">
									<div className="key-img">
										<img src="../assets/img/GameDevelopment/projects.svg" loading="lazy" width="60" height="60" alt="250+ APPS BUILT" title="250+ APPS BUILT" />
									</div>
									<div className="key-text">
										<h4>900 +</h4>
										<p>Finished Project</p>
									</div>
								</div>
							</div>
							<div className="col-sm-4 key-bg-2">
								<div className="key-point">
									<div className="key-img">
										<img src="../assets/img/GameDevelopment/rating.svg" loading="lazy" width="60" height="60" alt="75+ HAPPY CUSTOMERS" title="75+ HAPPY CUSTOMERS" />
									</div>
									<div className="key-text">
										<h4>890 +</h4>
										<p>Happy Clients</p>
									</div>
								</div>
							</div>
							<div className="col-sm-4 key-bg-3">
								<div className="key-point">
									<div className="key-img">
										<img src="../assets/img/GameDevelopment/handshake.svg" loading="lazy" width="60" height="60" alt="47% REFERRAL BUSINESS" title="47% REFERRAL BUSINESS" />
									</div>
									<div className="key-text">
										<h4>510 +</h4>
										<p>Honorable Award</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			
			</section>

			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}
			<Footer />
		</div>

	);
}
