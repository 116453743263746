import React from "react";
import 'react-awesome-slider/dist/styles.css';
import 'animate.css';
import 'react-circular-progressbar/dist/styles.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import DOMPurify from 'isomorphic-dompurify';
import { Helmet } from "react-helmet";
import Header from "./Header";

class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            Privacy: ''
        }
    
    }
    componentDidMount() {
            axios.get("https://www.oengines.com/terms-privacy-html-contents")
            .then(response => {
                this.setState({Privacy: response.data[0].privacy});
            })
            .catch(function (error) {
                console.log(error);
            })
    }
  
    render() {
        return (
            <div>
                {/* <div className="loader" ><span>Loading...</span></div> */}
                <Header />
               {/* <div className="loader" ><span>Loading...</span></div> */}
                <div className="space" id="about-sec" style={{transition: "all 0.5s"}}> 
                    <div className="container">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.Privacy) }}></div> 
                    </div>
                </div>
                                              
                <div className="pb" id="pb"></div>
                        <div className="pc">
                            <div className="ol">
                                <a href="#" id="btn-back-to-top">
                                    <i className="fa-solid fa-arrow-up">
                                    </i>
                                </a>
                            </div>
                            <div className="bg" id="bg"></div>
                        </div>                
              
            </div>
        )
    }
}

export default Privacy;
