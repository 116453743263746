import  React  from "react";
import { Helmet } from 'react-helmet';
import '../../../src/assets/css/app.min.css';
import '../../../src/assets/css/app.min.css';
import '../../../src/assets/css/fontawesome.min.css';
import 'react-awesome-slider/dist/styles.css';
import '../../assets/css/responsive.css'
import '../../../src/assets/css/Slick_Cdn/bootstrap.min.css';
import axios from 'axios';
import Swal from "sweetalert2";
import Footer from "../../Footer";
import Header from "../../Header";
import 'react-circular-progressbar/dist/styles.css';
import { Modal } from 'react-bootstrap';

class Androidpage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
            show: false,
            isOpen: false,
            isActive: false,
            selectedDate: null,
        }
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this);
    }
    componentDidMount() {

        axios.get("https://www.oengines.com/careers")
            .then(response => {
                console.log("Data is::::::::", response.data);
                this.setState({ Job: response.data });
                this.setState({ Description: response.data[0].description });

            })
            .catch(function (error) {
                console.log(error);
            })

    }
    onClickShowdetails(job) {
        console.log("job is:::::::", job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }
    openModal(e) {
        this.setState({ isOpen: !this.state.isOpen });
    }
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    handleOpenContectModal(e) {
        this.setState({ show: !this.state.show });
        console.log("state is::::::::", this.state.show);
    }
    handlevideopopup(e) {
        console.log("::::::add on this:");
        this.setState({ isActive: !this.state.isActive });
    }
    closeModal() {
        this.setState({ show: !this.state.show });
    };
    handleClickInsideModal = (event) => {
        event.stopPropagation();
    }
    maxlengthnumber(evt) {
        if (evt.target.value.length === 11) {
            return false;
        }
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};
        console.log("Enter in::::::::::::");
        if (this.state.name === '' || this.state.name === null) {

            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.message === '' || this.state.message === null) {
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else {

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: 'Android' + '-' + this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,

            };
            console.log("formObject::::::::::;", formObject);
            axios.post("https://www.oengines.com/contactus", formObject)
                .then((res) => {
                    console.log("Response is:::::::::", res);
                    if (res.data !== 'undefined') {
                        Swal.fire({
                            title: "Thank you!",
                            text: "Your request has been assigned successfully.",
                            icon: "success",
                        }).then(function () {
                            window.location.reload();
                        });
                    } else {
                        Swal.fire({
                            title: "Oops...!",
                            text: "Oh! Something went wrong, please try again!",
                            icon: "error",
                        }).then(function () {
                            window.location.reload();
                        });
                    }

                });
        }


        this.setState({
            name: '',
            email: '',
            subject: '',
            number: '',
            message: ''

        })

    }

    render() {
        var settings = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 3,
                    nav: true,
                    loop: true
                }

            }
        };
        var setIcons = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                576: {
                    items: 2,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 3,
                    nav: true,
                    loop: true
                },
                1200: {
                    items: 4,
                    nav: true,
                    loop: true
                }
            }
        };
        var setweb = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                576: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 3,
                    nav: true,
                    loop: true
                },
                1200: {
                    items: 4,
                    nav: true,
                    loop: true
                }
            }
        };
        var setblog = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 2,
                    nav: true,
                    loop: true
                },
                1200: {
                    items: 3,
                    nav: true,
                    loop: true
                }

            }
        };
        const mystyletow = {
            backgroundImage:
                "url('../assets/img/bg/counter_bg_1.png')",
        };
        const mystylefour = {
            backgroundImage:
                "url('../assets/img/bg/cta_bg_1.png')",
            background: 'rgb(8, 14, 28)'
        };
     
        var Information;
        if (this.state.Job.length) {
            Information = this.state.Job.map((job) =>
                <>
                    <li>
                        <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>
                </>
            )
        } else {
            Information = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }
        const { nameError, emailError, subjectError, numberError, toDateError, messageError } = this.state.formErrors;
        return (
            <>
                <Helmet>

                    <link href="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/css/coreui.min.css" rel="stylesheet" integrity="sha384-UkVD+zxJKGsZP3s/JuRzapi4dQrDDuEf/kHphzg8P3v8wuQ6m9RLjTkPGeFcglQU" crossorigin="anonymous" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                    <link rel="stylesheet" href="../assets/css/Slick_Cdn/animate.min.css" />
                    <link rel="stylesheet" href="../assets/css/Slick_Cdn/Datepicker.css" />
                    <script src="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/js/coreui.bundle.min.js" integrity="sha384-n0qOYeB4ohUPebL1M9qb/hfYkTp4lvnZM6U6phkRofqsMzK29IdkBJPegsyfj/r4" crossorigin="anonymous"></script>                    
                    <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js" type="text/javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                    <script src="../assets/js/mian.js" type="Javascript"></script>
                    <script src="../assets/js/script.js" type="text/Javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-circle-progress/1.1.3/circle-progress.min.js"></script>
                </Helmet>
                <div className="loader"><span>Loading...</span></div>
                <Header />
                <Modal show={this.state.show} onClick={this.handleClickInsideModal} className="modal_contect" centered >
                    <Modal.Header closeButton onHide={(e) => this.handleOpenContectModal()}>
                        <h5 className="modal-title m-auto" id="exampleModalLabel">Talk with our Experts</h5>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <form onSubmit={this.onSubmit} method="post" className="contact-form ajax-contact">
                            <div className="row">
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                    <i className="fal fa-user"></i>
                                    {nameError &&
                                        <div className="invalid-feedback" >{nameError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} autoComplete="Off" />
                                    <i className="fal fa-envelope"></i>
                                    {emailError &&
                                        <div className="invalid-feedback">{emailError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <select name="subject" id="subject" ref="subjectfield" className={subjectError ? "form-control is-invalid" : "form-control"} onChange={this.handleChange} autoComplete="Off">
                                        <option disabled="disabled">Select Subject
                                        </option>
                                        <option defaultValue={"App Development"}>App Development</option>
                                        <option defaultValue={"Game Development"}>Game Development</option>
                                        <option defaultValue={"App Brand Marketing"}>App Brand Marketing</option>
                                        <option defaultValue={"UI/UX Design"}>UI/UX Design</option>
                                        <option defaultValue={"App Digital Marketing"}>App Digital Marketing</option>
                                    </select>
                                    {subjectError &&
                                        <div className="invalid-feedback">{subjectError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} name="number" ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange={this.maxlengthnumber} value={this.state.number} />
                                    <i className="fal fa-phone"></i>
                                    {numberError &&
                                        <div className="invalid-feedback">{numberError}</div>
                                    }
                                </div> 
                                                                
                                <div className="form-group col-12">
                                    <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                        className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange} autoComplete="Off">
                                    </textarea>
                                    <i className="fal fa-comment"></i>
                                    {messageError &&
                                        <div className="invalid-feedback">{messageError}</div>
                                    }
                                </div>
                                <div className="form-btn text-xl-start text-center col-12">
                                    <button type="submit" className="as-btn style3 send-qty">Contact Now.
                                        <i className="fa-regular fa-arrow-right ms-2"></i>
                                    </button>
                                </div>
                            </div>
                            <p className="form-messages mb-0 mt-3"></p>
                        </form>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isOpen} onClick={this.handleClickInsideModal} className="modal_contect video-effect-modal" centered>
                    <Modal.Header closeButton onHide={() => this.openModal()}>
                        <h5 className="modal-title m-auto" id="exampleModalLabel">CREATE A QUOTE</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.onSubmit} method="post" className="contact-form ajax-contact">
                            <div className="row">
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                    <i className="fal fa-user"></i>
                                    {nameError &&
                                        <div className="invalid-feedback" >{nameError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} autoComplete="Off" />
                                    <i className="fal fa-envelope"></i>
                                    {emailError &&
                                        <div className="invalid-feedback">{emailError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <select name="subject" id="subject" ref="subjectfield" className={subjectError ? "form-control is-invalid" : "form-control"} onChange={this.handleChange} autoComplete="Off">
                                        <option disabled="disabled">Select Subject
                                        </option>
                                        <option defaultValue={"App Development"}>App Development</option>
                                        <option defaultValue={"Game Development"}>Game Development</option>
                                        <option defaultValue={"App Brand Marketing"}>App Brand Marketing</option>
                                        <option defaultValue={"UI/UX Design"}>UI/UX Design</option>
                                        <option defaultValue={"App Digital Marketing"}>App Digital Marketing</option>
                                    </select>
                                    {subjectError &&
                                        <div className="invalid-feedback">{subjectError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} name="number" ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange={this.maxlengthnumber} value={this.state.number} />
                                    <i className="fal fa-phone"></i>
                                    {numberError &&
                                        <div className="invalid-feedback">{numberError}</div>
                                    }
                                </div>

                                <div className="form-group col-12">
                                    <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                        className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange} autoComplete="Off">
                                    </textarea>
                                    <i className="fal fa-comment"></i>
                                    {messageError &&
                                        <div className="invalid-feedback">{messageError}</div>
                                    }
                                </div>
                                <div className="form-btn text-xl-start text-center col-12">
                                    <button type="submit" className="as-btn style3 send-qty">Send QUOTE
                                        <i className="fa-regular fa-arrow-right ms-2"></i>
                                    </button>
                                </div>
                            </div>
                            <p className="form-messages mb-0 mt-3"></p>
                        </form>
                    </Modal.Body>
                </Modal>
                <Modal size="lg" show={this.state.isActive} onClick={this.handleClickInsideModal} className="modal_contect video-effect-modal modalvideos" centered>
                    <Modal.Header closeButton className="modal_header p-0 border-none" style={{ border: "none" }} onHide={() => this.handlevideopopup()} >
                    </Modal.Header>
                    <Modal.Body>
                        <iframe width="960" height="415" src="https://www.youtube.com/embed/9xwazD5SyVg?autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </Modal.Body>
                </Modal>
                <section className="hero-android ptb--60">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="hero-text android-hero-text">
                                    <h2>
                                        Build high-quality apps and games
                                    </h2>
                                    <p>
                                        There are four pillars of Android app quality. High-quality apps and games deliver value to users, are delightful to use, make the most of premium devices, and are designed for safety. Use these guidelines to build great experiences on Android, and increase your chances of being featured on Google Play.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Mian-img">
                                    <img src="assets/img/bg/quality-hero.svg" alt="img" />
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="sub-hero ptb--60">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-xl-3 col-sm-6">
                                <div className="sub-inner-hero">
                                    <img src="assets/img/icon/lightbulb.svg" />
                                    <h4>Core value</h4>
                                    <p>
                                        Apps and games should deliver user value by being useful or fun.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3 col-sm-6">
                                <div className="sub-inner-hero">
                                    <img src="assets/img/icon/stars.svg" />
                                    <h4>User experience</h4>
                                    <p>
                                        Apps and games should be delightful to use.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3 col-sm-6">
                                <div className="sub-inner-hero">
                                    <img src="assets/img/icon/graph-bar.svg" />
                                    <h4>Technical quality</h4>
                                    <p>
                                        Apps and games should make the most of premium devices.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3 col-sm-6">
                                <div className="sub-inner-hero">
                                    <img src="assets/img/icon/security-2.svg" />
                                    <h4>Privacy and security</h4>
                                    <p>
                                        Learn to design your apps and games for safety.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="ptb--60">
                    <div className="container">
                        <div className="row">
                            <div className="text-formate mb-80 col-xl-4 col-xxl-4 col-md-12 col-sm-12">
                            <div className="devsite-landing-row-header-icon-container mb-30" size="large"> 
                                <img className="devsite-landing-row-header-icon" alt="" src="https://developer.android.com/static/images/picto-icons/google-play-code.svg" srcSet="" sizes="100vw" loading="lazy"/>
                            </div>
                                <h2>Find tools to help with trust, safety, and more</h2>
                                <p>Explore dozens of APIs, guides, docs, and more to help you do everything from monetizing your app to building user trust.</p>
                            </div>
                            <div className="col-md-12 col-xl-8 col-xxl-8  col-sm-12">
                                <div className="row">
                                    <div className="col-md-6 col-xxl-6 col-xl-6 col-sm-6">
                                        <div className="factors">
                                            <div className="factor-inner">
                                                <span>SECURITY & PRIVACY</span>
                                                <h3>Play Integrity API</h3>
                                                <p>Protect your apps and games from potentially risky and fraudulent interactions to reduce attacks   and abuse.
                                                </p>                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xxl-6 col-xl-6 col-sm-6">
                                        <div className="factors">   
                                            <div className="factor-inner">
                                                <span>GAMING</span>
                                                <h3>Develop Android Games</h3>
                                                <p>Develop Android games and deploy them to multiple device types on Android, ChromeOS, and Windows.</p>                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xxl-6 col-xl-6 col-sm-6">
                                        <div className="factors">
                                            <div className="factor-inner">
                                                <span>QUALITY</span>
                                                <h3>Reporting API</h3>
                                                <p>Access app-level data from outside Play Console to build internal dashboards, combine datasets, and more.</p>                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xxl-6 col-xl-6 col-sm-6">
                                        <div className="factors">
                                            <div className="factor-inner">
                                                <span>TOOLS</span>
                                                <h3>SDK Index</h3>
                                                <p>Get insights and usage data for over 100 commercial SDKs to make informed decisions about whether an SDK is right for you.</p>                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xxl-6 col-xl-6 col-sm-6">
                                        <div className="factors">
                                            <div className="factor-inner">
                                                <span>MONETIZATION</span>
                                                <h3>Play Billing APIs</h3>
                                                <p>
                                                Sell digital products and content in your Android app, including subscriptions and special offers.
                                                </p>                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xxl-6 col-xl-6 col-sm-6">
                                        <div className="factors">
                                            <div className="factor-inner">
                                                <span>TOOLS</span>
                                                <h3>Google Play Services</h3>
                                                <p>Build powerful and reliable Android apps for Google Play using the latest Google features and technologies.</p>                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                                           
                    </div>
                </section>
                <section className="get-started ptb--60">
                    <div className="container">
                        <div className="get-text mb-60">
                            <h2>Getting started</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-xxl-6">
                                <div className="sub-text pt-20">
                                    <h2>Build for billions guidelines</h2>
                                    <p>There are unique challenges when building for emerging markets around the world with incredibly fast growing internet and smartphone usage. Deliver the best possible experience for your users and help your app succeed in these markets by checking out our advice on building for the world.</p>                                
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xxl-6">
                                <div className="sub-img">
                                    <img src="assets/img/hero/hero-people.png" alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="row spc-top">
                            <div className="col-md-6 col-sm-6 col-xxl-6">
                                <div className="sub-imgs">
                                    <img src="assets/img/hero/hero-peaple-two.png" alt="img" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xxl-6">
                                <div className="sub-texts">
                                    <h2>Next Billion Users initiative</h2>
                                    <p>Everyone — no matter their location, language or digital literacy — deserves an internet that was made for them. Google’s Next Billion Users initiative conducts research and builds products for people around the world.</p>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="section section-life ptb--60">
                    <div className="container">
                        <div className="holder row">
                            <div className="col-lg-5 col-md-5 col-sm-6 col-xxl-5 col-print-12 holder-col">
                                <div className="title-with-decor d-inline-flex">
                                    <h2>Add life</h2>
                                    <img src="https://static.proto.io/images/publicsite/redesigned2021/line-02.svg?v=6.8.23.0" loading="lazy" width="273" height="123" alt="decor" className="line" />
                                </div>
                                <div className="text-holder">
                                    <p className="lead">Communicate the functionality behind your designs and improve communication. Add different levels of interactivity based on your project's needs and go from a simple wireframe to a prototype that feels real.</p>                                   
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-6 col-xxl-7 ">
                                <div className="img-holder text-center bodymove d-sm-block d-print-none">
                                    <img src="https://static.proto.io/images/publicsite/redesigned2021/illustrations-02.svg?v=6.8.23.0" className="img-fluid" loading="lazy" width="642" height="487" alt="illustrations" />
                                </div>
                            </div>
                        </div>
                        <div className="row article-area article-area--justified itme-flax" style={{justifyContent: "center"}}>
                            <div className="article-area__item col-lg-4 col-print-4">
                                <div className="row align-items-center align-items-lg-start h-100 flex-lg-nowrap flex-lg-column">                                 
                                    <div className="col-md-auto col-lg-auto">
                                        <article className="article print-no-break">
                                            <strong className="text-size-lg sub-title d-block">Over 80 events</strong>
                                            <h3 className="h5 article-title mb-0"><span className="text-purple-light">All web and mobile</span> events.</h3>
                                        </article>
                                    </div>
                                </div>
                            </div>
                            <div className="row article-area__item col-lg-4 col-print-4 set-rang">
                                <div className="flex-md-row-reverse flex-lg-row  h-100 flex-lg-nowrap flex-lg-column">
                                   
                                    <div className="col-md-auto col-lg-auto">
                                        <article className="article print-no-break">
                                            <strong className="text-size-lg sub-title d-block">Over 40 actions</strong>
                                            <h3 className="h5 article-title mb-0"><span className="text-warning">Interactions</span> with no limits.</h3>
                                        </article>
                                    </div>
                                </div>
                            </div>
                            <div className="article-area__item col-lg-4 col-print-4 set-rang">
                                <div className="row h-100 flex-lg-nowrap flex-lg-column">
                                   
                                    <div className="col-md-auto col-lg-auto">
                                        <article className="article print-no-break">
                                            <strong className="text-size-lg sub-title d-block">Nested &amp; multidirectional</strong>
                                            <h3 className="h5 article-title mb-0"><span className="text-blue-cyan">Scrollable</span><br /> areas.</h3>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="ptb--60">
                    <div className="container">
                        <div className="inner">
                            <div className="elements">
                                <img className="lazy t1" src="https://www.esparkinfo.com/wp-content/themes/espark/images/triangle.svg" alt="esparkinfo" width="60" height="43" />
                                <img className="lazy t2" src="https://www.esparkinfo.com/wp-content/themes/espark/images/triangle.svg" alt="esparkinfo" width="34" height="17" />
                                <img className="lazy t3" src="https://www.esparkinfo.com/wp-content/themes/espark/images/triangle.svg" alt="esparkinfo" width="30" height="13" />
                                <img className="lazy r1" src="https://www.esparkinfo.com/wp-content/themes/espark/images/round.svg" alt="esparkinfo" width="102" height="102" />
                                <img className="lazy r2" src="https://www.esparkinfo.com/wp-content/themes/espark/images/round.svg" alt="esparkinfo" width="122" height="122" />
                            </div>
                            <div className="innerbox bg-blue white-text flex space-between align-center">
                                <div className="title-part small-title">
                                    <h2>Give your Mobile App Project into the hands of a Company that truly cares!</h2>
                                </div>
                                <div className="button-3">
                                    <a target="" href="#!" onClick={(e) => this.handleOpenContectModal(e)} data-wpel-link="internal">Talk with our Expert </a>
                                </div>
                            </div>
                        </div>                      
                    </div>
                </section>

                <section className="ptb--60 business-modal business-modal-desktop">
                    <div className="container">
                        <div className="text-heading">
                            <h2 className="text-start text-center mb-4">Types of Business Model for Android Applications</h2>
                            <p>There are various business models that can be implemented to monetize an <span>Android application </span>but it is a difficult choice for app developers. The most popular choices are: selling the app, in-app purchases, subscriptions, freemiums, etc.</p>
                        </div>                        
                        <div className="row mt-4">
                            <div className="bussiness-text col-sm-6 col-md-6 col-6">
                                <h3>1. Paid Application</h3>
                                <img src="assets/img/service/paidApp.jpg" alt="img not found" />
                                <p className="mt-15">This business model requires the end-user to pay a specific charge before the applications can finally be downloaded. There is certainly no “Trial” choice. User needs to make a purchase decision simply by looking at the summary and the screenshots added at the app store. This is a method that can start giving you rapid results.</p>
                                <p>But in this case, maintaining consumer satisfaction is difficult. Once your application has been paid and installed by a user but does not generate outcomes as expected by the customer, it is a sure shot deterioration in customer satisfaction. Consequently, this market model is seeing a gradual decline due to this reason.</p>

                            </div>
                            <div className="bussiness-text col-sm-6 col-md-6 col-6 ">
                                <h3>2. In-app Purchases</h3>
                                <img src="assets/img/service/inapp.jpg" alt="img not found" />
                                <p className="mt-15">This <b>business model in the android application</b> has been most widely used throughout the world. These applications are free to use in the beginning, but keep introducing advanced features for which you will have to pay later on.</p>
                                <span>These features would look something like this:</span>
                                <ul className="buss-ul">
                                    <li>Unlock additional features</li>
                                    <li>Remove pop-up ads</li>
                                    <li>Download something that is needed in the app, etc.</li>
                                </ul>
                                <p>The basic app is free but in some apps, users need to pay for advanced features or goodies. This business model is beneficial as users get to “use” the app before making any kind of purchase. A fair amount of success has been seen in this business model.</p>

                            </div>
                            <div className="bussiness-text col-sm-6 col-md-6 col-6">
                                <h3>3. Affiliate Marketing</h3>
                                <img src="assets/img/service/Affiliate.jpg" alt="img not found" />
                                <p className="mt-15">This one is also very popular <b>business model in android applications</b>, quite frequently used by most of the entrepreneurs.</p>
                                <p>Affiliate marketing is a medium to promote the products (or businesses) of many other individuals to earn a commission in return. When you discover a product you are interested in, you can market it to others and earn a profit with every sale that the product makes.</p>
                                <p>The effect will be seen through your app. If not directly, you can make profits through leads or sales commissions. For example, if you have an android an eCommerce app, you can build an affiliate network that will give you a commission on every product you have sold through the use of the app.</p>

                            </div>
                            <div className="bussiness-text col-sm-6 col-md-6 col-6">
                                <h3>4. Advertising</h3>
                                <img src="assets/img/service/Advertising.jpg" alt="img not found" />
                                <p className="mt-15">In modern times, this  <b>business model in android applications</b>, for device monetization has seen an increase.</p>
                                <p>Affiliate marketing is a medium to promote the products (or businesses) of many other individuals to earn a commission in return. When you discover a product you are interested in, you can market it to others and earn a profit with every sale that the product makes.</p>
                                <p>The application is entirely available for free download and use, although the return on investment is recouped by introducing advertising within the application. A Pay Per Click Model or Cost Per Mille (CPM) model is another highly profitable <b>business model for android apps</b>.</p>
                                <p>This model is beneficial because your app receives wider access through popular advertising networks, and you only have to pay for what you are benefiting from. If your ad is clicked on or has been displayed for 1000 times.</p>
                            </div>                           
                            <div className="bussiness-text col-sm-6 col-md-6 col-6">
                                <h3>5. Subscription/ SaaS Model</h3>                                
                                <img src="assets/img/service/saaas.jpg" alt="img not found" />
                                <p className="mt-15">This is a profitable <b>business model in the android application</b> where you have to target people who like to use tablets or bigger screen phones.</p>
                                <p>Songs apps, tv-series apps, newsletter apps, book apps, and more can be considered for this business model, where you can charge consumers monthly or annually.</p>
                                <p className="web-clamp-3">If you are thinking about creating your own content, then it could be really difficult and time-consuming. Therefore, try using content from third parties but then be ready for low-profit margins also.</p>
                                <p>And it’s not going to be that easy to obtain third party content in terms of closing licensing deals etc., given that it puts pressure on your time and budget. So, crowdsource free content just like YouTube does.</p>
                            </div>
                            <div className="bussiness-text col-sm-6 col-md-6 col-6">
                                <h3>6. Video Advertising</h3>
                                <img src="assets/img/service/VideoAdvertising.jpg" alt="img not found" />
                                <p className="mt-15">Visual ads play a huge role in attracting customers as people are directly able to relate to what they are seeing. Through this <b>business model for android application</b>, you can show more than just your application’s display ads. You can offer users a short but compelling and enticing video of what you do.</p>
                                <p>Affiliate marketing is a medium to promote the products (or businesses) of many other individuals to earn a commission in return. When you discover a product you are interested in, you can market it to others and earn a profit with every sale that the product makes.</p>
                                <p>Additionally, the application developers can collaborate with advertising companies, to help in the production and display of these short video ads on their apps that will help generate revenue.</p>
                            </div>
                            <div className="bussiness-text col-sm-6 col-md-6 col-6">
                                <h3>7. Freemium</h3>
                                <img src="assets/img/service/Freemium.jpg" alt="img not found" />
                                <p className="mt-38">Users don’t have to pay anything in the Freemium <b>business model for android applications</b>. To install the app and optional in-app purchases for premium features, extra content, or digital goods, no extra costs are charged.</p>
                                <p>This fundamental approach is easy to understand, where you have to provide all the users a functional, basic, and completely free version of your product or service. This will convince prospective consumers to become regular customers after a certain time by supplying them with a premium, advanced, feature-rich version at a price.</p>
                            </div>
                            <div className="bussiness-text col-sm-6 col-md-6 col-6">
                                <h3>8. E-Commerce</h3>
                                <img src="assets/img/service/serv-2.jpg" alt="img not found" />
                                <p className="mt-15">In the presence of the big players such as <b>Amazon</b>, eCommerce apps dominate the highest position among monetization strategies. You can sell your own physical collection or sell other people’s physical collections. The latter would be comparatively less risky than the former.</p>
                                <p>Undeniably, this working approach has been adopted by many e-commerce platforms. But the profitability in this strategy is relatively low. Why? As the type of goods, you are going to sell would be somewhat similar to what the ones your competitors would be selling.</p>
                            </div>                           
                        </div>
                    </div>
                </section>
                <section className="ptb--60 business-modal business-modal-mobile">
                    <div className="container">
                        <div className="text-heading">
                            <h2 className="text-start text-center">Types of Business Model for Android Applications</h2>
                            <p>There are various business models that can be implemented to monetize an <span>Android application</span> but it is a difficult choice for app developers. The most popular choices are: selling the app, in-app purchases, subscriptions, freemiums, etc.</p>
                        </div>
                        <span>Check out here-</span>                          
                            <ul className="ps-0 pt-50">
                                <li className="bussiness-text">
                                    <h3>1. Paid Application</h3>
                                    <img src="assets/img/service/paidApp.jpg" alt="img not found" />
                                    <div className="text-set clamp-3">
                                        <p className="mt-15">This business model requires the end-user to pay a specific charge before the applications can finally be downloaded. There is certainly no “Trial” choice. User needs to make a purchase decision simply by looking at the summary and the screenshots added at the app store. This is a method that can start giving you rapid results.</p>
                                        <p>But in this case, maintaining consumer satisfaction is difficult. Once your application has been paid and installed by a user but does not generate outcomes as expected by the customer, it is a sure shot deterioration in customer satisfaction. Consequently, this market model is seeing a gradual decline due to this reason.</p>
                                    </div>
                                </li>
                                <li className="bussiness-text ">
                                    <h3>2. In-app Purchases</h3>
                                    <img src="assets/img/service/inapp.jpg" alt="img not found" />
                                    <div className="text-set clamp-3">
                                        <p className="mt-15">This <b>business model in the android application</b> has been most widely used throughout the world. These applications are free to use in the beginning, but keep introducing advanced features for which you will have to pay later on.</p>
                                        <span>These features would look something like this:</span>
                                        <ul className="buss-ul">
                                            <li>Unlock additional features</li>
                                            <li>Remove pop-up ads</li>
                                            <li>Download something that is needed in the app, etc.</li>
                                        </ul>
                                        <p>The basic app is free but in some apps, users need to pay for advanced features or goodies. This business model is beneficial as users get to “use” the app before making any kind of purchase. A fair amount of success has been seen in this business model.</p>
                                    </div>
                                </li>
                                <li className="bussiness-text" >
                                    <h3>3. Affiliate Marketing</h3>
                                    <img src="assets/img/service/Affiliate.jpg" alt="img not found" />
                                    <div className="text-set clamp-3">
                                        <p className="mt-15">This one is also very popular <b>business model in android applications</b>, quite frequently used by most of the entrepreneurs.</p>
                                        <p>Affiliate marketing is a medium to promote the products (or businesses) of many other individuals to earn a commission in return. When you discover a product you are interested in, you can market it to others and earn a profit with every sale that the product makes.</p>
                                        <p>The effect will be seen through your app. If not directly, you can make profits through leads or sales commissions. For example, if you have an android an eCommerce app, you can build an affiliate network that will give you a commission on every product you have sold through the use of the app.</p>
                                    </div>
                                </li>
                                <li className="bussiness-text">
                                    <h3>4. Advertising</h3>
                                    <img src="assets/img/service/Advertising.jpg" alt="img not found" />
                                    <div className="text-set clamp-3">
                                        <p className="mt-15">In modern times, this  <b>business model in android applications</b>, for device monetization has seen an increase.</p>
                                        <p>Affiliate marketing is a medium to promote the products (or businesses) of many other individuals to earn a commission in return. When you discover a product you are interested in, you can market it to others and earn a profit with every sale that the product makes.</p>
                                        <p>The application is entirely available for free download and use, although the return on investment is recouped by introducing advertising within the application. A Pay Per Click Model or Cost Per Mille (CPM) model is another highly profitable <b>business model for android apps</b>.This model is beneficial because your app receives wider access through popular advertising networks.</p>
                                       
                                    </div>
                                </li>                               
                                <li className="bussiness-text">
                                    <h3>5. Subscription/ SaaS Model</h3>
                                    <img src="assets/img/service/saaas.jpg" alt="img not found" />
                                    <div className="text-set clamp-3">
                                        <p className="mt-15">This is a profitable <b>business model in the android application</b> where you have to target people who like to use tablets or bigger screen phones.</p>
                                        <p>Songs apps, tv-series apps, newsletter apps, book apps, and more can be considered for this business model, where you can charge consumers monthly or annually.</p>
                                        <p className="web-clamp-3">If you are thinking about creating your own content, then it could be really difficult and time-consuming. Therefore, try using content from third parties but then be ready for low-profit margins also.</p>
                                        <p>And it’s not going to be that easy to obtain third party content in terms of closing licensing deals etc., given that it puts pressure on your time and budget. So, crowdsource free content just like YouTube does.</p>
                                    </div>
                                </li>
                                <li className="bussiness-text">
                                    <h3>6. Video Advertising</h3>
                                    saaas.jpg
                                    <div className="text-set clamp-3">
                                        <p className="mt-15">Visual ads play a huge role in attracting customers as people are directly able to relate to what they are seeing. Through this <b>business model for android application</b>, you can show more than just your application’s display ads. You can offer users a short but compelling and enticing video of what you do.</p>
                                        <p>Affiliate marketing is a medium to promote the products (or businesses) of many other individuals to earn a commission in return. When you discover a product you are interested in, you can market it to others and earn a profit with every sale that the product makes.</p>
                                        <p>Additionally, the application developers can collaborate with advertising companies, to help in the production and display of these short video ads on their apps that will help generate revenue.</p>
                                    </div>
                                
                                </li>
                                <li className="bussiness-text">
                                    <h3>7. Freemium</h3>
                                    <img src="assets/img/service/Freemium.jpg" alt="img not found" />
                                    <div className="text-set clamp-3">
                                        <p className="mt-15">Users don’t have to pay anything in the Freemium <b>business model for android applications</b>. To install the app and optional in-app purchases for premium features, extra content, or digital goods, no extra costs are charged.</p>
                                        <p>This fundamental approach is easy to understand, where you have to provide all the users a functional, basic, and completely free version of your product or service. This will convince prospective consumers to become regular customers after a certain time by supplying them with a premium, advanced, feature-rich version at a price.</p>
                                    </div>
                                </li>
                                <li className="bussiness-text">
                                    <h3>8. E-Commerce</h3>
                                    <img src="assets/img/service/serv-2.jpg" alt="img not found" />
                                    <div className="text-set clamp-3">
                                        <p className="mt-15">In the presence of the big players such as <b>Amazon</b>, eCommerce apps dominate the highest position among monetization strategies. You can sell your own physical collection or sell other people’s physical collections. The latter would be comparatively less risky than the former.</p>
                                        <p>Undeniably, this working approach has been adopted by many e-commerce platforms. But the profitability in this strategy is relatively low. Why? As the type of goods, you are going to sell would be somewhat similar to what the ones your competitors would be selling.</p>
                                    </div>
                                </li>                    
                            </ul>
                        
                    </div>
                </section>
                <section className="bg-bottom-right position-relative" style={mystylefour} data-bg-color="#080E1C">
                    <div className="img-half img-left as-video2">
                        <img src="assets/img/normal/cta_1.jpg" alt="video" />
                        <p className="play-btn popup-video" id="" onClick={(e) => this.handlevideopopup(e)}><i
                            className="fas fa-play vid-play"></i>
                        </p>
                    </div>
                    <div className="container">
                        <div className="row justify-content-end" >
                            <div className="col-xl-7 space" id="half-particles">
                                <div className="title-area mb-35">
                                    <span className="sub-title"><img
                                        src="assets/img/theme-img/title_shape_1.svg" alt="shape" />WATCH THE VIDEO</span>
                                    <h2 className="sec-title text-white">We makes the games / app, that stands out from crowd!</h2>
                                </div>
                                <p className="mt-n2 mb-30 text-white">Requirements for games or an applications explored in the market are increasing in the proportional manner day by day. Hence, opting the company to develop your game is a crucial decision. </p>
                                <p className="mt-n2 mb-30 text-white">We provide a full-scale game or app development services by improving our outstanding combination of experience and skills.</p>

                                <button className="as-btn" id="" onClick={(e) => this.openModal(e)} style={{ border: "none" }}>GET A QUOTE
                                    <i className="fa-regular fa-arrow-right ms-2"></i>
                                </button>

                            </div>
                        </div>
                    </div>

                </section>
                
                <Footer />
            </>
        )
    }
}

export default Androidpage;

