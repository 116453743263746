import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../config.json';
import { Helmet } from 'react-helmet';
import Header from "../../GameServiceHeader";
import Footer from '../../over_games/Footer';
import '../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';



export default function Board() {
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  

	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Board'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};

	

	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }
	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>				
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />

			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>

				<section id="hero" className="bg-scroll-card board-bg hero-section division board-sec">
					<div className="container">
						<div className="row card-her pt-100 pb-100">
							<div className="col-md-6 col-lg-6 arcade-col">
								<div className="hero-17-title white-color mb-30">
									<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
										<h2 className="h2-xl">Board Game Development</h2>
									</ScrollAnimation>
								</div>
								<div className="hero-17-txt">
									<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
										<p className="p-xl white-color max-conent">Build incredible board games here. We have seasoned game developers who are masters in creating exciting board games that keep players hooked for a long.
										</p>
									</ScrollAnimation>
								</div>
								<button className="theme-button mt-4"><a href="tel:+02614054978" ><strong>Call Now</strong></a></button>
							</div>
							<div className="col-md-6 col-lg-6">
								<div className="hero-17-img video-preview">
									<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
										<img className="img-fluid" src="../assets/img/GameDevelopment/Board/ludo_board.png" alt="ludo-hero-image" />
									</ScrollAnimation>
								</div>
							</div>
						</div>
					</div>			
				</section>
				<section className="chs_oeg board mt-80 mb-80">
					<div className="container">
						<div className="Oeg_main">
							<div className="row">
								<div className="col-md-6 col-sm-12 col-lg-6 col-12">
									<div className="Oeg_imgs m-4">
										<img src="../assets/img/GameDevelopment/Board/new_board_game.png" alt="img not found" />
									</div>
								</div>
								<div className="col-md-6 col-sm-12 col-lg-6 col-12">
									<div className="Oeg_text">
										<h2>Board Game</h2>
										<div className="elementor-divider style-left">
											<span className="elementor-divider-separator">
											</span>
										</div>
										<p className="mt-30 text">Board games are great when you can physically spread them out in front of you and play with your friends, but sometimes you may want to play on the go, whenever and wherever you are. There can be a downside to board games, as many of them require you to play with other people in the same room. We have found that board game apps are a solution to this problem and can help pass the time, while also giving you your board gaming fix all from your mobile device. Mobile app board games also allow you to download a digital version of a board game and learn the play style and logistics of the game. This is great for someone who wants to play a new board game before playing with others.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="development pb-80 pt-80">
					<div className="container">
						<div className="row justify-content-center pt-40 pb-50">
							<h3 className="text-center bg-color fs-2 ">Our Board Game Development Services</h3>						
							<div className="row justify-content-center pt-60">
								<div className="col-md-6 col-sm-6 col-xl-4 col-lg-6 " data-tilt data-tilt-max="30" data-tilt-glare data-tilt-max-glare="0.55">
									<div className="development-sevice hvr-float service-box-amin">
										<h4><strong>Multi Gaming Modules</strong></h4>
										<p>Our dedicated resources create multiple gaming modules with interactive user interfaces and engaging gameplay. We have professional game model designers and developers who are proficient in building attractive user interfaces for your sensational game.</p>
									</div>									
								</div>
								<div className="col-md-6 col-sm-6 col-xl-4 col-lg-6 ">
									<div className="development-sevice hvr-float service-box-amin">
										<h4><strong>Game Controls</strong></h4>
										<p>Oengines is your one-stop destination for all varieties of board game development. We build enthralling games with user-friendly controls that make it easy for players to interact with the virtual gaming environment.</p>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-xl-4 col-lg-6 ">
									<div className="development-sevice hvr-float service-box-amin">
										<h4><strong>Versatile Game Development</strong></h4>
										<p> Tap into the rapidly growing gaming industry with an engaging board game. Hire our board game developers to build
										thrilling and intuitive board games like Checkers, Ludo, Carrom and more.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="Why-Choose-Us pt-20 pb-80">
					<h2 className="why-choose-heading">Why <lavel> Choose Us</lavel></h2>
					<h3 className="why-choose-sub-heading mt-20">We are well famous in providing the best Board game development services which are listed below</h3>
					<div className="container">
						<div className="row mt-60">
							<div className="col-md-6 mt-15">
								<div className="accordion accordion-flush" id="accordionFlushExample">
									<div className="accordion-item">
										<h2 className="accordion-header" id="flush-headingOne">
											<div className="accordion-button collapsed">
												<img src="../assets/img/GameDevelopment/money.png" alt="" /><span>Product designed by a team of experts who have been in the industry for years</span>
											</div>                                       
										</h2>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header" id="flush-headingTwo">
											<div className="accordion-button collapsed">
												<img src="../assets/img/GameDevelopment/rating.png" alt="" /> <span>Solutions that work on all platforms such as Web, Mobile, PC & Mac</span>
											</div>                                        
										</h2>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header" id="flush-headingOne">
											<div className="accordion-button collapsed">
												<img src="../assets/img/GameDevelopment/link.png" alt="" /> <span>24x7 monitoring & support team to ensure 99.99% game uptime</span>
											</div>                                        
										</h2>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header" id="flush-headingTwo">
											<div className="accordion-button collapsed">
												<img src="../assets/img/GameDevelopment/money.png" alt="" /> <span>Experienced Mobile Game Designers</span>
											</div>                                        
										</h2>
									</div>                               
								</div>
							</div>
							<div className="col-md-6 mt-15">
								<div className="why">
									<div className="accordion" id="accordionExample">
										<div className="accordion-item">
											<h2 className="accordion-header" id="headingOne">
												<div className="accordion-button collapsed">
													<img src="../assets/img/GameDevelopment/link.png" alt="" /> <span>Top Programmers to build and enhance the games to ensure the best quality</span>
												</div>
											</h2>
										</div>
										<div className="accordion-item">
											<h2 className="accordion-header" id="headingThree">
												<div className="accordion-button collapsed">
													<img src="../assets/img/GameDevelopment/money.png" alt="" /> <span>Customer-Centric Gaming Platform Developments</span>
												</div>
											</h2>
										</div>
										<div className="accordion-item">
											<h2 className="accordion-header" id="flush-headingTwo">
												<div className="accordion-button collapsed">
													<img src="../assets/img/GameDevelopment/link.png" alt="" /> <span>Expert Mobile Game Developers</span>
												</div>
											</h2>
										</div>
										<div className="accordion-item">
											<h2 className="accordion-header" id="flush-headingTwo">
												<div className="accordion-button collapsed">
													<img src="../assets/img/GameDevelopment/rating.png" alt="" /> <span>Developing games supporting all platforms.</span>
												</div>
											</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="high-bond-sec pt-100 pb-100">
					<div className="container">
						<div className="high-bond-main">
							<div className="boadr-title">
								<h2 className="text-white">Get High-end Board Game Features with the Leading Board Game Development Company</h2>
							</div>
							<div className="row mt-40 board-features-main">
								<div className="col-md-6 col-sm-6 col-lg-4 col-xxl-4">
									<div className="board-features">
										<div className="board-featur-img">
											<img src="../assets/img/GameDevelopment/private-mode-card-new.svg" alt="img" />
											<h4 className="text-white mt-30"><strong>Private Room</strong></h4>
											<p className="text-white mt-20">Offer players private rooms where they can challenge and compete with one another.</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-lg-4 col-xxl-4">
									<div className="board-features">
										<div className="board-featur-img">
											<img src="../assets/img/GameDevelopment/Group-8.svg" alt="img" />
											<h4 className="text-white mt-30"><strong>Chat UI</strong></h4>
											<p className="text-white mt-20">The built-in chat features allow players to seamlessly interact with other players and enhance their gaming experience.</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-lg-4 col-xxl-4">
									<div className="board-features">
										<div className="board-featur-img">
											<img src="../assets/img/GameDevelopment/Group-8.svg" alt="img" />
											<h4 className="text-white mt-30"><strong>Invite & Earn Referral</strong></h4>
											<p className="text-white mt-20">Run an invite & earn campaign where players can invite their friends and family through referral codes and earn cash.</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-lg-4 col-xxl-4">
									<div className="board-features">
										<div className="board-featur-img">
											<img src="../assets/img/GameDevelopment/private-mode-card-new.svg" alt="img" />
											<h4 className="text-white mt-30"><strong>Online Multiplayer</strong></h4>
											<p className="text-white mt-20">The multiplayer module enables players to play with other players in real-time on the gaming app or platform.</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-lg-4 col-xxl-4">
									<div className="board-features">
										<div className="board-featur-img">
											<img src="../assets/img/GameDevelopment/Group-8.svg" alt="img" />
											<h4 className="text-white mt-30"><strong>Game History</strong></h4>
											<p className="text-white mt-20">It is a valuable tool for tracking a player’s progress and performance over time and adds a new level of depth and engagement to the gaming experience.</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-lg-4 col-xxl-4">
									<div className="board-features">
										<div className="board-featur-img">
											<img src="../assets/img/GameDevelopment/Group-8.svg" alt="img" />
											<h4 className="text-white mt-30"><strong>In-App Purchases</strong></h4>
											<p className="text-white mt-20">We also offer a bunch of exciting in-store games to encourage your players to spend more time on your platform.</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-lg-4 col-xxl-4">
									<div className="board-features">
										<div className="board-featur-img">
											<img src="../assets/img/GameDevelopment/private-mode-card-new.svg" alt="img" />
											<h4 className="text-white mt-30"><strong>Daily Bonus</strong></h4>
											<p className="text-white mt-20">Daily Bonus motivates players to come back each day by keeping them engaged and hooked with your board game app.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				
				<section className="board-app">
					<div className="container">
						<div className="board-app-devlp">
							<div className="row">
								<div className="col-md-6">
									<div className="board-app-devlp-img">
										<img src="../assets/img/GameDevelopment/boardgame2.png"  alt="img"/>
									</div>                                
								</div>
								<div className="col-md-6">
									<div className="board-app-text pt-30 text mt-30">
										<h2 className="pt-20 text-start">Hire Board Game App Developers from OENGINES</h2>
										<p className="pt-15">Being a competent and fully-fledged online Board Game App Development Company, Oengines Technologies offers a
										comprehensive range of development and design solutions. Our board game developers bring up innovative
										development ideas to overcome obstacles and hurdles.</p>
										<p className="pt-15">No matter whether you require a 2D or 3D board games app, our experienced and talented developers will help you
										achieve this dream. The Board Game App will be able to run on Windows, iOS, Android, and Desktop.</p>
										<p className="pt-15">We also provide support and maintenance services after the development so that your Board Game app runs flawlessly.</p>
									</div>
								</div>
							</div>
						</div>	
					</div>
				</section>
				<section className="board-contect-details pt-50 pb-50">
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 board-span">
								<h2>Contact Us</h2>
								<h4 className="text-white mt-35">Discuss your thoughts with our dedicated team and get to start a new business goal with board game development, let us connect with you to start your business in the gaming industry</h4>
								<button className="theme-button mt-40" onClick={handleShow}><strong>GET IN TOUCH</strong></button>
							</div>
							<div className="col-md-6 col-sm-12 col-lg-6 col-xl-6">
								<div className="chess-img">
									<img src="../assets/img/GameDevelopment/sec-7-img.png" alt="img"/>
								</div>
							</div>
						</div>
					</div>
				</section>	
				{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}		
			<Footer />
		</div>

	);
}
