import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';



export default function Racing() {	
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  	
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Racing'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};

	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }

	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />

			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>

			<section id="hero" className="racing-section hero-section division">
				<div className="container">
					<div className="row card-hero pb-100 text-center">
						<div className="col-md-12 col-lg-12 mt-80">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Racing Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt action-hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color max-conent m-auto mt-40">We are a smart development company mixing talented, passionate and community-driven developers making racing games for the players and sharing core values!</p>
								</ScrollAnimation>
							</div>
							<a href="tel:+02614054978" ><button className="theme-button mt-4">Call Now</button></a>
						</div>
					</div>
				</div>
			</section>
        
			<section className="racing-game pt-80 pb-60">
				<div className="container">
                    <div className="row">
                        <div className="action-heaing col-md-7 p-4">
                            <h2 className="text-start">Racing Game</h2>
                            <div className="elementor-divider racing-elementor-divider">
                                <span className="elementor-divider-separator">
                                </span>
                            </div>
                            <p className="pt-30 text-start text mt-30">Car racing games are one of the most popular genres in the gaming world. These games offer an adrenaline-fueled, fast-paced experience that can keep players engaged for hours.</p>
                            <p className="text mt-30">Racing games have been popular in the gaming industry for decades. Games like Need for Speed and Asphalt
                            are an integral part of our childhood. PC racing games have been dominating the industry for a long time but the popularity of these games has shattered the platform boundaries and moved on to the mobile sector.</p>
                            <p className="text mt-30">With the introduction of emerging technologies, racing game development companies are now focusing on
                            the mobile platform and developing online racing games specifically for Android & iOS. Racing games have been dominating the mobile sector and with the arrival of advanced technologies, the mobile gaming experience has reached new heights.</p>
                        </div>
                        <div className="col-md-5 mt-20">
                            <div className="racing-gif">
                                <img src="../assets/img/GameDevelopment/car-racing-game-app.gif" alt="img not found" />
                            </div>
                        </div>
                    </div>
				</div>
			</section>
		
            <section className="game-benefits threeD-game-benefit ">
                <div className="container custom-container"> 
				    <div className="racing-fetures">
						<h2 className="text-center mb-60">Features of Racing game Development</h2>
					</div>         
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="benefits-box"> <i className="icons icon1"></i>
                                <h3 className="f-24 mb-20">World Map</h3>
                                <p>The world map is one of the essential features of a racing game application. Maps show the optimal
                                route to the finishing line. Some open-world racing games provide a map of a whole area or city where the user can freely roam.</p>
                            </div>
                            <div className="benefits-box"> <i className="icons icon2"></i>
                                <h3 className="f-24 mb-20">Interactive Map Elements</h3>
                                <p>Most racing games nowadays provide a non-interactive<a href="https://theninehertz.com/services/game-development-company/2d">3D model </a>,of a map. It would be great to have more interactive elements in an open-world racing car game.</p>
                                 
                            </div>
                            <div className="benefits-box"> <i className="icons icon3"></i>
                                <h3 className="f-24 mb-20">Daily Missions</h3>
                                <p>In-game challenges provide a more interactive and engaging racing experience. These are various
                                missions that the user has to complete to win additional rewards.</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 ios-b-img">
                            <picture><source type="image/webp" data-lazy-srcset="https://theninehertz.com/wp-content/themes/ninehertz/assets-2022/img/3d-game-development/webp/3d-game-benefits.webp" width="509" height="800" srcSet="https://theninehertz.com/wp-content/themes/ninehertz/assets-2022/img/3d-game-development/webp/3d-game-benefits.webp" /><source type="image/png" data-lazy-srcset="https://theninehertz.com/wp-content/themes/ninehertz/assets-2022/img/3d-game-development/png/3d-game-benefits.png" width="509" height="800" srcSet="https://theninehertz.com/wp-content/themes/ninehertz/assets-2022/img/3d-game-development/png/3d-game-benefits.png" /><img decoding="async" className="img-fluid anim1 entered lazyloaded" src="https://theninehertz.com/wp-content/themes/ninehertz/assets-2022/img/3d-game-development/png/3d-game-benefits.png" alt="3D Game Development Benefits" width="509" height="800" data-lazy-src="https://theninehertz.com/wp-content/themes/ninehertz/assets-2022/img/3d-game-development/png/3d-game-benefits.png" data-ll-status="loaded" /><noscript><img decoding="async" className="img-fluid anim1" src="https://theninehertz.com/wp-content/themes/ninehertz/assets-2022/img/3d-game-development/png/3d-game-benefits.png" alt="3D Game Development Benefits" width="509" height="800" /></noscript> </picture>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 row justify-conent-center">
                            <div className="benefits-box col-md-6 col-lg-12"> <i className="icons icon4"></i>
                                <h3 className="f-24 mb-20">Instant Replay</h3>
                                <p>The Instant replay feature allows users to see the replay if their cars are destroyed in an accident. It is a
                                great feature that lets players know their faults and improve their driving skills. Instant replay also works whenever you finish a race.</p>
                            </div>
                            <div className="benefits-box col-md-6 col-lg-12 sb1"> <i className="icons icon5"></i>
                                <h3 className="f-24 mb-20">Multiplayer Gaming</h3>
                                <p>The thrill of racing cars is taken to the next level when it is a head-to-head competition. Multiplayer
                                gaming allows players to challenge other players to a race. The players can race with anyone in the world on the map of their choice.</p>
                            </div>
                            <div className="benefits-box col-md-6 col-lg-12 sb1"> <i className="icons icon6"></i>
                                <h3 className="f-24 mb-20">Innovative Graphics</h3>
                                <p>The graphics in the unity platform are really innovative which ensures the quality of your 3D games.</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center"><a className="btn mt-30" href="https://theninehertz.com/contact-us">Start Your Game Project Together <i className="arrow"></i></a></div>
                </div>
            </section>

            
            <section className="why-choose-racing pt-80 pb-60">
				<div className="container">
					<div className="why-choose-text hire-our-action-text p-3">
						<h2 className="text-center">Why Choose OEngines for RACING GAME Development</h2>
						<p className="text-start mt-30 text">Graphical content is what differentiates one car racing game from others. Bearing that in mind our team of creative designers worked dedicatedly and formed an extensive set of graphical illustrations to create a game outline. Additionally, there were several game interfaces to be built so that players can perform various game
                        associated activities. Therefore, a good amount of time and effort was invested by our talented, skilled and experienced game designers.</p>
						<ul>
							<li>We create exciting, future-proof gaming solutions.</li>
							<li>Extremely Reliable.</li>
							<li>100% data security for users.</li>
							<li>Delivering cost-effective solutions.</li>
							<li>To help our clients and address any development-related questions, we are available 24/7.</li>							
						</ul>
					</div>
				</div>
			</section>

            <section id="choose_poker" className="why-poker pb-100">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-lg-6">
							<div className="poker-choose-desc ">
								<h2>Benefits of Playing Racing Game</h2>
								<p className="mt-30 text">
									Racing games imitate real-life driving, and it helps most people learn how to drive without causing any damage. They are non-violent, help to calm stress and improve the brain in many ways. While racing games should not be the only lesson to drive on the road, it could be helpful, as it makes it easier when you start real-life training.
								</p>
								<ul className="mt-30 text">							
									<li>Improves Concentration and Focus</li>
									<li>Helps in Decision Making</li>
									<li>Improves Memory</li>
									<li>Helps in Strategy and Problem Solving</li>
									<li>Improves Coordination</li>
									<li>Offers an Exciting Break from Boredom</li>
								</ul>
							</div>							
						</div>
						<div className="col-md-6">
							<div className="choose-img"><picture> <img decoding="async" title="poker game developer" src="../assets/img/GameDevelopment/3D-Environment11.png" alt="poker game developer" /></picture></div>
						</div>
					</div>										
				</div>
			</section>

            <section className="racing-contect pt-70 pb-70">
				<div className="container  pt-60 pb-60">
                    <div className="row justify-content-center">
                        <div className="hire-our-action-text racing-contect-mian col-md-8 col-xl-8">
                            <h2 className="text-white">Build a Successful Racing Game with Our winning process</h2>
                           <button className="theme-button mt-30" onClick={handleShow}>GET IN TOUCH</button>
                        </div>
                    </div>
				</div>
			</section>
			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}
          
			<Footer />
		</div>

	);
}
