import  React  from "react";
import { Helmet } from 'react-helmet';
import '../../../src/assets/css/app.min.css';
import '../../../src/assets/css/app.min.css';
import '../../../src/assets/css/fontawesome.min.css';
import 'react-awesome-slider/dist/styles.css';
import '../../assets/css/responsive.css'
import '../../../src/assets/css/Slick_Cdn/bootstrap.min.css';
import axios from 'axios';
import Footer from "../../Footer";
import Swal from "sweetalert2";
import Header from "../../Header";
import { Modal } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
class webbasedpage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
            setContactFormBool: false,
            show: false,
            isOpen: false,
            isActive: false,
            selectedDate: null,
            isAnimationRunning: true,
            autoplay: true,
            formfile: ''    
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this);
      
    }
    componentDidMount() {

        axios.get("https://www.oengines.com/careers")
            .then(response => {
                console.log("Data is::::::::", response.data);
                this.setState({ Job: response.data });
                this.setState({ Description: response.data[0].description });

            })
            .catch(function (error) {
                console.log(error);
            })
    }
       
    openModal(e) {
        this.setState({ isOpen: !this.state.isOpen });
    }
    handleOpenContectModal(e) {
        this.setState({ show: !this.state.show });
        console.log("state is::::::::", this.state.show);
    }
    handlevideopopup(e) {
        console.log("::::::add on this:");
        this.setState({ isActive: !this.state.isActive });
    }
    onContactFormHide(e) {
        console.log(" event ::::onContactFormHide:: ", this.state.setContactFormBool);
        this.setState({ setContactFormBool: false });
    }
    onContactFormShow(e) {
        console.log(" event ::onContactFormShow:::: ", this.state.setContactFormBool);        
        this.setState({ show: !this.state.show })
    }
    onClickShowdetails(job) {
        console.log("job is:::::::", job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    handleChangedatepicker = (date) => {
        this.setState({
            selectedDate: date,
        });
    };
    onClickblogdetails(blog) {
        console.log("blog is:::::::", blog);
        localStorage.setItem("Blog", JSON.stringify(blog));
        window.location.href = '/blogdetails';
    }
    closeModal() {
        this.setState({ show: !this.state.show });
    };
    handleClickInsideModal = (event) => {
        event.stopPropagation();
    }
    maxlengthnumber(evt) {
        if (evt.target.value.length === 11) {
            return false;
        }
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};
        console.log("Enter in::::::::::::");
        if (this.state.name === '' || this.state.name === null) {

            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.message === '' || this.state.message === null) {
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
       
        else {

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: 'Webbased' + '-' + this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,
                formfile: this.state.formfile
            };
            console.log("formObject::::::::::;", formObject);
            axios.post("https://www.oengines.com/contactus", formObject)
                .then((res) => {
                    console.log("Response is:::::::::", res);
                    if (res.data !== 'undefined') {
                        Swal.fire({
                            title: "Thank you!",
                            text: "Your request has been assigned successfully.",
                            icon: "success",
                        }).then(function () {
                            window.location.reload();
                        });
                    } else {
                        Swal.fire({
                            title: "Oops...!",
                            text: "Oh! Something went wrong, please try again!",
                            icon: "error",
                        }).then(function () {
                            window.location.reload();
                        });
                    }

                });
        }


        this.setState({
            name: '',
            email: '',
            subject: '',
            number: '',
            message: ''

        })

    }

    render() {
        const inputColor = {
            color: "#121212 !important" 
        }
        var Information;
        if (this.state.Job.length) {
            Information = this.state.Job.map((job) =>
                <>
                    <li>
                        <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>
                </>
            )
        } else {
            Information = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }
        const { nameError, emailError, subjectError, numberError, toDateError, messageError } = this.state.formErrors;
        return (
            <>
                <Helmet>

                    <link href="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/css/coreui.min.css" rel="stylesheet" integrity="sha384-UkVD+zxJKGsZP3s/JuRzapi4dQrDDuEf/kHphzg8P3v8wuQ6m9RLjTkPGeFcglQU" crossorigin="anonymous" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                    <script src="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/js/coreui.bundle.min.js" integrity="sha384-n0qOYeB4ohUPebL1M9qb/hfYkTp4lvnZM6U6phkRofqsMzK29IdkBJPegsyfj/r4" crossorigin="anonymous"></script>                    
                    <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js" type="text/javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                    <script src="../assets/js/mian.js" type="Javascript"></script>
                    <script src="../assets/js/jquery-min.js"></script>                    
                    <script src="../assets/js/Datetimepicker.full.min.js"></script>
                    <script src="../assets/js/jquery-particles.js"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-circle-progress/1.1.3/circle-progress.min.js"></script>
                    <script src="../assets/js/script.js" type="text/Javascript"></script>
                </Helmet>
                {/* <div className="loader" ><span>Loading...</span></div> */}
                <Header />
                <Modal show={this.state.show} onClick={this.handleClickInsideModal} className="modal_contect" centered >
                    <Modal.Header closeButton onHide={(e) => this.handleOpenContectModal()}>
                        <h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.onSubmit} method="post" className="contact-form ajax-contact">
                            <div className="row">
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                    <i className="fal fa-user"></i>
                                    {nameError &&
                                        <div className="invalid-feedback" >{nameError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} autoComplete="Off" />
                                    <i className="fal fa-envelope"></i>
                                    {emailError &&
                                        <div className="invalid-feedback">{emailError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <select name="subject" id="subject" ref="subjectfield" className={subjectError ? "form-control is-invalid" : "form-control"} onChange={this.handleChange} autoComplete="Off">
                                        <option disabled="disabled">Select Category
                                        </option>
                                        <option defaultValue={"Action"}>Action</option>
                                        <option defaultValue={"Real money"}>Real money</option>
                                        <option defaultValue={"Puzzle"}>Puzzle</option>
                                        <option defaultValue={"Adventure"}>Adventure</option>
                                        <option defaultValue={"Arcade"}>Arcade</option>
                                        <option defaultValue={"MMO RPG"}>MMO RPG</option>
                                        <option defaultValue={"Casual"}>Casual</option>
                                        <option defaultValue={"Card"}>Card</option>
                                        <option defaultValue={"Racing"}>Racing</option>
                                        <option defaultValue={"Sports"}>Sports</option>
                                    </select>
                                    {subjectError &&
                                        <div className="invalid-feedback">{subjectError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} name="number"  ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange={this.maxlengthnumber} value={this.state.number} />
                                    <i className="fal fa-phone"></i>
                                    {numberError &&
                                        <div className="invalid-feedback">{numberError}</div>
                                    }
                                </div>
                                    <div className="form-group col-12">
                                        <input className="form-control datetimepickerone d-desktop" id="datetimepickerone" name="toDate" onBlur={this.endDateChange} type="text" ref="toDatefield" autoComplete="off" placeholder="Appointment Date" />
                                        <i className="fal fa-calendar"></i>
                                        {toDateError &&
                                            <div className="invalid-feedback">{toDateError}</div>
                                        }
                                    </div>
                                    
                                <div className="form-group col-12">
                                    <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                        className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange} autoComplete="Off">
                                    </textarea>
                                    <i className="fal fa-comment"></i>
                                    {messageError &&
                                        <div className="invalid-feedback">{messageError}</div>
                                    }
                                </div>
                                <div className="form-btn text-xl-start text-center col-12">
                                    <button type="submit" className="as-btn style3 send-qty">Send Details
                                        <i className="fa-regular fa-arrow-right ms-2"></i>
                                    </button>
                                </div>
                            </div>
                            <p className="form-messages mb-0 mt-3"></p>
                        </form>
                    </Modal.Body>
                </Modal>

                <section className="hero-webbsed">  
                    <div className="container-fliued">
                        <div className="row m-0">
                            <div className="col-md-6 hero-main">
                                <div className="hero-text">
                                    <h2 className="text-white mb-4">
                                        HTML5 Game Development
                                    </h2>
                                    <p className="text-white">
                                        Oengines is the premier HTML5 game development company that combines top-notch design, development, art, and quality assurance expertise to deliver immersive, engaging games. Our HTML5 game developers are skilled at creating captivating games across various genres that can easily adapt to different screen sizes and input options while delivering an unparalleled gaming experience. At Oengines, we prioritize fun and interactivity in every game we develop. With our commitment to excellence and attention to detail, you can trust us on our HTML5 game development services!
                                    </p>
                                    <button className="as-btn-web as-btn" onClick={(e) => this.handleOpenContectModal(e)}>
                                        <a href="#!">                                       
                                            <i className="fa fa-phone" aria-hidden="true"></i>                                            
                                            Contact Us</a>
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="web-img">
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
                <section className="ptb--60 html-solution-one">
                    <div className="container">
                        <div className="title-mian text-center">
                            <h2>HTML5 Game Development Company</h2>
                            <div className="html-text">
                                <p>
                                    Oengines Technologies is a leading HTML5 gaming development company that offers a comprehensive range of gaming solutions based on this dynamic language. We specialize in creating interactive and engaging HTML5 games that are tailored to the specific needs of your business. With our extensive experience in the field, we understand the nuances of HTML5 game development and are committed to creating high-quality, customized games that deliver an unmatched gaming experience.
                                </p>
                                <p>
                                    Our team of expert HTML5 developers possesses in-depth knowledge of the language and its capabilities, allowing us to create games that stand out from the crowd. We have successfully developed HTML5 games for clients from around the world, ranging from small businesses to large enterprises. Our ability to understand the unique requirements of each client and deliver customized solutions has helped us earn a reputation as one of the most trusted names in the industry.
                                </p>
                                <p>
                                    At Oengines Technologies, we strive to provide our clients with the highest level of professionalism and quality in every project we undertake. Our focus on innovation and customer satisfaction has made us a preferred partner for businesses looking to create unique gaming experiences for their customers.
                                </p>

                            </div>

                        </div>

                    </div>
                </section>
                <section className="ptb--60 html-solution-two pt-0">
                    <div className="container">
                        <div className="title-mian text-center">
                            <h2>HTML5 Game Development Solutions</h2>
                            <div className="html-text">
                                <p className="html-sub-text">
                                    Oengines Technologies is a trusted and established provider of premium web game development solutions, with a strong emphasis on HTML5. We take pride in our team of proficient game developers who are constantly conducting research into the latest trends and technologies in the gaming industry to ensure that our services are always cutting-edge and effective. Our HTML5 game development approach is highly innovative, and we leverage the power of advanced WebGL and HTML5 language to create incredibly immersive and visually stunning web games that captivate and engage players of all ages.
                                </p>
                            </div>
                            <div className="row game-solv">
                                <div className="col-md-6">
                                    <img src="assets/img/banner/HTML5.jpg" alt="img not found" />
                                </div>
                                <div className="col-md-6">
                                    <ul className="elementor-icon-list-items text-start mt-15 ps-0">
                                        <li className="elementor-icon-list-item">
                                            <span className="elementor-icon-list-icon">
                                                <i aria-hidden="true" className="fas fa-check"></i></span>
                                            <span className="elementor-icon-list-text">With years of experience in developing HTML5 games, we develop the most unique games that deliver the best-in-class gaming experience to the users. </span>
                                        </li>
                                        <li className="elementor-icon-list-item">
                                            <span className="elementor-icon-list-icon">
                                                <i aria-hidden="true" className="fas fa-check"></i></span>
                                            <span className="elementor-icon-list-text">Our developers have the perfect idea of how the HTML5 language works, and they use this knowledge to develop unmatched games for clients from all around the world. </span>
                                        </li>
                                        <li className="elementor-icon-list-item">
                                            <span className="elementor-icon-list-icon">
                                                <i aria-hidden="true" className="fas fa-check"></i></span>
                                            <span className="elementor-icon-list-text">Our expert HTML5 Developers will comprehend the needs of your product, and they can turn your idea into an interactive game that is filled with wonderful features.</span>
                                        </li>
                                        <li className="elementor-icon-list-item">
                                            <span className="elementor-icon-list-icon">
                                                <i aria-hidden="true" className="fas fa-check"></i></span>
                                            <span className="elementor-icon-list-text">We use HTML5 to develop games that can be used to target multiple platforms. Our games are filled with great features, epic sounds, amazing graphics, and a very engaging storyline. </span>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="html-services ptb--60">
                    <div className="container">
                        <div className="over-html-service-text mt-20">
                            <h2 className="text-center">HTML5 Game Development Services</h2>
                            <div className="html-text">
                                <p className="html-sub-text">
                                    With our focus on innovation and customer satisfaction, we are committed to delivering high-quality solutions that meet the unique requirements of each client. Whether you are looking to develop a game for the web or mobile, trust Oengines to bring your vision to life with our HTML5 game development services.
                                </p>
                            </div>
                        </div>
                        <div className="row main-html-service">
                            <div className="col-md-3">
                                <div className="unty-srv-img">
                                    <img src="assets/img/service/HTML_serv_1.jpg" alt="unity_services Not Found" />
                                </div>
                            </div>
                            <div className="col-md-9 mb-35">
                                <div className="srv-text">
                                    <h3 className="heading-main">
                                        HTML5 Game Development
                                    </h3>
                                    <p>We use HTML5 to develop games that can be used to target multiple platforms. Our games are filled with great features, epic sounds, amazing graphics, and a very engaging storyline. With HTML5 Game Development, we can turn your game idea into an amazing product.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="unty-srv-img">
                                    <img src="assets/img/service/HTML_serv_2.jpg" alt="unity_services Not Found" />
                                </div>
                            </div>
                            <div className="col-md-9 mb-35">
                                <div className="srv-text">
                                    <h3 className="heading-main">
                                        HTML5 UI Design and Prototyping
                                    </h3>
                                    <p>Our team of designers have extensive experience in designing the perfect Website or app UI and prototype based on the client’s needs and wants. We will conduct a niche-research and then we will design the most useful and reliable UI and prototype for your solution.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="unty-srv-img">
                                    <img src="assets/img/service/HTML_serv_3.jpg" alt="unity_services Not Found" />
                                </div>
                            </div>
                            <div className="col-md-9 mb-35">
                                <div className="srv-text">
                                    <h3 className="heading-main">
                                        HTML5 UI Design and Prototyping
                                    </h3>
                                    <p>AR/VR are emerging technologies that provide your HTML5 website or app with an extraordinary advantage over the competitors. Provide your users with the best experience and increase your user satisfaction. Upgrade your app or website now!</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="unty-srv-img">
                                    <img src="assets/img/service/HTML_serv_4.jpg" alt="unity_services Not Found" />
                                </div>
                            </div>
                            <div className="col-md-9 mb-35">
                                <div className="srv-text">
                                    <h3 className="heading-main">
                                        HTML5 Real Money Game Development
                                    </h3>
                                    <p>Real money games are dominating the gaming industry. Get the best out of this scenario with Oengines’s HTML5 Real Money Game Development service and get your real money game developed now. Let the user’s play and use their skills to earn real cash.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="unty-srv-img">
                                    <img src="assets/img/service/HTML_serv_5.jpg" alt="unity_services Not Found" />
                                </div>
                            </div>
                            <div className="col-md-9 mb-35">
                                <div className="srv-text">
                                    <h3 className="heading-main">
                                        HTML5 Support and Maintenance
                                    </h3>
                                    <p>We also provide extensive support and maintenance service to the clients. We also provide updates for your HTML5 website and app. Our developers will make sure that your website or app runs at 100% capacity and they will solve all the technical errors and challenges ASAP.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                
                <section className="unity-why-choose-area pt-100 pb-70 webbased_serve">
                    <div className="container">
                        <div className="over-html-service-text mb-30">
                            <h2 className="text-center text-white">Html5 Game Genres We Serve</h2>
                            <div className="html-text">
                                <p className="html-sub-text text-white">
                                    With HTML5 we target a wide range of genres across various platforms. Here is a list of game genres for which we can develop HTML5 Games.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/webbasedCategories/action.jpg" alt="image" />

                                    <div className="title">                                        
                                            <h3>Action</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">
                                            <h3>
                                                <a>Action</a>
                                            </h3>
                                            <p className="unity-serv-par">Action is the game genre that focuses on fighting with fists, weapons, or various martial arts. It also focuses on physical challenges and tests the user’s reaction time.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/webbasedCategories/adventure.jpg" alt="image" />

                                    <div className="title">                                        
                                            <h3>Real money</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">                                            
                                                <h3>
                                                    <a>Real money</a>
                                                </h3>                                                                                        
                                                <p className="unity-serv-par">Real money games can play with real money. These games are mostly multiplayer in which the winner gets the money.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/webbasedCategories/arcade.jpg" alt="image" />

                                    <div className="title">                                        
                                            <h3>Puzzle</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">                                            
                                                <h3>
                                                    <a>Puzzle</a>
                                                </h3>                                                                                        
                                                <p className="unity-serv-par">Puzzle games test the ingenuity and knowledge of the players. The player has to solve the puzzle or mystery by using logic and a lot of brain.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/webbasedCategories/card.jpg" alt="image" />

                                    <div className="title">                                        
                                            <h3>Adventure</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">                                            
                                                <h3>
                                                    <a>Adventure</a>
                                                </h3>                                                                                        
                                                <p className="unity-serv-par">Adventure games allow the user to control the main player that is ready to enmark a new journey and pass missions in a fantasy world.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/webbasedCategories/casual.jpg" alt="image" />
                                    <div className="title">                                        
                                            <h3>Arcade</h3>                                        
                                    </div>
                                    <div className="content">
                                        <div className="inner-content">                                            
                                                <h3>
                                                    <a>Arcade</a>
                                                </h3>                                                                                        
                                                <p className="unity-serv-par">Arcade games focus more on fast-paced gaming activity in which there are various levels in the game with increasing challenges.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/webbasedCategories/puzzle.jpg" alt="image" />
                                    <div className="title">                                        
                                        <h3>MMO RPG</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">                                            
                                            <h3>
                                                <a>MMO RPG</a>
                                            </h3>                                                                                        
                                            <p className="unity-serv-par">Massively multiplayer role playting games is currently trending and dominating the market.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/webbasedCategories/race.jpg" alt="image" />

                                    <div className="title">                                        
                                            <h3>Casual</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">                                            
                                            <h3>
                                                <a>Casual</a>
                                            </h3>                                                                                        
                                            <p className="unity-serv-par">Casual games are games that have simple rules, shooter sessions, and require less learned skills to play. The player can play these games spontaneously.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/webbasedCategories/realmoney.jpg" alt="image" />

                                    <div className="title">                                        
                                            <h3>Card</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">                                            
                                            <h3>
                                                <a>Card</a>
                                            </h3>                                                                                        
                                            <p className="unity-serv-par">Card games consist of various variations of card games like Teen Patti, Poker, and so on. These games also include fantasy cards like UNO and pokemon cards.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/webbasedCategories/rpg.jpg" alt="image" />

                                    <div className="title">                                        
                                            <h3>Racing</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">                                            
                                            <h3>
                                                <a>Racing</a>
                                            </h3>                                                                                        
                                            <p className="unity-serv-par">Racing games allow the player to control a car, bike, or other vehicle and race against the computer or real players.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/webbasedCategories/sports.jpg" alt="image" />

                                    <div className="title">                                        
                                            <h3>Sports</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">                                            
                                            <h3>
                                                <a>Sports</a>
                                            </h3>                                                                                        
                                            <p className="unity-serv-par">Sports games include Baseball, Cricket, Soccer, Volleyball, Tennis, and so on. The new entry in sports games is fantasy sports game.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ptb--60 html-benift">
                    <div className="container">
                        <div className="over-html-service-text">
                            <h2 className="text-center">Benefits of choosing HTML5 for Game Development</h2>
                            <div className="html-text">
                                <p className="html-sub-text">
                                    HTML5 is the latest version of the Hyper Text Markup Language and is extremely useful in developing engaging and attractive apps and websites. Here is a list of benefits that this extremely useful language offers
                                </p>
                            </div>
                        </div>
                        <div className="row benifits-main-rw">
                            <div className="col-md-4 col-sm-6 col-lg-4">
                                <div className="benifits-card">
                                    <img src="assets/img/icon/beniftis-1.jpg" alt="img" />
                                    <h3>Cost Effective</h3>
                                    <p>
                                        The game developed using HTML5 can work on all the latest mobile devices, and you can target any operating system using the same coding. With small changes, screen-size can be adjusted and therefore HTML5 is a cost effective language.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-lg-4">
                                <div className="benifits-card">
                                    <img src="assets/img/icon/beniftis-2.jpg" alt="img" />
                                    <h3>Earn more revenue</h3>
                                    <p>
                                        With HTML5 you can use the same code for multiple platforms and you can target a large audience. This provides you more exposure and as a result you will earn more revenue as you will target and get more audience on your app.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-lg-4">
                                <div className="benifits-card">
                                    <img src="assets/img/icon/beniftis-3.jpg" alt="img" />
                                    <h3>Target vast audience</h3>
                                    <p>
                                        Targeting a single platform will hinder your growth and will provide you with a limited audience. However, with HTML5 games, your game will be available for as many platforms as you want and therefore you will be able to target a vast audience.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-lg-4">
                                <div className="benifits-card">
                                    <img src="assets/img/icon/beniftis-4.jpg" alt="img" />
                                    <h3>Easily integrate advertisements</h3>
                                    <p>
                                        The HTML5 game allows you to show as many advertisements as you want. Integrating the advertisements in HTML5 games is a comparatively easy task. You can show the advertisements anywhere, you can show them in the game or around the game too.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-lg-4">
                                <div className="benifits-card">
                                    <img src="assets/img/icon/beniftis-5.jpg" alt="img" />
                                    <h3>Flexibility in installation</h3>
                                    <p>
                                        You can distribute your HTML5 game however you want. You can launch it through app stores, or as it is an HTML5 game, you can always distribute it using the browser. You can also choose if you want your users to install your game, or directly use your game.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="ptb--60 gm-process">
                    <div className="container">
                        <div className="over-html-service-text">
                            <h2 className="text-center text-white">HTML5 Game Development Process</h2>
                            <div className="html-text">
                                <p className="html-sub-text text-white">
                                    The process that is used to develop the game, has a huge impact on the final product. Here is our HTML5 game development process that facilitates us in developing outstanding HTML5 games
                                </p>
                            </div>
                        </div>
                        <div className="mobile-process row">
                            <div className="col-md-4 col-4">
                                <div className="mobile-app-dev-blocks">
                                    <div className="mobile-app-dev-block mobile-app-dev-block-1">
                                        <p className="mobile-app-text">Understanding your requirements</p>
                                    </div>
                                    <div className="mobile-app-dev-block mobile-app-dev-block-2">
                                        <p className="mobile-app-text">Designing game structure</p>
                                    </div>
                                    <div className="mobile-app-dev-block mobile-app-dev-block-3">
                                        <p className="mobile-app-text">Game Development</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-4">
                                <div className="mbl-img">
                                    <img src="assets/img/techs/Mobile.jpg" alt="img" />
                                </div>
                            </div>
                            <div className="col-md-4 col-4">
                                <div className="mobile-app-dev-blocks">
                                    <div className="mobile-app-dev-block mobile-app-dev-block-4">
                                        <p className="mobile-app-text">Quality Analysis and Testing</p>
                                    </div>
                                    <div className="mobile-app-dev-block mobile-app-dev-block-5">
                                        <p className="mobile-app-text">Launching the game</p>
                                    </div>
                                    <div className="mobile-app-dev-block mobile-app-dev-block-6">
                                        <p className="mobile-app-text">Support and Maintenance</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ptb--60">
                    <div className="container">
                        <div className="over-html-service-text">
                            <h2 className="text-center">Our Advantages for HTML5 Game Development</h2>
                            <div className="html-text">
                                <p className="html-sub-text">
                                    We offer various advantages with our HTML5 Game Development Service that keeps us ahead of the competition and our clients happy. <br/> Advantages of Oengines HTML5 Game Development are as follow,
                                </p>
                            </div>
                        </div>
                        <div className="our-advantage row">
                            <div className="col-md-6">
                                <div className="d-flex mt-20 justify-content-center">
                                    <div className="avnatge-icon">
                                        <img src="assets/img/icon/html5.jpg" alt="img not found" />
                                    </div>
                                    <div className="avntage-text">
                                        <h4>Robust HTML5 Game Performance</h4>
                                        <p>
                                            The work that we do defines us, and we want to be defined as the best; and therefore, all our HTML5 games work seamlessly across all devices.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex mt-20 justify-content-center">
                                    <div className="avnatge-icon">
                                        <img src="assets/img/icon/idea.jpg" alt="img not found" />
                                    </div>
                                    <div className="avntage-text">
                                        <h4>Visionary Game Concepts</h4>
                                        <p>
                                            Our HTML5 game developers are serious game lovers, and therefore they know what the audience wants and needs, and with this, they can develop visionary game concepts for you.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex mt-20 justify-content-center">
                                    <div className="avnatge-icon">
                                        <img src="assets/img/icon/ninja.jpg" alt="img not found" />
                                    </div>
                                    <div className="avntage-text">
                                        <h4>Creative UI & Character Design</h4>
                                        <p>
                                            HTML5 provides the developers and designers with all the tools that are needed to develop amazing UI & characters for the game.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex mt-20 justify-content-center">
                                    <div className="avnatge-icon">
                                        <img src="assets/img/icon/money-bag.jpg" alt="img not found" />
                                    </div>
                                    <div className="avntage-text">
                                        <h4>Cost-Effective Solutions</h4>
                                        <p>
                                            We understand the cut-throat competition that prevails in the market, and therefore we have kept our pricing policy highly flexible that can fit every budget.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex mt-20 justify-content-center">
                                    <div className="avnatge-icon">
                                        <img src="assets/img/icon/customer-service-2.jpg" alt="img not found" />
                                    </div>
                                    <div className="avntage-text">
                                        <h4>End-to-End Support</h4>
                                        <p>
                                            From planning, design, development, deployment, testing, launching to after services, our HTML5 game    development process covers it all.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex mt-20 justify-content-center">
                                    <div className="avnatge-icon">
                                        <img src="assets/img/icon/maintenance.jpg" alt="img not found" />
                                    </div>
                                    <div className="avntage-text">
                                        <h4>Maintenance & Support</h4>
                                        <p>
                                            To keep something up and running, you have to maintain it. We also work on any changes that you want and on the updates too.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="platform pt-80">
                    <div className="container">
                        <div className="over-html-service-text">
                            <h2 className="text-center">HTML5 Game Development Platforms</h2>
                            <div className="html-text">
                                <p className="html-sub-text">
                                    To create an awesome and easy interface for the admin, we have added tons of control features for users that they can use to personalize their gameplay.
                                </p>
                            </div>
                        </div>
                        <div className="over-platforms row">
                            <div className="col-md-6 col-sm-6 col-lg-4 col-xxl-4 plt-from">
                                <div className="paltform-card">
                                    <div className="plt-img-1 plt-img"></div>
                                    <div className="platform-text">
                                        <h3>GDevelop</h3>
                                        <p>GDevelop is a 2D cross-platform, free and open-source game engine, which mainly focuses on creating HTML5 games playable in the browser and on the mobile devices.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-4 col-xxl-4 plt-from">
                                <div className="paltform-card">
                                    <div className="plt-img-2"></div>
                                    <div className="platform-text">
                                        <h3>Construct 3 </h3>
                                        <p>Created by Scirra, Construct 3 is an HTML5 engine that is used to develop amazing 2D games. It is a powerful tool that comes with numerous great features to develop engaging games.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-4 col-xxl-4 plt-from">
                                <div className="paltform-card">
                                    <div className="plt-img-3"></div>
                                    <div className="platform-text">
                                        <h3>Cocos2d-X</h3>
                                        <p>Cocos2d-X is a package of game development tools that supports cross-browser and cross-platform game development. It is a script development and data-driven development tool.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-4 col-xxl-4 plt-from">
                                <div className="paltform-card">
                                    <div className="plt-img-4"></div>
                                    <div className="platform-text">
                                        <h3>PlayCanvas</h3>
                                        <p>PlayCanvas is a full-fledged 3D game development engine that comes with resource loading, advanced graphic manipulation, and many other features.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-4 col-xxl-4 plt-from">
                                <div className="paltform-card">
                                    <div className="plt-img-5"></div>
                                    <div className="platform-text">
                                        <h3>PixiJS</h3>
                                        <p>PixiJS is an HTML5 creation engine that is developer friendly and feature rich API. It is also one of the fastest and flexible WebGL renderers in the world.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-4 col-xxl-4 plt-from">
                                <div className="paltform-card">
                                    <div className="plt-img-6"></div>
                                    <div className="platform-text">
                                        <h3>Phaser</h3>
                                        <p>Phaser is a 2D game framework used for making HTML5 games for desktop and mobile. Our developers have expertise in developing incredible 2D games using this framework.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                   
                </section>
                <section className="ptb--60 html-games">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="htmlgame-img">
                                    <img src="assets/img/project/htmlgames.jpg" alt="img not found"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="htmlgame-content">
                                    <h2>How much does it cost to develop a HTML5 Game?</h2>
                                    <p>
                                        HTML5 game development can cost around $25,000 to $30,000 for a single platform with basic functionality. If you wish to develop a HTML5 Game with all the advanced features and functionalities, with support for multiple platforms, it may cost up to $250,000.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </section>
                <section className="ptb--60 cpter-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 cpt-padding-0">
                                <div className="cpter-img">
                                    <img src="assets/img/project/chpter.png" alt="img not found"/>
                                </div>
                                <div className="cpter-text-main">
                                    <div className="chter-text-inner">
                                        <h2 className="text-white">
                                            Why Oengines is the best HTML5 Game Development Company?
                                        </h2>
                                        <p className="text-white">
                                            Oengines Technologies is a reputed HTML5 Game development company that provides secure, scalable, sustainable, and cross-platform HTML5 solutions that leverage the best features, and design. Here is why Oengines is the best HTML5 Game Development company.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 cpter-bg">
                                <div className="row cpter-parts">
                                    <div className=" col-md-3 cpter-parts-box">
                                        <div className="cpter-part-img">
                                            <img src="assets/img/project/plt-1.jpg" alt="img" />
                                        </div>                                      
                                    </div>
                                    <div className="col-md-9">
                                        <div className="cpt-cont-text">
                                            <h4 className="text-white">Efficient HTML5 Developers</h4>
                                            <p className="text-white">We have a team of efficient HTML5 game developers who develop the next-gen games with best features and graphics.</p>
                                        </div>
                                    </div>
                                    <div className=" col-md-3 cpter-parts-box">
                                        <div className="cpter-part-img">
                                            <img src="assets/img/project/plt-2.jpg" alt="img" />
                                        </div>                                      
                                    </div>
                                    <div className="col-md-9">
                                        <div className="cpt-cont-text">
                                            <h4 className="text-white">Technological Expertise</h4>
                                            <p className="text-white">Our developers have on-hand experience and expertise in using HTML5 to develop ranking and awe-inspiring games.</p>
                                        </div>

                                    </div>
                                    <div className=" col-md-3 cpter-parts-box">
                                        <div className="cpter-part-img">
                                            <img src="assets/img/project/plt-3.jpg" alt="img" />
                                        </div>                                      
                                    </div>
                                    <div className="col-md-9">
                                        <div className="cpt-cont-text">
                                            <h4 className="text-white">Effective Development Process</h4>
                                            <p className="text-white">Structured HTML5 game development process helps us in developing the most amazing products.</p>
                                        </div>

                                    </div>
                                    <div className=" col-md-3 cpter-parts-box">
                                        <div className="cpter-part-img">
                                            <img src="assets/img/project/plt-4.jpg" alt="img" />
                                        </div>                                      
                                    </div>
                                    <div className="col-md-9">
                                        <div className="cpt-cont-text">
                                            <h4 className="text-white">End-to-end HTML5 Game Development</h4>
                                            <p className="text-white">From planning to post-support, our development process covers it all for HTML5 game development.</p>
                                        </div>

                                    </div>
                                    <div className=" col-md-3 cpter-parts-box">
                                        <div className="cpter-part-img">
                                            <img src="assets/img/project/plt-5.jpg" alt="img" />
                                        </div>                                      
                                    </div>
                                    <div className="col-md-9">
                                        <div className="cpt-cont-text">
                                            <h4 className="text-white">Impactful Design</h4>
                                            <p className="text-white">With a pool of designers we design the most graphical and easy to use graphics for HTML5 games.</p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="ptb--60 talk-sec">
                    <div className="container">
                        <div className="over-html-service-text">
                            <h2 className="text-center">Let's talk!</h2>                        
                        </div>
                        <div className="talk-main">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="get-in-touch">
                                        <img src="assets/img/project/Bachha.jpg" alt="img not found" />
                                        <div className="get-in-text">
                                            <h2 className="text-white text-frm" >Get in touch with us.</h2>
                                            <p className="text-white text-frm" >Get your idea transformed into a full-fledged & highly profitable mobile game/app.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <form onSubmit={this.onSubmit} method="post" className="contact-form" style={{padding: "50px 60px"}}>
                                        <div className="form-filed">                                            
                                            <input type="text" ref="namefield" className={nameError ? "form-control is-invalid " : "form-control "} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off"/>    
                                            {nameError &&
                                                <div className="invalid-feedback" >{nameError}</div>
                                            }                                       
                                        </div>
                                        <div className="form-filed row">
                                            <div className="col-md-6">                                                
                                                <input type="email" className={emailError ? "form-control  is-invalid" : " form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} autoComplete="Off" />   
                                                {emailError &&
                                                    <div className="invalid-feedback">{emailError}</div>
                                                }
                                                                                            
                                            </div>
                                            <div className="col-md-6">                                                
                                                <input type="number" className={numberError ? "form-control  is-invalid" : " form-control"} name="number" on ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange={this.maxlengthnumber} value={this.state.number}/>  
                                                {numberError &&
                                                    <div className="invalid-feedback">{numberError}</div>
                                                }                                            
                                            </div>                                     
                                        </div>
                                        <div className="form-filed">                                            
                                            <select name="subject" id="subject" ref="subjectfield" className={subjectError ? " form-control is-invalid" : " form-control"} onChange={this.handleChange} autoComplete="Off">
                                                <option defaultValue={""}>Select Game Category</option>
                                                <option defaultValue={"Action"}>Action</option>
                                                <option defaultValue={"Real money"}>Real money</option>
                                                <option defaultValue={"Puzzle"}>Puzzle</option>
                                                <option defaultValue={"Adventure"}>Adventure</option>
                                                <option defaultValue={"Arcade"}>Arcade</option>
                                                <option defaultValue={"MMO RPG"}>MMO RPG</option>
                                                <option defaultValue={"Casual"}>Casual</option>
                                                <option defaultValue={"Card"}>Card</option>
                                                <option defaultValue={"Racing"}>Racing</option>
                                                <option defaultValue={"Sports"}>Sports</option>
                                            </select>  
                                            {subjectError &&
                                                <div className="invalid-feedback">{subjectError}</div>
                                            }                                         
                                        </div>  
                                        <div className="form-filed row">
                                            <div className="col-md-6">                                                
                                                <input name="message" id="message" cols="10" rows="2" ref="messagefield"
                                                className={messageError ? " form-control is-invalid" : " form-control"} placeholder="Your Message" onChange={this.handleChange} autoComplete="Off"/>                                               
                                            </div>  
                                            <div className="col-md-6">
                                                <div className="form-field file-field">
                                                    <p>
                                                        <span className="wpcf7-form-control-wrap" data-name="file-field">
                                                        <input size="40" className="wpcf7-form-control wpcf7-file form-file" id="formfile" accept=".gif,.jpg,.jpeg,.pdf,.txt,.doc,.zip" aria-invalid="false" type="file" name="formfile" placeholder="Uploads" onChange={this.handleChange}/>
                                                        </span>
                                                        <span className="txt">Only Document, Image, or ZIP file. Size upto 10MB</span>
                                                    </p>
                                                </div>
                                            </div>                                         
                                        </div>
                                        <div className="form-filed row">                                  
                                            <div className="col-md-6 text-start">
                                                <button className="text-center btn-frm" type="submit">
                                                    SEND
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                            
                <Footer />
            </>
        )
    }
}

export default webbasedpage;

