import React from "react";
import { Helmet } from 'react-helmet';
import '../../src/assets/css/app.min.css';
import '../../src/assets/css/dark.css'
import '../../src/assets/css/responsive.css';
import '../../src/assets/css/app.min.css';
import '../../src/assets/css/style.css';
import '../../src/assets/css/fontawesome.min.css';
import OwlCarousel from 'react-owl-carousel';
import 'react-awesome-slider/dist/styles.css';
import Slider from "react-slick";
import axios from 'axios';
import Footer from "../Footer";
import Header from "../Header";
import ScrollAnimation from 'react-animate-on-scroll';
import { Modal } from 'react-bootstrap';
import Swal from "sweetalert2";

class mobiledevelopment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
            isActive: false,
            sliderRef: null
        }
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this);
    }
    componentDidMount() {

        axios.get("https://www.oengines.com/careers")
            .then(response => {
                console.log("Data is::::::::", response.data);
                this.setState({ Job: response.data });
                this.setState({ Description: response.data[0].description });

            })
            .catch(function (error) {
                console.log(error);
            })

    }
    onClickShowdetails(job) {
        console.log("job is:::::::", job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    maxlengthnumber(evt) {
        if (evt.target.value.length === 11) {
            return false;
        }   
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    handlevideopopup(e) {
        this.setState({ isActive: !this.state.isActive });
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};

        if (this.state.name === '' || this.state.name === null) {
            console.log("Nmae")
            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            console.log("mail")
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            console.log("subject")
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            console.log("number")
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.message === '' || this.state.message === null) {
            console.log("message")
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else {

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: 'MobileDevelopment' + '-' + this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,

            };
            console.log("formObject::::::::::;", formObject);
            axios.post("https://www.oengines.com/contactus", formObject)
                .then((res) => {
                    console.log("Response is:::::::::", res.data);
                    if (res.data !== 'undefined') {
                        console.log("Response is:::::::::", res.data);
                        Swal.fire({
                            title: "Thank you!",
                            text: "Your request has been assigned successfully.",
                            icon: "success",
                        }).then(function () {
                            window.location.reload();
                        });
                    } else {
                        Swal.fire({
                            title: "Oops...!",
                            text: "Oh! Something went wrong, please try again!",
                            icon: "error",
                        }).then(function () {
                            window.location.reload();
                        });
                    }

                });
        }
    }
    render() {
        const sliderSettings = {
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  arrows: false,
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 576,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                },
              },
            ],
          };
      
        var set = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                576: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 3,
                    nav: true,
                    loop: true
                },
                1200: {
                    items: 3,
                    nav: true,
                    loop: true
                }
            }
        };        
        const myStyleone = {
            backgroundImage:
                "url('../assets/img/bg/pattern.png')",                     
            zIndex: 2            
        };
        const myStyletwo = {
            backgroundImage:
            "url('../assets/img/project/bg27.jpg')"           
        };        

        const { nameError, emailError, subjectError, numberError, toDateError, messageError } = this.state.formErrors;
        var Information;
        if (this.state.Job.length) {
            Information = this.state.Job.map((job, Index) =>
                <>

                    <li>
                        <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>
                </>
            )
        } else {
            Information = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }
       
        return (
            <>
                <Helmet>

                    <link href="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/css/coreui.min.css" rel="stylesheet" integrity="sha384-UkVD+zxJKGsZP3s/JuRzapi4dQrDDuEf/kHphzg8P3v8wuQ6m9RLjTkPGeFcglQU" crossorigin="anonymous" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                    <script src="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/js/coreui.bundle.min.js" integrity="sha384-n0qOYeB4ohUPebL1M9qb/hfYkTp4lvnZM6U6phkRofqsMzK29IdkBJPegsyfj/r4" crossorigin="anonymous"></script>                    
                    <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js" type="text/javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                    <script src="../assets/js/script.js" type="text/Javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                </Helmet>
                {/* <div className="loader" ><span>Loading...</span></div> */}
                <Header />
                <Modal size="lg" show={this.state.isActive} onClick={this.handleClickInsideModal} className="modal_contect video-effect-modal modalvideos" centered>
                    <Modal.Header closeButton className="modal_header p-0 border-none" style={{ border: "none" }} onHide={() => this.handlevideopopup()} >
                    </Modal.Header>
                    <Modal.Body>
                        <iframe width="960" height="415" src="https://www.youtube.com/embed/9xwazD5SyVg?autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </Modal.Body>
                </Modal>
               
                 <section className="wrapper overflow-hidden bg-soft-primary">
                    <div className="container pt-19 pt-md-21 text-center position-relative">
                        <div className="position-absolute bg-lights" style={{top: "0", left: "50%", transform: "translateX(-50%)"}} data-cue="fadeIn"><img src="assets/img/shape/blurry.png" alt="" /></div>
                        <div className="row position-relative">
                            <div className="col-lg-8 col-xxl-7 mx-auto position-relative">
                                <div className="position-absolute shape-width shape grape w-5 d-lg-block" style={{top: "-5%", left: "-15%"}} data-cue="fadeIn" data-delay="1500">
                                     <img src="assets/img/icon/pie.svg" className="svg-inject icon-svg w-100 h-100" alt="" />
                                </div>
                                <div className="position-absolute shape-width shape violet w-10 d-lg-block" style={{bottom: "30%", left: "-20%"}} data-cue="fadeIn" data-delay="1500">
                                     <img src="assets/img/icon/scribble.svg" className="svg-inject icon-svg w-100 h-100" alt="" />
                                </div>
                                <div className="position-absolute shape-width shape fuchsia w-6 d-lg-block" style={{top: "0%", right: "-25%", transform: "rotate(70deg)"}} data-cue="fadeIn" data-delay="1500">
                                     <img src="assets/img/icon/tri.svg" className="svg-inject icon-svg w-100 h-100" alt="" />
                                    </div>
                                <div className="position-absolute shape-width shape yellow w-6 d-lg-block" style={{bottom: "25%", right: "-17%"}} data-cue="fadeIn" data-delay="1500">
                                     <img src="assets/img/icon/circle.svg" className="svg-inject icon-svg w-100 h-100" alt="" />
                                </div>
                                <div data-cues="slideInDown" data-group="page-title">                                    
                                    <h1  className="fw-bold mb-100" style={{ fontSize: "68px"}}>We are a digital web agency specializing on
                                        <div className="aggettivi mobile-aggettivi">
                                            
                                            <span className="word-rotator-words">
                                                <span className="spinny-words mobiledev-word">
                                                    <span>&nbsp;web design.&nbsp;</span>
                                                    <span>&nbsp;SEO services.&nbsp;</span>
                                                    <span>IGoogle Adwords.</span>
                                                    <span>&nbsp;web design.&nbsp;</span>
                                                    <span>&nbsp;SEO services.&nbsp;</span>
                                                    <span>IGoogle Adwords.</span>
                                                </span>
                                            </span>
                                        </div>
                                    </h1>
                                    
                                    <p className="lead fs-24 mb-8">We are an award winning digital web agency that strongly believes in the power of creative ideas.</p>
                                </div>
                                <div className="d-flex justify-content-center" data-cues="slideInDown" data-delay="600">
                                    <span><a href="#!" className="as-btn style3 me-2">See Projects</a></span>
                                    <span><a href="/contact" className="as-btn style3">Contact Us</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid px-xl-0 pt-16 pt-md-18 pb-18 pb-md-20">
                        <div className="swiper-container swiper-auto nav-color nav-bottom nav-far" data-margin="40" data-nav="true" data-centered="true" data-loop="true" data-items-auto="true">
                            <div className="swiper overflow-visible">
                                <OwlCarousel items={3} className="owl-theme" {...set} loop autoplay={true}
                                    margin={8} >
                                    <div className="swiper-slide">
                                        <figure className="rounded-xl shadow-xl"><img src="assets/img/banner/bp1.jpg"  alt="" /><a className="item-link" href="single-project.html"><i className="uil uil-arrow-up-right"></i></a></figure>
                                    </div>
                                    <div className="swiper-slide">
                                        <figure className="rounded-xl shadow-xl"><img src="assets/img/banner/bp2.jpg"  alt="" /><a className="item-link" href="single-project.html"><i className="uil uil-arrow-up-right"></i></a></figure>
                                    </div>
                                    <div className="swiper-slide">
                                        <figure className="rounded-xl shadow-xl"><img src="assets/img/banner/bp3.jpg"  alt="" /><a className="item-link" href="single-project.html"><i className="uil uil-arrow-up-right"></i></a></figure>
                                    </div>
                                    <div className="swiper-slide">
                                        <figure className="rounded-xl shadow-xl"><img src="assets/img/banner/bp4.jpg"  alt="" /><a className="item-link" href="single-project.html"><i className="uil uil-arrow-up-right"></i></a></figure>
                                    </div>
                                    <div className="swiper-slide">
                                        <figure className="rounded-xl shadow-xl"><img src="assets/img/banner/bp5.jpg"  alt="" /><a className="item-link" href="single-project.html"><i className="uil uil-arrow-up-right"></i></a></figure>
                                    </div>
                                    <div className="swiper-slide">
                                        <figure className="rounded-xl shadow-xl"><img src="assets/img/banner/bp6.jpg" alt="" /><a className="item-link" href="single-project.html"><i className="uil uil-arrow-up-right"></i></a></figure>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                    <div className="container pt-80">
                        <div className="row">
                            <div className="col-md-11 col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
                                <h2 className="display-2 mb-4">The full service we are offering</h2>
                                <p className="lead fs-lg mb-11">Curabitur blandit tempus porttitor. Donec id elit non mi porta gravida at eget metus. Integer posuere ante venenatis.</p>
                            </div>
                        </div>
                        <div className="row gx-md-8 gy-8 text-center mb-14 mb-md-17 service-offering">
                            <div className="col-md-6 col-lg-3 col-sm-6">
                                <div className="svg-bg svg-bg-lg bg-white rounded-xl shadow-xl mb-6"> 
                                     <img src="assets/img/icon/pen-tool.svg" className="svg-inject icon-svg solid-duo text-grape-fuchsia" alt="" /> 
                                </div>
                                <h3 className="mt-28 fw-bold">Web Design</h3>
                                <p className="mb-3">Etiam porta sem malesuada magna mollis euismod. Donec ullamcorper nulla non metus auctor fringilla.</p>                                
                            </div>
                            <div className="col-md-6 col-lg-3 col-sm-6">
                                <div className="svg-bg svg-bg-lg bg-white rounded-xl shadow-xl mb-6">
                                     <img src="assets/img/icon/seo-2.svg" className="svg-inject icon-svg solid-duo text-grape-fuchsia" alt="" /> 
                                 </div>
                                <h3 className="mt-28 fw-bold">SEO Services</h3>
                                <p className="mb-3">Etiam porta sem malesuada magna mollis euismod. Donec ullamcorper nulla non metus auctor fringilla.</p>                                
                            </div>
                            <div className="col-md-6 col-lg-3 col-sm-6">
                                <div className="svg-bg svg-bg-lg bg-white rounded-xl shadow-xl mb-6"> 
                                     <img src="assets/img/icon/shopping-basket.svg" className="svg-inject icon-svg solid-duo text-grape-fuchsia" alt="" />
                                     </div>
                                <h3 className="mt-28 fw-bold">E-commerce</h3>
                                <p className="mb-3">Etiam porta sem malesuada magna mollis euismod. Donec ullamcorper nulla non metus auctor fringilla.</p>                                
                            </div>
                            <div className="col-md-6 col-lg-3 col-sm-6">
                                <div className="svg-bg svg-bg-lg bg-white rounded-xl shadow-xl mb-6"> 
                                     <img src="assets/img/icon/server.svg" className="svg-inject icon-svg solid-duo text-grape-fuchsia" alt="" /> 
                                </div>
                                <h3 className="mt-28 fw-bold">Web Hosting</h3>
                                <p className="mb-3">Etiam porta sem malesuada magna mollis euismod. Donec ullamcorper nulla non metus auctor fringilla.</p>                                
                            </div>
                        </div>
                    </div>
                </section>

              <section className="wrapper pattern-wrapper bg-image section-frame bg-soft-primary" style={myStyleone}>
                    <div className="container pt-15 pt-md-17 pb-16 pb-md-18">
                        <div className="row gx-lg-0 gy-10 align-items-center mb-18">
                            <div className="col-lg-6 order-lg-2 ms-lg-auto">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInright">
                                    <div className="card rounded-xl me-lg-6 bg-pale-grape c-one">
                                        <div className="card-body p-6">
                                            <div className="d-flex flex-row">
                                                <div>
                                                    <span className="icons btn btn-block btn-lg shadow-xl rounded-xl btn-white pe-none me-4"><span className="number text-grape">01</span></span>
                                                </div>
                                                <div>
                                                    <h3 className="mb-1 text-grape">Collect Ideas</h3>
                                                    <p className="mb-0">Nulla vitae elit libero pharetra augue dapibus.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInright">
                                    <div className="card rounded-xl ms-lg-13 mt-6 bg-pale-fuchsia c-two">
                                        <div className="card-body p-6">
                                            <div className="d-flex flex-row">
                                                <div>
                                                    <span className="icons btn btn-block btn-lg shadow-xl rounded-xl btn-white pe-none me-4"><span className="number text-fuchsia">02</span></span>
                                                </div>
                                                <div>
                                                    <h3 className="mb-1 text-fuchsia">Data Analysis</h3>
                                                    <p className="mb-0">Vivamus sagittis lacus vel augue laoreet.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInright">
                                    <div className="card rounded-xl mx-lg-6 mt-6 bg-pale-green c-three">
                                        <div className="card-body p-6">
                                            <div className="d-flex flex-row">
                                                <div>
                                                    <span className="icons btn btn-block btn-lg shadow-xl rounded-xl btn-white pe-none me-4"><span className="number text-green">03</span></span>
                                                </div>
                                                <div>
                                                    <h3 className="mb-1 text-green">Finalize Product</h3>
                                                    <p className="mb-0">Cras mattis consectetur purus sit amet.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div className="col-lg-5 text-inverse">
                                <h2 className="display-2 mb-3 text-white">Our Working Process</h2>
                                <p className="lead fs-lg pe-lg-5 text-white">Find out everything you need to know and more about how we create our business process models.</p>
                                <p className="text-white">Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
                                <p className="mb-6 text-white">Nullam id dolor id nibh ultricies vehicula ut id elit. Vestibulum id ligula porta felis euismod semper. Aenean lacinia bibendum nulla.</p>                               
                            </div>
                        </div>
                        <div className="row gx-lg-0 gy-10 align-items-center text-inverse">
                            <div className="col-lg-6">
                                <figure>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInleft">
                                        <img className="img-auto" src="assets/img/project/ui4.png"  alt="" />
                                    </ScrollAnimation>
                                </figure>
                            </div>
                            <div className="col-lg-5 ms-lg-auto">
                                <h2 className="display-2 mb-3 text-white">Our Solutions</h2>
                                <p className="lead fs-lg pe-lg-5 text-white">We carefully consider our solutions to support each and every stage of your growth.</p>
                                <p className="mb-6 text-white">Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Praesent commodo cursus maecenas sed.</p>
                                <div className="row align-items-center counter-wrapper gy-6">
                                    <div className="col-md-6">
                                        <h3 className="counter counter-lg mb-1 text-white">99.7%</h3>
                                        <h5 className="mb-1 text-white">Customer Satisfaction</h5>
                                        <span className="ratings five text-red"></span>
                                    </div>
                                    <div className="col-md-6">
                                        <h3 className="counter counter-lg mb-1 text-white">4x</h3>
                                        <h5 className="mb-1 text-white">New Visitors</h5>
                                        <span className="ratings five"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                  <section className="wrapper overflow-hidden bg-soft-primary">
                    <div className="container py-15 py-md-17">
                        <div className="row">
                            <div className="col-md-11 col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
                                <h2 className="display-2 mb-4">What makes us different?</h2>
                                <p className="lead fs-lg mb-11">Donec ullamcorper nulla non metus auctor fringilla. Maecenas sed diam eget risus varius blandit sit amet non magna.</p>
                            </div>
                        </div>
                        <div className="row gx-lg-0 gy-10 align-items-center mb-15 mb-md-17">
                            <div className="col-lg-7 position-relative">
                                <div className="position-absolute" style={{top: "50%", left: "50%", width: "100%", height: "auto", transform: "translate(-50%,-50%)", zIndex: "-1"}}><img className="w-100" src="assets/img/shape/blurry.png" alt="" /></div>
                                <figure>
                                     <ScrollAnimation animateIn="animate__animated animate__fadeInleft">
                                        <img src="assets/img/project/mi1.png" alt="" />
                                    </ScrollAnimation>
                                </figure>
                            </div>
                            <div className="col-lg-4 ms-auto">
                                <h2 className="display-5 mb-2 mt-xxl-n10">Design</h2>
                                <p className="mb-8">Maecenas sed diam eget risus varius blandit sit amet non magna justo sit amet risus.</p>
                                <div className="d-flex flex-row mb-6">
                                    <div>
                                        <div className="svg-bg bg-white rounded-xl shadow-xl me-5"> 
                                            <img src="assets/img/icon/puzzle.svg" className="svg-inject icon-svg solid-duo text-grape-fuchsia" alt="" /> 
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <h4 className="mb-1 fw-bold">UI/UX Design</h4>
                                        <p className="mb-0">Nulla vitae elit libero, a pharetra augue. Cum sociis natoque penatibus et.</p>
                                    </div>
                                </div>
                                <div className="d-flex flex-row mb-6">
                                    <div>
                                        <div className="svg-bg bg-white rounded-xl shadow-xl me-5"> 
                                             <img src="assets/img/icon/pen-tool.svg" className="svg-inject icon-svg solid-duo text-grape-fuchsia" alt="" /> 
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <h4 className="mb-1 fw-bold">Design System</h4>
                                        <p className="mb-0">Nulla vitae elit libero, a pharetra augue. Cum sociis natoque penatibus et.</p>
                                    </div>
                                </div>
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="svg-bg bg-white rounded-xl shadow-xl me-5"> 
                                             <img src="assets/img/icon/share.svg" className="svg-inject icon-svg solid-duo text-grape-fuchsia" alt="" />
                                         </div>
                                    </div>
                                    <div className="mb-3">
                                        <h4 className="mb-1 fw-bold">Digital Branding</h4>
                                        <p className="mb-0">Nulla vitae elit libero, a pharetra augue. Cum sociis natoque penatibus et.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-lg-0 gy-10 align-items-center">
                            <div className="col-lg-7 position-relative order-lg-2">
                                <div className="position-absolute" style={{top: "50%", left: "50%", width: "100%", height: "auto", transform: "translate(-50%,-50%)", zIndex: "-1"}}><img className="w-100" src="assets/img/shape/blurry.png" alt="" /></div>
                                <figure>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInright">
                                        <img src="assets/img/project/mi2.png" alt="" />
                                    </ScrollAnimation>
                                </figure>
                            </div>
                            <div className="col-lg-4 me-auto">
                                <h2 className="display-5 mb-2 mt-xxl-n10">Development</h2>
                                <p className="mb-8">Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh fermentum.</p>
                                <div className="d-flex flex-row mb-6">
                                    <div>
                                        <div className="svg-bg bg-white rounded-xl shadow-xl me-5">
                                              <img src="assets/img/icon/devices.svg" className="svg-inject icon-svg solid-duo text-grape-fuchsia" alt="" /> 
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <h4 className="mb-1 fw-bold">Responsive Website</h4>
                                        <p className="mb-0">Nulla vitae elit libero, a pharetra augue. Cum sociis natoque penatibus et.</p>
                                    </div>
                                </div>
                                <div className="d-flex flex-row mb-6">
                                    <div className="mb-3">
                                        <div className="svg-bg bg-white rounded-xl shadow-xl me-5"> 
                                             <img src="assets/img/icon/safe.svg" className="svg-inject icon-svg solid-duo text-grape-fuchsia" alt="" /> 
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <h4 className="mb-1 fw-bold">Backup and Updates</h4>
                                        <p className="mb-0">Nulla vitae elit libero, a pharetra augue. Cum sociis natoque penatibus et.</p>
                                    </div>
                                </div>
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="svg-bg bg-white rounded-xl shadow-xl me-5"> 
                                             <img src="assets/img/icon/globe-2.svg" className="svg-inject icon-svg solid-duo text-grape-fuchsia" alt="" /> 
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <h4 className="mb-1 fw-bold">SEO Optimization</h4>
                                        <p className="mb-0">Nulla vitae elit libero, a pharetra augue. Cum sociis natoque penatibus et.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-11 col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
                                <h2 className="display-2 mb-4">Creative. Smart. Awesome.</h2>
                                <p className="lead fs-lg mb-11">Curabitur blandit tempus porttitor. Cras mattis consectetur purus sit amet fermentum. Nullam quis risus eget urna.</p>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-container swiper-auto swiper-auto-xs mb-8" data-margin="40" data-nav="false" data-dots="false" data-centered="true" data-loop="true" data-items-auto="true" data-autoplay="true" data-autoplaytime="1" data-drag="false" data-resizeupdate="false" data-speed="7000">
                        <div className="swiper overflow-visible pe-none mt-28">
                        <OwlCarousel items={3} className="owl-theme" {...set} loop autoplay={true}
                                    margin={8} >
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma8.jpg"  alt="" /></figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma9.jpg"  alt="" /></figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma10.jpg"  alt="" /></figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma11.jpg"  alt="" /></figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma12.jpg"  alt="" /></figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma13.jpg"  alt="" /></figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma14.jpg"  alt="" /></figure>
                                </div>
                        </OwlCarousel>
                        </div>
                    </div>
                    <div className="swiper-container swiper-auto swiper-auto-xs mt-20" data-margin="40" data-nav="false" data-dots="false" data-centered="true" data-loop="true" data-items-auto="true" data-autoplay="true" data-autoplaytime="1" data-drag="false" data-resizeupdate="false" data-speed="7000">
                        <div className="swiper overflow-visible pe-none" dir="rtl">
                             <Slider ref={(slider) => (this.sliderRef = slider)} {...sliderSettings} rtl={true}>
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma1.jpg" alt="" /></figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma2.jpg" alt="" /></figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma3.jpg" alt="" /></figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma4.jpg" alt="" /></figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma5.jpg" alt="" /></figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma6.jpg" alt="" /></figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="rounded-xl shadow-xl"><img src="assets/img/project/ma7.jpg" alt="" /></figure>
                                </div>
                            </Slider>
                        </div>
                    </div>
                    <div className="text-center mt-8 mb-15 mb-md-18 mb-lg-25 mt-20">
                        <a className="as-btn style3">See Our Projects</a>
                    </div>
                </section> 
                <section className="wrapper pattern-wrapper bg-image section-frame" data-image-src="assets/img/bg/pattern.png" style={myStyleone}>
                    <div className="container py-14 pt-md-16 pt-lg-0 pb-md-12">
                        <div className="row text-center mb-13">
                            <div className="col-lg-12 mx-auto">
                                <div className="mt-lg-n23 mt-xl-n21">
                                    <figure className="rounded-xl"><img src="assets/img/project/about18.jpg" alt="" /></figure>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-md-8 gx-lg-12 gy-6 gy-lg-0 mb-13">
                            <div className="col-lg-6">
                                <h1 className="display-2 mb-0 text-white fw-bold">We are an award winning digital web design agency that strongly believes in the power of creative ideas.</h1>
                            </div>
                            <div className="col-lg-6 text-sm-center">
                                <p className="lead fs-lg mb-3 text-inverse text-white">Donec ullamcorper nulla non metus auctor fringilla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis risus eget urna mollis ornare vel eu leo. Nullam quis risus eget urna mollis ornare vel eu leo. Maecenas faucibus mollis elit interdum. Duis mollis, est non commodo luctus.</p>                                
                            </div>
                        </div>
                    </div>
                </section>
              <section className="bg-soft-primary">
                    <div className="container pt-16 pt-md-18">
                        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center mb-15 mb-md-17">
                            <div className="col-lg-7 position-relative">
                                <div className="position-absolute d-md-block" style={{top: "50%", left: "50%", width: "130%", height: "auto", transform: "translate(-50%,-50%)", zIndex: "-1"}}><img className="w-100" src="assets/img/shape/blurry.png" alt="" /></div>
                                <div className="row gx-md-5 gy-5 me-3 m-0">
                                    <div className="col-md-6 col-xl-5 align-self-end">
                                        <div className="card shadow-xl rounded-xl">
                                            <div className="card-body p-4">
                                                <blockquote className="icon mb-0 me-0">
                                                    <p>“Cum sociis natoque penatibus et magnis dis parturient montes.”</p>
                                                    <div className="blockquote-details">
                                                        <div className="info p-0">
                                                            <h5 className="mb-1">Coriss Ambady</h5>
                                                            <p className="mb-0">Financial Analyst</p>
                                                        </div>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 align-self-end">
                                        <div className="card shadow-xl rounded-xl">
                                            <div className="card-body p-4">
                                                <blockquote className="icon mb-0 me-0">
                                                    <p>“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta felis euismod.”</p>
                                                    <div className="blockquote-details">
                                                        <div className="info p-0">
                                                            <h5 className="mb-1">Cory Zamora</h5>
                                                            <p className="mb-0">Marketing Specialist</p>
                                                        </div>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-5 offset-xl-1">
                                        <div className="card shadow-xl rounded-xl">
                                            <div className="card-body p-4">
                                                <blockquote className="icon mb-0 me-0">
                                                    <p>“Donec id elit non porta gravida at eget metus. Duis mollis est luctus commodo nisi erat.”</p>
                                                    <div className="blockquote-details">
                                                        <div className="info p-0">
                                                            <h5 className="mb-1">Barclay Widerski</h5>
                                                            <p className="mb-0">Sales Specialist</p>
                                                        </div>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 align-self-start">
                                        <div className="card shadow-xl rounded-xl">
                                            <div className="card-body p-4">
                                                <blockquote className="icon mb-0 me-0">
                                                    <p>“Nisi erat porttitor ligula, eget lacinia odio sem nec elit. Aenean eu leo pellentesque.”</p>
                                                    <div className="blockquote-details">
                                                        <div className="info p-0">
                                                            <h5 className="mb-1">Jackie Sanders</h5>
                                                            <p className="mb-0">Investment Planner</p>
                                                        </div>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <h2 className="display-2 mb-2 mt-lg-n6">Our Community</h2>
                                <p className="lead fs-lg mb-4">Customer satisfaction is our major goal. See what our clients are saying about our services.</p>
                                <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Nulla vitae elit libero, a pharetra augue. Maecenas faucibus mollis interdum. Vestibulum id ligula porta felis euismod.</p>
                                <a href="#" className="as-btn style3">All Testimonials</a>
                            </div>
                        </div>                       
                        <section className="join-us pb-100 bg-soft-primary">
                            <div className="container">
                                <div className="card image-wrapper bg-full bg-image bg-overlay mt-n50p m-0 rounded-xl overflow-hidden" style={myStyletwo} data-image-src="assets/img/photos/bg27.jpg">
                                    <div className="card-body p-6 p-md-11 d-lg-flex flex-row align-items-lg-center justify-content-md-between text-center text-lg-start">
                                        <h3 className="display-2 mb-6 mb-lg-0 pe-lg-10 pe-xl-5 pe-xxl-18 text-white">We are trusted by over 5000+ clients. Join them now and grow your business.</h3>
                                        <a href="#" className="btn btn-lg btn-white rounded-xl mb-0 text-nowrap" style={{ backgroundColor: "#FFFF", padding: "15px 25px" }}>Join Us</a>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="bg-smokes gy-6 form-bg-image">            
                            <div className="container-fluied">                                
                                <div className="row" style={{ justifyContent: "center" }}>
                                    <div className="col-xl-6 ctn-from pb-80 pt-50">
                                        <div className="title-area mb-35 text-lg-start text-md-center text-sm-center m-5">
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                <span className="sub-title"><img
                                                    src="../assets/img/theme-img/title_shape_2.svg" alt="shape" />contact with us!</span>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                <h2 className="sec-title">Drop Us a Line</h2>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                <p className="sec-texts text-lg-start text-md-center text-sm-center">Reach Out to us from our Contact form and we will get back to you shortly.</p>
                                            </ScrollAnimation>
                                        </div>
                                        <form onSubmit={this.onSubmit} method="post" className="m-5 contact-forms ajax-contact mobiledev-forsam">
                                            <div className="row">
                                                <div className="form-group col-md-6" >
                                                    <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                                    <i className="fal fa-user"></i>
                                                    {nameError &&
                                                        <div className="invalid-feedback" >{nameError}</div>
                                                    }
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} autoComplete="Off" />
                                                    <i className="fal fa-envelope"></i>
                                                    {emailError &&
                                                        <div className="invalid-feedback">{emailError}</div>
                                                    }
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="text" id="subject" className={subjectError ? "form-control is-invalid" : "form-control"} ref="subjectfield" name="subject" placeholder="Subject" onChange={this.handleChange} autoComplete="Off" />
                                                    <i className="fal fa-note-sticky"></i>
                                                    {subjectError &&
                                                        <div className="invalid-feedback">{subjectError}</div>
                                                    }
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} name="number" value={this.state.number} ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off" onChange={this.maxlengthnumber} />
                                                    <i className="fal fa-phone"></i>
                                                    {numberError &&
                                                        <div className="invalid-feedback">{numberError}</div>
                                                    }
                                                </div>
                                                <div className="form-group col-12">
                                                    <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                                        className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange} autoComplete="Off">
                                                    </textarea>
                                                    <i className="fal fa-comment"></i>
                                                    {messageError &&
                                                        <div className="invalid-feedback">{messageError}</div>
                                                    }
                                                </div>
                                                <div className="form-btn text-lg-start text-md-center text-sm-center col-12 cont-btn">
                                                    <button type="submit" className="as-btn style3">Send Appointment
                                                        <i className="fa-regular fa-arrow-right ms-2"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <p className="form-messages mb-0 mt-3"></p>
                                        </form>
                                    </div>
                                    <div className="col-xl-6 ctn-from pb-80 text-center">
                                        <img src="../assets/img/project/new-banner-16.png" alt="img not found" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </section> 
                
                <Footer />
            </>
        )
    }
}

export default mobiledevelopment;

