import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import Header from "./Gameheader";
import Footer from '../over_games/Footer';
import OwlCarousel from 'react-owl-carousel';
import CountUp from 'react-countup';
import '../assets/css_game/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import '../assets/css_game/dropdown-effects/fade-down.css';


export default function bhabhioffline() {
	return (

		<div>
			<Helmet>
				<link id="googleFonts" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800%7CShadows+Into+Light&amp;display=swap" rel="stylesheet" type="text/css"></link>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
			</Helmet>
			<Header />
			<section id="hero-17" className="bg-scroll rel hero-section division">
				<div className="container">
					<div className="row">
						<div className="col-md-9 col-lg-8">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Rummy Game</h2>
								</ScrollAnimation>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 col-lg-5">
							<div className="hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color">Enhance your Gaming experience with the Rummy Game
										Development offered by us. Our Developers build games for both web and mobiles
										including all app platforms. Our aim is to provide you with all the services
										that will meet your needs and requirements in a professional manner. Hire our
										Rummy Game Developers with their rich experience and skills to have the best
										Rummy Game Development services in India.
									</p>
								</ScrollAnimation>
								<a href="#" className="os-btn bg-white d-flex align-items-center">
									<div className="os-btn-ico">
										<div className="ico-50">
											<img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
										</div>
									</div>
									<div className="os-btn-txt">
										<ScrollAnimation animateIn="animate__animated animate__fadeInup">
											<h6 className="h6-lg">Download for Android</h6>
										</ScrollAnimation>
									</div>
								</a>
								<a href="#" className="os-btn mac-os-btn bg-white d-flex align-items-center">
									<div className="os-btn-ico">
										<div className="ico-50">
											<img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
										</div>
									</div>
									<div className="os-btn-txt">
										<ScrollAnimation animateIn="animate__animated animate__fadeInup">
											<h6 className="h6-lg">Download for IOS</h6>
										</ScrollAnimation>
									</div>
								</a>
								<ul className="advantages white-color clearfix mt-30">
									<li className="first-li">
										<p>Version 2.10.074</p>
									</li>

								</ul>
							</div>
						</div>
						<div className="col-md-4 col-lg-7">
							<div className="hero-17-img video-preview">
								<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
									<img className="img-fluid" src="../assets/img/GamesImg/Rummy_images/dashboard-04.png" alt="hero-image" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="content-2" className="content-2 pb-60 pt-50 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-5 col-lg-6">
							<div className="rel img-block left-column wow fadeInRight">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<img className="img-fluid" src="../assets/img/GamesImg/Rummy_images/img-10.png" alt="content-image" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-7 col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">
								<ScrollAnimation animateIn="animate__animated animate__fadeInright">
									<h2 className="h2-xs">Rummy Game Development Services</h2>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__animated animate__fadeInright">
									<p className="p-lg">Our Company is a leading Rummy game Development Company in India.
										Our team of Rummy game Developers like Designers and programmers strive to give
										you the best Rummy game software in terms of quality. Our rummy game developers
										specifically keep clients in the loop throughout the game process to ensure that
										the clients are highly satisfied with the results. We use advanced technologies
										to create a game that supports android, iOS, Desktop & all other platforms. Our
										developers make sure that you and your audience receive memorable hands-on
										experiences. For that, our passionate gaming coders make use of the latest
										mobile technology.
									</p>
									<p className="p-lg">Adept Rummy game providers are working hard on different Rummy game
										app solutions consisting of varied options and blends of features,
										functionalities, modules, etc. to perfectly deliver you the best idea for rummy
										game app development. We will provide you with a rich-featured app that will
										help you to gain more rummy users. Set your Rummy game to a level higher than
										other Rummy game developers with us.
									</p>
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="features-8" className="pb-60 features-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-70">
								<ScrollAnimation animateIn="animate__animated animate__fadeInup">
									<h2 className="h2-md">Rummy Game Script</h2>
								</ScrollAnimation>
							</div>
						</div>
					</div>
					<div className="fbox-8-wrapper text-center">
						<div className="row row-cols-1 row-cols-md-3 justify-content-center">
							<div className="col">
								<div className="fbox-8 mb-40 wow fadeInUp">
									<div className="fbox-img">
										<img className="img-fluid" src="../assets/img/GamesImg/Rummy_images/rummyonline.png"
											alt="feature-icon" />
									</div>
									<ScrollAnimation animateIn="animate__animated animate__fadeInup">
										<h5 className="h5-md">Desktop Version</h5>
										<p className="p-lg">This version is mainly for Windows and MacOS. You can simply
											Download and install this version and it's good to go.
										</p>
									</ScrollAnimation>
								</div>
							</div>
							<div className="col">
								<div className="fbox-8 mb-40 wow fadeInUp">

									<div className="fbox-img">
										<img className="img-fluid change" src="../assets/img/GamesImg/Rummy_images/img-01.png"
											alt="feature-icon" />
									</div>
									<ScrollAnimation animateIn="animate__animated animate__fadeInup">
										<h5 className="h5-md">IOS Version</h5>
										<p className="p-lg">Our Rummy game software mobile version is available for iOS,
											Android and Windows app.
										</p>
									</ScrollAnimation>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="content-9" className="content-9 bg-whitesmoke-gradient pt-100 content-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-10 col-lg-8">
							<div className="section-title title-02 mb-60">
								<h2 className="h2-xs">Discover powerful features to boost your productivity</h2>
							</div>
						</div>
					</div>
					<div className="img-block">
						<div className="row">
							<div className="col-md-6">
								<div className="about-rummy">
									<ScrollAnimation animateIn="animate__animated animate__fadeInup">
										<h2 className="heading">What is an Online Rummy Game?</h2>
										<p>Rummy is a popular Indian card game played with sets of playing cards. It
											comes under the draw and discards card games category. It is a game of skill
											that usually requires 1 or 2 decks of cards. The rummy game’s primary
											objective is to improve your hand by dealing with sets of cards and forming
											a particular sequence or set adhering to specific rules of the game. This
											Online Rummy game is a Multiplayer card game, usually played between a group
											of 2 to 6 people where each player has to draw and discard one card, in
											turn, until the thirteen cards form a sequence in sets. A player who manages
											to build the successful sequence to card before anyone will be declared as
											the winner.</p>
									</ScrollAnimation>
								</div>
							</div>
							<div className="col-md-6">
								<div className="content-9-img video-preview wow fadeInUp v-change">
									<a className="video-popup1" href="https:www.youtube.com/embed/SZEflIVnhH8">
										<div className="video-btn video-btn-xl bg-red ico-90">
											<div className="video-block-wrapper"><span className="flaticon-play-button"></span></div>
										</div>
									</a>
									<img className="img-fluid" src="../assets/img/GamesImg/Rummy_images/dashboard-07.png"
										alt="video-preview" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="statistic-2" className="wide-100 statistic-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">
								<h3 className="h3-xs">More than 5,000 users around the world are already using Rummy
									actively</h3>
							</div>
						</div>
						<div className="col-sm-5 col-md-4 col-lg-3 offset-sm-1 offset-md-2 offset-lg-1">
							<div className="statistic-block wow fadeInUp">
								<h2 className="h2-title-xs statistic-number"><span className="count-element"><CountUp end={5000} duration={10} /></span>
								</h2>
								<p className="p-lg mt-20">Active downloads <br /> from the community</p>
							</div>
						</div>
						<div className="col-sm-5 col-md-4 col-lg-2">
							<div className="statistic-block wow fadeInUp">
								<h2 className="h2-title-xs statistic-number">
									<span className="count-element"><CountUp end={3} duration={5} /></span>.<span className="count-element"><CountUp end={3} duration={5} /></span>
								</h2>
								<div className="txt-block-rating ico-15 yellow-color">
									<span className="flaticon-star-1"></span>
									<span className="flaticon-star-1"></span>
									<span className="flaticon-star-1"></span>
									<span className="flaticon-star-half-empty"></span>
								</div>
								<p className="p-lg txt-400">33 Rating</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="Flow">
				<div className="container">
					<div className="our-flow">
						<div className="flow-content">
							<h2>Related Games to Rummy Game Development</h2>
							<p>If you are searching for similar or related games to rummy game development, you can
								end your search here.</p>
						</div>
						<div className="flow-img">
							<img src="../assets/img/GamesImg/28_29_images/infographic.jpg" alt="" />
						</div>
					</div>
				</div>
			</section>

			<hr className="divider" />

			<section id="content-3" className="content-3 wide-60 content-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-70">
								<h2 className="h2-md">Get Ready to Be Surprised</h2>
								<p className="p-xl">To make it easy and convenient for you to play the game, we have
									added various features to the game.
								</p>
							</div>
						</div>
					</div>
					<div className="top-row pb-50">
						<div className="row d-flex align-items-center">
							<div className="col-md-5 col-lg-6">
								<div className="img-block left-column wow fadeInRight">
									<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
										<img className="img-fluid" src="../assets/img/GamesImg/Rummy_images/img-01.png" alt="content-image" />
									</ScrollAnimation>
								</div>
							</div>
							<div className="col-md-7 col-lg-6">
								<div className="txt-block right-column wow fadeInLeft">	'
									<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
										<h2 className="h2-xs">Online Rummy multiplayer Game Features</h2>
										<p className="p-lg">The Best Feature of this game is that you get the Leaderboard to
											get competition with worldwide players with the rummy online multiplayer
											game.
										</p>
										<div className="txt-box">
											<ul className="simple-list">
												<li className="list-item">
													<p className="p-md">Daily Bonus Get Daily Wheel with rummy multiplayer
														games and collect the coins.
													</p>
												</li>
												<li className="list-item">
													<p className="p-md">Get the Best sound effects and easy controls.
													</p>
												</li>
												<li>
													<p className="p-md">Easily take and throw the card from suit in our
														rummy multiplayer card game.
													</p>
												</li>
												<li>
													<p className="p-md">Rummy Multiplayer card game brings this classic 2 to
														6 players card game to Google Play, with the high quality of
														Oengines Games.
													</p>
												</li>
												<li>
													<p className="p-md">And the game has various game modes like Pool rummy,
														Deal rummy and Point Rummy, so you can enjoy the Different
														variants of the game.
													</p>
												</li>
											</ul>
										</div>
									</ScrollAnimation>
								</div>
							</div>
						</div>
					</div>

				</div>
			</section>

			<section className="techno-sec">
				<div className="over-techno">
					<div className="heading">
						<h2>Our Professionals are working on the following top-notch techs</h2>
						<p>We build the nexus of talent and innovation with Optimum oengines</p>
					</div>
					<div className="row align">
						<div className="col-md-2 box">
							<div className="nodejs wow fadeInUp">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/nodejs-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box">
							<div className="javascript  wow fadeInUp">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/reactjs-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box wow fadeInUp">
							<div className="rabbit">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/mean-stack.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box wow fadeInUp">
							<div className="mongodb">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/mongodb-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box wow fadeInUp">
							<div className="redis ">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/unity-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box  wow fadeInUp">
							<div className="socket">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/angular-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="service-section py-5">
				<div className="container">
					<div className="row justify-content-center py-3">
						<div className="col-md-8 col-12 text-center">
							<h2 className="service-main-heading">Our Features</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
							<div className="icon-box wow fadeInUp">
								<img src="../assets/img/GamesImg/icon/multi-table.png" alt="" />
								<p className="service-title"><a href="#">Multi-Table </a></p>
								<p className="service-para">One latest feature applicable to Rummy games is the multi-table feature that enables users to play 3 games at a time. While the player waits for their next turn, they can switch between games.</p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
							<div className="icon-box  wow fadeInUp">
								<img src="../assets/img/GamesImg/icon/multilingual.png" alt="" />
								<p className="service-title"><a href="#">Multi-Languages</a></p>
								<p className="service-para">We create an Rummy Multiplayer Card game app/software/website supporting different regional and native languages for a better gaming experience.</p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
							<div className="icon-box  wow fadeInUp">
								<img src="../assets/img/GamesImg/icon/dailybonus.png" alt="" />
								<p className="service-title"><a href="#">Daily Bonuse</a></p>
								<p className="service-para">Play the game Regularly and get Daily Bonus Points.</p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
							<div className="icon-box  wow fadeInUp">
								<img src="../assets/img/GamesImg/icon/customizerummysolution.png" alt="" />
								<p className="service-title"><a href="#">Customize Rummy Solution</a></p>
								<p className="service-para">Have a particular feature in mind? We will incorporate it
									into your rummy game according to your exact requisites. </p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="content-2a" className="content-2 pb-60 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-7 col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<h2 className="h2-xs">Make it simpler with Quick Commands</h2>
									<ul className="simple-list">
										<li className="list-item">
											<p className="p-lg">Fringilla risus, luctus mauris orci auctor euismod iaculis
												luctus
												magna purus pretium ligula purus undo quaerat tempor sapien rutrum
												mauris quaerat ultrice
											</p>
										</li>
										<li className="list-item">
											<p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
										</li>
										<li className="list-item">
											<p className="p-lg">Nemo ipsam egestas volute turpis dolores undo ultrice
												aliquam
												quaerat at sodales sapien purus
											</p>
										</li>
									</ul>
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-5 col-lg-6">
							<div className="rel img-block left-column wow fadeInRight">
								<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
									<img className="img-fluid" src="../assets/img/GamesImg/Rummy_images/img-07.png" alt="content-image" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="Rummy-sec">
				<div className="container">
					<div className="over-techno">
						<div className="heading">
							<h2 className="wow fadeInUp">Rummy Games Variations</h2>
							<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
								<p className="wow fadeInUp">To make your Rummy game playing experience even better, use this
									Rummy game variations and enjoy more than before-</p>
							</ScrollAnimation>
						</div>
						<div className="types">
							<div className="row">
								<div className="col-md-4">
									<div className="rummy-type">
										<div className="rummy-img">
											<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(10).png" alt="" className="wow fadeInUp" />
										</div>
										<div className="rummy-txt">
											<h5 className="wow fadeInUp">Deals Card Rummy</h5>
											<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
												<p className="wow fadeInUp">Deals Rummy is another variant of the 13 card
													Rummy game & Indian Rummy where a fixed number of cards are dealt
													with each player. At the start of an online deals rummy game, all
													players are given a fixed amount of coins to play with. At the end
													of a deals rummy game, the player with the higher coins count wins
													the game.</p>
											</ScrollAnimation>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="rummy-type">
										<div className="rummy-img">
											<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(12).png" alt="" className="wow fadeInUp" />
										</div>
										<div className="rummy-txt">
											<h5 className="wow fadeInUp">Pool Card Rummy</h5>
											<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
												<p className="wow fadeInUp">Pool Rummy is a variant of 13 cards Indian rummy
													where players pay a fixed entry fee which forms the prize pool. A
													player is eliminated once their score reaches the maximum limit of
													points on the table eg: 101 Points Pool or 201 Points Pool.</p>
											</ScrollAnimation>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="rummy-type">
										<div className="rummy-img">
											<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(14).png" alt="" className="wow fadeInUp" />
										</div>
										<div className="rummy-txt">
											<h5 className="wow fadeInUp"> 13 Card Rummy</h5>
											<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
												<p className="wow fadeInUp">13 Card rummy is a variant of 13 cards Indian
													rummy where players play for points with pre-decided coin value. One
													player who makes the first valid declaration wins the game with 0
													points and wins all the coins based on the opponent's total points.
													It is one of the most popular and fastest variants of 2829 games.
												</p>
											</ScrollAnimation>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="rummy-type">
										<div className="rummy-img">
											<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(13).png" alt="" className="wow fadeInUp" />
										</div>
										<div className="rummy-txt">
											<h5 className="wow fadeInUp">21 Card Rummy</h5>
											<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
												<p className="wow fadeInUp">21 card rummy is a variant of 21 cards Indian
													rummy where players play for points with pre-decided coin value. One
													player who makes the first valid declaration wins the game with 0
													points and wins all the coins based on the opponent's total points.
													It is one of the most popular and fastest variants of rummy games.
												</p>
											</ScrollAnimation>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="rum-img"></div>
								</div>
								<div className="col-md-4">
									<div className="rummy-type">
										<div className="rummy-img">
											<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(11).png" alt="" className="wow fadeInUp" />
										</div>
										<div className="rummy-txt">
											<h5 className="wow fadeInUp">27 Card Rummy</h5>
											<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
												<p className="wow fadeInUp">27 card rummy is a variant of 27 cards Indian
													rummy where players play for points with pre-decided coin value. One
													player who makes the first valid declaration wins the game with 0
													points and wins all the coins based on the opponent's total points.
													It is one of the most popular and fastest variants of 2829 games.
												</p>
											</ScrollAnimation>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="faqs-2" className="pb-60 faqs-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-80">
								<h2 className="h2-md">Frequently Asked Question? </h2>

							</div>
						</div>
					</div>
					<div className="faqs-2-questions">
						<div className="row row-cols-1 row-cols-lg-2">
							<div className="col">
								<div className="questions-holder pr-15">
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">How Much Time Does It Take to Develop a Rummy Game? </h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">Rummy game development is a complex process, so it will take between six months and a year. </p>
										</ScrollAnimation>
									</div>

									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Do you provide continued maintenance support for Rummy mobile game development? </h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">Yes, We provide you with continuous maintenance support for rummy game development. Even after the development, you can opt for the monthly retention plan.</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Is Rummy legal in India?
											</h5>
										</ScrollAnimation>
										<ul className="simple-list">
											<li className="list-item">
												<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
													<p className="p-lg">Rummy is a skill-based game requiring a level of strategy, tactic, knowledge, and experience, so it is legal. Other luck-based games are illegal in India.
													</p>
												</ScrollAnimation>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="questions-holder pl-15">
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Can I Access Our Rummy Game Admin Panel Using Mobile
												Devices?</h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">You can access the admin panel of the hearts game on your
												PC, mobile devices seamlessly. Login credentials are required only.
											</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Where Do You Store All Data of This Rummy Game?</h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg"> The answer to this question completely depends upon you. We
												prefer to store your Rummy game data on highly secured gaming servers.
												If you have any recommendations for the gaming server, then you may
												discuss them with us and we will store the data on the chosen server.
											</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md"> Will You Provide Both The Rummy Game Website & Mobile App
												Development Services?</h5>
										</ScrollAnimation>
										<ul className="simple-list">
											<li className="list-item">
												<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
													<p className="p-lg">Yes, we offer a range of Rummy game development services including website development, mobile app development, and software development.</p>
												</ScrollAnimation>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 text-center pt-25">
						</div>
					</div>
				</div>
			</section>

			<section className="variation-main">
				<div className="container">
					<div className="variation-inner">
						<div className="heading">
							<h2>Rummy Games Screen Shot And Reviews</h2>
							<p>We provide complete and advanced Rummy game development services at our Oengines Games to reach the targeted audience base.</p>
						</div>
						<OwlCarousel items={3}
							className="owl-theme"
							loop
							autoplay={true}
							margin={8} >
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/Rummy_images/Screen_shot/rumm-game-7.png" alt="Img not found" />
									<h5>1. Jason walker</h5>
									<p className="set-height">Great work by team amazing game to play rummy maker thanks</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/Rummy_images/Screen_shot/rummy-game-6.png" alt="Img not found" />
									<h5>2. D.S</h5>
									<p className="set-height">It's best game ever I played.</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/Rummy_images/Screen_shot/rummy-game-5.png" alt="Img not found" />
									<h5>3. Gamer roy</h5>
									<p className="set-height">Superb game.... Awesome graphics and animations also</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/Rummy_images/Screen_shot/rummy-game-4.png" alt="Img not found" />
									<h5>4. Ali Khan</h5>
									<p className="set-height">Goood....game...</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/Rummy_images/Screen_shot/rummy-game-3.png" alt="Img not found" />
									<h5>5. Lingraj</h5>
									<p className="set-height">I am very much like this. It is so interesting to play and has a great user manual. I will recommend this to others.</p>
								</div>
							</div>
						</OwlCarousel>
					</div>
				</div>
			</section>

			<section id="cta-9" className="bg-01 wide-80 cta-section division">
				<div className="container">
					<div className="row justify-content-md-center">
						<div className="col col-lg-8">
							<div className="cta-9-txt text-center">
								<h2 className="h2-xs white-color">Getting Started is Fast & Easy</h2>
								<p className="p-xl white-color mb-35">Download Rummy on your Windows or Mac and get 30
									days free trial.</p>
								<a href="#" className="os-new-btn bg-white d-flex align-items-center">
									<div className="os-btn-ico">
										<div className="ico-50">
											<img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
										</div>
									</div>
									<div className="os-btn-txt">
										<h6 className="h6-lg">Download for Android</h6>

									</div>
								</a>
								<a href="#" className="os-new-btn mac-os-btn bg-white d-flex align-items-center">
									<div className="os-btn-ico">
										<div className="ico-50">
											<img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
										</div>
									</div>
									<div className="os-btn-txt">
										<h6 className="h6-lg">Download for IOS</h6>

									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>

	);
}