import React from "react";
import { Helmet } from 'react-helmet';
import '../../src/assets/css/app.min.css';
import '../../src/assets/css/app.min.css';
import '../../src/assets/css/style.css';
import '../../src/assets/css/fontawesome.min.css';
import OwlCarousel from 'react-owl-carousel';
import 'react-awesome-slider/dist/styles.css';
import '../../src/assets/css/responsive.css';
import axios from 'axios';
import Swal from "sweetalert2";
import Footer from "../Footer";
import Header from "../Header";
import ScrollAnimation from 'react-animate-on-scroll';
import 'react-circular-progressbar/dist/styles.css';
import { Modal } from 'react-bootstrap';
class Flutterpage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
            // isActive: false,
            // isOpen: false
        }
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
    }
    componentDidMount() {

        axios.get("https://www.oengines.com/careers")
            .then(response => {
                console.log("Data is::::::::", response.data);
                this.setState({ Job: response.data });
                this.setState({ Description: response.data[0].description });

            })
            .catch(function (error) {
                console.log(error);
            })

    }
    // openModal = (e) =>{        
    //     this.setState({ isOpen: !this.state.isOpen });
    //     console.log("enter in open modal",this.state.isOpen);
    // }
    // handleClickInsideModal = (event) => {
    //     event.stopPropagation();
    // }
    // handlevideopopup(e) {
    //     console.log("::::::add on this:");
    //     this.setState({ isOpen: !this.state.isOpen });
    // }

    onClickShowdetails(job) {
        console.log("job is:::::::", job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    handleChangedatepicker = (date) => {
        this.setState({
            selectedDate: date,
        });
    };
    maxlengthnumber(evt) {
        if (evt.target.value.length === 11) {
            return false;
        }
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};
        console.log("Enter in::::::::::::");
        if (this.state.name === '' || this.state.name === null) {

            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else if (this.state.message === '' || this.state.message === null) {
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else {

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: 'flutter' + '-' + this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,

            };
            console.log("formObject::::::::::;", formObject);
            axios.post("https://www.oengines.com/contactus", formObject)
                .then((res) => {
                    console.log("Response is:::::::::", res);
                    if (res.data !== 'undefined') {
                        Swal.fire({
                            title: "Thank you!",
                            text: "Your request has been assigned successfully.",
                            icon: "success",
                        }).then(function () {
                            window.location.reload();
                        });
                    } else {
                        Swal.fire({
                            title: "Oops...!",
                            text: "Oh! Something went wrong, please try again!",
                            icon: "error",
                        }).then(function () {
                            window.location.reload();
                        });
                    }

                });
        }


        this.setState({
            name: '',
            email: '',
            subject: '',
            number: '',
            message: ''

        })

    }
    render() {
        var settings = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 3,
                    nav: true,
                    loop: true
                }

            }
        };
        var mystyleone = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                576: {
                    items: 2,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 3,
                    nav: true,
                    loop: true
                },
                1200: {
                    items: 4,
                    nav: true,
                    loop: true
                }
            }
        };
        var setweb = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                576: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 3,
                    nav: true,
                    loop: true
                },
                1200: {
                    items: 4,
                    nav: true,
                    loop: true
                }
            }
        };
        var setblog = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 2,
                    nav: true,
                    loop: true
                },
                1200: {
                    items: 3,
                    nav: true,
                    loop: true
                }

            }
        };
        const mystyletow = {
            backgroundImage:
                "url('../assets/img/bg/counter_bg_1.png')",

        };

        var Information;
        if (this.state.Job.length) {
            Information = this.state.Job.map((job) =>
                <>
                    <li>
                        <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>
                </>
            )
        } else {
            Information = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }

        var careersec;
        if (this.state.Job.length) {
            this.state.Job.map((job) =>
                careersec = this.state.Job.map((job) => {
                    if (job.job === 'Flutter Development') {
                        return <>
                            <section className="conversational-area pb-100 mt-10">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="conversational-content">
                                                <span>CAREER AT OENGINES</span>
                                                <h2>Join Our Amazing Team Now!</h2>

                                                <ul className="list">
                                                    <li>
                                                        <i className='fa fa-check'></i>
                                                        We are passionate developers. If you are too, this is the right place for you.
                                                    </li>
                                                    <li>
                                                        <i className='fa fa-check'></i>
                                                        A good place to explore your potential and continue to learn and grow.
                                                    </li>
                                                    <li>
                                                        <i className='fa fa-check'></i>
                                                        A Professional Environment to collaborate with teammates! Brainstorming & exploring various perspectives
                                                    </li>
                                                    <li>
                                                        <i className='fa fa-check'></i>
                                                        We welcome those with leadership skills to contribute to the team and explore their potential.
                                                    </li>
                                                </ul>
                                                <a className="conversational-btn">
                                                    Apply For Job
                                                    <i className='bx bx-right-arrow-alt'></i>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="conversational-image">
                                                <img src="assets/img/GameDevelopment/react/banner-6.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="shape">
                                    <img src="assets/img/shape/white-shape1.png" alt="image" />
                                </div>
                            </section>
                        </>
                    } else {
                        return null;
                    }
                }
                )
            )
        } else {
            careersec = '';
        }

        const { nameError, emailError, subjectError, numberError, toDateError, messageError } = this.state.formErrors;
        return (
            <>
                <Helmet>

                    <link href="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/css/coreui.min.css" rel="stylesheet" integrity="sha384-UkVD+zxJKGsZP3s/JuRzapi4dQrDDuEf/kHphzg8P3v8wuQ6m9RLjTkPGeFcglQU" crossorigin="anonymous" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                    <script src="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/js/coreui.bundle.min.js" integrity="sha384-n0qOYeB4ohUPebL1M9qb/hfYkTp4lvnZM6U6phkRofqsMzK29IdkBJPegsyfj/r4" crossorigin="anonymous"></script>
                    <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js" type="text/javascript"></script>
                    <script src="../assets/js/mian.js" type="Javascript"></script>
                    <script src="../assets/js/script.js" type="text/Javascript"></script>
                    <script src="../assets/js/slick-min.js"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-circle-progress/1.1.3/circle-progress.min.js"></script>
                </Helmet>
                {/* <Modal size="lg" show={this.state.isOpen} onClick={this.handleClickInsideModal} className="modal_contect video-effect-modal modalvideos" centered>
                    <Modal.Header closeButton className="modal_header p-0 border-none" style={{ border: "none" }} onHide={() => this.handlevideopopup()} >
                    </Modal.Header>
                    <Modal.Body>
                        <iframe width="960" height="415" src="https://www.youtube.com/embed/9xwazD5SyVg?autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>                        
                    </Modal.Body>
                </Modal>   */}
                <Header />
                <div className="as-hero-wrapper hero-4" id="hero">
                    <div className="body-particle" id="body-particle"></div>
                    <div className="hero-img tilt-active mt-30"><img src="assets/img/GameDevelopment/react/new-SaaS-4.png" alt="Hero Image" /></div>
                    <div className="container">
                        <div className="hero-style4 hero-style-new">
                            <div className="ripple-shape"><span className="ripple-1"></span><span className="ripple-2"></span><span
                                className="ripple-3"></span><span className="ripple-4"></span><span className="ripple-5"></span><span
                                    className="ripple-6"></span></div><span className="hero-subtitle">LET'S CREATE THE BEST</span>
                            <h1 className="hero-title">Flutter Developement</h1>
                            <p className="hero-text">Creating exceptional user experience with custom web Development services. Oengines helps you convert your brilliant ideas into outstanding ultra-digital solutions that catalyze business growth.</p>
                            <div className="btn-group"><a href="tel:+02614054978" className="as-btn style3">Call Now<i
                                className="fa-regular fa-arrow-right ms-2"></i></a>
                                {/* <div className="call-btn"><a href="#!"
                                    className="play-btn popup-video"><i className="fas fa-play"></i></a>
                                   <div className="media-body"><a href="#!" onClick={(e) => this.openModal(e)}
                                        className="btn-title popup-video">Watch Our Video</a> <span className="btn-text">Subscribe
                                            Now</span></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="triangle-1"></div>
                    <div className="triangle-2"></div>
                    <div className="hero-shape2"><img src="assets/img/hero/hero_shape_2_2.png" alt="shape" /></div>
                    <div className="hero-shape3"><img src="assets/img/hero/hero_shape_2_3.png" alt="shape" /></div>
                </div>
                <div className="why-sec-v2" data-bg-src="assets/img/bg/why_bg_2.jpg">
                    <div className="container space">
                        <div className="row align-items-center flex-row-reverse">
                            <div className="col-xl-6 mb-30 mb-xl-0">
                                <div className="img-box5"><img className="tilt-active" src="assets/img/GameDevelopment/react/SaaS-2.png" alt="Why" />

                                </div>
                            </div>
                            <div className="col-xl-6 chose-us">
                                <div className="title-area mb-35">
                                    <div className="shadow-title color3">Choose US</div><span className="sub-title"><img
                                        src="assets/img/theme-img/title_shape_1.svg" alt="shape" />WHY CHOOSE US</span>
                                    <h2 className="sec-title">More Than 6+ Years Flutter Experience <span className="text-theme">Team</span></h2>
                                </div>
                                <p className="mt-n2 mb-30">As a leading website development company, we believe that your website should support your business goals. It takes more than just technical skills to create a digital product that becomes an asset to you.</p>
                                <div className="feature-circle-wrap">
                                    <div className="feature-circle">
                                        <div className="circle-big">
                                            <div className="text">
                                                95%
                                            </div>
                                            <svg>
                                                <circle className="bg" cx="57" cy="57" r="52"></circle>
                                                <circle className="progress" cx="57" cy="57" r="52"></circle>
                                            </svg>
                                        </div>
                                        <div className="media-body">
                                            <h3 className="feature-circle_title">6 +</h3>
                                            <p className="feature-circle_text">Years of Experience</p>
                                        </div>
                                    </div>
                                    <div className="feature-circle">
                                        <div className="feature-circle">
                                            <div className="circle-big">
                                                <div className="text">
                                                    90%
                                                </div>
                                                <svg>
                                                    <circle className="bg" cx="57" cy="57" r="52"></circle>
                                                    <circle className="progress one" cx="57" cy="57" r="52"></circle>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <h3 className="feature-circle_title">20 +</h3>
                                            <p className="feature-circle_text">Team of Experts</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="media-lern-btn">
                                    <a href="#service-secs" className="as-btn style3">LEARN MORE
                                        <i className="fa-regular fa-arrow-right ms-2"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="bg-top-center z-index-common space" id="service-secs" style={mystyleone}>
                    <div className="container">
                        <div className="row justify-content-lg-between justify-content-center align-items-center">
                            <div className="col-lg-12 col-sm-12 pe-xl-12">
                                <div className="title-area text-center text-lg-center">
                                    <div className="shadow-title">FEATURES</div><span className="sub-title"><img
                                        src="assets/img/theme-img/title_shape_2.svg" alt="shape" />WHAT WE DO</span>
                                    <h2 className="sec-title text-white">Flutter Development <span className="text-theme"> Services</span></h2>
                                    <p className="pt-20">
                                        OEngines has excellence in Flutter development services. We have experience with 7 + years in web development. Oengines is a Flutter Development company that offers various Flutter development services.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row slider-shadow d-none as-carousel menu-as-desk" data-slide-show="4" data-lg-slide-show="3" data-md-slide-show="2"
                            data-sm-slide-show="2" data-xs-slide-show="1">
                            <div className="col-md-4 col-lg-3 col-xl-3 col-sm-6">
                                <div className="service-grid new-service-grid flutter-service">
                                    <div className="new-service-grid-icon"></div>
                                    <div className="service-grid_content new-service-content ctn-new">
                                        <img src="assets/img/icon/development-cycle.png" />
                                        <h3 className="box-title"><a>Custom Flutter App Development</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-3 col-sm-6">
                                <div className="service-grid new-service-grid flutter-service">
                                    <div className="new-service-grid-icon"></div>
                                    <div className="service-grid_content new-service-content ctn-new">
                                        <img src="assets/img/icon/experienced-developers.png" />
                                        <h3 className="box-title"><a>API Development & Integration</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-3 col-sm-6">
                                <div className="service-grid new-service-grid flutter-service">
                                    <div className="new-service-grid-icon"></div>
                                    <div className="service-grid_content new-service-content ctn-new">
                                        <img src="assets/img/icon/information.png" />
                                        <h3 className="box-title"><a>Support & Maintenance</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-3 col-sm-6">
                                <div className="service-grid new-service-grid flutter-service">
                                    <div className="new-service-grid-icon"></div>
                                    <div className="service-grid_content new-service-content ctn-new">
                                        <img src="assets/img/icon/node-js-development.png" />
                                        <h3 className="box-title"><a>Flutter App Consulting</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row slider-shadow as-carousel menu-as-mobile" data-slide-show="4" data-lg-slide-show="3" data-md-slide-show="2"
                            data-sm-slide-show="2" data-xs-slide-show="1">
                            <div className="col-md-4 col-lg-3 col-xl-3 col-sm-6 col-6">
                                <div className="service-grid new-service-grid flutter-service">
                                    <div className="new-service-grid-icon"></div>
                                    <div className="service-grid_content new-service-content ctn-new">
                                        <img src="assets/img/icon/development-cycle.png" />
                                        <h3 className="box-title"><a>Custom Flutter App Development</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-3 col-sm-6 col-6">
                                <div className="service-grid new-service-grid flutter-service">
                                    <div className="new-service-grid-icon"></div>
                                    <div className="service-grid_content new-service-content ctn-new">
                                        <img src="assets/img/icon/experienced-developers.png" />
                                        <h3 className="box-title"><a>API Development & Integration</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-3 col-sm-6 col-6">
                                <div className="service-grid new-service-grid flutter-service">
                                    <div className="new-service-grid-icon"></div>
                                    <div className="service-grid_content new-service-content ctn-new">
                                        <img src="assets/img/icon/information.png" />
                                        <h3 className="box-title"><a>Support & Maintenance</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-3 col-sm-6 col-6">
                                <div className="service-grid new-service-grid flutter-service">
                                    <div className="new-service-grid-icon"></div>
                                    <div className="service-grid_content new-service-content ctn-new">
                                        <img src="assets/img/icon/node-js-development.png" />
                                        <h3 className="box-title"><a>Flutter App Consulting</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-smoke" style={mystyletow}>
                    <div className="container-fluied">
                        <div className="row ctn-from">
                            <div className="col-xl-6">
                                <div className="title-area mb-35 text-xl-start text-center">
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <span className="sub-title"><img
                                            src="../assets/img/theme-img/title_shape_2.svg" alt="shape" />contact with us!</span>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h2 className="sec-title">Drop Us a Line</h2>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p className="sec-text">Reach Out to us from our Contact form and we will get back to you shortly.</p>
                                    </ScrollAnimation>
                                </div>
                                <form onSubmit={this.onSubmit} method="post" className="contact-forms ajax-contact">
                                    <div className="row">
                                        <div className="form-group col-md-6" >
                                            <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" style={{ backgroundColor: "#FFFFFF" }} />
                                            <i className="fal fa-user"></i>
                                            {nameError &&
                                                <div className="invalid-feedback" >{nameError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} style={{ backgroundColor: "#FFFFFF" }} autoComplete="Off" />
                                            <i className="fal fa-envelope"></i>
                                            {emailError &&
                                                <div className="invalid-feedback">{emailError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="text" id="subject" className={subjectError ? "form-control is-invalid" : "form-control"} ref="subjectfield" name="subject" placeholder="Subject" onChange={this.handleChange} style={{ backgroundColor: "#FFFFFF" }} autoComplete="Off" />
                                            <i className="fal fa-note-sticky"></i>
                                            {subjectError &&
                                                <div className="invalid-feedback">{subjectError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} name="number" value={this.state.number} ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off" onChange={this.maxlengthnumber} />
                                            <i className="fal fa-phone"></i>
                                            {numberError &&
                                                <div className="invalid-feedback">{numberError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-12">
                                            <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                                className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange} style={{ backgroundColor: "#FFFFFF" }} autoComplete="Off">
                                            </textarea>
                                            <i className="fal fa-comment"></i>
                                            {messageError &&
                                                <div className="invalid-feedback">{messageError}</div>
                                            }
                                        </div>
                                        <div className="form-btn text-xl-start text-center col-12 cont-btn">
                                            <button type="submit" className="as-btn style3">Send Details
                                                <i className="fa-regular fa-arrow-right ms-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3"></p>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>



                <section className="platform-area new-platforn ptb-100">
                    <div className="container-fluid">
                        <div className="section-title">
                            <span>HOW IT WORKS</span>
                            <h2>Flutter Developement Cycle</h2>
                            <p>Have a Look at our seamless Implementation Process!</p>
                        </div>

                        <div className="tab platform-list-tab">
                            <div className="row align-items-center">
                                <div className="col-lg-3">
                                    <ul className="tabs">
                                        <li>
                                            <a href="#" className="bg-f12711">
                                                <i className='bx bx-right-arrow-alt'></i>
                                                1.Requirement Gathering
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="bg-0067ff">
                                                <i className='bx bx-right-arrow-alt'></i>
                                                2.Designing
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="bg-1b2b37">
                                                <i className='bx bx-right-arrow-alt'></i>
                                                3.Prototyping
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="bg-f12711">
                                                <i className='bx bx-right-arrow-alt'></i>
                                                4.Development
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="bg-0067ff">
                                                <i className='bx bx-right-arrow-alt'></i>
                                                5.Team Collaboration
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="bg-1b2b37">
                                                <i className='bx bx-right-arrow-alt'></i>
                                                6.Support & Maintenance
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-lg-9">
                                    <div className="tab_content">
                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image1.jpg" alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Requirement Gathering</h2>

                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>
                                                                    <p>Focus would be on documentation first for clarity and better understanding from both sides and come to the same page.</p>

                                                                    <a className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image2.jpg" alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Designing</h2>

                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>

                                                                    <p>We help our clients to create an interactive and wonder to watch UI designs that describes user friendly flow of web/all/platform.</p>

                                                                    <a className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image3.jpg" alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Prototyping</h2>

                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>
                                                                    <h3></h3>
                                                                    <p>After finalizing and designs approval, we freee the scope and help our client demo or prototype to have a look and feel of the app/web/platform.</p>
                                                                    <a className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image5.jpg" alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Development</h2>

                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>
                                                                    <p>After clients approval, we start the development with the technology best suited for you results in the timerame as commited by our technical team.</p>

                                                                    <a className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image4.jpg" alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Deployment</h2>

                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>

                                                                    <p>We are open to help our clients with final testing ,training and final deployment of the source code, and launching in clients platform.</p>

                                                                    <a className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image5.jpg" alt="image" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Support & Maintenance</h2>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>
                                                                    <p>We provide free service and support as per decided terms in our agreement.</p>
                                                                    <a className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image5.jpg" alt="image" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Run the Task-Runner</h2>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>
                                                                    <p>Our quality analysis team tests the developed product for use in real-time and with live users. Resolve bug problems, and deliver the final product to you for actual launching.</p>

                                                                    <a className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tabs_item">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="platform-image">
                                                        <img src="assets/img/platform/image5.jpg" alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="platform-content">
                                                        <h2>Deploy</h2>

                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <div className="platform-text bar">
                                                                    <div className="image">
                                                                        <img src="assets/img/icon/file.png" alt="image" />
                                                                    </div>
                                                                    <p>Our work isn't done until your project is successfully launched. We will help you with everything from planning your launch to distributing your project to your users.</p>

                                                                    <a className="platform-btn">
                                                                        <i className='bx bx-right-arrow-alt'></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>





                <section className="sales-area pt-100 pb-70">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="sales-content">
                                    <span>Company Benefits</span>
                                    <h2>Get Top-Notch Flutter Development Services</h2>
                                    <p>OEngines games is a fastest growing Web and Mobile Development Company based in India, since 2017. We are professional, experienced and forward thinking, and our great strength is decoding our client’s needs into effective solutions.</p>

                                    <ul className="list">
                                        <li>
                                            <i className="fa-solid fa-arrow-right"></i>
                                            Proficient team
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-arrow-right"></i>
                                            High-performance and intuitive design
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-arrow-right"></i>
                                            Streamlined development process
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-arrow-right"></i>
                                            Keeping the user in mind
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="sales-box">
                                            <div className="image">
                                                <img src="assets/img/icon/shopping.png" alt="image" />
                                            </div>
                                            <h3>Managed Team Extensions</h3>
                                            <p>Our managed team extensions offer complete transparency and follow specially designed processes inspired by proven methodologies for improved project delivery.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="sales-box">
                                            <div className="image">
                                                <img src="assets/img/icon/numbered.png" alt="image" />
                                            </div>
                                            <h3>Digital Product Development</h3>
                                            <p>We don't just develop IT solutions; We engineer complex digital products that foster innovation and support transformative change.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="sales-box">
                                            <div className="image">
                                                <img src="assets/img/icon/collaboration.png" alt="image" />
                                            </div>
                                            <h3>Tech-Driven Business Transformation</h3>
                                            <p>We understand your unique needs and guide you to transform your business using the latest technologies to help you adapt and overcome the dynamic business environment.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="shape">
                        <img src="assets/img/shape/white-shape2.png" alt="image" />
                    </div>
                </section>

                <section className="space" id="project-card">
                    <div className="container">
                        <div className="title-area text-center">
                            <div className="shadow-title">PROJECTS</div><span className="sub-title"><img
                                src="assets/img/theme-img/title_shape_2.svg" alt="shape" />LATEST PROJECTS</span>
                            <h2 className="sec-title">Our Recent Latest <span className="text-theme">Projects</span></h2>
                        </div>
                        <div className="row slider-shadow as-carousel" id="projectSlide2" data-slide-show="3" data-lg-slide-show="3"
                            data-md-slide-show="2" data-sm-slide-show="1" data-arrows="true">
                            <OwlCarousel items={3}
                                className="owl-theme"
                                loop
                                autoplay={true}
                                margin={2} {...settings}>
                                <div className="">
                                    <div className="project-grid">
                                        <div className="project-grid_img"><img src="https://blog.gamedev.tv/content/images/size/w1000/2022/12/pexels-max-deroin-51415.jpg" alt="project image" />
                                            <a href="https://blog.gamedev.tv/content/images/size/w1000/2022/12/pexels-max-deroin-51415.jpg" className="play-btn style3 popup-image"><i
                                                className="far fa-plus"></i></a></div>
                                        <div className="project-grid_content">
                                            <h3 className="box-title"><a>What Game Should I Make First?</a></h3>
                                            <p className="project-grid_text">Distinctively enable premium expertise for multifunctional
                                                action items. Energistically benchmark worldwide communities.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="project-grid">
                                        <div className="project-grid_img"><img src="https://blog.gamedev.tv/content/images/size/w2000/2022/11/pixel-art-course-image-without-logo.jpg" alt="project image" />
                                            <a href="https://blog.gamedev.tv/content/images/size/w2000/2022/11/pixel-art-course-image-without-logo.jpg" className="play-btn style3 popup-image"><i
                                                className="far fa-plus"></i></a></div>
                                        <div className="project-grid_content">
                                            <h3 className="box-title"><a>How To Draw Pixel Art</a></h3>
                                            <p className="project-grid_text">Distinctively enable premium expertise for multifunctional
                                                action items. Energistically benchmark worldwide communities.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="project-grid">
                                        <div className="project-grid_img"><img src="https://blog.gamedev.tv/content/images/size/w1000/2022/12/pexels-max-deroin-51415.jpg" alt="project image" />
                                            <a href="https://blog.gamedev.tv/content/images/size/w1000/2022/12/pexels-max-deroin-51415.jpg" className="play-btn style3 popup-image"><i
                                                className="far fa-plus"></i></a></div>
                                        <div className="project-grid_content">
                                            <h3 className="box-title"><a>Node js1</a></h3>
                                            <p className="project-grid_text">Distinctively enable premium expertise for multifunctional
                                                action items. Energistically benchmark worldwide communities.</p>
                                        </div>
                                    </div>
                                </div>

                            </OwlCarousel>
                        </div>
                    </div>
                </section>

                {careersec}

                <div _ngcontent-mfs-c21="" className="header-lines-decoration">
                    <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                    <span _ngcontent-mfs-c21="" className="bg-blue"></span>
                    <span _ngcontent-mfs-c21="" className="bg-white-light"></span>
                    <span _ngcontent-mfs-c21="" className="bg-orange-light"></span>
                    <span _ngcontent-mfs-c21="" className="bg-red"></span>
                    <span _ngcontent-mfs-c21="" className="bg-green"></span>
                    <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                </div>

                <Footer />

            </>
        )
    }
}

export default Flutterpage;

