import React from "react";
import { Helmet } from 'react-helmet';
import 'react-awesome-slider/dist/styles.css';
import 'animate.css';
import Swal from "sweetalert2";
import 'react-circular-progressbar/dist/styles.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import DOMPurify from 'isomorphic-dompurify';

class Terms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
            Privacy: ''
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
    }
    componentDidMount() {

        axios.get("https://www.oengines.com/careers")
            .then(response => {
                this.setState({ Job: response.data });
                this.setState({ Description: response.data[0].description });

            })
            .catch(function (error) {
                console.log(error);
            })
        axios.get("https://www.oengines.com/terms-privacy-html-contents")
            .then(response => {
                this.setState({ Privacy: response.data[0].terms });
            })
            .catch(function (error) {
                console.log(error);
            })

    }
    onClickShowdetails(job) {
        console.log("job is:::::::", job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};
        console.log("Enter in::::::::::::");
        if (this.state.name === '' || this.state.name === null) {

            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.message === '' || this.state.message === null) {
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else if (this.state.toDate === '' || this.state.toDate === null) {
            formErrors["toDateError"] = "Date is required.";
            this.refs.toDatefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else {

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,

            };
            console.log("formObject::::::::::;", formObject);
            axios.post('https://www.oengines.com/contactus', formObject)
                .then((res) => {
                    console.log("Response is:::::::::", res);
                    if (res.data !== 'undefined') {
                        Swal.fire({
                            title: "Thank you!",
                            text: "Your request has been assigned successfully.",
                            icon: "success",
                        }).then(function () {
                            window.location.reload();
                        });
                    } else {
                        Swal.fire({
                            title: "Oops...!",
                            text: "Oh! Something went wrong, please try again!",
                            icon: "error",
                        }).then(function () {
                            window.location.reload();
                        });
                    }

                });
        }


        this.setState({
            name: '',
            email: '',
            subject: '',
            number: '',
            message: ''

        })
    }
    render() {
        var set = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidestoshow: 3,
                        slidestoscroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidestoshow: 2,
                        slidestoscroll: 1,
                        initialslide: 2,
                        dots: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidestoshow: 1,
                        slidestoscroll: 1,
                        dots: false
                    }
                }
            ]
        };

        const myStyleone = {
            backgroundImage:
                "url('../assets/img/hero/hero_bg_2_1.jpg')",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 2,
            opacity: 0.7,
        };
        const mystyletow = {
            backgroundImage:
                "url('../assets/img/hero/hero_bg_2_2.jpg')",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 2,
            opacity: 0.7,
        };
        const mystylethree = {
            backgroundImage:
                "url('../assets/img/hero/hero_bg_2_3.jpg')"
        };
        const mystylefour = {
            backgroundImage:
                "url('../assets/img/bg/cta_bg_1.png')",
            background: 'rgb(8, 14, 28)'
        };
        const mystylefive = {
            backgroundImage:
                "url('../assets/img/bg/service_bg_2.jpg')",
            backgroundRepeat: 'no-repeat'
        }
        const mystylesix = {
            backgroundImage:
                "url('../assets/img/bg/counter_bg_2.png')"
        }
        const mystyleseven = {
            backgroundImage:
                "url('../assets/img/bg/appointment_bg_1.jpg')"
        }
        const myStyleeight = {
            backgroundImage:
                "url('../assets/img/bg/blog_bg_1.png')",
            backgroundRepeat: 'no-repeat'
        }
        const { nameError, emailError, subjectError, numberError, toDateError, messageError } = this.state.formErrors;
        var Information;
        if (this.state.Job.length) {
            Information = this.state.Job.map((job, Index) =>
                <>

                    <li>
                        <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>
                </>
            )
        } else {
            Information = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }

        return (
            <div>
                <Helmet>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/jquery-datetimepicker/2.5.20/jquery.datetimepicker.min.css" />
                    <link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet" />
                    <link href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700&amp;
                    family=AnandaBlackPersonalUseRegular-rg9Rx:ital,wght@300;400;500;700;900&amp;family=Roboto:wght@300;400;500;700;900&amp;display=swap" rel="stylesheet" />
                    <link href="https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/css/demo.css" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                    <link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet" />
                    <link href="../assets/css/style.css" rel="stylesheet" />

                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
                    <script src="https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/js/TweenLite.min.js"></script>
                    <script src="https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/js/rAF.js"></script>
                    <script src="https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/js/EasePack.min.js"></script>
                    <script src="https://cdn.jsdelivr.net/npm/tsparticles-engine"></script>
                    <script src="https://cdn.jsdelivr.net/npm/tsparticles/tsparticles.bundle.min.js"></script>
                    <script src="https://cdn.jsdelivr.net/npm/jquery-particles"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/tsparticles/2.9.3/tsparticles.min.js"></script>
                    <script src="https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js"></script>                    
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-datetimepicker/2.5.20/jquery.datetimepicker.full.min.js"></script>
                    <script src="../assets/js/script.js" type="text/Javascript"></script>
                </Helmet>

                {/* <div className="loader" ><span>Loading...</span></div> */}
                <div className="space" id="about-sec" style={{ transition: "all 0.5s" }}>
                    <div className="container">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.Privacy) }}></div>
                    </div>
                </div>

                <div className="pb" id="pb"></div>
                <div className="pc">
                    <div className="ol">
                        <a href="#" id="btn-back-to-top">
                            <i className="fa-solid fa-arrow-up">
                            </i>
                        </a>
                    </div>
                    <div className="bg" id="bg"></div>
                </div>

            </div>
        )
    }
}

export default Terms;
