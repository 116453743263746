import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';

export default function RPG() {
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Rpg'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};



	const handleChange = (event) => {

	};



	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }


	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />
			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">

							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>
			<section id="hero" className="RPG-section hero-section division" style={{backgroundImage: "url(https://gameanalytics.com/wp-content/uploads/2020/05/RPG-game-cover.jpg)"}}>
				<div className="rpg-cared-hero">
					<div className="container">
						<div className="row card-hero pb-100 text-center">
							<div className="col-md-12 col-lg-12 mt-80">
								<div className="hero-17-title white-color mb-30">
									<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
										<h2 className="h2-xl">RPG Game Development</h2>
									</ScrollAnimation>
								</div>
								<div className="hero-17-txt action-hero-17-txt">
									<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
										<p className="p-xl white-color max-conent m-auto mt-40">Mobile gaming expert, OEngines Games puts the RPG genre under the spotlight, and shares the best tips and advice when making your own.</p>
									</ScrollAnimation>
								</div>
								<a href="tel:+02614054978" ><button className="theme-button mt-4">Call Now</button></a>
							</div>
						</div>
					</div>
				</div>
			</section>		
        
			<section className="rpg-game pt-80 pb-60">
				<div className="container">
                    <div className="row">
                        <div className="action-heaing">
                            <h2 className="text-center">RPG Game</h2>
                            <div className="elementor-divider rpg-elementor-divider">
                                <span className="elementor-divider-separator">
                                </span>
                            </div>
                            <p className="pt-10 text-start text mt-30">“A role-playing game (abbreviated RPG) is a game in which players assume the roles of characters in a fictional setting. Players take responsibility for acting out these roles within a narrative, either through literal acting, or through a process of structured decision-making regarding character development. Actions taken within many games succeed or fail according to a formal system of rules and guidelines.”</p>
                        </div>                        
                    </div>
				</div>
			</section>

			<section className="type-rpg pt-80 pb-80">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="type-rpg-img">
								<img src="../assets/img/GameDevelopment/mac-book-1.png" alt="img" />
							</div>
						</div>
						<div className="col-md-6">
							<div className="type-rpg-text">
								<h2>Types of RPG</h2>
								<p>Within the RPG genre, there are number of types of games, each of which adds one or more unique features or types of
								gameplays that distinguish it from others</p>
								<ul>
									<li>Action RPG</li>
									<li>Massively multiplayer online RPG (MMORPG)</li>
									<li>Roguelikes</li>
									<li>Tactical RPG</li>
									<li>Sandbox RPG</li>
									<li>First-person, party-based RPG</li>
									<li>Monster tamer</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="elemts-rpg-game pb-80 pt-50">
				<div className="container">
					<div className="Oeg_main pt-50 pb-60">
						<div className="row">							
							<div className="col-md-12">
								<div className="Oeg_text">
									<h2 className="text-center text-white">Why Choose OEngines?</h2>	
									<p className="mt-30 text-center text-white">We have in-depth understanding of the core RPG games characteristics and our game artists, designers offer high-fidelity graphics, engaging storyline and quest levels design seamlessly integrated with core gameplay, quest level systems and other typical RPG game mechanics. We have experience in 3D isometric engines for rapid development of multi-user virtual worlds and MMO communities</p>
								</div>
							</div>
						</div>
					</div>					
					<div className="row">
							<h2 className="text-center text-white mb-40">Here are some of the important elements for a typical RPG game</h2>									
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">1</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Immersive Storyline and Strong Narrative Settings</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">2</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Character Customization</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">3</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Experience and Skill development system</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">4</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Level-Up or Level Scaling Class system</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">5</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Inventory system</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">6</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Strong AI</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">7</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">3D Isometric engines for rapid development</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">8</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Experts in customized bot development</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">9</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">World Generation</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">10</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Quest based system</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">11</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Loot and Economy system</span>
							</div>					
						</div><div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">12</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Weapon</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">13</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Strategic and Tactical Combat System</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">14</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Non-Playing Characters (NPC)</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">15</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Sound Effects</span>
							</div>					
						</div>
						<div className="col-md-6 d-flex elemts-box col-lg-4 col-sm-6">
							<div className="icon-box">
								<i className="fa fa-gamepads" aria-hidden="true">16</i>
							</div>		
							<div className="icon-text">
								<span className="text-white">Perspective and POV</span>
							</div>					
						</div>
					</div>				
				</div>
			</section>

			<section className="benifit-rpg-sec pt-80 pb-80"> 
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="benifits-text">
								<h2 className="ml-20">Benefits of RPG Games</h2>
								<p className="mt-30 text">Role-Play Games are collaborative and interactive with other players while giving individuals a chance to build
								social and problem-solving skills. Role-Play Games are designed so that the group members are given tasks
								and from there the group must figure out how to best proceed forward. This type of open-ended play gives
								players the chance to be innovative in their problem-solving while giving players the opportunity to
								collaborate and build social skills that not only work within the context of gameplay but outside of the
								therapeutic setting as well. The relationship building aspect of RPGs is one that makes each game campaign
								unique.</p>
								<div className="d-flex">
									<ul>
										<li>Improved social skills and decreased isolation</li>
										<li>Stronger creative thinking and problem-solving skills</li>
										<li>Increased self-confidence and self-esteem</li>
									</ul>
									<ul className="ml-40">
										<li>Ability to express wants and needs assertively</li>
										<li>Improved practice with taking turns and impulse control</li>
										<li>Increased empathy and developing bonds with others</li>
									</ul>
								</div>							
							</div>
						</div>
						<div className="col-md-6">
							<div className="benifits-rpg-img mt-50 text-end">
								<img src="../assets/img/GameDevelopment/second-section-right.svg" alt="img not funf"/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="rpg-contect pt-80 pb-80">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="cnt-rpg-img">	
								<img src="../assets/img/GameDevelopment/why-wait-left-img.png" alt="img" />
							</div>
						</div>
						<div className="col-md-6">
							 <div className="rpg-cnt-details mt-50">
								<h2 className="text-white">Why Wait !</h2>
								<p className="mt-30 text-white">Develop your own RPG Game right away. You are just one click away from launching your
								own real money RPG game.</p>
								<button className="theme-button mt-30" onClick={handleShow}>Contact Us</button>
							 </div>
						</div>
					</div>
				</div>
			</section>
			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}
			<Footer />
		</div>

	);
}
