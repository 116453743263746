import React from "react";
import { Helmet } from 'react-helmet';
import '../../../src/assets/css/app.min.css';
import '../../../src/assets/css/app.min.css';
import '../../../src/assets/css/fontawesome.min.css';
import 'react-awesome-slider/dist/styles.css';
import '../../assets/css/responsive.css'
import '../../../src/assets/css/Slick_Cdn/bootstrap.min.css';
import axios from 'axios';
import Footer from "../../Footer";
import Header from "../../Header";
import 'react-circular-progressbar/dist/styles.css';
class IOSPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
        }
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
    }
    componentDidMount() {

        axios.get("https://www.oengines.com/careers")
            .then(response => {
                console.log("Data is::::::::", response.data);
                this.setState({ Job: response.data });
                this.setState({ Description: response.data[0].description });

            })
            .catch(function (error) {
                console.log(error);
            })

    }
    onClickShowdetails(job) {
        console.log("job is:::::::", job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }
    render() {
     
        var Information;
        if (this.state.Job.length) {
            Information = this.state.Job.map((job) =>
                <>
                    <li>
                        <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>
                </>
            )
        } else {
            Information = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }
        
        return (
            <>
                <Helmet>

                    <link href="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/css/coreui.min.css" rel="stylesheet" integrity="sha384-UkVD+zxJKGsZP3s/JuRzapi4dQrDDuEf/kHphzg8P3v8wuQ6m9RLjTkPGeFcglQU" crossorigin="anonymous" />
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                    <link href="https://fonts.googleapis.com/css2?family=Rajdhani:wght@500;600;700&display=swap" rel="stylesheet" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                    <script src="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/js/coreui.bundle.min.js" integrity="sha384-n0qOYeB4ohUPebL1M9qb/hfYkTp4lvnZM6U6phkRofqsMzK29IdkBJPegsyfj/r4" crossorigin="anonymous"></script>
                    
                    <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js" type="text/javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                    <script src="../assets/js/mian.js" type="Javascript"></script>
                    <script src="../assets/js/script.js" type="text/Javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-circle-progress/1.1.3/circle-progress.min.js"></script>
                </Helmet>
                {/* <div className="loader" ><span>Loading...</span></div> */}
                <Header />

                <section className="hero-ios ptb--60">
                    <div className="container">
                        <div className="">
                            <div className="">
                                <div className="hero-text max-content">
                                    <p className="text-white">MOBILE GAMES</p>
                                    <h2 className="text-white">
                                        BUILDING GAMES FOR IOS
                                    </h2>
                                    <p className="text-white">
                                        Bring your game to life with Unity, the chosen platform for over 60% of the top-grossing mobile games on the Apple App Store.
                                    </p>
                                    <div className="get-ntm-set">
                                        <a href="https://store.unity.com/configure-plan/unity-pro" className="as-btn mt-30" rel="noopener">create own game</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ptb--60">
                    <div id="social-stats-70159--2" className="component-social-stats component component-view-mode-default container">
                        <div className="inners row">
                            <div className="item social-stat col-md-auto col-sm-auto col-auto">
                                <i className="fa-solid fa-power-off"></i>
                                    <p>Unity powers 72% of the top 1,000 mobile games.</p>                               
                            </div>
                            <div className="item social-stat col-md-auto col-sm-auto col-auto">
                                <i className="fa-solid fa-power-off"></i>
                                    <p>Over 50% of new mobile games are created in Unity.</p>
                                
                            </div>
                            <div className="item social-stat col-md-auto col-sm-auto col-auto">
                                <i className="fa-solid fa-power-off"></i>
                                    <p>60%+ top-grossing App Store games are Unity-made.</p>                                
                            </div>
                        </div>
                    </div>
                </section>
                <section id="create-your-next-ios-mobile-game--2" className="component-cards component-cards-stacked bg-white component component-cards--has-shadow component-view-mode-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <h2 className="cards-section-title">Create your next iOS mobile game</h2>
                            </div>
                        </div>
                        <div className="row component-cards__space-top">
                            <div className="col-md-4 col-sm-6 col-lg-3 xol-xxl-4">
                                <div id="bring-your-creative-vision-life" className="component-card" style={{ height: "892.188px" }}>
                                    <div className="component-card--image" style={{ height: "892.188px" }}>
                                        <div className="component-card__image">
                                            <img src="assets/img/project/Unity_Tools.jpg" />
                                        </div>
                                        <div className="component-card__content">
                                            <h5 className="component-card__title">Bring your creative vision to life</h5>
                                            <div className="component-card__subtitle">BUILD</div>
                                            <div className="component-card__description">
                                                <div><p><strong>Unity</strong><br />
                                                    Build your dream game, and make it a huge success.</p>
                                                    <p><strong>Asset Store</strong><br />
                                                        Accelerate your game development with tools, assets, and templates.</p>
                                                    <p><strong>Plastic SCM</strong><br />
                                                        Enable effective collaboration between programmers and artists.</p>
                                                    <p><strong>Multiplayer</strong><br />
                                                        Host and manage your game servers.</p>
                                                    <p><strong>Game Backend</strong><br />
                                                        Managed infrastructure hosts your game data on the cloud.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="component-card__link">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-lg-3 xol-xxl-4">
                                <div id="better-insights-faster-fixes" className="component-card" style={{ height: "640px" }}>
                                    <div className="component-card--image" style={{ height: "640px" }}>
                                        <div className="component-card__image">
                                            <img src="assets/img/project/Faster_fixes.jpg" />
                                        </div>
                                        <div className="component-card__content">
                                            <h5 className="component-card__title">Better insights, faster fixes</h5>
                                            <div className="component-card__subtitle">OPTIMIZE</div>
                                            <div className="component-card__description">
                                                <div><p><strong>Backtrace</strong><br />
                                                    Capture, prioritize, and resolve errors with cross-platform crash and exception management.</p>
                                                    <p><strong>Unity Gaming Services Analytics</strong><br />
                                                        Understand game performance and player behavior.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="component-card__link">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-lg-3 xol-xxl-4">
                                <div id="publish-great-experiences" className="component-card" style={{ height: "640px" }}>
                                    <div className="component-card--image" style={{ height: "640px" }}>
                                        <div className="component-card__image">
                                            <img src="assets/img/project/launch_game.jpg" />
                                        </div>
                                        <div className="component-card__content">
                                            <h5 className="component-card__title">Publish great experiences</h5>
                                            <div className="component-card__subtitle">LAUNCH</div>
                                            <div className="component-card__description">
                                                <div><p><strong>Cloud Content Delivery</strong><br />
                                                    Manage assets and deliver content via the cloud.</p>
                                                    <p><strong>Helpshift</strong><br />
                                                        Engage your customers with mobile-first in-game support.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="component-card__link">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-lg-3 xol-xxl-4">
                                <div id="grow-sustainable-business" className="component-card" style={{ height: "640px" }}>
                                    <div className="component-card--image" style={{ height: "640px" }}>
                                        <div className="component-card__image">
                                            <img src="assets/img/project/Unity_Stock_772424500_0.jpg" />
                                        </div>
                                        <div className="component-card__content">
                                            <h5 className="component-card__title">Grow a sustainable business</h5>
                                            <div className="component-card__subtitle">MONETIZE</div>
                                            <div className="component-card__description">
                                                <div><p><strong>Unity Ads</strong><br />
                                                    Drive revenue and acquire new players with in-game ads.</p>
                                                    <p><strong>In-app purchases</strong><br />
                                                        Simplified integration across several app stores.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="component-card__link">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ptb--60">
                    <div className="benefits-2d">
                        <div className="container">
                            <div className="benefits-2d__head">
                                <h2 className="benefits-2d__title"><span>Our Fundamental Benefits</span> in iOS Game Development</h2>
                                <p className="benefits-2d__text">Key aspects of our approach to iOS game app development  are solid technical knowledge and an excellent focus on player desires and expectations. We follow all the wishes of the customerand at the same time offer innovative and relevant solutions to increase player engagement and achieve a large number of game downloads.</p>
                            </div>
                            <ul className="benefits-2d__list js-benefits-2d-slider row">
                                <li className="benefits-2d__item-wrapper col-md-6 col-sm-12 col-lg-6">
                                    <div className="benefits-2d__item">
                                        <div className="benefits-2d__item-title-wrapper">
                                            <h3 className="benefits-2d__item-title">Customized iPhone Game App Development</h3>
                                            <div className="benefits-2d__item-wreath">
                                                <img src="https://kevurugames.com/wp-content/uploads/2020/10/controls.svg" alt="iOS Game Development Services 71" />
                                            </div>
                                        </div>
                                        <p className="benefits-2d__item-text">Our iOS game company will help you throughout the entire development life cycle of a game from initial analytics and conceptualization to final product development and post-support. For each project, we build a customized scheme of cooperation, which allows us to provide the best quality within the specified budget and terms. We will select the optimal composition of the team from the best specialists of the artistic direction and genre you need.</p>
                                    </div>
                                </li>
                                <li className="benefits-2d__item-wrapper col-md-6 col-sm-12 col-lg-6">
                                    <div className="benefits-2d__item">
                                        <div className="benefits-2d__item-title-wrapper">
                                            <h3 className="benefits-2d__item-title">Rich Experience in the Gaming Industry</h3>
                                            <div className="benefits-2d__item-wreath">
                                                <img src="https://kevurugames.com/wp-content/uploads/2020/10/partnership.svg" alt="iOS Game Development Services 72" />
                                            </div>
                                        </div>
                                        <p className="benefits-2d__item-text">Working in the field of iOS app game development for more than 8 years, we are well aware of the requirements and expectations of players using iPhones and are successfully implementing our knowledge to create vivid memorable projects. It doesn't matter if it is iOS 2D game development or iOS 3D game development, in any case, our team will create a perfectly optimized and fun product.</p>
                                    </div>
                                </li>
                                <li className="benefits-2d__item-wrapper col-md-6 col-sm-12 col-lg-6">
                                    <div className="benefits-2d__item">
                                        <div className="benefits-2d__item-title-wrapper">
                                            <h3 className="benefits-2d__item-title">Most Relevant Technologies and Techniques</h3>
                                            <div className="benefits-2d__item-wreath">
                                                <img src="https://kevurugames.com/wp-content/uploads/2020/10/shield.svg" alt="iOS Game Development Services 73" />
                                            </div>
                                        </div>
                                        <p className="benefits-2d__item-text">We adhere to the highest standards of the iOS gaming industry, follow the latest trends in the gaming market and use the most advanced technologies, approaches and creative ideas to always be one step ahead. When technological updates appear, we immediately take them into service and implement them in our work. Thanks to this, our developments are always fresh and relevant.</p>
                                    </div>
                                </li>
                                <li className="benefits-2d__item-wrapper col-md-6 col-sm-12 col-lg-6">
                                    <div className="benefits-2d__item">
                                        <div className="benefits-2d__item-title-wrapper">
                                            <h3 className="benefits-2d__item-title">iOS Games with 10+ Million Downloads</h3>
                                            <div className="benefits-2d__item-wreath">
                                                <img src="https://kevurugames.com/wp-content/uploads/2020/10/flexibility.svg" alt="iOS Game Development Services 74" />
                                            </div>
                                        </div>
                                        <p className="benefits-2d__item-text">It is important for our team not only to create a high-quality product that will be appreciated by the players, but also to bring the client the maximum material value. We are focused on creating exclusive, exciting and unique games that resonate in the gaming market, collect thousands of comments, get featured on the App Store, and receive millions of downloads. Our games always attract attention and are vividly discussed.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
               
                <Footer />

            </>
        )
    }
}

export default IOSPage;

