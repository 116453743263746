import React from "react";
import { Helmet } from 'react-helmet';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-awesome-slider/dist/styles.css';
import OwlCarousel from 'react-owl-carousel';  
import CountUp from 'react-countup';
import Footer from "./Footer";
import axios from 'axios';
import Header from "./Header";
import ScrollAnimation from 'react-animate-on-scroll';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
        }
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
    }
    componentDidMount() {
  
        axios.get("https://www.oengines.com/careers")
            .then(response => {          
                this.setState({Job: response.data});
                this.setState({Description: response.data[0].description});
                
            })
            .catch(function (error) {
                console.log(error);
            })

    }
    onClickShowdetails(job){       
        console.log("job is:::::::",job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';       
    }
    render() {

        var settings = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                    nav:true
                },
                767:{
                    items:2,
                    nav:true
                },
                1000:{
                    items:3,
                    nav:true,
                    loop:true
                }
                
            }
        };

        const myStyleone = {
            background: "#1f1f22",
            width: "100%",
            zIndex: 2,
        };
   
        const mystylethree = {
            backgroundImage:
                "url('../assets/img/bg/why_bg_1.png')",
            backgroundRepeat: 'no-repeat'
        };
        const mystylefour = {
            backgroundImage:
                "url('../assets/img/bg/testi_bg_3.jpg')",
            backgroundRepeat: 'no-repeat'

        };

        var Information;
        if(this.state.Job.length){
        Information = this.state.Job.map((job,Index)=>       
            <>
                
                <li>
                    <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                </li>                                          
            </>
        )
        }else{
            Information = <div><h4 style={{color: "Red", fontSize: "20px"}}>No Record Found !</h4></div>;
        }
        return (
            <div>
                <Helmet>
                    <link rel="stylesheet"  href="../../assets/css/Slick_Cdn/Slic-min.css" />                   
                    <script src="../assets/js/particles-min.js"></script>             
                    <script src="../assets/js/script.js" type="text/Javascript"></script>                                                 
                </Helmet>
                {/* <div className="loader" ><span>Loading...</span></div> */}
                <Header />
                <div className="breadcumb-wrapper" style={myStyleone}>
                    <div className="container">
                        <div className="breadcumb-content">
                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                            <h1 className="breadcumb-title">About Us</h1>
                        </ScrollAnimation>
                            <ul className="breadcumb-menu">
                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                
                            </ScrollAnimation>
                            </ul>   
                        </div>
                    </div>
                </div>
                <div className="mt-60" id="about-sec">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 mb-30 mb-xl-0">
                                <div className="img-box1">
                                    <div className="img1"><img src="assets/img/normal/faq_1_1.png" alt="About" /></div>
                                    <div className="year-counter">
                                        <img src="../assets/img/theme-img/yearexperience.svg" alt="img not found" />
                                        <h3 className="year-counter_number "><span className="counter-number" id="foo"><CountUp end={sessionStorage.getItem("yearOfExp")}/>+</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="ps-xxl-4 ms-xl-3 p-4">
                                    <div className="title-area mb-35"><span className="sub-title"><img
                                        src="assets/img/theme-img/title_shape_1.svg" alt="shape" />About Oengines</span>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <div className="d-flex our-sgv-flex">
                                                <div className="abt-svg-icon">
                                                    <img src="../assets/img/icon/megaphone.svg" alt="img not found" />
                                                </div>
                                                <h2 className="sec-title fadeInLeft">
                                                    <span className="">Who we are</span></h2>
                                            </div>
                                        </ScrollAnimation>
                                    </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p className="mt-n2 mb-25">Welcome to OENGINES, your one-stop destination for cutting-edge web, app, and game development. We are a team of passionate innovators, creative thinkers, and tech enthusiasts dedicated to transforming ideas into reality.</p></ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p className="mt-n2 mb-25">We adhere to the highest industry standards and best practices. Our certifications reflect our commitment to quality, security, and professionalism in all aspects of our work. You can trust that our solutions are built with the utmost care and expertise.</p></ScrollAnimation>
                                    <div className="about-feature-wrap">
                                        <div className="about-feature">
                                            <div className="about-feature_icon"><img src="../assets/img/icon/about_feature_1_1.svg"
                                                alt="Icon" /></div>
                                            <div className="media-body">
                                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                    <h3 className="about-feature_title">Certified Company</h3>
                                                </ScrollAnimation>
                                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                    <p className="about-feature_text">Best Provide Skills Services</p>
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                        <div className="about-feature">
                                            <div className="about-feature_icon"><img src="../assets/img/icon/about_feature_1_2.svg"
                                                alt="Icon" /></div>
                                            <div className="media-body">
                                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                    <h3 className="about-feature_title">Experts Team</h3>
                                                </ScrollAnimation>
                                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                    <p className="about-feature_text">100% Experts Team</p>
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                    </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <div className="btn-group"><a href="tel:+02614054978" className="as-btn style3">Call now<i
                                            className="fa-regular fa-arrow-right ms-2"></i></a>
                                            <div className="call-btn">
                                                <div className="play-btn"><i className="fas fa-phone"></i></div>
                                                <div className="media-body"  ><span className="btn-text">Call Us On:</span> <a
                                                    href="tel:+02614054978" className="btn-title">(0261) - 4054978</a></div>
                                            </div>
                                        </div></ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="space"  style={mystylethree}>
                    <div className="container">
                        <div className="row align-items-center flex-row-reverse">
                            <div className="col-xxl-7 col-xl-6 mb-30 mb-xl-0 new-space-bottom">
                                <div className="img-box2">
                                    <div className="img1"><img src="assets/img/normal/why_1_1.jpg" alt="Why"/></div>
                                </div>
                            </div>
                            <div className="col-xxl-5 col-xl-6">
                                <div className="title-area mb-35">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <span className="sub-title"><img
                                    src="assets/img/theme-img/title_shape_1.svg" alt="shape"/>WHY CHOOSE US</span>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <h2 className="sec-title">Get in Touch with Us for the <span className="text-theme">Best Work!</span></h2>
                                </ScrollAnimation>
                                </div>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <p className="mt-n2 mb-30 new_get_in_touch">We nourish the needs of our clients with the best team distribution. In addition, our pool of talent helps us achieve outstanding and astonishing results in each project that we undertake.</p>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <p className="mt-n2 mb-30 new_get_in_touch">Our mission is to create the most useful solutions for our clients and to help them achieve all their digital goals.</p>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <p className="mt-n2 mb-30 new_get_in_touch">Oengines Game provides a wide range of App & Game Development Services at optimal cost to help you accomplish your goals.</p>
                                </ScrollAnimation>
                                <div className="two-column">
                                    <div className="checklist style2">
                                        <ul>
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                <li className="mb-4"><i className="far fa-check"></i> Reliable solutions</li>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                <li className="mb-4"><i className="far fa-check"></i> 24/7 Online Support</li>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                <li className="mb-4"><i className="far fa-check"></i>Business Improvement</li>
                                            </ScrollAnimation>
                                        </ul>
                                    </div>
                                    <div className="checklist style2">
                                        <ul>
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                <li className="mb-4"><i className="far fa-check"></i>High-quality standards</li>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                <li className="mb-4"><i className="far fa-check"></i> Availability of highly educated  team</li>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                <li className="mb-4"><i className="far fa-check"></i>Easy Solutions</li>
                                            </ScrollAnimation>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <section className="bg-top-center space slider-space" style={mystylefour} >
                    <div className="container">
                        <div className="title-area text-center">
                            <div className="shadow-title color2">TESTIMONIALS</div>
                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                <span className="sub-title"><img
                                src="assets/img/theme-img/title_shape_2.svg" alt="shape"/>Happy Clients</span>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                <h2 className="sec-title text-white">Here’s What Our Clients<br/><span className="text-theme">Have To Say</span>
                                </h2>
                            </ScrollAnimation>
                        </div>
                        <div className="row slider-shadow as-carousel" data-slide-show="3" data-lg-slide-show="2" data-md-slide-show="2"
                            data-sm-slide-show="1" data-arrows="true">
                            <OwlCarousel items={3}  
                            className="owl-theme"  
                            loop  
                            autoplay={true}
                            margin={8} {...settings}>    
                                <div className="new-box">
                                    <div className="testi-grid">
                                    <div className="testi-grid_img"><img src="assets/img/testimonial/testi_3_1.jpg" alt="Avater"/>
                                            <div className="testi-grid_quote pt-2"><img src="assets/img/icon/quote_left_3.svg" alt="quote"/></div>
                                        </div>
                                        <div className="testi-grid_review"><i className="fa-solid fa-star-sharp"></i><i
                                            className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i><i
                                            className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i></div>
                                        <div className="testi-grid_content">                                       
                                            <p className="testi-grid_text">Objectively visualize error-free technology for B2B alignment.
                                            Monotonectally harness an expanded array of models via effective collaboration. Globally
                                                synergize resource sucking value via cutting-edge.</p>                                                                             
                                            <h3 className="box-title">David Farnandes</h3>                                                                             
                                            <p className="testi-grid_desig">CEO at Anaton</p>                                      
                                    </div>
                                    </div>
                                </div>
                                <div className="new-box">
                                    <div className="testi-grid">
                                        <div className="testi-grid_img"><img src="assets/img/testimonial/testi_3_2.jpg" alt="Avater"/>
                                            <div className="testi-grid_quote pt-2"><img src="assets/img/icon/quote_left_3.svg" alt="quote"/></div>
                                        </div>
                                        <div className="testi-grid_review"><i className="fa-solid fa-star-sharp"></i><i
                                            className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i><i
                                                className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i></div>
                                        <div className="testi-grid_content">                                       
                                            <p className="testi-grid_text">Objectively visualize error-free technology for B2B alignment.
                                                Monotonectally harness an expanded array of models via effective collaboration. Globally
                                                synergize resource sucking value via cutting-edge.</p>
                                                                               
                                            <h3 className="box-title">Jackline Techie</h3>
                                                                               
                                            <p className="testi-grid_desig">CEO at Kormola</p>
                                        
                                        </div>
                                    </div>
                                </div>
                                <div className="new-box">
                                    <div className="testi-grid">
                                        <div className="testi-grid_img"><img src="assets/img/testimonial/testi_3_3.jpg" alt="Avater"/>
                                            <div className="testi-grid_quote pt-2"><img src="assets/img/icon/quote_left_3.svg" alt="quote"/></div>
                                        </div>
                                        <div className="testi-grid_review"><i className="fa-solid fa-star-sharp"></i><i
                                            className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i><i
                                                className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i></div>
                                        <div className="testi-grid_content">                                        
                                            <p className="testi-grid_text">Objectively visualize error-free technology for B2B alignment.
                                                Monotonectally harness an expanded array of models via effective collaboration. Globally
                                                synergize resource sucking value via cutting-edge.</p>                                                                               
                                            <h3 className="box-title">Abraham Khalil</h3>                                                                               
                                            <p className="testi-grid_desig">CEO at Anatora</p>                                       
                                        </div>
                                    </div>
                                </div>
                                <div className="new-box">
                                    <div className="testi-grid">
                                        <div className="testi-grid_img"><img src="assets/img/testimonial/testi_3_4.jpg" alt="Avater"/>
                                            <div className="testi-grid_quote pt-2"><img src="assets/img/icon/quote_left_3.svg" alt="quote"/></div>
                                        </div>
                                        <div className="testi-grid_review"><i className="fa-solid fa-star-sharp"></i><i
                                            className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i><i
                                                className="fa-solid fa-star-sharp"></i><i className="fa-solid fa-star-sharp"></i></div>
                                        <div className="testi-grid_content">                                        
                                            <p className="testi-grid_text">Objectively visualize error-free technology for B2B alignment.
                                                Monotonectally harness an expanded array of models via effective collaboration. Globally
                                                synergize resource sucking value via cutting-edge.</p>                                                                                
                                            <h3 className="box-title">Md Sumon Mia</h3>                                                                                
                                            <p className="testi-grid_desig">CEO at Rimasu</p>                                        
                                        </div>
                                    </div>
                                </div>
                           </OwlCarousel>
                        </div>
                    </div>
                </section> */}
                <Footer />              
            </div>
        )
    }
}

export default App;
