import  React  from "react";
import { Helmet } from 'react-helmet';
import '../../../src/assets/css/app.min.css';
import '../../../src/assets/css/app.min.css';
import '../../../src/assets/css/fontawesome.min.css';
import 'react-awesome-slider/dist/styles.css';
import '../../assets/css/responsive.css'
import '../../../src/assets/css/Slick_Cdn/bootstrap.min.css';
import Swal from "sweetalert2";
import axios from 'axios';
import Footer from "../../Footer";
import Header from "../../Header";
import 'react-circular-progressbar/dist/styles.css';
import { Modal } from 'react-bootstrap';

class Unitypage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
            setContactFormBool: false,
            show: false,
            isOpen: false,
            isActive: false,
            selectedDate: null,
            isAnimationRunning: true,
            autoplay: true        
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this);
      
    }
    componentDidMount() {

        axios.get("https://www.oengines.com/careers")
            .then(response => {
                console.log("Data is::::::::", response.data);
                this.setState({ Job: response.data });
                this.setState({ Description: response.data[0].description });

            })
            .catch(function (error) {
                console.log(error);
            })
    }
       
    openModal(e) {
        this.setState({ isOpen: !this.state.isOpen });
    }
    handleOpenContectModal(e) {
        this.setState({ show: !this.state.show });
        console.log("state is::::::::", this.state.show);
    }
    handlevideopopup(e) {
        console.log("::::::add on this:");
        this.setState({ isActive: !this.state.isActive });
    }
    onContactFormHide(e) {
        console.log(" event ::::onContactFormHide:: ", this.state.setContactFormBool);
        this.setState({ setContactFormBool: false });
    }
    onContactFormShow(e) {
        console.log(" event ::onContactFormShow:::: ", this.state.setContactFormBool);
    
        this.setState({ show: !this.state.show })
    }
    onClickShowdetails(job) {
        console.log("job is:::::::", job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    handleChangedatepicker = (date) => {
        this.setState({
            selectedDate: date,
        });
    };
    onClickblogdetails(blog) {
        console.log("blog is:::::::", blog);
        localStorage.setItem("Blog", JSON.stringify(blog));
        window.location.href = '/blogdetails';
    }
    closeModal() {
        this.setState({ show: !this.state.show });
    };
    handleClickInsideModal = (event) => {
        event.stopPropagation();
    }
    maxlengthnumber(evt) {
        if (evt.target.value.length === 11) {
            return false;
        }
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};
        console.log("Enter in::::::::::::");
        if (this.state.name === '' || this.state.name === null) {

            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.message === '' || this.state.message === null) {
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }  else {

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: 'Unity' + '-' + this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,

            };
            console.log("formObject::::::::::;", formObject);
            axios.post("https://www.oengines.com/contactus", formObject)
                .then((res) => {
                    console.log("Response is:::::::::", res);
                    if (res.data !== 'undefined') {
                        Swal.fire({
                            title: "Thank you!",
                            text: "Your request has been assigned successfully.",
                            icon: "success",
                        }).then(function () {
                            window.location.reload();
                        });
                    } else {
                        Swal.fire({
                            title: "Oops...!",
                            text: "Oh! Something went wrong, please try again!",
                            icon: "error",
                        }).then(function () {
                            window.location.reload();
                        });
                    }

                });
        }


        this.setState({
            name: '',
            email: '',
            subject: '',
            number: '',
            message: ''

        })

    }
    render() {

        const mystylefour = {
            backgroundImage:
                "url('../assets/img/bg/cta_bg_1.png')",
            background: 'rgb(8, 14, 28)'
        };
        
        var Information;
        if (this.state.Job.length) {
            Information = this.state.Job.map((job) =>
                <>
                    <li>
                        <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>
                </>
            )
        } else {
            Information = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }
        const { nameError, emailError, subjectError, numberError, messageError } = this.state.formErrors;
        return (
            <>
                <Helmet>

                    <link href="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/css/coreui.min.css" rel="stylesheet" integrity="sha384-UkVD+zxJKGsZP3s/JuRzapi4dQrDDuEf/kHphzg8P3v8wuQ6m9RLjTkPGeFcglQU" crossorigin="anonymous" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                    <script src="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/js/coreui.bundle.min.js" integrity="sha384-n0qOYeB4ohUPebL1M9qb/hfYkTp4lvnZM6U6phkRofqsMzK29IdkBJPegsyfj/r4" crossorigin="anonymous"></script>                
                    <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js" type="text/javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                    <script src="../assets/js/mian.js" type="Javascript"></script>
                    <script src="../assets/js/script.js" type="text/Javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-circle-progress/1.1.3/circle-progress.min.js"></script>
                </Helmet>
                {/* <div className="loader" ><span>Loading...</span></div> */}
                <Header />
                <Modal size="lg" show={this.state.isActive} onClick={this.handleClickInsideModal} className="modal_contect video-effect-modal modalvideos" centered>
                    <Modal.Header closeButton className="modal_header p-0 border-none" style={{border: "none"}} onHide={() => this.handlevideopopup()} >                                            
                    </Modal.Header>
                    <Modal.Body>
                        <iframe width="960" height="415" src="https://www.youtube.com/embed/9xwazD5SyVg?autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isOpen} onClick={this.handleClickInsideModal} className="modal_contect video-effect-modal" centered>
                    <Modal.Header closeButton onHide={() => this.openModal()} >
                        <h5 className="modal-title m-auto" id="exampleModalLabel">Contact Us</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.onSubmit} method="post" className="contact-form ajax-contact">
                            <div className="row">
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                    <i className="fal fa-user"></i>
                                    {nameError &&
                                        <div className="invalid-feedback" >{nameError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} autoComplete="Off" />
                                    <i className="fal fa-envelope"></i>
                                    {emailError &&
                                        <div className="invalid-feedback">{emailError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <select name="subject" id="subject" ref="subjectfield" className={subjectError ? "form-control is-invalid" : "form-control"} onChange={this.handleChange} autoComplete="Off">
                                        <option disabled="disabled">Select Subject
                                        </option>
                                        <option defaultValue={"Full Cycle Unity Game Developement"}>Full Cycle Unity Game Developement</option>
                                        <option defaultValue={"AR/VR Game Developement"}>AR/VR Game Developement</option>
                                        <option defaultValue={"2D/3D Game Developement"}>2D/3D Game Developement</option>
                                        <option defaultValue={"Cross Platform Game Developement"}>Cross Platform Game Developement</option>
                                        <option defaultValue={"3D Environment and Visulization"}>3D Environment and Visulization</option>
                                        <option defaultValue={"Real Money Gambling Game"}>Real Money Gambling Game</option>
                                    </select>
                                    {subjectError &&
                                        <div className="invalid-feedback">{subjectError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} name="number" ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange={this.maxlengthnumber} value={this.state.number} />
                                    <i className="fal fa-phone"></i>
                                    {numberError &&
                                        <div className="invalid-feedback">{numberError}</div>
                                    }
                                </div>

                                <div className="form-group col-12">
                                    <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                        className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange} autoComplete="Off">
                                    </textarea>
                                    <i className="fal fa-comment"></i>
                                    {messageError &&
                                        <div className="invalid-feedback">{messageError}</div>
                                    }
                                </div>
                                <div className="form-btn text-xl-start text-center col-12">
                                    <button type="submit" className="as-btn style3 send-qty">Send Details
                                        <i className="fa-regular fa-arrow-right ms-2"></i>
                                    </button>
                                </div>
                            </div>
                            <p className="form-messages mb-0 mt-3"></p>
                        </form>
                    </Modal.Body>
                </Modal>
                <div className="slider-area">
                    <div className="container-fluid padding-contorler-am-slide">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-6 col-xl-6 order-2 order-xl-1 padding-contorler-am-slide-right">

                                <div className="banner-top-rating sal-animate">
                                    <div className="banner-badge-top" data-sal-delay="300" data-sal="slide-up" data-sal-duration="500">
                                        <div className="icons">
                                            <img src="../assets/img/icon/rating-2.png" alt="Icons Images"/>
                                        </div>
                                        <span className="subtitle">8000+ Trust Customer</span>
                                    </div>

                                    <div className="banner-badge-top" data-sal-delay="500" data-sal="slide-up" data-sal-duration="500">
                                        <div className="icons">
                                            <img src="../assets/img/icon/trophy.png" alt="Icons Images"/>
                                        </div>
                                        <span className="subtitle">#1 Top Leaderboards</span>
                                    </div>
                                </div>

                                <h1 className="title large-height theme-color" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">WITH YOU FROM BIG IDEA TO<br/> BIG SUCCESS.</h1>
                                <p className="slide-disc mt-20" data-sal-delay="300" data-sal="slide-up" data-sal-duration="800">Bring your vision to life with gaming industry-leading solutions, and grow a successful business with our dedicated operating solutions that work on Android, iOS, or web game engines.</p>
                                <div className="button-group">
                                    <a className="as-btn style1 btn-6 mb-30" onClick={(e) => this.openModal(e)} style={{border: "none"}} data-sal-delay="400" data-sal="slide-up" data-sal-duration="800">Create Your Game</a>                                   
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 order-1 order-xl-2">

                                <div className="banner-gallery-wrapper">
                                    <div className="banner-gallery-loop">
                                        <div className="banner-gallery banner-gallery-1 mt--90">
                                            <img src="../assets/img/banner/banner-01.png" alt="Banner Images"/>
                                            <img src="../assets/img/banner/banner-01.png" alt="Banner Images"/>
                                        </div>
                                        <div className="banner-gallery banner-gallery-2">
                                            <img src="../assets/img/banner/banner-02.png" alt="Banner Images"/>
                                            <img src="../assets/img/banner/banner-02.png" alt="Banner Images"/>
                                        </div>
                                        <div className="banner-gallery banner-gallery-3 mt--90">
                                            <img src="../assets/img/banner/banner-03.png" alt="Banner Images"/>
                                            <img src="../assets/img/banner/banner-03.png" alt="Banner Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
                <section className="unity-why-choose-area pt-100 pb-70">
                    <div className="container">
                        <div className="section-title-two">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className="title text-white">                                        
                                            <span>Our Creative Work</span>                                                                                
                                            <h2>Our Unity Game Developement Services</h2>                                        
                                    </div>
                                </div>
                                <div className="col-lg-5">                                    
                                        <div className="text">
                                            <p className="text-white">Turning your idea into a captivating game or speeding up the development process with our Unity 2D/3D game development services.</p>
                                        </div>                                    
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/GameDevelopment/multiplayer_game.jpg" alt="image" />

                                    <div className="title">                                        
                                            <h3>Full Cycle Unity Game Developement</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">
                                            <h3>
                                                <a href="#">Full Cycle Unity Game Developement</a>
                                            </h3>
                                            <p className="unity-serv-par">Our Full-cycle Unity 3D Game Developement service offers game development across any genre and conecept With their technical expertise and vision, Our unity 3D development create games filled with great features, epic sounds, amazing graphoces, and a very engaging storyline.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/GameDevelopment/2d.jpg" alt="image" />

                                    <div className="title">                                        
                                            <h3>AR/VR Game Developement</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">                                            
                                                <h3>
                                                    <a href="#">AR/VR Game Developement</a>
                                                </h3>                                                                                        
                                                <p className="unity-serv-par">AR/VR Games are known to provide a breakthrough gaming experience. Our Unity Developement follow creative designing and technically advanced tools to develop VR/AR mobile games. Our AR/VR games can transform your idea into a digital reality that is beyond imagination.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/GameDevelopment/3d.jpg" alt="image" />

                                    <div className="title">                                        
                                            <h3>2D/3D Game Developement</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">                                            
                                                <h3>
                                                    <a href="#">2D/3D Game Developement</a>
                                                </h3>                                                                                        
                                                <p className="unity-serv-par">Our developers develop engaging 2D/3D mobile games that are not just a visual treat, but they make the gameplay si remarkable thar the useers will not be able to stop themselves from playing your game we offer unmatched 2D/3D game development services eith Unity 3D.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/GameDevelopment/onlinr-casino.jpg" alt="image" />

                                    <div className="title">                                        
                                            <h3>Cross Platform Game Developement</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">                                            
                                                <h3>
                                                    <a href="#">Cross Platform Game Developement</a>
                                                </h3>                                                                                        
                                                <p className="unity-serv-par">Capermint Technology provides an extensive range of servies, including cross-paltform game development. Our developers will help you target any Platforms that you want. We have ample experience in developing cross-platform games using Unity 3D.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/GameDevelopment/ar.jpg" alt="image" />
                                    <div className="title">                                        
                                            <h3>3D Environment and Visulization</h3>                                        
                                    </div>
                                    <div className="content">
                                        <div className="inner-content">                                            
                                                <h3>
                                                    <a href="#">3D Environment and Visulization</a>
                                                </h3>                                                                                        
                                                <p className="unity-serv-par">Every game we develop is filled with an amazing and attractive environmwnt, smooth animation and appealng assets that contribute immensely to storyline. Our environment and assets are perfectly optimizes for continuous interation by the players.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/GameDevelopment/vr.jpg" alt="image" />

                                    <div className="title">                                        
                                            <h3>Real Money Gambling Games</h3>                                        
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">                                            
                                            <h3>
                                                <a href="#">Real Money Gambling Games</a>
                                            </h3>                                                                                        
                                            <p className="unity-serv-par">There is a hug emand out there for real money games, and at OEngines Games. we have a track record of successful real money gambling game development. The real money games will have all the necessary features. along with various payment getways.</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ptb--6 mt-50 mb-50">
                    <div className="cv-section cv-gaming-services">
                        <div className="cv-gaming-banner">
                            <div className="cv-gaming-banner-inner">
                                <h4 className="mb-3">Unity Gaming Services </h4>
                                <p>Our professional team is ready to build your live games with tools for multiplayer services, game operations, user acquisition, and monetization. </p>
                                <div className="cv-gaming-img">
                                    <img src="assets/img/bg/cv-game-banner.png" alt="Unity Gaming Services" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="unity-service unity-service-bg ptb--60">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="title-area text-center">
                                <h2 className="title mb--15 text-white">50% Of games across sevices are made with Unity</h2>                               
                            </div>
                            <div className="our-lang row justify-content-center">
                                <div className="col-md-2 col-auto  our-lan-img">
                                    <img src="assets/img/service/new-1.jpg" alt="img not found"/>
                                </div>
                                <div className="col-md-2 col-auto our-lan-img">
                                    <img src="assets/img/service/new-2.jpg" alt="img not found"/>
                                </div>
                                <div className="col-md-2 col-auto our-lan-img">
                                    <img src="assets/img/service/new-3.jpg" alt="img not found"/>
                                </div>
                                <div className="col-md-2 col-auto our-lan-img">
                                    <img src="assets/img/service/new-4.jpg" alt="img not found"/>
                                </div>
                                <div className="col-md-2 col-auto our-lan-img">
                                    <img src="assets/img/service/new-5.jpg" alt="img not found"/>
                                </div>
                                <div className="col-md-2 col-auto our-lan-img">
                                    <img src="assets/img/service/new-6.jpg" alt="img not found"/>
                                </div>
                                <div className="col-md-2 col-auto our-lan-img">
                                    <img src="assets/img/service/new-7.jpg" alt="img not found"/>
                                </div>
                                <div className="col-md-2 col-auto our-lan-img">
                                    <img src="assets/img/service/new-8.jpg" alt="img not found"/>
                                </div>
                                <div className="col-md-2 col-auto our-lan-img">
                                    <img src="assets/img/service/new-9.jpg" alt="img not found"/>
                                </div>
                                <div className="col-md-2 col-auto our-lan-img">
                                    <img src="assets/img/service/new-10.jpg" alt="img not found"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="component component-callout component-callout__image-background ptb--60">
                    <div className="container">
                        <div id="create-and-grow-more-unity--2" className="uni-bg component-callout component-callout__inner has-overlay has-overlay--darken-30">
                            <div className="row has-overlay__inner">
                                <div className="col-xs-12 col-sm-offset-1 col-sm-8">
                                    <h2 className="component-callout__title color-white">
                                        <div>Create and grow more with Unity</div>
                                    </h2>
                                    <div className="component-callout__description large">
                                        <div><p>Unity is the leading video game engine – and so much more. Discover solutions to help us at every stage of the game development lifecycle, from big idea to big success.</p>
                                        </div>
                                    </div>
                                    <div className="component-callout__buttons md-m-top">
                                        <a href="#" className="as-btn style1 btn-6">Learn more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
             
                <section className="ptb--60">
                    <div className="container">
                        <div className="row component-cards__space-top">
                            <div className="col-xs-12 col-md-4 col-sm-6 mt-35">
                                <div id="2d-ufo-tutorial" className="component-card" style={{height: "520px"}}>
                                    <div className="component-card component-card--image" style={{height: "520px"}}>
                                        <div className="component-card__image">
                                            <a href="https://learn.unity.com/project/2d-ufo-tutorial" target="">
                                                <img src="assets/img/blog/unity_blog_1.jpg" alt="Screenshot from UFO tutorial" />
                                            </a>
                                        </div>
                                        <div className="component-card__content">
                                            <h5 className="component-card__title">2D UFO tutorial</h5>
                                            <div className="component-card__description">
                                                <div><p>Start out by creating a simple 2D UFO game, which introduces many of the principles of working with Unity and creating 2D games, including Game Objects, Components, Prefabs, Physics and Scripting.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="component-card__link">
                                            <a href="https://learn.unity.com/project/2d-ufo-tutorial" target="_blank" rel="noopener">Learn more</a>

                                        </div>
                                    </div>	
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-4 col-sm-6 mt-35">
                                <div id="2d-roguelike-tutorial" className="component-card" style={{height: "520px"}}>
                                    <div className="component-card component-card--image" style={{height: "520px"}}>
                                        <div className="component-card__image">
                                            <a href="https://learn.unity.com/project/2d-roguelike-tutorial" target="">
                                                <img src="assets/img/blog/unity_blog_2.jpg" alt="Screenshot from Roguelike Tutorial" />
                                            </a>
                                        </div>
                                        <div className="component-card__content">
                                            <h5 className="component-card__title">2D Roguelike tutorial</h5>
                                            <div className="component-card__description">
                                                <div><p>Get an introduction to more advanced techniques, including how to create procedural tile-based levels, implement turn-based movement, and add a hunger system, audio, and mobile touch controls.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="component-card__link">
                                            <a href="https://learn.unity.com/project/2d-roguelike-tutorial" target="_blank" rel="noopener">Learn more</a>
                                        </div>
                                    </div>	
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-4 col-sm-6 mt-35">
                                <div id="dedicated-2d-game-creation-section" className="component-card" style={{height: "460px"}}>
                                    <div className="component-card component-card--image" style={{height: "460px"}}>
                                        <div className="component-card__image">
                                            <a href="https://learn.unity.com/search?k=%5B%22q%3A2D%20Game%22%5D" target="">
                                                <img src="assets/img/blog/unity_blog_3.jpg" alt="Screenshot from 2D Tutorial" />
                                            </a>
                                        </div>
                                        <div className="component-card__content">
                                            <h5 className="component-card__title">Dedicated 2D game creation section</h5>
                                            <div className="component-card__description">
                                                <div><p>Find tutorials on specific features, 2D physics, and videos of <a href="https://unity.com/products/unity-learn">live training sessions</a>.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="component-card__link">
                                            <a href="https://learn.unity.com/search?k=%5B%22q%3A2D%20Game%22%5D" target="_blank" rel="noopener">Learn more</a>
                                        </div>
                                    </div>	
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-4 col-sm-6 mt-35">
                                <div id="sorting-and-9-slicing-unity-2d" className="component-card" style={{height: "570px"}}>
                                    <div className="component-card component-card--image" style={{height: "570px"}}>
                                        <div className="component-card__image">
                                            <a href="https://youtu.be/XoRfOFwduTw" target="">
                                                <img src="assets/img/blog/unity_blog_4.jpg" alt="Sorting and 9-slicing in Unity 2D" />
                                            </a>
                                        </div>
                                        <div className="component-card__content">
                                            <h5 className="component-card__title">Sorting and 9-slicing in Unity 2D</h5>
                                            <div className="component-card__description">
                                                <div><p>In this live training session we will learn a workflow for laying out a simple 2D scene using 2D sorting groups, sprite meshes and 9-Slice sprites introduced in Unity 5.6. We will use these tools to create a simple 2D game.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="component-card__link">
                                            <a href="https://youtu.be/XoRfOFwduTw" rel="noopener">Watch session</a>
                                        </div>
                                    </div>	
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                  <section className="bg-bottom-right position-relative" style={mystylefour} data-bg-color="#080E1C">
                    <div className="img-half img-left as-video2">
                        <img src="assets/img/normal/cta_1.jpg" alt="video" />
                        <p className="play-btn popup-video" id="" onClick={(e) => this.handlevideopopup(e)}><i
                            className="fas fa-play vid-play"></i>
                        </p>
                    </div>
                    <div className="container">
                        <div className="row justify-content-end" >
                            <div className="col-xl-7 space" id="half-particles">
                                <div className="title-area mb-35">
                                    <span className="sub-title"><img
                                        src="assets/img/theme-img/title_shape_1.svg" alt="shape" />WATCH THE VIDEO</span>
                                    <h2 className="sec-title text-white">We makes the games, that stands out from crowd!</h2>
                                </div>
                                <p className="mt-n2 mb-30 text-white">Because the gaming industry becoming stable, the requirements for games explored in the market are increasing in the proportional manner day by day. Hence, opting the company to develop your game is a crucial decision. </p>
                                <p className="mt-n2 mb-30 text-white">We provide a full-scale game development services by improving our outstanding combination of experience and skills.</p>

                                <div className="signature">
                                    <img src="assets/img/about/sandip.png" alt="image" />
                                    <h3 style={{"color": "white"}}>Sandip Vala</h3>
                                    <p>Co-Founder of OENGINES GAMES LLP</p>
                                </div>

                                <button className="as-btn" id="" onClick={(e) => this.openModal(e)} style={{border: "none"}}>GET WITH US
                                    <i className="fa-regular fa-arrow-right ms-2"></i>
                                </button>

                            </div>
                        </div>
                    </div>

                </section>

                <Footer />
            </>
        )
    }
}

export default Unitypage;

