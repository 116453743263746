import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import { Helmet } from 'react-helmet';
import Header from '../../../GameServiceHeader';
import Footer from '../../../over_games/Footer.js';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from 'react-slick';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';

export default function Adventure() {
	const [sliderRef, setSliderRef] = useState(null);
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);

	const sliderSettings = {
		arrows: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,	
		autoplay: true,
		responsive: [
			{
				breakpoint: 992,
				settings: {
				  arrows: false,
				  slidesToShow: 3,
				},
			},
			{
			  breakpoint: 768,
			  settings: {
				arrows: false,
				slidesToShow: 2,
			  },
			},
			{
			  breakpoint: 576,
			  settings: {
				arrows: false,
				slidesToShow: 1,
			  },
			},
		],
	}
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  
	const handleClickInsideModal = (event) => {
        event.stopPropagation();
    };

	const EducationQualificationSubmit = (event) => {
		console.log("Submites Sucsssfully");
	};
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Adventure'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};

	const endDateChange = (e) => {
       
    };

	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }
	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />

			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>

			<section id="hero" className="advanture-section hero-sections division ">
				<div className="container">
					<div className="row card-her pt-100 pb-100 text-start">
						<div className="col-md-12 col-sm-12 col-xl-6 col-xxl-6 col-lg-6 text-md-center text-xl-start text-sm-center text-center">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Adventure Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt action-hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color max-conent m-auto mt-40">We deliver immersive and captivating Android and iOS Adventure games for clients worldwide</p>
								</ScrollAnimation>
							</div>
							<a href="tel:+02614054978" ><button className="theme-button mt-50">Call Now</button></a>
						</div>
						<div className="col-md-12 col-sm-6 col-xl-6 col-xxl-6 col-lg-6">
							<div className="adventure-hero-sec">							
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="action-games adventures-advantage pt-80 pb-80">
				<div className="container">
					<div className="action-heaing">
						<h2 className="text-center">Adventure Game</h2>
						<div className="elementor-divider action-elementor-divider" style={{ left: "41%" }}>
							<span className="elementor-divider-separator">
							</span>
						</div>
						<p className="pt-20 mt-30 text text-start">An adventure game (rarely called quest game) is a video game genre in which the player assumes the role of a protagonist in an interactive story, driven by exploration and/or puzzle-solving. The genre's focus on story allows it to draw heavily from other narrative-based media, such as literature and film, encompassing a wide variety of genres.</p>
					</div>
				</div>
			</section>

			<section className="adventure-mian pt-80 pb-80">
				<div className="container">
					<div className="adventure-title">
						<h2 className="text-center text-white">Benefits of Adventure Game Development</h2>
					</div>
					<div className="row mt-35 justify-content-center">
						<div className="col-md-6 col-sm-12 col-xl-4 col-lg-6 mt-35">
							<div className="adventure-box">
								<h4>Multitasking</h4>
								<p>The ability to perform two or more tasks at the same time comes from the brain. An adventure game requires the player
									to divide attention between targets, this increases their ability to multitask effectively. The more you play adventure
									games, the better your multitasking skills will be.</p>
								<i className="fontawesome-icon fa-cube fas circle-yes" />
							</div>
						</div>
						<div className="col-md-6 col-sm-12 col-xl-4 col-lg-6 mt-35">
							<div className="adventure-box">	
								<h4>Making a Faster Decision</h4>
								<p>A person who has played adventure games is better at making a faster decision than one who has not. A video game
									needs the player to make spur of the moment choices based on evidence given.</p>
								<i className="fontawesome-icon fa-male fas circle-yes" />
							</div>
						</div>
						<div className="col-md-6 col-sm-12 col-xl-4 col-lg-6 mt-35">
							<div className="adventure-box">
								<h4>Improve dyslexia</h4>
								<p>Adventure video games are also known to improve dyslexia. The condition is generally caused by problems in visual
									attention. Adventure games require a person to keep the target in their sight. Constant playing has been shown to
									reduce chances of dyslexia and better visuals.</p>
								<i className="fontawesome-icon fa-cubes fas circle-yes" />
							</div>
						</div>
						<div className="col-md-6 col-sm-12 col-xl-4 col-lg-6 mt-35">
							<div className="adventure-box">
								<h4>Increases Concentration</h4>
								<p>It increases concentration and thus decreases impulsiveness. Every game demands the complete attention of the player.
									When the focus is lost, mistakes are made, and the player loses. This raises the level of concentration a person has.</p>
								<i className="fontawesome-icon fa-gamepad fas circle-yes" />
							</div>
						</div>
						<div className="col-md-6 col-sm-12 col-xl-4 col-lg-6 mt-35">
							<div className="adventure-box">
								<h4>Decreases Impulsiveness</h4>
								<p>When playing adventure games, one learns to respond only to target stimuli. The brain learns not to react to the rest of the provocations. This ability reduces impulsiveness in a person.</p>
								<i className="fontawesome-icon fa-mobile-alt fas circle-yes" />
							</div>
						</div>
					</div>
				</div>
			</section>
			
			<section className="about-main pt-80">
				<div className="container">
					<div className="row">
						<div className="col-md-6 align-self-center mt-3 mt-md-0 wow fadeInRight">
							<div className="pl-lg-5 pl-0 p-4">
								<h2 className="font-weight-bold text-primry mb-4">
								Best Game Development Company</h2>
								<p className="text-muted-light mb-4 pr-md-5" style={{color: "rgb(126, 127, 129) !important"}}>
								</p><p>OEngines Games is a notable Game Development company that applies a unique and unorthodox development
								technique to each game genre while developing game concepts into real-world experience games. Our game designer
								places equal emphasis on full-cycle game production from inception, co-generation of gaming solutions through highly
								productive teamwork, and speedy and effective game porting. Entrust your idea to a game development company that
								can illuminate the gaming business with real stars. Our team's expertise enables us to cover all gaming platforms and
								create an exceptional product that suits the customer's vision and the preferences of the gamers.</p>
							</div>
						</div>
						<div className="col-md-6 wow fadeInLeft">
							<div className="about-img-outer">
								<div className="about-img">
									<img src="../assets/img/GameDevelopment/Adventure/Advanture.png" style={{width: "140%!important"}} alt="Best VR Games Solutions" className="w-100 " />
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<br />
				<br />								
			</section>

			<section className="hire-adventure pt-80 pb-90">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="hire-text mt-60">
								<h2>Hire Our Adventure Game Developer!</h2>
								<p className="mt-40">We provide staffing solutions for prototype development and projects that require continuous support w.r.t. development, game improvements/updates, and debugging on request. Hire our dedicated resources to develop your adventure concept depending on the complexity of your title.</p>
								<button className="theme-button mt-40 style-2" onClick={handleShow} ><a >Contactus</a></button>
						    </div>				
						</div>
						<div className="col-md-6">
							<div data-vc-video-bg="https://www.youtube.com/watch?v=cQDnfFNup54" className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 thegem-custom-64916f851b5598972 vc_col-has-fill vc_video-bg-container">
								<div className="vc_video-bg vc_hidden-xs">
									<iframe className="inner" frameBorder="0" allowFullScreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="MOBA - Game Character Design" width="100%" height="100%" src="https://www.youtube.com/embed/cQDnfFNup54?playlist=cQDnfFNup54&amp;iv_load_policy=3&amp;enablejsapi=1&amp;disablekb=1&amp;autoplay=1&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;loop=1&amp;mute=1&amp;wmode=transparent&amp;origin=https%3A%2F%2Fwww.macrobiangames.com&amp;widgetid=3" id="widget4" style={{maxWidth: "146%", marginLeft: "0", marginTop: "0px", width: "100%", height: "522px"}} data-gtm-yt-inspected-15="true"></iframe>
								</div>
								<div className="vc_column-inner thegem-custom-inner-64916f851b55b vc_custom_1671606081543">
									<div className="wpb_wrapper thegem-custom-64916f851b5598972"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}
			<Footer />
		</div>

		);
}
