import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';

export default function Quiz() {	
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Quiz'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};



	const handleChange = (event) => {

	};



	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }
	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />

			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>

			<section id="hero" className="quiz-section hero-section division">
				<div className="container">
					<div className="row card-her pt-100 pb-100 text-start">
						<div className="col-md-6 col-lg-6 colxl-6 mt-80 m-card">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Quiz Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt action-hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color max-conent m-auto mt-30 text">We, OENGINES GAMES, is a quiz application development company. We have a talented and dedicated team of mobile application developers that are working on different platforms such as iOS, Android, and Windows. OEngines assure to deliver the best quality of outputs to our precious clients</p>
								</ScrollAnimation>
							</div>
							<a href="tel:+02614054978" ><button className="theme-button mt-4">Call Now</button></a>
						</div>
						<div className="col-md-6 col-lg-6 col-xl-6">
							<div className="hero-quize-img">
								<img src="../assets/img/GameDevelopment/quiztopmobile.png" alt="img"/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="racing-game pt-80 pb-60">
				<div className="container">                    
					<div className="action-heaing-quiz">
						<h2 className="text-center">Quiz Game</h2>
						<div className="elementor-divider quiz-divider">
							<span className="elementor-divider-separator">
							</span>
						</div>
						<p className="pt-10 mt-30 text text-start">Quiz games are a fun way of learning. Children find it exciting to compete with one another and solve the questions. Even as adults, we enjoy a good quiz. It’s like a mini mystery to solve while we have a cup of tea during our break.</p>
						<p className="pt-10 mt-30 text">Quizzes are simply enjoyable. We all know that when learning is lovely, we learn more effectively.</p>
						<p className="pt-10 mt-30 text">When on your way to work, some quick general knowledge quizzes can help you gain information and pass the time.
						After a tiring day, you can take fun quizzes about your favorite topics to know you’re still the best at your game. It makes
						you feel better, doesn’t it?</p>
					</div>                                        
				</div>
			</section>

			<section className="p-whychoose mb-100 mt-60">
				<div className="container">
					<div className="row justify-conent-center">
						<div className="col-md-8 col-sm-12 col-lg-10">
							<h2>Why Choose <strong> OEngines Game Development?</strong></h2>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="tab-content" id="pills-tabContent">
								<div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
									<h3>Our Quiz Game Feature</h3>
									<div className="row">
										<div className="col-md-9">
											<ul>
												<li>Quick And Simple</li>
												<li>Select Favorite Topic</li>
												<li>Timed Questions</li>
												<li>Customize The Quiz</li>
												<li>Display Correct Answer After The Quiz</li>
												<li>View Report Page</li>
												<li>Multiplayer Game</li>
												<li>Challenge Friends For Competition</li>
												<li>Different Levels of Quiz</li>
												<li>Multi-media Based Quiz</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 pc-r order-lg-6">
							<ul className="nav nav-pills mb-3 row" id="pills-tab" role="tablist">

								<li className="nav-item col-sm-4 col-md-6 col-lg-4">
									<a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">
										<span className="p-img"><img src="../assets/img/GameDevelopment/q-backend.png" alt="" className="img-fluid" /></span>
										<strong>
											Back-end Control Panel
										</strong>
									</a>
								</li>
								<li className="nav-item col-sm-4 col-md-6 col-lg-4">
									<a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
										<span className="p-img"><img src="../assets/img/GameDevelopment/quizappfeat.png" alt="" className="img-fluid" /></span>
										<strong>

											Abundant Quiz App Features
										</strong>
									</a>
								</li>
								<li className="nav-item col-sm-4 col-md-6 col-lg-4">
									<a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false"><span className="p-img"><img src="../assets/img/GameDevelopment/q-Deployments.png" alt="" className="img-fluid" /></span>
										<strong>
											Different Deployments
										</strong></a>
								</li>
							</ul>
						</div>
						
					</div>
				</div>
			</section>
			<section className="key-features pt-80 pb-80">
				<div className="container">
					<div className="key-features-title">
						<h2 className="text-center text-white">Key Features of OEngines Games</h2>
						<p className="text-center text-white">OEngines develops an app that provides genuine and meaningful value and also with an incredibly rich
						experience for players.</p>
					</div>
					<div className="row mt-50">
						<div className="col-md-4 col-sm-6 mt-30 key-main">
							<div className="key-features-box">
								<div className="key-ftr-header">													
									<i className="fa-solid fa-envelope" aria-hidden="true"></i>
								</div>
								<h4 className="text-center text-white mt-30">Easy Invitation</h4>
								<p className="text-center text-white mt-25">Users can invite friends, family or others to play the game and one can challenge each other.</p>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 mt-30 key-main">
							<div className="key-features-box">
								<div className="key-ftr-header">		
									<i className="fa fa-circle-notch" aria-hidden="true"></i>											
								</div>
								<h4 className="text-center text-white mt-30">Easy Usage</h4>
								<p className="text-center text-white mt-25">Users can login with the credentials and can access the game on any mobile devices.</p>
							</div>

						</div>
						<div className="col-md-4 col-sm-6 mt-30 key-main">
							<div className="key-features-box">
								<div className="key-ftr-header">													
									<i className="fa-brands fa-twitter" aria-hidden="true"></i>
								</div>
								<h4 className="text-center text-white mt-30">Social Media Integration</h4>
								<p className="text-center text-white mt-25">Even users can log in to apps by using other social media profiles like Twitter, Facebook, Instagram.</p>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 mt-30 key-main">
							<div className="key-features-box">	
								<div className="key-ftr-header">													
									<i className="fa fa-play-circle" aria-hidden="true"></i>
								</div>
								<h4 className="text-center text-white mt-30">Multiple Options to Play</h4>
								<p className="text-center text-white mt-25">Using this app, you can have single player mode and even allows two players to play on one mobile device.</p>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 mt-30 key-main">
							<div className="key-features-box">
								<div className="key-ftr-header">													
									<i className="fa fa-usd" aria-hidden="true"></i>
								</div>
								<h4 className="text-center text-white mt-30">Monetize your App</h4>
								<p className="text-center text-white mt-25">This app allows the third parties to let play advertisements while playing the game.</p>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 mt-30 key-main">
							<div className="key-features-box">
								<div className="key-ftr-header">													
									<i className="fab fa-hacker-news"></i>
								</div>
								<h4 className="text-center text-white mt-30">Media Sharing</h4>
								<p className="text-center text-white mt-25">Members can share pictures, can have video calls and share memories upon mutual understanding.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="choose_poker" className="why-poker pt-80 pb-100">
				<div className="container">
					<div className="row">
						<div className="quize-choose-desc col-md-6 mt-35">
							<h2 className="mb-40">Quiz App Development Solution with OEngines Games</h2>
							<p className="mt-30 text">We have developed online quiz games that serve different ideas and trends in the segment. We have coding
							experts who are closely related to the most attractive quiz game concepts that have a big impact on user
							platforms and the timeline of trends. We combine our knowledgeable technical skills and enterprising digital
							talent to create great competition opportunities for gamers and those looking to do something interesting
							with online games. When it comes to quiz app development, we’re everywhere – logo quizzes, general
							curiosities, world affairs, IQ tests, and weird quests. You can trust us with any idea or design around the
							mobile quiz app, based on the platform, features, and theme.</p>
						</div>				
						<div className="poker-choose-ul col-md-6 mt-35">
						<h3 className="ml-60 mt-15 mb-40">Benefits of Quiz Games</h3>	
							<ul className="ml-60">												
								<li>Raise confidence</li>
								<li>Potential to support individual learning</li>
								<li>Stress-free way of learning</li>
								<li>Helps in Strategy and Problem Solving</li>
								<li>Constant learning</li>
								<li>Competitive learning</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section className="quiz-contect mb-80">
				<div className="container  pt-70 pb-70">
                    <div className="row justify-conent-center">
                        <div className="hire-our-action-text racing-contect-mian col-md-12 col-lg-12">
							<span className="text-white">Contact Us</span>
                            <h2 className="text-white mt-35">WANT TO HIRE OUR QUIZ GAME DEVELOPERS?</h2>
                           <button className="theme-button mt-35" onClick={handleShow}>GET IN TOUCH WITH US</button>
                        </div>
                    </div>
				</div>
			</section>	
			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}

			<Footer />
		</div>

	);
}
