import React, { useEffect, useState } from 'react';
import '../assets/css_game/responsive.css';
import '../assets/css_game/style.css';
import '../assets/css_game/menu.css';

function GameHeader() {


    const [data, setData] = useState([]);
    useEffect(() => {
        var url = 'https://www.oengines.comheaders/Games';
        fetch(url)
            .then(res => res.json())
            .then((data) => {
                console.log("Gmaes name and Data is ::::::::::;", data);
                setData(data);
            })
            .catch(console.log)
    }, []);
    console.log("Data state is :::::::::;;", data);

    var GameHader;
    if (data.length) {
        GameHader = data.map((header, Index) =>
            <li className="text-center" key={Index}>
                <img src={header.icon} alt={header.name} width={"50px"} />
                <a className="game-services" href={header.href}>{header.name}</a>
            </li>
        )
    } else {
        GameHader = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
    }


    return (
        <header id="header" className="header tra-menu navbar-light">
            <div className="header-wrapper">
                <div className="wsmobileheader clearfix">
                    <span className="smllogo"><img src="../assets/img/GamesImg/logo-01.png" alt="mobile-logo" /></span>
                    <a id="wsnavtoggle" className="wsanimated-arrow"><span></span></a>
                </div>
                <div className="wsmainfull menu clearfix wsmainfull1">
                    <div className="wsmainwp clearfix">
                        <div className="desktoplogo"><a href="#hero-17" className="logo-black"><img src="../assets/img/GamesImg/logo-01.png" alt="header-logo" /></a></div>
                        <div className="desktoplogo"><a href="#hero-17" className="logo-white"><img src="../assets/img/GamesImg/logo-white.png" alt="header-logo" /></a></div>
                        <nav className="wsmenu clearfix">
                            <ul className="wsmenu-list nav-skyblue-hover">
                                <li aria-haspopup="true"><a href={"/"}>Home</a>
                                </li>
                            <li aria-haspopup="true"><a href="/about">About</a>
                                </li>
                                <li aria-haspopup="true"><a href="/contact">Contact Us</a>
                                </li>
                                <li aria-haspopup="true" className="row nl-simple d-none d-lg-block"><a href="#">Game List <span className="wsarrow"></span></a>
                                    <div className="wsmegamenu clearfix halfmenu">
                                        <div className="container-fluid">
                                            <div className="row">                                                                                  
                                                <ul className="d-flex sub-menu" style={{ flexWrap: "inherit", justifyContent: "center", gap: "34px" }}>                                                    
                                                    {data.map((header, Index) => (
                                                        <li className="text-center d-grid" key={Index} style={{width: "100px"}}>
                                                            <img src={header.icon} alt={header.name} width={"50px"} style={{margin: "10px auto"}}/>
                                                            <a className="game-services" style={{color: "#151515"}} href={header.href}>{header.name}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="has-child menu-item-has-childrens d-lg-none d-md-block">
                                    <a href="#" className="menu-item-has-children">Game List</a>
                                    <ul className="menu-ul sub-menu" style={{ flexWrap: "inherit", justifyContent: "center", gap: "34px" }}>
                                        {data.map((header, Index) => (
                                            <li className="menu-item-has-childrens" key={Index} style={{ display: "-webkit-box" }}>
                                                <img src={header.icon} alt={header.name} width={"50px"} style={{ margin: "10px auto" }} />
                                                <a className="game-services ms-3" href={header.href} style={{ lineHeight: "3.5" }}> {header.name} </a>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li className="nl-simple" aria-haspopup="true"><a href="#">Features</a></li>
                                <li className="nl-simple" aria-haspopup="true">
                                    <a href="#gaming" className="btn btn-red tra-white-hover hover-stop last-link" style={{fontSize: "17px"}}>Let's Play</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
}
export default GameHeader;