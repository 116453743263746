import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../config.json';
import { Helmet } from 'react-helmet';
import Header from "../../GameServiceHeader";
import Footer from '../../over_games/Footer';
import '../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';


var mystyleone = {
	backgroundImage:
	"url('../assets/img/bg/cta_bg_2.jpg')"
};

export default function Casino() {
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Casino'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};

	

	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }
	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />

			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>

			<section id="hero" className="bg-scroll-card hero-section division">
				<div className="container">
					<div className="row card-her pt-100 pb-100">
						<div className="col-md-6 col-lg-6 arcade-col">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Casino Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color max-conent">OEngines is a leading casino game development company that offers customized casino games with high-end solutions. With striking 2D/3D graphics, our casino game developers can turn your game idea into a fascinating casino game.
									</p>
								</ScrollAnimation>
							</div>
							<button className="theme-button mt-4"><a href="tel:+02614054978" >Call Now</a></button>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="hero-17-img video-preview">
								<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
									<img className="img-fluid" src="../assets/img/GamesImg/Rummy_images/dashboard-04.png" alt="hero-image" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>			
			</section>
			<section className="cardgame-content mt-80 mb-90">
				<div className="container">
					<div className="cardgame-titles">
						<h2 className="text-center mb-20">What is Casino Game</h2>
					</div>
					<div className="cardgame-text mt-30 text">
						<p>A casino app enables players to gamble or bet money on games. This game is a game of luck
                        and chance.</p>
                        <p>Casino games have gone online due to the widespread use of the Internet and smartphones.
                        As a result, millions of gamers worldwide now use their computers and cellphones to play
                        casino games.</p>
                        <p>For a long time, gambling and casinos have captivated and fascinated people. Because of the
                        increment in the worldwide market, demand for casino game development has also increased.</p>
                    </div>					
				</div>
			</section>            
            <section className="cosino-service pt-50 pb-80">
                <div className="container">
                    <div className="cardgame-titles">
                        <h2 className="text-center text-white">Casino Game Feature</h2>
                        <p className="text-white pt-20 cardgame-p">The success and user-friendliness of the game are determined by the features and functionalities
                           it has. Here is a list of gameplay features that we use for casino games.</p>
                    </div>
                    <div className="row mt-50">
                        <div className="col-md-6 col-sm-6 col-xl-4 col-lg-4">
                            <div className="casino-service-card casino-1">
                                <h4 className="text-white text-center">Multi-Language Support</h4>
                                <p className="text-white text-center">Players will easily be able to play any game by leveraging the benefits of multi-Language support.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xl-4 col-lg-4">
                            <div className="casino-service-card casino-2">
                                <h4 className="text-white text-center">Avalanche Reels</h4>
                                <p className="text-white text-center">Our website doesn’t have traditional spins but rather a more advanced take on the reels. So, the players get to have free spins if they win.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xl-4 col-lg-4">
                            <div className="casino-service-card casino-3">
                                <h4 className="text-white text-center">Multiplayer</h4>
                                <p className="text-white text-center">Adds an element of social interaction and competition to the gaming experience, where players can join virtual tables with other players from around the world.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xl-4 col-lg-4">
                            <div className="casino-service-card casino-4">
                                <h4 className="text-white text-center">Irresistible Jackpots</h4>
                                <p className="text-white text-center">Our casino games are filled with amazing rewards and jackpots. So, this is the time to make some more bets.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xl-4 col-lg-4">
                            <div className="casino-service-card casino-5">
                                <h4 className="text-white text-center">Multiple Screen Slots</h4>
                                <p className="text-white text-center">The players in our casino games can easily choose between different screens when playing here.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xl-4 col-lg-4">
                            <div className="casino-service-card casino-6">
                                <h4 className="text-white text-center">2D and 3D Games</h4>
                                <p className="text-white text-center">Along with 2D casino software solutions, our game developers are experienced enough to provide some casino     3D game development features as well.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="chs_oeg mt-100 mb-80">
				<div className="container">
					<div className="Oeg_main">
						<div className="row">
							<div className="col-md-6">
								<div className="Oeg_img">
									<img src="../assets/img/GameDevelopment/card-game1.png" alt="img not found" />
								</div>
							</div>
							<div className="col-md-6">
								<div className="Oeg_text casino-new">
									<h2>Why choose OEngines for Casino game Development</h2>
									<div className="elementor-divider style-left-top">
										<span className="elementor-divider-separator">
										</span>
									</div>
									<p className="mt-30 text">Our casino game developers at OEngines are the finest in the industry, and they are provided with cutting-edge technology and tools to deliver you an excellent casino game experience. We have
                                    also confirmed our casino game developers have owned the current technology stack such as
                                    AR/VR technology, NFT, and machine learning to provide our clients with a competitive edge. We
                                    are able to deliver a high-quality casino gaming experience across multiple platforms. During our
                                    casino game development process, we keep in touch with clients to guarantee the right things are
                                    done.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="cardgame_modes pb-100 casiono-modas">
				<div className="container">
					<div className="cardgame-title casino-title text-center pt-50">
						<h2>Our Casino Game Development Services</h2>
						<p>We offer custom and distinctive Casino games development services to keep our partner ahead of competitors.</p>
					</div>
					<div className="row">
						<div className="col-md-4 col-xxl-4 col-lg-4 col-sm-6 mt-30">
							<div className="cardmode newcasino-box mt-50">
								<div className="cradmode-img">
									<img src="../assets/img/GameDevelopment/computer-Mode-card.svg" alt="img" />
								</div>
								<div className="crd-text casino">
									<h3>Live Casino Development Services</h3>
									<p>We help clients to start their Live casino portal with our technically advanced and customized casino
                                    development solutions which help them to grab more business opportunities in the gambling business.</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-xxl-4 col-lg-4 col-sm-6 mt-30">
							<div className="cardmode newcasino-box mt-50">
								<div className="cradmode-img">
									<img src="../assets/img/GameDevelopment/local-mode-card.svg" alt="img" />
								</div>
								<div className="crd-text casino">
									<h3>Mobile Casino Development Services</h3>
									<p>We offer the most reliable mobile casino solutions that help clients gain popularity in the gambling world by connecting many mobile users through our ultra-advanced Mobile casino games services.</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-xxl-4 col-lg-4 col-sm-6 mt-30">
							<div className="cardmode newcasino-box mt-50">
								<div className="cradmode-img">
									<img src="../assets/img/GameDevelopment/private-mode-card.svg" alt="img" />
								</div>
								<div className="crd-text casino">
									<h3>VR Casino Development Services</h3>
									<p>We offer VR and AR-based Casino game development services. Our dedicated developers can help you to
                                    develop casino games with a realistic touch that gives a unique gaming experience to your audience.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>		
			<section className="position-relative space">
				<div className="as-bg-img" style={mystyleone}>
					<img src="assets/img/bg/bg_overlay_1.png" alt="overlay"/>
				</div>
				<div className="container z-index-common">
					<div className="row justify-content-center">
						<div className="col-xl-6 col-lg-7 col-md-9 text-center">
							<div className="title-area mb-35"><span className="sub-title">
								<img src="assets/img/theme-img/title_shape_2.svg" alt="shape"/>CONTACT US</span>
								<h2 className="sec-title text-white">WANT TO HIRE OUR CASINO<span
									className="text-theme fw-normal"> GAME DEVELOPERS?</span></h2>
							</div><a  className="as-btn" onClick={handleShow}>GET IN TOUCH WITH US</a>
						</div>
					</div>
				</div>
			</section>
			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}

			<Footer />
		</div>

	);
}
