import React, { useEffect, useState } from "react";
import Header from "./Gameheader";
import Footer from "../over_games/Footer";
import '../assets/css_game/style.css';
import CountUp from 'react-countup';
import { Helmet } from 'react-helmet';
import ScrollAnimation from 'react-animate-on-scroll';
import '../assets/css_game/dropdown-effects/fade-down.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from "axios";
import config from '../config.json';
const sliderSettings = {
    dots: false,
    items: 5,
    slidestoscroll: 1,
    autoplay: true,
    interval: 5000,
    initialslide: 0,
    autoplaytimeout: 8000,
    autoplaySpeed: 2000,
    responsive: {
        0: {
            items: 2,
            nav: false
        },
        600: {
            items: 3,
            nav: false
        },
        1000: {
            items: 4,
            nav: false,
        }
    }
}
// config.GAME_CATEGORIES_GAMEDATA_API+"callbreak&filter[where][category]="+action

export default function Callbreak() {

    const [gameDetails, setGame] = useState({});
    useEffect(() => {
        var str = window.location.pathname;
		let Pathname = str.substring(1);
        axios.get(config.GAME_DETAILS_FOR_PAGES_API+Pathname).then((res) => {
            if (res.data && res.data.length){
                setGame(res.data[0]);
            }                
        })
    }, []);

    return (

        <div>
            <Helmet>
                <link id="googleFonts" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800%7CShadows+Into+Light&amp;display=swap" rel="stylesheet" type="text/css"></link>
                <link rel="stylesheet" href="../assets/css_game/style.css" />
                <script src="../assets/js/script.js" type="JavaScript"></script>
            </Helmet>

            <Header />

            <section id="hero-17" className="bg-scroll rel hero-section division">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 col-lg-8">
                            <div className="hero-17-title white-color mb-30">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
                                    <h2 className="h2-xl">Callbreak Game</h2>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-5">
                            <p className="p-xl white-color">Enhance your Gaming experience with the Call Break Game
                                Development offered by us. Our Developers build games for both web and mobiles
                                including all app platforms. Our aim is to provide you with all the services
                                that will meet your needs and requirements in a professional manner. Hire our
                                Call Break Game Developers with their rich experience and skills to have the
                                best Call Break Game Development services in India.
                            </p>
                            {
                                gameDetails.android_link ?
                                    <a target="_blank" href={gameDetails.android_link} className="os-btn bg-white d-flex align-items-center">
                                        <div className="os-btn-ico">
                                            <div className="ico-50">
                                                <img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
                                            </div>
                                        </div>
                                        <div className="os-btn-txt">
                                            <h6 className="h6-lg">Download for Android</h6>
                                        </div>
                                    </a>
                                    :
                                    ''
                            }

                            {
                                gameDetails.ios_link ? <a target="_blank" href={gameDetails.ios_link} className="os-btn mac-os-btn bg-white d-flex align-items-center">
                                    <div className="os-btn-ico">
                                        <div className="ico-50">
                                            <img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
                                        </div>
                                    </div>

                                    <div className="os-btn-txt">
                                        <h6 className="h6-lg">Download for IOS</h6>
                                    </div>
                                </a> : ''
                            }
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="hero-17-img video-preview">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInRight">
                                    <img className="img-fluid" src="../assets/img/GamesImg/callbreak_images/dashboard-04.png" alt="hero-image" />
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="content-2" className="content-2 pb-60 pt-50 content-section division">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-5 col-lg-6">
                            <div className="rel img-block left-column wow fadeInRight">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInleft">
                                    <img className="img-fluid" src="../assets/img/GamesImg/callbreak_images/img-10.png" alt="content-image" />
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-6">
                            <div className="txt-block right-column wow fadeInLeft">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInright">
                                    <h2 className="h2-xs">Callbreak Game Development Services</h2>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInright">
                                    <p className="p-lg">Oengines is a leading Call Break game Development Company in
                                        India. Our team of Call Break game Developers like Designers and programmers
                                        strives to give you the best Call Break game software in terms of quality. Our
                                        team specifically keep clients in the loop throughout the game process to ensure that the clients are highly satisfied with the results.
                                        We use advanced technologies to create a game that supports android, iOS,
                                        Desktop & all other platforms. Our developers make sure that you and your
                                        audience receive memorable hands-on experiences.
                                    </p>
                                    <p className="p-lg">Adept Call Break game providers are working hard on different Call
                                        Break game app solutions consisting of varied options and blends of features,
                                        functionalities, modules, etc. to perfectly deliver you the best idea for Call
                                        Break game app development. We will provide you with a rich-featured app that
                                        will help you to gain more Call Break users. Set your Call Break game to a level
                                        higher than other Call Break game developers with us.
                                    </p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="features-8" className="pb-60 features-section division">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                            <div className="section-title title-01 mb-70">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInup">
                                    <h2 className="h2-md">
                                        --- Ability to React Quickly ---</h2>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="fbox-8-wrapper text-center">
                        <div className="row row-cols-1 row-cols-md-3 justify-content-center">
                            <div className="col">
                                <div className="fbox-8 mb-40 wow fadeInUp">
                                    <div className="fbox-img">
                                        <img className="img-fluid" src="../assets/img/GamesImg/callbreak_images/callbreak.png"
                                            alt="feature-icon" />
                                    </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInup">
                                        <h5 className="h5-md">Desktop Version</h5>
                                        <p className="p-lg">This version is mainly for Windows and MacOS. You can simply
                                            download and install this version and good to go.
                                        </p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div className="col">
                                <div className="fbox-8 mb-40 wow fadeInUp">
                                    <div className="fbox-img">
                                        <img className="img-fluid change" src="../assets/img/GamesImg/callbreak_images/img-01.png"
                                            alt="feature-icon" />
                                    </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInup">
                                        <h5 className="h5-md">Smartphone Version</h5>
                                        <p className="p-lg">Our callbreak game software mobile version is available for IOS,
                                            Android and Windows app.
                                        </p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="content-9" className="content-9 bg-whitesmoke-gradient pt-100 content-section division">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <div className="section-title title-02 mb-60">
                                <h2 className="h2-xs">Discover powerful features to boost your productivity</h2>
                            </div>
                        </div>
                    </div>
                    <div className="img-block">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="about-rummy">
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInup">
                                        <h2 className="heading">What is Call Break Game?</h2>
                                        <p>Call Break is an ancient variation of the Spades card game played with 4
                                            players. All the 52 cards will be divided equally among all the players, and
                                            they will play 5 rounds to complete the game. The player with the highest
                                            deals after the 5th round ends will be declared the winner. The main
                                            contribution of our game Call Break is playing offline Call Break
                                            challenging opponents with Strong AI, classNamey graphics, and smooth gameplay.
                                        </p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="content-9-img video-preview wow fadeInUp">
                                    <img className="img-fluid" src="../assets/img/GamesImg/callbreak_images/dashboard-07.png"
                                        alt="video-preview" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="statistic-2" className="wide-100 statistic-section division">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6">
                            <div className="txt-block right-column wow fadeInLeft">
                                <h3 className="h3-xs">More than 50k+ users around the world are already using Callbreak
                                    actively</h3>
                            </div>
                        </div>
                        <div className="col-sm-5 col-md-4 col-lg-3 offset-sm-1 offset-md-2 offset-lg-1">
                            <div className="statistic-block wow fadeInUp">
                                <h2 className="h2-title-xs statistic-number"><span className="count-element"><CountUp end={50} duration={10} />K</span>
                                </h2>
                                <p className="p-lg mt-20">Active downloads <br /> from the community</p>
                            </div>
                        </div>
                        <div className="col-sm-5 col-md-4 col-lg-2">
                            <div className="statistic-block wow fadeInUp">
                                <h2 className="h2-title-xs statistic-number">
                                    <span className="count-element"><CountUp end={5} duration={5} /></span>.<span className="count-element"><CountUp end={0} duration={5} /></span>
                                </h2>
                                <div className="txt-block-rating ico-15 yellow-color">
                                    <span className="flaticon-star-1"></span>
                                    <span className="flaticon-star-1"></span>
                                    <span className="flaticon-star-1"></span>
                                    <span className="flaticon-star-half-empty"></span>
                                </div>
                                <p className="p-lg txt-400">5.0 Rating</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="space-bottom space-top contact-infos" style={{ 'background-color': 'antiquewhite' }}>
                <div className="container">
                    <div className="row text-center pt-4">
                        <div className="col">
                            <h2 className="word-rotator slide font-weight-bold text-8 mb-25">
                                <span>Callbreak UI is&nbsp;</span>
                                <span className="word-rotator-words bg-primary" style={{ padding: "0 20px 0 0" }}>
                                    <span className="spinny-words text-start">
                                        <span>incredibly</span>
                                        <span>especially</span>
                                        <span>extremely</span>
                                        <span>incredibly</span>
                                        <span>especially</span>
                                        <span>extremely</span>
                                    </span>
                                </span>
                                <span>&nbsp;&nbsp;We care about design & developments.</span>
                            </h2>
                            <p className="lead appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300" style={{ animationDelay: "300ms", maxWidth: "880px", margin: "10px auto" }}>

                            </p>
                        </div>
                    </div>
                    <div className="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
                        <div className="home-concept mt-50">
                            <div className="container-flied">

                                <div className="row text-center">
                                    <span className="sun"></span>
                                    <span className="cloud"></span>
                                    <div className="col-lg-2 ms-lg-auto">
                                        <div className="process-image">
                                            <img src="assets/img/GamesImg/ludo_images/home-concept-item-1.png" alt="" />
                                            <strong>Strategy</strong>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="process-image process-image-on-middle">
                                            <img src="assets/img/GamesImg/ludo_images/home-concept-item-2.png" alt="" />
                                            <strong>Planning</strong>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="process-image">
                                            <img src="assets/img/GamesImg/ludo_images/home-concept-item-3.png" alt="" />
                                            <strong>Build</strong>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 ms-lg-auto">
                                        <div className="project-image">
                                            <div id="fcSlideshow" className="fc-slideshow">
                                                <ul className="fc-slides hero-carousel verticalFlip">
                                                    <li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-1.jpg" alt="" /></a></li>
                                                    <li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-2.jpg" alt="" /></a></li>
                                                    <li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-3.jpg" alt="" /></a></li>
                                                    <li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-1.jpg" alt="" /></a></li>
                                                    <li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-2.jpg" alt="" /></a></li>
                                                </ul>
                                            </div>
                                            <strong className="our-work">Our Work</strong>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="content-3 " className="content-3 wide-60 content-section division service-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-xl-12">
                            <div className="section-title title-01 mb-70">
                                <h2 className="h2-md">Get Ready to Be Surprised</h2>
                                <p className="p-xl">To make it easy and convenient for you to play the game, we have
                                    added various features to the game.
                                    <p className="p-lg">Interactive UI and animation effects to Call Break game, game is normally played by 4 people using a standard international 52-card pack, Dealer deals out all the cards, one at a time, face down, so that each player has 13 cards. The players pick up their cards and look at them.</p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="top-row pb-50">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-5 col-lg-6">
                                <div className="img-block left-column wow fadeInRight">
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
                                        <img className="img-fluid" src="../assets/img/GamesImg/callbreak_images/img-01.png" alt="content-image" />
                                    </ScrollAnimation>
                                </div>
                            </div>

                            <div className="col-md-7 col-lg-6">
                                <div className="txt-block right-column wow fadeInLeft">
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInRight">
                                        <h2 className="h2-xs">Game Features </h2>
                                        <div className="txt-box">
                                            <div className="col-md-12">
                                                <div className="icon-box  wow fadeInUp">
                                                    <img src="../assets/img/GamesImg/features/DailyBonus.png" alt="" />
                                                    <p className="service-title"><strong>Daily Bonus</strong></p>
                                                    <p className="service-para">Get some more call break gold coins, and come daily to the app to get a daily bonus with luck-based wheel feature.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="icon-box wow fadeInUp">
                                                    <img src="../assets/img/GamesImg/features/Quests.png" alt="" />
                                                    <p className="service-title"><strong>Quests</strong></p>
                                                    <p className="service-para">Winning the gold coins in the call break can be difficult for the average player, but completing the weekly quests is an easy way to get gold rewards from callbreak card game. Weekly quests will remain available until the end of the seasons, so take your time!</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="icon-box wow fadeInUp">
                                                    <img src="../assets/img/GamesImg/features/Leaderboard.png" alt="" />
                                                    <p className="service-title"><strong>Leaderboard</strong></p>
                                                    <p className="service-para">It's essentially a visual representation of who's leading the most game-won in call break, Leaderboards will showcase the top players, teams, or individuals based on their scores, points, or achievements, creating an environment of healthy competition and motivation.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="icon-box wow fadeInUp">
                                                    <img src="../assets/img/GamesImg/features/TimerBonusProgram.png" alt="" />
                                                    <p className="service-title"><strong>Timer Bonus Program</strong></p>
                                                    <p className="service-para">The clock has a key that will unlock the free bonus gold coins on a certain time period. keep analyzing the clock time and make a smart move to get some free gold coins with the timer bonus program.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="icon-box wow fadeInUp">
                                                    <img src="../assets/img/GamesImg/features/ReferralProgram.png" alt="" />
                                                    <p className="service-title"><strong>Referral Program</strong></p>
                                                    <p className="service-para">Families, Friends, and in groups, share your referral code with your group via social media or from WhatsApp, Twitter, Facebook, Instagram, or any other channels to get some gold reward coins. Reward coins are only applicable when a referee uses your referral code to claim a bonus.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="icon-box wow fadeInUp">
                                                    <img src="../assets/img/GamesImg/features/AvatarStore.png" alt="" />
                                                    <p className="service-title"><strong>Avatar Store</strong></p>
                                                    <p className="service-para">Smiley, Angry, Lovely, Impressively. Use the smart profile from the avatar store to get setup your today's status via profile picture and use it for your profile picture, you have to pay a certain amount of Callbreak gold coins.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="icon-box wow fadeInUp">
                                                    <img src="../assets/img/GamesImg/features/CustomTable.png" alt="" />
                                                    <p className="service-title"><strong>Custom Table</strong></p>
                                                    <p className="service-para">1 Round, 2 Round or upto 5 Round, set your own custom table and play the callbreak gameplay.</p>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="icon-box  wow fadeInUp">
                                                    <img src="../assets/img/GamesImg/features/MultiLanguages.png" alt="" />
                                                    <p className="service-title"><strong>Multi-Languages</strong></p>
                                                    <p className="service-para">We can create an callbrek multiplayer game app/software/website
                                                        supporting different regional and native languages for the better gaming
                                                        experience.</p>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="icon-box  wow fadeInUp">
                                                    <img src="../assets/img/GamesImg/features/customize.png" alt="" />
                                                    <p className="service-title"><strong>Customize Call Break Solution </strong></p>
                                                    <p className="service-para">Have a particular feature in mind? We will incorporate it
                                                        into your Call Break game according to your exact requisites. </p>
                                                </div>
                                            </div>

                                        </div>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="techno-sec">
                <div className="over-techno">
                    <div className="heading">
                        <h2>Our Professionals are working on the following top-notch techs</h2>
                        <p>We build the nexus of talent and innovation with Optimum oengines</p>
                    </div>
                    <div className="row align">
                        <div className="col-md-2 box">
                            <div className="nodejs wow fadeInUp">
                                <ScrollAnimation animateIn="animate__animated animate__bounceInDown">
                                    <img src="../assets/img/GamesImg/Languages_images/nodejs-technology.png" alt="" />
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-2 box">
                            <div className="javascript  wow fadeInUp">
                                <ScrollAnimation animateIn="animate__animated animate__bounceInDown">
                                    <img src="../assets/img/GamesImg/Languages_images/reactjs-technology.png" alt="" />
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-2 box wow fadeInUp">
                            <div className="rabbit">
                                <ScrollAnimation animateIn="animate__animated animate__bounceInDown">
                                    <img src="../assets/img/GamesImg/Languages_images/mean-stack.png" alt="" />
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-2 box wow fadeInUp">
                            <div className="mongodb">
                                <ScrollAnimation animateIn="animate__animated animate__bounceInDown">
                                    <img src="../assets/img/GamesImg/Languages_images/mongodb-technology.png" alt="" />
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-2 box wow fadeInUp">
                            <div className="redis ">
                                <ScrollAnimation animateIn="animate__animated animate__bounceInDown">
                                    <img src="../assets/img/GamesImg/Languages_images/unity-technology.png" alt="" />
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-2 box  wow fadeInUp">
                            <div className="socket">
                                <ScrollAnimation animateIn="animate__animated animate__bounceInDown">
                                    <img src="../assets/img/GamesImg/Languages_images/angular-technology.png" alt="" />
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="content-2" className="content-2 pb-60 content-section division">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-7 col-lg-6">
                            <div className="txt-block right-column wow fadeInLeft">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInleft">
                                    <h2 className="h2-xs">Make it simpler with Quick Commands</h2>
                                    <ul className="simple-list">
                                        <li className="list-item">
                                            <p className="p-lg">Fringilla risus, luctus mauris orci auctor euismod iaculis
                                                luctus
                                                magna purus pretium ligula purus undo quaerat tempor sapien rutrum
                                                mauris quaerat ultrice
                                            </p>
                                        </li>
                                        <li className="list-item">
                                            <p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
                                        </li>
                                        <li className="list-item">
                                            <p className="p-lg">Nemo ipsam egestas volute turpis dolores undo ultrice
                                                aliquam
                                                quaerat at sodales sapien purus
                                            </p>
                                        </li>
                                    </ul>
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-6">
                            <div className="rel img-block left-column wow fadeInRight">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInRight">
                                    <img className="img-fluid" src="../assets/img/GamesImg/callbreak_images/img-07.png" alt="content-image" />
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="faqs-2" className="pb-60 faqs-section division">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                            <div className="section-title title-01 mb-80">
                                <h2 className="h2-md">Frequently Asked Question?</h2>

                            </div>
                        </div>
                    </div>
                    <div className="faqs-2-questions">
                        <div className="row row-cols-1 row-cols-lg-2">
                            <div className="col">
                                <div className="questions-holder pr-15">
                                    <div className="question wow fadeInUp">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h5 className="h5-md">How Much Time Does it Take to Develop a Call Break Game?
                                            </h5>
                                        </ScrollAnimation>
                                        <p className="p-lg">Call Break game development deadlines will be depend on the customization or on requirements and graphical elements.
                                        </p>
                                    </div>
                                    <div className="question wow fadeInUp">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h5 className="h5-md">Do you provide continued maintenance support for Call
                                                Break mobile game development?</h5>
                                        </ScrollAnimation>
                                        <p className="p-lg">Yes, We provide you with continuous maintenance support for
                                            Call Break game development. Even after the development, you can opt for
                                            the monthly retention plan.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="questions-holder pl-15">
                                    <div className="question wow fadeInUp">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h5 className="h5-md">Will You Provide Both the Call Break Game Website & Mobile
                                                App Development Services? </h5>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p className="p-lg">Yes, we offer a range of Call Break game development
                                                services including website development, mobile app development, and
                                                software development.
                                            </p>
                                        </ScrollAnimation>
                                    </div>
                                    <div className="question wow fadeInUp">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h5 className="h5-md">Do You Provide Customized Call Break Game Development
                                                Services? </h5>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p className="p-lg"> Of course, YES. We offer 100% customized gaming solutions
                                                that meet clients’ requirements and even exceed them. No matter what
                                                kind of gaming solutions you need, our team of talented professionals is
                                                capable of fulfilling your needs.
                                            </p>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center pt-25">
                        </div>
                    </div>
                </div>
            </section>

            <section id="cta-9" className="bg-01 wide-80 cta-section division">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col col-lg-8">
                            <div className="cta-9-txt text-center row justify-content-center">
                                <h2 className="h2-xs white-color">Download & Play</h2>
                                <p className="p-xl white-color mb-35">Use the Appropriate store to get an amazing call break card game</p>
                                <div className="d-flex justify-content-center gap-4">
                                    {
                                        gameDetails.android_link ?
                                            <a target="_blank" href={gameDetails.android_link} className="os-btn bg-white d-flex align-items-center">
                                                <div className="os-btn-ico">
                                                    <div className="ico-50">
                                                        <img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
                                                    </div>
                                                </div>
                                                <div className="os-btn-txt">
                                                    <h6 className="h6-lg">Download for Android</h6>
                                                </div>
                                            </a>
                                            :
                                            ''
                                    }

                                    {
                                        gameDetails.ios_link ? <a target="_blank" href={gameDetails.ios_link} className="os-btn mac-os-btn bg-white d-flex align-items-center">
                                            <div className="os-btn-ico">
                                                <div className="ico-50">
                                                    <img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
                                                </div>
                                            </div>

                                            <div className="os-btn-txt">
                                                <h6 className="h6-lg">Download for IOS</h6>
                                            </div>
                                        </a> : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <>
                <Footer />
            </>


        </div>

    );
}
