import React, {useState} from "react";
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from 'react-slick';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';


var mystyleone = {
	backgroundImage:
		"url(https://tiltlabs.io/wp-content/uploads/2023/01/Sandbox-05.png)",
	backgroundPosition: "center"	
}; 

export default function Sendbox() {
	const [sliderRef, setSliderRef] = useState(null);
	const [show, setShow] = useState(false);

	const sliderSettings = {
		
		arrows: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,		
		autoplay: true,
		responsive: [
			{
				breakpoint: 992,
				settings: {
				  arrows: false,
				  slidesToShow: 3,
				},
			},
			{
			  breakpoint: 768,
			  settings: {
				arrows: false,
				slidesToShow: 2,
			  },
			},
			{
			  breakpoint: 576,
			  settings: {
				arrows: false,
				slidesToShow: 1,
			  },
			},
		],
	  }

	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  	
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Sandbox'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};

	

	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }

	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />

			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
						
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>

			<section id="hero" className="sendbox-bg bg-scroll sendbox-section division">
				<div className="container">
					<div className="row card-hero pb-100">
						<div className="col-md-5 col-xl-6 col-sm-12 mt-80 sendbox-hero-center">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">SANDBOX Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color max-conent">Build your first experience in the Metaverse with the OENGINES Sandbox's game Development.</p>
								</ScrollAnimation>
							</div>
						
							<a href="tel:+02614054978" ><button className="theme-button mt-4">Call Now</button></a>
						</div>
						<div className="col-md-7 col-xl-6 col-sm-12">
							<div className="tb-vertical-middle">
								<div className="tb-vertical-middle-in">
									<div className="tb-hero-img tb-style1">
										<div className="tb-hero-img-box tb-bg"style={mystyleone}>
										</div>
										<div className="tb-hero-img-box-pattern">
											<div className="tb-pattern1"></div>
										</div>
										<div className="tb-hero-img-box-circle">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>		
				</div>			
			</section>
        
			<section className="sendbox-game pt-80 pb-60">
				<div className="container">
                    <div className="row"> 
                        <div className="action-heaing col-md-12">
                            <h2 className="text-center">SANDBOX Game</h2> 
                            <div className="elementor-divider sandbox-elementor">
                                <span className="elementor-divider-separator">
                                </span>
                            </div>
                            <p className="pt-10 mt-30 text text-center">A sandbox game is a video game with a gameplay element that provides players a great degree of creativity to interact with, usually without any predetermined goal, or alternatively with a goal that the players set for themselves.</p>
							<p className="pt-10 mt-30 text text-center">A sandbox is a style of game in which minimal character limitations are placed on the gamer, allowing the gamer to roam and change a virtual world at will. In contrast to a progression-style game, a sandbox game emphasizes roaming and allows a gamer to select tasks. Instead of featuring segmented areas or numbered levels, a sandbox game usually occurs in a “world” to which the gamer has full access from start to finish.</p>
                        </div>                      
                    </div>	
				</div>
			</section>

			<section id="choose_poker" className="why-poker pb-100">
				<div className="container">
					<div className="row">
						<div className="poker-choose-desc pt-60 col-md-6">
							<h2>Benefits of Playing SANDBOX Game</h2>                        
							<ul className="text mt-30">							
								<li>It fosters perseverance</li>
								<li>It improves creativity and complex thinking skills</li>
								<li>It rewards quick thinking</li>
								<li>Problem Solving</li>
								<li>Improves Coordination</li>
								<li>Social Skills</li>
							</ul>
						</div>
						<div className="choose-img col-md-6">
							<picture>
								<img decoding="async" title="poker game developer" src="../assets/img/GameDevelopment/Sandbox-03.png" alt="poker game developer" />
							</picture>
						</div>
					</div>					
				</div>
			</section>

			<section className="metaverse_game_services pt-80 pb-80">
				<div className="container">
					<h2 className="title">How does OENGINES help you gain this virtual edge?</h2>					
					<div className="row" id="cus_row">
						<div className="col-lg-4 col-xl-3 col-md-6 col-sm-6">
							<div className="services_box">
								<img src="https://www.gamesd.app/images/metaverse-game-development/icons.svg" className="icon-sprite" alt="Metaverse App Development" title="Metaverse App Development" style={{objectPosition: "-6px 0px"}}/>
								<div className="sub_title">Quality Served Economically</div>
								<p className="sub_contents">OENGINES has garnered huge respect and earned its name in the market. The popularity rides on the fact that we have been one of the pioneers of Metaverse technology, offering services for over a decade. Our high-quality service available at the most reasonable prices has gained great attention and appreciation.</p>
							</div>
						</div>
						<div className="col-lg-4 col-xl-3 col-md-6 col-sm-6">
							<div className="services_box">
								<img src="https://www.gamesd.app/images/metaverse-game-development/icons.svg" title="Metaverse 3D Space Development" className="icon-sprite" style={{objectPosition: "-103px 0px"}}/>
								<div className="sub_title">Always Ahead</div>
								<p className="sub_contents">The market, in general, and Metaverse, in particular, is about being proactive. The trends keep on changing at lightning speed. This is where OENGINES shines. Having a proactive approach by keeping a keen eye on the changing currents and employing the next-gent technologies keeps us ahead of the market and makes us the trend-setters.</p>
							</div>
						</div>
						<div className="col-lg-4 col-xl-3 col-md-6 col-sm-6">
							<div className="services_box"> 
								<img src="https://www.gamesd.app/images/metaverse-game-development/icons.svg" className="icon-sprite" alt="Metaverse 3D Avatar Development" title="Metaverse 3D Avatar Development" style={{objectPosition: "-200px 0px"}}/>
								<div className="sub_title">Virtuosity in the Virtual World</div>
								<p className="sub_contents">With over a decade of experience in virtual world development, OENGINES has a lot to offer, the range of resources and expertise we gather over these years contributes a great deal and gets reflected in our products and services. In short, we know exactly what you need and how to serve you better.</p>
							</div>
						</div>
						<div className="col-lg-4 col-xl-3 col-md-6 col-sm-6">
							<div className="services_box">
								<img src="https://www.gamesd.app/images/metaverse-game-development/icons.svg" className="icon-sprite" alt="Metaverse Digital Twin Development" title="Metaverse Digital Twin Development" style={{objectPosition: "-298px 0px"}}/>
								<div className="sub_title">Global Expertise</div>
								<p className="sub_contents">At OENGINES, we always strive to attain the best of talents and transcend boundaries to cherry pick them from across the globe. This enables us to bring the best of services and products that meet international standards and serve your business needs in the most fulfilling manner possible.</p>
							</div>
						</div>						
					</div>
				</div>
			</section>

			<section className="virtual-sec  pt-80 pb-80">
				<div className="elementor-background-overlay"></div>
				<div className="container">
					<div className="vartual-text text-center">
						<h2 className="text-white">Turn your ideas into a 'virtual' reality with OEngines</h2>
						<p className="text-white mt-50">We help you bring your idea and give them a spectacular virtual lease of life. And in the process and earn your way
						through it. Get the best shape, look and feel of your virtual real estate that awestruck the viewer.</p>
						<button className="theme-button mt-4" onClick={handleShow}><a>Contact Us Now</a></button>
					</div>
				</div>
			</section>

			<section className="we-do-sec pt-80 pb-80">
				<div className="container">
					<div className="row mt-50">
						<div className="col-md-6 col-xl-6 col-lg-6 p-4">
							<div className="we-do-title">
								<h2 className="text-white">What we do</h2>
								<p className="text-white pt-30">We extend our support to build, play, and monetize your digital gaming experiences in a sandbox platform utilizing
								   blockchain technology and NFTs to empower the players and creators. We can help you build and animate 3D objects,
								   including avatars, peoples, etc., and export them to your environment to add them as your game assets.</p>
								<button className="theme-button mt-4"><a href="#">Get in Touch</a></button>
							</div>
						</div>
						<div className="col-md-6 col-xl-6 col-lg-6">
							<div className="we-do-img">
								<img src="./assets/img/GameDevelopment/why-choose.png" alt="img not found" />
							</div>
						</div>
					</div>
				</div>
			</section>


			<section className="our-arcade-games send-slider pt-90">
				<div className="arcade-features action-games">
					<div className="arcade-features-title">
						<h2 className="text-center pt-30">Our SANDBOX Game Work</h2>
					</div>
					<div className="row mt-60">
						 <Slider ref={setSliderRef} {...sliderSettings}>
							<div className="">
								<div className="arcade-card">
                                    <img src="assets/img/GameDevelopment/our-professional-2d-img.png" alt="img not found" />
								</div>
							</div>
							<div className="">
								<div className="arcade-card">									
                                    <img src="assets/img/GameDevelopment/blue-peers.png" alt="img not found" />
								</div>
							</div>
							<div className="">
								<div className="arcade-card">
									<img src="assets/img/GameDevelopment/rumble.png" alt="img not found" />
                                </div>
							</div>
                            <div className="">
								<div className="arcade-card">									
                                    <img src="assets/img/GameDevelopment/adventure-hero-multi.png" alt="img not found" />
								</div>
							</div>
							<div className="">
								<div className="arcade-card">									
                                    <img src="assets/img/GameDevelopment/rarity-epic.png" alt="img not found" />
								</div>
							</div>
							<div className="">
								<div className="arcade-card">									
                                    <img src="assets/img/GameDevelopment/HexGL.png" alt="img not found" />
								</div>
							</div>
							</Slider>
					</div>
				</div>
			</section>            
			<Footer />
		</div>

	);
}
