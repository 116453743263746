import React from "react";
import { Helmet } from 'react-helmet';
import '../src/assets/css/app.min.css'
import '../src/assets/css/app.min.css';
import '../src/assets/css/style.css';
import '../src/assets/css/fontawesome.min.css';
import 'react-awesome-slider/dist/styles.css';
import Footer from "./Footer";
import axios from 'axios';
import Header from "./Header";

class Service extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
        }
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
    }
    componentDidMount() {
  
        axios.get("https://www.oengines.com/careers")
            .then(response => {
                console.log("Data is::::::::",response.data);               
                this.setState({Job: response.data});
                this.setState({Description: response.data[0].description});
                
            })
            .catch(function (error) {
                console.log(error);
            })

    }
    onClickShowdetails(job){       
        console.log("job is:::::::",job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';       
    }
    render() {
        const myStyleone={
            backgroundImage: 
            "url('../assets/img/bg/breadcumb-bg.jpg')",
            width: "100%",
            zIndex: 2,
          };
          const myStyletwo={
            backgroundImage: 
            "url('../assets/img/bg/cta_bg_2.jpg')",
            width: "100%"
          };
          const mystylethree={
            backgroundImage: 
            "url('../assets/img/bg/process_bg_1.png')",
            width: "100%"
          }

          
        var Information;
        if(this.state.Job.length){
        Information = this.state.Job.map((job,Index)=>       
            <>
                
                <li>
                    <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                </li>                                          
            </>
        )
        }else{
            Information = <div><h4 style={{color: "Red", fontSize: "20px"}}>No Record Found !</h4></div>;
        }
         
        
      return (  
        <>
            <Helmet>
                <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css"/>
                <script src="https://threejs.org/examples/js/libs/stats.min.js"></script>
                <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js" type="text/javascript"></script>
                <script src="../assets/js/script.js"></script>
                <script src="../assets/js/script.js" type="text/Javascript"></script>            
            </Helmet>
            {/* <div className="loader" ><span>Loading...</span></div> */}
            
                <div className="sidemenu-wrapper d-none d-lg-block">
                    <div className="sidemenu-content"><button className="closeButton sideMenuCls"><i className="far fa-times"></i></button>
                        <div className="widget woocommerce widget_shopping_cart">
                            <h3 className="widget_title">Shopping cart</h3>
                            <div className="widget_shopping_cart_content">
                                <ul className="woocommerce-mini-cart cart_list product_list_widget">
                                    <li className="woocommerce-mini-cart-item mini_cart_item"><a href="#"
                                            className="remove remove_from_cart_button"><i className="far fa-times"></i></a> <a href="#"><img
                                                src="assets/img/product/product_thumb_1_1.jpg" alt="Cart Image"/>Gaming Computer</a>
                                        <span className="quantity">1 × <span className="woocommerce-Price-amount amount"><span
                                                    className="woocommerce-Price-currencySymbol">$</span>940.00</span></span></li>
                                    <li className="woocommerce-mini-cart-item mini_cart_item"><a href="#"
                                            className="remove remove_from_cart_button"><i className="far fa-times"></i></a> <a href="#"><img
                                                src="assets/img/product/product_thumb_1_2.jpg" alt="Cart Image"/>Smartphone Vivo
                                            V9</a> <span className="quantity">1 × <span className="woocommerce-Price-amount amount"><span
                                                    className="woocommerce-Price-currencySymbol">$</span>899.00</span></span></li>
                                    <li className="woocommerce-mini-cart-item mini_cart_item"><a href="#"
                                            className="remove remove_from_cart_button"><i className="far fa-times"></i></a> <a href="#"><img
                                                src="assets/img/product/product_thumb_1_3.jpg" alt="Cart Image"/>SanDisk Flash
                                            Drive</a> <span className="quantity">1 × <span className="woocommerce-Price-amount amount"><span
                                                    className="woocommerce-Price-currencySymbol">$</span>756.00</span></span></li>
                                    <li className="woocommerce-mini-cart-item mini_cart_item"><a href="#"
                                            className="remove remove_from_cart_button"><i className="far fa-times"></i></a> <a href="#"><img
                                                src="assets/img/product/product_thumb_1_4.jpg" alt="Cart Image"/>Smart Power Bank</a>
                                        <span className="quantity">1 × <span className="woocommerce-Price-amount amount"><span
                                                    className="woocommerce-Price-currencySymbol">$</span>723.00</span></span></li>
                                    <li className="woocommerce-mini-cart-item mini_cart_item"><a href="#"
                                            className="remove remove_from_cart_button"><i className="far fa-times"></i></a> <a href="#"><img
                                                src="assets/img/product/product_thumb_1_5.jpg" alt="Cart Image"/>Apple Smartwatch</a>
                                        <span className="quantity">1 × <span className="woocommerce-Price-amount amount"><span
                                                    className="woocommerce-Price-currencySymbol">$</span>1080.00</span></span></li>
                                </ul>
                                <p className="woocommerce-mini-cart__total total"><strong>Subtotal:</strong> <span
                                        className="woocommerce-Price-amount amount"><span
                                            className="woocommerce-Price-currencySymbol">$</span>4398.00</span></p>
                                <p className="woocommerce-mini-cart__buttons buttons"><a href="cart.html" className="as-btn wc-forward">View
                                        cart</a> <a href="checkout.html" className="as-btn checkout wc-forward">Checkout</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
                <div className="breadcumb-wrapper" style={myStyleone}>
                    <div className="container">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">Services</h1>
                            <ul className="breadcumb-menu">
                                <li><a href="/">Home</a></li>
                                <li>Services</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <section className="space" id="service-sec">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-md-6 col-xl-4">
                                <div className="service-card">
                                    <div className="service-card_number">01</div>
                                    <div className="shape-icon"><img src="assets/img/icon/service_card_1.svg" alt="Icon"/> <span
                                            className="dots"></span></div>
                                    <h3 className="box-title"><a href="service-details.html">Web Development</a></h3>
                                    <p className="service-card_text">We provide customized Web Application Development services with the latest technology to suit your requirements that help increasing work efficiencies and reduces working costs.</p><a href="service-details.html"
                                        className="as-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                                    <div className="bg-shape"><img src="assets/img/bg/service_card_bg.png" alt="bg"/></div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4">
                                <div className="service-card">
                                    <div className="service-card_number">02</div>
                                    <div className="shape-icon"><img src="assets/img/icon/service_card_2.svg" alt="Icon"/> <span
                                            className="dots"></span></div>
                                    <h3 className="box-title"><a href="service-details.html">Mobile App Development</a></h3>
                                    <p className="service-card_text">ILooking for an mobile app developer. We have highly proficient developers who can convert your innovative ideas into real world Mobile Application.</p><a href="service-details.html"
                                        className="as-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                                    <div className="bg-shape"><img src="assets/img/bg/service_card_bg.png" alt="bg"/></div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4">
                                <div className="service-card">
                                    <div className="service-card_number">03</div>
                                    <div className="shape-icon"><img src="assets/img/icon/service_card_3.svg" alt="Icon"/> <span
                                            className="dots"></span></div>
                                    <h3 className="box-title"><a href="service-details.html">Game Development</a></h3>
                                    <p className="service-card_text">Deliver the ideal gaming experience that's both addictive and fun to play with our game development services.</p><a href="service-details.html"
                                        className="as-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                                    <div className="bg-shape"><img src="assets/img/bg/service_card_bg.png" alt="bg"/></div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4">
                                <div className="service-card">
                                    <div className="service-card_number">04</div>
                                    <div className="shape-icon"><img src="assets/img/icon/service_card_4.svg" alt="Icon"/> <span
                                            className="dots"></span></div>
                                    <h3 className="box-title"><a href="service-details.html">UI/UX Development</a></h3>
                                    <p className="service-card_text">Our designer's team has creative logo design ideas and corporate designs and custom logos that are original and help to build the winning brand.</p><a href="service-details.html"
                                        className="as-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                                    <div className="bg-shape"><img src="assets/img/bg/service_card_bg.png" alt="bg"/></div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4">
                                <div className="service-card">
                                    <div className="service-card_number">05</div>
                                    <div className="shape-icon"><img src="assets/img/icon/service_card_5.svg" alt="Icon"/> <span
                                            className="dots"></span></div>
                                    <h3 className="box-title"><a href="service-details.html">Software Service</a></h3>
                                    <p className="service-card_text">Our custom software development services helps your business to manage efficiently so you can focus on your core work.</p><a href="service-details.html"
                                        className="as-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                                    <div className="bg-shape"><img src="assets/img/bg/service_card_bg.png" alt="bg"/></div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4">
                                <div className="service-card">
                                    <div className="service-card_number">06</div>
                                    <div className="shape-icon"><img src="assets/img/icon/service_card_6.svg" alt="Icon"/> <span
                                            className="dots"></span></div>
                                    <h3 className="box-title"><a href="service-details.html">IT Management</a></h3>
                                    <p className="service-card_text">Get a team of dedicated experts and turn your business ideas into reality with Oengines.</p><a href="service-details.html"
                                        className="as-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                                    <div className="bg-shape"><img src="assets/img/bg/service_card_bg.png" alt="bg"/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="position-relative space">
                    <div className="as-bg-img" style={myStyletwo}>
                        <img src="assets/img/bg/bg_overlay_1.png" alt="overlay"/>
                    </div>
                    <div className="container z-index-common">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-7 col-md-9 text-center">
                                <div className="title-area mb-35"><span className="sub-title"><img
                                            src="assets/img/theme-img/title_shape_2.svg" alt="shape"/>CONTACT US</span>
                                    <h2 className="sec-title text-white">Need Any Kind Of IT Solution For <span
                                            className="text-theme fw-normal">Your Business?</span></h2>
                                </div><a href="#!" className="as-btn">Get In Touch</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-smoke" id="process-sec" style={mystylethree}>
                    <div className="space">
                        <div className="title-area text-center"><span className="sub-title"><img
                                    src="assets/img/theme-img/title_shape_2.svg" alt="shape"/>WORK PROCESS</span>
                            <h2 className="sec-title">How We <span className="text-theme">Work!</span></h2>
                        </div>
                        <div className="process-card-area">                          
                            <div className="row gy-40 justify-content-center">
                                <div className="col-sm-6 col-md-4  col-xl-3 col-xxl-2 process-card-wrap">
                                    <div className="process-card">
                                        <div className="process-card_number">01</div>
                                        <div className="process-card_icon"><img src="assets/img/icon/process_card_1.svg" alt="icon"/>
                                        </div>
                                        <h2 className="box-title">Initiation</h2>
                                        <p className="process-card_text">Understanding project goals, deadlines, priorities, and associated risks.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4  col-xl-3 col-xxl-2 process-card-wrap">
                                    <div className="process-card">
                                        <div className="process-card_number">02</div>
                                        <div className="process-card_icon"><img src="assets/img/icon/process_card_2.svg" alt="icon"/>
                                        </div>
                                        <h2 className="box-title">Planning</h2>
                                        <p className="process-card_text">Overview of tasks and timeline needed to execute the project.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4  col-xl-3 col-xxl-2 process-card-wrap">
                                    <div className="process-card">
                                        <div className="process-card_number">03</div>
                                        <div className="process-card_icon"><img src="assets/img/icon/process_card_3.svg" alt="icon"/>
                                        </div>
                                        <h2 className="box-title">Execution</h2>
                                        <p className="process-card_text">Execute the Project plan.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4  col-xl-3 col-xxl-2 process-card-wrap">
                                    <div className="process-card">
                                        <div className="process-card_number">04</div>
                                        <div className="process-card_icon"><img src="assets/img/icon/process_card_4.svg" alt="icon"/>
                                        </div>
                                        <h2 className="box-title">Monitoring & Performance</h2>
                                        <p className="process-card_text">Monitor the performance of the project.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4  col-xl-3 col-xxl-2 process-card-wrap">
                                    <div className="process-card">
                                        <div className="process-card_number">05</div>
                                        <div className="process-card_icon"><img src="assets/img/icon/process_card_4.svg" alt="icon"/>
                                        </div>
                                        <h2 className="box-title">Closure</h2>
                                        <p className="process-card_text">Analyze and summarize the results.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
         
                <Footer />
              
               
        </>
      )
    }
  }

export default Service;

