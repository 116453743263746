import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';


export default function Horror() {	
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  	
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Horror'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};
	

	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }


	return (
		<div>
			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />

			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>



			<section id="hero" className="horror-section hero-section division">
				<div className="container">
					<div className="row card-hero pb-100 text-center">
						<div className="col-md-12 col-lg-12 mt-80">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Horror Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt action-hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color max-conent m-auto mt-40">OEngines is a global player in the game entertainment industry with the mission to connect the world through games and vision to be the most innovative, scalable and valuable developer of the global competitive game entertainment industry.</p>
								</ScrollAnimation>
							</div>
							<a href="tel:+02614054978" ><button className="theme-button mt-50">Call Now</button></a>
						</div>
					</div>
				</div>
			</section>

			<section className="horror-game pt-80 pb-80">
				<div className="container">
					<div className="row horror-game-center">
						<div className="col-md-12 col-xl-3 col-sm-12 col-lg-3">
							<div className="horror-man-img">
								<img src="../assets/img/GameDevelopment/sec-3-img-1.png" alt="img not found" />
							</div>
						</div>
						<div className="action-heaings horro col-md-5 col-xl-5 col-sm-12 col-lg-5">
							<h2 className="text-start text-white">Horror Game</h2>
							<div className="elementor-divider horro-divider-one" style={{ left: "26%" }}>
								<span className="elementor-divider-separator">
								</span>
							</div>
							<p className="pt-10 text mt-30 text-start text-white">A horror game is a video game genre centered on horror fiction and typically designed to scare the player. The term may also be used to describe tabletop games with horror fiction elements.</p>
							<p className="text-white text mt-30">Unlike most other video game genres, which are classified by their gameplay, horror games are nearly always based on
								narrative or visual presentation, and use a variety of gameplay types</p>
						</div>
						<div className="col-md-4 col-xl-4 col-sm-12 col-lg-4">
							<div className="horror-type">
								<ul className="text-white">
									<h3 className="text-white mb-40">Types of Horror Game</h3>
									<div className="elementor-divider horro-divider-two" style={{ left: "68%" }}>
										<span className="elementor-divider-separator">
										</span>
									</div>
									<li>Survival Horror Games</li>
									<li>Action Horror Games</li>
									<li>Stealth Horror Games</li>
									<li>Psychological Horror Games</li>
									<li>Reaction/Jump Scare Horror Games</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="ptb150 what_we_do_best pt-80 pb-80" id="what_we_do_best">
				<div className="container">
					<div className="row">
						<div className="title-bl text-center wow fadeIn" data-wow-duration="2s">
							<h2 className="title color-white">
								Horror Game Development Services
							</h2>
							<p className="mt-35 text-white">
								Offer a sense of value to ghost seekers on a digital gaming platform strategized and crafted from the creative minds of
								our adroit ghost game developers. Engage in business with us and receive an impactful game project based on an eerie storyline.
							</p>
						</div>

					</div>
					<div className="game-el-4 paroller2" style={{ transform: "translateX(-31px)", transition: "transform 0s linear 0s" }}>
						<img src="../assets/img/GameDevelopment/game-el-4.png" alt="image" />
					</div>
					<div className="row mt-60">
						<div className="left-column-1 column col-lg-6  col-md-12 col-sm-12 col-xs-12">
							<div className="table">
								<div className="table-row">
									<div className="counter-round table-cell valign-top">
										<div className="icon one">
											<i className="fad fa-edit"></i>
										</div>
									</div>
									<div className="counter-content table-cell valign-top">
										<h4 className="counter-title text-white">Swift Development</h4>
										<p className="counter-text mt25 text-white">
											Experience a high-quality full-fledged game development process regardless of project size, complexity, and features that demand sheer focus and in-depth research.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="right-column-2 column col-lg-6  col-md-12 col-sm-12 col-xs-12">
							<div className="table">
								<div className="table-row">
									<div className="counter-content table-cell valign-top text-right">
										<h4 className="counter-title text-white">High-impact Themes</h4>
										<p className="counter-text mt25 text-white">
											Craft a creepy and mysterious environment with carefully selected dark themes pretty enough to trigger the nervous system and increase the players' heart rate in seconds.
										</p>
									</div>
									<div className="counter-round table-cell valign-top">
										<div className="icon two">
											<i className="fad fa-cube"></i>
										</div>

									</div>
								</div>
							</div>
						</div>
						<div className="left-column-3 column col-lg-6  col-md-12 col-sm-12 col-xs-12">
							<div className="table">
								<div className="table-row">
									<div className="counter-round table-cell valign-top">
										<div className="icon three">
											<i className="fad fa-dice-d20"></i>
										</div>

									</div>
									<div className="counter-content table-cell valign-top">
										<h4 className="counter-title">High Visual Effects</h4>
										<p className="counter-text mt25 text-white">
											Obtain a horror-based game comprised of 3D realistic visuals and quality animation based on an unsettling and spine-tingling storyline that involves players in unrealistic gameplay stories.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="right-column-4 column col-lg-6  col-md-12 col-sm-12 col-xs-12">
							<div className="table">
								<div className="table-row">
									<div className="counter-content table-cell valign-top text-right">
										<h4 className="counter-title">Player-Centered Approach</h4>
										<p className="counter-text mt25 text-white">
											Offer full control to the player base over dire gameplay with popular engines that support 2D/3D capabilities through high-quality code by top vetted designers and skilled developers.
										</p>
									</div>
									<div className="counter-round table-cell valign-top">
										<div className="icon four">
											<i className="fad fa-running"></i>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="game-el-1 paroller" style={{ transform: "translateY(441px)", transition: "transform 0s linear 0s", willChange: "transform" }}>
					<img src="./assets/img/GameDevelopment/game-el-1.png" alt="image" />
				</div>
			</section>

			<section className="custom-horror pt-100 pb-100">
				<div className="container text-center">
					<div className="hire-team-title">
						<h2 className="text-white">Looking For Custom Horror Game Solutions?</h2>
						<h2 className="text-white">Hire Our Augmented Reality Game Developers!</h2>
					</div>
					<button className="theme-button mt-35" onClick={handleShow}><a >Contact Us</a></button>
				</div>
			</section>

			<section className="game-expertise ar-expertise-icons">
				<div className="container custom-container">
					<div className="row">
						<div className="col-sm-6 col-md-6">
							<h2 className="f-48 mb-30 text-white mt-35"><span className="ez-toc-section" id="Ready_To_Launch_AR_Games_Clone" ez-toc-data-id="#Ready_To_Launch_AR_Games_Clone"></span>Why Choose OEngines for <b>Horror Game Development</b> <span className="ez-toc-section-end"></span></h2>
						</div>
						<div className="col-sm-6 col-md-6">
							<p className="mb-0 text-white">We are known for providing spotless Game development services focused on making the best Game Application Development in India. Our success rate has always surpassed the client’s expectations.</p>
							<p className="mb-0 text-white">Our pool of developers has expertise in various platforms. Be it Android App Development of any kind like Game Apps,Educational Apps, and much more.</p>
						</div>
					</div>
					<ul className="game-expertise-list">
						<li><i className="Ar-icons icon1"></i><span className="name">On-time Project Delivery</span></li>
						<li><i className="Ar-icons icon2"></i><span className="name">Top-notch Technology Stack Implementation</span></li>
						<li><i className="Ar-icons icon3"></i><span className="name">Flexible and Customized Applications</span></li>
						<li><i className="Ar-icons icon4"></i><span className="name">Quick Response with Our Technical Support</span></li>
						<li><i className="Ar-icons icon5"></i><span className="name">High-end Solution for Android OS</span></li>
						<li><i className="Ar-icons icon6"></i><span className="name">Cost-effective and Time-saving Mechanism</span></li>						
					</ul>
				</div>
			</section>
			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}
		
			<Footer />
		</div>

	);
}
