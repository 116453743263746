import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import '../../src/assets/css/app.min.css'
import '../../src/assets/css/app.min.css';
import '../../src/assets/css/style.css';
import '../../src/assets/css/fontawesome.min.css';
import 'react-awesome-slider/dist/styles.css';
import 'react-circular-progressbar/dist/styles.css';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Swal from 'sweetalert2'
import DOMPurify from 'isomorphic-dompurify';
import Header from "../Header";
class career extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            job: {} ,
            uploadedFiles: [],   
        }
    }    
    componentDidMount() {
        const Jobdata = JSON.parse(localStorage.getItem('Job'));
        this.setState({position: Jobdata.position});
        this.setState({job: Jobdata});
    }
    onDrop = (acceptedFiles) => {
        var formData = new FormData();    
        var file = {}            ;
        file.resume = acceptedFiles[0];
        
        formData.append('job_type', this.state.job.job)
        formData.append('resume', acceptedFiles[0]);
        
        if (file.resume.type === 'application/pdf' && file.resume.size <= 5 * 1024 * 1024) {           
            axios.post('https://www.oengines.com/resumeUpload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                if(!response.data.error){
                    Swal.fire(
                        'Thank you!',
                        'We have been received your resume, We will contact you soon.',
                        'success'

                    ).then(() => {
                       // window.location.reload();
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Please upload a valid PDF file (max 5MB)!'
                    }).then(() => {
                        //window.location.reload();
                    })
                }
            })
            .catch((error) => {
                
                //window.location.reload();
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please upload a valid PDF file (max 5MB)!'
            }).then(() => {
                //window.location.reload();
            })
        }
    };
    
    render() {

        const myStyleone = {
            background: "#1f1f22",
            width: "100%",
            zIndex: 2,
        };
        var Information;
        var Description;
        if(this.state.job !== ''){
            Information =  <div className="project-info-list">
            <div className="contact-feature">
                <div className="icon-btn"><i className="fa-solid fa-user"></i></div>
                <div className="media-body">
                    <p className="contact-feature_label">No Of Opening</p>
                    <a href="#" className="contact-feature_link">{this.state.job.position !== '' ? this.state.job.position : 'No Record Found'}</a>
                </div>
            </div>
            <div className="contact-feature">
                <div className="icon-btn"><i className="fa-solid fa-folder-open"></i></div>
                <div className="media-body">
                    <p className="contact-feature_label">Designation:</p>
                    <a href="#" className="contact-feature_link">{this.state.job.job !== '' ? this.state.job.job : 'No Record Found'}</a>
                </div>
            </div>
            <div className="contact-feature">
                <div className="icon-btn"><i className="fa-solid fa-location-dot"></i></div>
                <div className="media-body">
                    <p className="contact-feature_label">Location:</p>
                    <a href="#" className="contact-feature_link">{this.state.job.job_location !== '' ? this.state.job.job_location : 'No Record Found'}</a>
                </div>
            </div> 
        </div> 
        }else{
            Information = <div><h4 style={{color: "Red", fontSize: "20px"}}>No Record Found !</h4></div>;
        }
        if(this.state.job.description !== ''){
            Description =   <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.job.description) }}></div> 
        }else{
            Description = <div><h4 style={{color: "Red", fontSize: "20px"}}>No Record Found !</h4></div>;
        }
        return (
            <>
                <Helmet>
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" type="text/javascript" />
                    <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js" type="text/javascript"></script>
                    <script src="../assets/js/script.js" type="Javascript"></script>

                </Helmet>
                {/* <div className="loader" ><span>Loading...</span></div> */}
                <Header />
                
                <div className="breadcumb-wrapper" style={myStyleone}>
                    <div className="container">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">Career Details</h1>
                            <ul className="breadcumb-menu">
                                <li><a href="/career">Career</a></li>
                                <li>Career Details</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <section className="space-top space-extra-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-8 col-lg-8">
                                <div className="page-single">                                    
                                    <div className="page-content">
                                        {Description}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-lg-4">
                                <aside className="sidebar-area">
                                    <div className="widget widget_info">
                                        <h3 className="widget_title">Information</h3>
                                        {Information}
                                    </div>                                  
                                    <div className="widget widget_download text-md-center text-sm-center">
                                        <h4 className="widget_title">Upload your Resume</h4>
                                    <Dropzone onDrop={this.onDrop} className="download-widget-wrap">
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone" {...getRootProps()} >
                                            <input {...getInputProps()} />
                                            <a href="#!" className="as-btn style3">
                                                <i className="fa-light fa-file-pdf me-2"></i>Upload File (Doc / PDF Acceptable)</a>
                                            </div>
                                        )}
                                    </Dropzone>
                                    </div>                                   
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <footer className="footer-wrapper footer-layout1">
                    <div className="pb" id="pb"></div>
                    <div className="pc">
                        <div className="ol">
                            <a href="#" id="btn-back-to-top">
                                <i className="fa-solid fa-arrow-up">
                                </i>
                            </a>
                        </div>
                        <div className="bg" id="bg"></div>
                    </div>
                    <div className="copyright-wrap bg-title">
                        <div className="container abc">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6">
                                        <p className="copyright-text text-white">Copyright <i className="fal fa-copyright"></i> {sessionStorage.getItem("currentYear")} <a
                                        href="#"> OENGINES GAMES LLP</a></p>
                                </div>
                                <div className="col-lg-6 text-end d-none d-lg-block">
                                    <div className="footer-links">
                                        <ul className="d-flex" style={{ float: "right" }}>
                                            <li><a href="/terms">Terms & Condition</a></li>
                                            <li><a href="/career">Careers</a></li>
                                            <li><a href="/home/privacy">Privacy Policy</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-left"><img src="assets/img/shape/footer_shape_2.svg" alt="shape" /></div>
                </footer>


            </>
        )
    }
}

export default career;

