import React from "react";
import { Helmet } from 'react-helmet';
import '../../src/assets/css/app.min.css';
import '../../src/assets/css/dark.css'
import '../../src/assets/css/responsive.css';
import '../../src/assets/css/app.min.css';
import '../../src/assets/css/style.css';
import '../../src/assets/css/fontawesome.min.css';
import OwlCarousel from 'react-owl-carousel';
import 'react-awesome-slider/dist/styles.css';
import Swal from "sweetalert2";
import Footer from "../Footer";
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Header from "../Header";


class WebDevelopment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Gamedata: [],
            Description: '',
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this);
    }
    componentDidMount() {

        axios.get("https://www.oengines.com/careers")
            .then(response => {                
                this.setState({ Job: response.data });
                this.setState({ Description: response.data[0].description });
            })
            .catch(function (error) {
                console.log(error);
            })       

        // axios.get("https://localhost:3001/games?filter[where][icon][neq]=&filter[order]=priority%20DESC&filter[offset]=0&filter[limit]=5&filter[skip]=0&filter[where][organization]=oe&filter[where][is_deleted]=false&filter[where][is_comming_soon]=false&filter[fields][android_link]=true&filter[fields][ios_link]=true&filter[fields][canvas_link]=true&filter[fields][facebook_community]=true&filter[fields][icon]=true&filter[fields][banner]=true&filter[fields][feature_graphics]=true&filter[fields][screenshots]=true&filter[fields][priority]=true&filter[fields][short_description]=true&&filter[fields][game_name]=true")
        //     .then(response => {
        //         console.log("Data is more games::::::::", response.data);
        //         this.setState({ Gamedata: response.data });
        //         this.setState({ Description: response.data[0].description });

        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     })

        axios.get('https://localhost:3001/games?filter[limit]=55&filter[where][is_deleted]=false&filter[where][is_active]=true').then((response) => {
            console.log("oengines Game Data Data is more games::::::::", response.data);   
            this.setState({ Gamedata: response.data });
            this.setState({ Description: response.data[0].description });                     
        })

    }
    onClickShowdetails(job) {
        console.log("job is:::::::", job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })        
    }
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    maxlengthnumber(evt) {
        if (evt.target.value.length === 11) {
            return false;
        }
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};
        console.log("Enter in::::::::::::");
        if (this.state.name === '' || this.state.name === null) {

            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.message === '' || this.state.message === null) {
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else if (this.state.toDate === '' || this.state.toDate === null) {
            formErrors["toDateError"] = "Date is required.";
            this.refs.toDatefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else {

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: 'GameDevelopment' + '-' + this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,

            };
            console.log("formObject::::::::::;", formObject);
            axios.post('https://www.oengines.com/contactus', formObject)
            .then((res) => {
                console.log("Response is:::::::::",res);
                if(res.data !== 'undefined'){
                    Swal.fire({
                        title: "Thank you!",
                        text: "Your request has been assigned successfully.",
                        icon: "success",
                    }).then(function () {
                        window.location.reload();
                    });
                }else{
                    Swal.fire({
                        title: "Oops...!",
                        text: "Oh! Something went wrong, please try again!",
                        icon: "error",
                    }).then(function () {
                        window.location.reload();
                    });
                }

            });
        }


        this.setState({
            name: '',
            email: '',
            subject: '',
            number: '',
            message: ''

        })
    }
    render() {
        const { nameError, emailError, subjectError, numberError, toDateError, messageError } = this.state.formErrors;
        var Our_Games;

        {
            this.state.Gamedata.length && (
                Our_Games =
                <section className="team-sec space new-space-bg">
                    <div className="container z-index-common">
                        <div className="title-area text-center">
                            <h2 className="sec-title">Checkout Some Games on Store.</h2>
                        </div>
                        <OwlCarousel className="owl-theme" loop margin={10} nav>
                            {this.state.Gamedata.map((gamedata, index) => {
                                return (
                                    <>
                                        <div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
                                            <div className="as-team team-card">
                                                <div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
                                                <div className="team-content new-con-box row d-flex">
                                                    <div className="box-particle" id="team-p1"></div>
                                                    <div className="team-social">
                                                        <a target="_blank" href="https://facebook.com/">
                                                            <i className="fab fa-facebook-f"></i>
                                                        </a>
                                                        <a target="_blank" href="https://twitter.com/">
                                                            <i className="fab fa-twitter"></i>
                                                        </a>
                                                        <a target="_blank" href="https://instagram.com/">
                                                            <i className="fab fa-instagram"></i>
                                                        </a>
                                                        <a target="_blank" href="https://linkedin.com/">
                                                            <i className="fab fa-linkedin-in"></i>
                                                        </a>
                                                    </div>

                                                    <h3 className="box-title col-auto"><a href={`${gamedata.android_link}`}>{gamedata.game_name}</a></h3>

                                                    <div className="applink d-flex ml-2 justify-content-between m-auto row">
                                                        {gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`}><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
                                                        }
                                                        {gamedata.ios_link === '' ? '' :
                                                            <button className="border-none"><a href={`${gamedata.ios_link}`}><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </OwlCarousel>
                    </div>
                    <div className="shape-mockup" data-bottom="0" data-left="0">
                        <div className="particle-2" id="particle-2"></div>
                    </div>
                </section>
            )
        }

        var Informations;
        if (this.state.Job.length) {
            Informations = this.state.Job.map((job, Index) =>
                <>
                    <li>
                        <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>
                </>
            )
        } else {
            Informations = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }

        var explore;
        if (this.state.Gamedata.length) {
            explore = <a className="as-btn btn-abs" href="#Our_Games">Explore</a>;
        }
        else {
            explore = '';
        }


        return (
            <>
                <Helmet>                    
                    <link href="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/css/coreui.min.css" rel="stylesheet" integrity="sha384-UkVD+zxJKGsZP3s/JuRzapi4dQrDDuEf/kHphzg8P3v8wuQ6m9RLjTkPGeFcglQU" crossorigin="anonymous" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                    <script src="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/js/coreui.bundle.min.js" integrity="sha384-n0qOYeB4ohUPebL1M9qb/hfYkTp4lvnZM6U6phkRofqsMzK29IdkBJPegsyfj/r4" crossorigin="anonymous"></script>                    
                    <script src="path/to/jquery.nice-select.js"></script>
                    <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js" type="text/javascript"></script>                    
                    <script src="../assets/js/script.js" type="text/Javascript"></script>                   
                </Helmet>

                {/* <div className="loader" ><span>Loading...</span></div> */}
                <div className="modal fade mopen" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title m-auto" id="exampleModalLabel">QUOTE</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "55px", position: "absolute", right: "9px", bottom: "-12px" }}>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade modelopencontect fade-scale" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
                                <button type="button" className="close cls-animate" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "55px", position: "absolute", right: "9px", bottom: "-12px" }}>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.onSubmit} method="post" className="contact-form ajax-contact">
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                            <i className="fal fa-user"></i>
                                            {nameError &&
                                                <div className="invalid-feedback" >{nameError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} autoComplete="Off" />
                                            <i className="fal fa-envelope"></i>
                                            {emailError &&
                                                <div className="invalid-feedback">{emailError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <select name="subject" id="subject" ref="subjectfield" className={subjectError ? "form-control is-invalid" : "form-control"} onChange={this.handleChange}>
                                                <option disabled="disabled">Select Subject
                                                </option>
                                                <option value={"Fantasy Sports Development"}>Fantasy Sports Development</option>
                                                <option value={"Online Casino Development"}>Online Casino Development</option>
                                                <option value={"Multiplayer Game Development"}>Multiplayer Game Development</option>
                                                <option value={"Offline Game Development"}>Offline Game Development</option>
                                                <option value={"2D/3D Game Development"}>Multiplayer Game Development</option>
                                                <option value={"VR Development"}>Offline Game Development</option>
                                            </select>
                                            {subjectError &&
                                                <div className="invalid-feedback">{subjectError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="tel" className={numberError ? "form-control is-invalid" : "form-control"} name="number" ref="numberfield" id="number" placeholder="Phone Number" onChange={this.handleChange} autoComplete="Off" />
                                            <i className="fal fa-phone"></i>
                                            {numberError &&
                                                <div className="invalid-feedback">{numberError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-12">
                                            <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                                className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange} autoComplete="Off">
                                            </textarea>
                                            <i className="fal fa-comment"></i>
                                            {messageError &&
                                                <div className="invalid-feedback">{messageError}</div>
                                            }
                                        </div>
                                        <div className="form-btn text-xl-start text-center col-12">
                                            <button type="submit" className="as-btn style3">Send Contect Details
                                                <i className="fa-regular fa-arrow-right ms-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3"></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Header />
                <div className="banner-area banner-12 bg_image--23 bg_image with-down-shadow">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="banner-xii-wrapper">
                                    <div className="banner-content-wrapper">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p className="pre-title">Turning Ideas Into Interactive Digital Reality</p>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h1 className="title">Develop Awesome games with Us </h1>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p className="post-title">Are you tired of searching for the perfect solution for your game development needs? <br/>Look no further! At our company, we specialize in providing customized solutions tailored specifically for your 2D / 3D game. Whether you're looking to enhance the graphics, improve the gameplay mechanics, or optimize the overall performance, we've got you covered.</p>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            {explore}
                                        </ScrollAnimation>
                                        <div className="wallet-image-wrapper row">
                                            <a href="/Arcarde" className="avatar col-auto" data-tooltip="ARCADE"><img src="assets/img/icon/wallet/ARCADE.png" alt="wallet_image" /></a>
                                            <a href="/Action" className="avatar col-auto" data-tooltip="ACTION"><img src="assets/img/icon/wallet/ACTION.png" alt="wallet_image" /></a>
                                            <a href="/Adventure" className="avatar col-auto" data-tooltip="ADVENTURE"><img src="assets/img/icon/wallet/ADVENTURE.png" alt="wallet_image" /></a>
                                            <a href="/Board" className="avatar col-auto" data-tooltip="BOARD"><img src="assets/img/icon/wallet/BOARD.png" alt="wallet_image" /></a>
                                            <a href="/Card" className="avatar col-auto" data-tooltip="CARD"><img src="assets/img/icon/wallet/CARD.png" alt="wallet_image" /></a>
                                            <a href="/Casino" className="avatar col-auto" data-tooltip="CASINO"><img src="assets/img/icon/wallet/CASINO.png" alt="wallet_image" /></a>
                                            <a href="/Casual" className="avatar col-auto" data-tooltip="CASUAL"><img src="assets/img/icon/wallet/CASUAL.png" alt="wallet_image" /></a>
                                            <a href="/Educational" className="avatar col-auto" data-tooltip="EDUCATIONAL"><img src="assets/img/icon/wallet/EDUCATIONAL.png" alt="wallet_image" /></a>
                                            <a href="/Horror" className="avatar col-auto" data-tooltip="HORROR"><img src="assets/img/icon/wallet/HORROR.png" alt="wallet_image" /></a>
                                            <a href="/Music" className="avatar col-auto" data-tooltip="MUSIC"><img src="assets/img/icon/wallet/MUSIC.png" alt="wallet_image" /></a>
                                            <a href="/Puzzle" className="avatar col-auto" data-tooltip="PUZZLE"><img src="assets/img/icon/wallet/PUZZLE.png" alt="wallet_image" /></a>
                                            <a href="/Quiz" className="avatar col-auto" data-tooltip="QUIZ"><img src="assets/img/icon/wallet/QUIZ.png" alt="wallet_image" /></a>
                                            <a href="/Racing" className="avatar col-auto" data-tooltip="RACING"><img src="assets/img/icon/wallet/RACING.png" alt="wallet_image" /></a>
                                            <a href="/Roguelike" className="avatar col-auto" data-tooltip="ROGUELIKE"><img src="assets/img/icon/wallet/ROGUCLIKC.png" alt="wallet_image" /></a>
                                            <a href="/Rpg" className="avatar col-auto" data-tooltip="RPG"><img src="assets/img/icon/wallet/RPG.png" alt="wallet_image" /></a>                                            
                                            <a href="/Simulator" className="avatar col-auto" data-tooltip="SIMULATOR"><img src="assets/img/icon/wallet/SIMULATOR.png" alt="wallet_image" /></a>
                                            <a href="/Sport" className="avatar col-auto" data-tooltip="SPORT"><img src="assets/img/icon/wallet/SPORT.png" alt="wallet_image" /></a>
                                            <a href="/Strategy" className="avatar col-auto" data-tooltip="STRATEGY"><img src="assets/img/icon/wallet/STRATEGY.png" alt="wallet_image" /></a>
                                            <a href="/Word" className="avatar col-auto" data-tooltip="WORD"><img src="assets/img/icon/wallet/WORD.png" alt="wallet_image" /></a>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="why-choose-area pt-100 pb-70">
                    <div className="container">
                        <div className="section-title-two">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className="title">
                                        <span>Our Creative Work</span>
                                        <h2>Our Mobile Game Developers Expertise Area</h2>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="text">
                                        <p>As a leading game development company in India, it is passionate to work and deliver the best gaming experience to our clients as well as users with latest technologies</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/GameDevelopment/multiplayer_game.jpg" alt="image" />

                                    <div className="title">
                                        <h3>Multiplayer Games</h3>
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">
                                            <h3>
                                                <a>Multiplayer Games</a>
                                            </h3>
                                            <p>We provide multiplayer support with our excellent admin features.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/GameDevelopment/2d.jpg" alt="image" />

                                    <div className="title">
                                        <h3>2D Games</h3>
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">
                                            <h3>
                                                <a>2D Games</a>
                                            </h3>
                                            <p>We are converting your requirements and specifications to smartphones, our leading IT experts have a good command of 2D game development.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/GameDevelopment/3d.jpg" alt="image" />

                                    <div className="title">
                                        <h3>3D Games</h3>
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">
                                            <h3>
                                                <a>3D Games</a>
                                            </h3>
                                            <p>Our experienced game development team has been moving on daily innovation for the 3D environment</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/GameDevelopment/onlinr-casino.jpg" alt="image" />

                                    <div className="title">
                                        <h3>Online Casino</h3>
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">
                                            <h3>
                                                <a>Online Casino</a>
                                            </h3>
                                            <p>We have expertise in real time Casino Game Development, with its Analytics, Notifications, Maintenance, Supports, Tracking Reports, we are providing the wallet features for the admin user.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/GameDevelopment/ar.jpg" alt="image" />
                                    <div className="title">
                                        <h3>AR Development</h3>
                                    </div>
                                    <div className="content">
                                        <div className="inner-content">
                                            <h3>
                                                <a>AR Development</a>
                                            </h3>
                                            <p>App ideas are no longer constrained to a smartphone screen. AR and VR technology allows you to fully immerse your users in interactive experiences like never before.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-choose-item">
                                    <img src="assets/img/GameDevelopment/vr.jpg" alt="image" />

                                    <div className="title">
                                        <h3>VR Development</h3>
                                    </div>

                                    <div className="content">
                                        <div className="inner-content">
                                            <h3>
                                                <a>VR Development</a>
                                            </h3>
                                            <p>As a leading mobile game development company, we can help you fully harness the power of the medium.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="software-area pt-100 pb-70">
                    <div className="container">
                        <div className="section-title">
                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                <span>Why Choose Us?</span>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                <h2 className="text-white">Here are some reasons</h2>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                <p>Why choose Oengines as your Technology Partner</p>
                            </ScrollAnimation>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="software-item">
                                    <div className="image">
                                        <a>
                                            <img src="assets/img/software/welfare.png" alt="image" />
                                        </a>
                                    </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h3>
                                            <a>
                                                Quality Assurance
                                            </a>
                                        </h3>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p>With our team of experienced professionals, we guarantee that your business will deliver exceptional quality to your customers, every single time. From product testing to process improvement, we have you covered.</p>
                                        <p>By investing in Quality Assurance, you are not only protecting your brand reputation but also increasing customer satisfaction and loyalty. Let us help you build trust and confidence in your offerings.</p>
                                    </ScrollAnimation>                                    
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="software-item">
                                    <div className="image">
                                        <a>
                                            <img src="assets/img/software/skills.png" alt="image" />
                                        </a>
                                    </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h3>
                                            <a>
                                            We're Tech Experts with Experience: Our Skills Speak for Themselves
                                            </a>
                                        </h3>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p>We have the skills to tackle any tech challenge that comes our way. But don't just take our word for it. Our track record speaks for itself. With a proven history of successful projects and satisfied clients, we have built a reputation as reliable and trusted tech experts in the industry. So if you're in need of tech solutions that deliver results, look no further than .</p>
                                    </ScrollAnimation>                                    
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="software-item">
                                    <div className="image">
                                        <a>
                                            <img src="assets/img/software/monster.png" alt="image" />
                                        </a>
                                    </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h3>
                                            <a>
                                                Customized Solutions
                                            </a>
                                        </h3>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p>Being the best in business, with perfection and skilled software engineers, Oengines fulfills its utmost responsibility of providing the best-customized solution for every business.</p>
                                    </ScrollAnimation>                                    
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="software-item">
                                    <div className="image">
                                        <a>
                                            <img src="assets/img/software/compliance.png" alt="image" />
                                        </a>
                                    </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h3>
                                            <a>
                                                Creative Solutions
                                            </a>
                                        </h3>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p>We provide a creative outlook to your game that will feel not only intuitive and enjoyable while playing but also look good while you use your brain to win the game.</p>
                                    </ScrollAnimation>                                    
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="software-item">
                                    <div className="image">
                                        <a>
                                            <img src="assets/img/software/phone.png" alt="image" />
                                        </a>
                                    </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h3>
                                            <a>
                                                Top-Notch Technology
                                            </a>
                                        </h3>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p>The company has experienced and skilled software engineers, as well as a wide range of Top-Notch technologies that can help your business shine.</p>
                                    </ScrollAnimation>
                                </div>
                            </div>

                            <div id="Our_Games"></div>
                        </div>
                    </div>
                </section>
                {Our_Games}               
                <div className="space-top space-bottom why-choose-area" id="contact-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 pe-xxl-5 mb-40 mb-xl-0">
                                <div className="title-area mb-35 text-xl-start text-center text-white">
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <span className="sub-title"><img
                                            src="assets/img/theme-img/title_shape_1.svg" alt="shape" />Get in Touch  </span>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h2 className="sec-title">Have a game in mind?</h2>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h2 className="sec-title pt-10">Build your dream Game today.</h2>
                                    </ScrollAnimation>
                                </div>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <p className="mt-n2 mb-30 text-xl-start text-center text-white">We are here to develop exclusive features that help you to enhance your business or make happiness in your life.</p>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <p className="mt-n2 mb-30 text-xl-start text-center text-white">Unlock Your Users’ Imagination with Immersive Gaming Experiences.</p>
                                </ScrollAnimation>
                                <div className="contact-feature-wrap">
                                    <div className="contact-feature" style={{ backgroundColor: "#1F1F22" }}>
                                        <div className="icon-btn" style={{ backgroundColor: "transparent" }}><i className="fa-solid fa-phone"></i></div>
                                        <div className="media-body">
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                <p className="contact-feature_label text-white">Call Us On:</p><a href="tel:+19088000393"
                                                    className="contact-feature_link text-white">(0261) - 4054978</a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                    <div className="contact-feature" style={{ backgroundColor: "#1F1F22" }}>
                                        <div className="icon-btn" style={{ backgroundColor: "transparent" }}><i className="fa-solid fa-envelope"></i></div>
                                        <div className="media-body">
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                <p className="contact-feature_label text-white">Quick Mail Us:</p><a href="mailto:support@oengines.com"
                                                    className="contact-feature_link text-white">support@oengines.com</a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 ps-xl-4">
                                <h3 className="h4 mt-n2 mb-30 text-center text-white mb-4" style={{ fontWeight: "600" }}>Make An Appointment</h3>
                                <form onSubmit={this.onSubmit} method="post" className="appoitment-form ajax-contact">
                                    <div className="row">
                                        <div className="form-group col-md-6 chg-box">
                                            <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                            <i className="fal fa-user"></i>
                                            {nameError &&
                                                <div className="invalid-feedback" >{nameError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6 chg-box">
                                            <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} autoComplete="Off" />
                                            <i className="fal fa-envelope"></i>
                                            {emailError &&
                                                <div className="invalid-feedback">{emailError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6 chg-box">
                                            
                                            <Box>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Select Subject</InputLabel>
                                                    <Select                                                        
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="subject"
                                                        value={this.state.subject}
                                                        label="Select"
                                                        ref="subjectfield"
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                    >
                                                        <MenuItem value={""} disabled >Select Subject</MenuItem>
                                                        <MenuItem value={"Fantasy Sports Development"}>Fantasy Sports Development</MenuItem>
                                                        <MenuItem value={"Online Casino Development"}>Online Casino Development</MenuItem>
                                                        <MenuItem value={"Multiplayer Game Development"}>Multiplayer Game Development</MenuItem>
                                                        <MenuItem value={"Offline Game Development"}>Offline Game Development</MenuItem>
                                                        <MenuItem value={"2D/3D Game Development"}>2D/3D Game Development</MenuItem>
                                                        <MenuItem value={"VR Development"}>VR Development</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            {subjectError &&
                                                <div className="invalid-feedback">{subjectError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6 chg-box">
                                            <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} name="number" value={this.state.number} ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off" onChange={this.maxlengthnumber} />
                                            <i className="fal fa-phone"></i>
                                            {numberError &&
                                                <div className="invalid-feedback">{numberError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-12 chg-box">
                                            <input className="form-control datetimepicker" id="datetimepicker" name="toDate" onBlur={this.endDateChange} type="text" ref="toDatefield" autoComplete="off" placeholder="Appointment Date" />
                                            <i className="fal fa-calendar"></i>
                                            {toDateError &&
                                                <div className="invalid-feedback">{toDateError}</div>
                                            }
                                        </div>


                                        <div className="form-group col-12 chg-box">
                                            <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                                className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange} autoComplete="Off">
                                            </textarea>
                                            <i className="fal fa-comment"></i>
                                            {messageError &&
                                                <div className="invalid-feedback">{messageError}</div>
                                            }
                                        </div>
                                        <div className="form-btn text-xl-start text-center col-12 cont-btn">
                                            <button type="submit" className="as-btn">Send Appointment
                                                <i className="fa-regular fa-arrow-right ms-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3"></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </>
        )
    }
}

export default WebDevelopment;

