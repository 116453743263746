import React from "react";
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-awesome-slider/dist/styles.css';
import { Helmet } from 'react-helmet';
import axios from "axios";
const header = sessionStorage.getItem("header");;
class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
            setContactFormBool: false,
            show: false,
            isOpen: false,
            isActive: false,
            selectedDate: null,
            isAnimationRunning: true,
            autoplay: true        
        }
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
       
      
    }

    componentDidMount() {

        axios.get("https://www.oengines.com/careers")
        .then(response => {
            this.setState({ Job: response.data });
            this.setState({ Description: response.data[0].description });

        })
        .catch(function (error) {
            console.log(error);
        })
    }
    onClickShowdetails(job) {
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }

    render() {

        var Information;
        var footerwidgetarea;
        if(this.state.Job.length){            
            footerwidgetarea = 
                    <div className="widget-area" >
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <div className="widget footer-widget">

                                        <h3 className="widget_title">OENGINES GAMES LLP</h3>
                                        <div className="as-widget-about">

                                            <p className="about-text">If you're looking to work with a team of professional techs who are dedicated, skilled, and passionate about technology, look no further. Whether you're a business seeking exceptional digital solutions or a professional eager to join a dynamic and forward-thinking team, Oengines is the place for you.</p>
                                            <div className="as-social"><a href="https://www.facebook.com/oe.studio091">

                                                <i className="fab fa-facebook-f"></i>
                                            </a><a href="https://www.twitter.com/">

                                                    <i className="fab fa-twitter"></i>
                                                </a><a href="https://www.linkedin.com/company/oenginesgames"><i className="fab fa-linkedin-in"></i>
                                                </a> 
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="row justify-content-between">
                                {
                                    this.state.Job.length ?
                                <div className={typeof header != 'undefined' && header != "" && header != null && JSON.parse(header).includes ( x => x.title == "Ready / On Demand") ? "col-md-4" : "col-md-6"}>
                                    <div className="widget widget_nav_menu footer-widget">
                                        <h3 className="widget_title">Hiring Team</h3>
                                        <div className="menu-all-pages-container" id="Ready / On Demand">
                                            <ul className="menu">
                                                {this.state.Job.map((job, Index) =>
                                                    <>                                
                                                        <li key={"footerItem"+Index}>
                                                            <a onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                                                        </li>
                                                    </>                                    
                                                )} 
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                :
                                ''}

                                {
                                     typeof header != 'undefined' && header != "" && header != null && JSON.parse(header).includes ( x => x.title == "Ready / On Demand") ? 
                                        <div className="col-md-4">
                                            <div className="widget widget_nav_menu footer-widget">
                                                <h3 className="widget_title">On Demand / Ready Services</h3>

                                                <div className="menu-all-pages-container" id="Multiplayers">
                                                    <ul className="menu">
                                                        <li>
                                                            <a href="/rummy_multiplayer">Rummy Multiplayer Development</a>
                                                        </li>                                               
                                                        <li>
                                                            <a href="/spades_multiplayer">Spades Multiplayer Development</a>
                                                        </li>
                                                        
                                                        <li>
                                                            <a href="/ludo">Ludo Board Game Development</a>
                                                        </li>
                                                        <li>
                                                            <a href="/carrom">Carrom Board Game Development</a>
                                                        </li>
                                                        <li>
                                                            <a href="/bluff_card_game">Bluff Multiplayer Development</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                     : ''

                                }
                                
                                <div className={typeof header != 'undefined' && header != "" && header != null && JSON.parse(header).includes ( x => x.title == "Ready / On Demand") ? "col-md-4" : "col-md-6"}>
                                    <div className="widget widget_nav_menu footer-widget">
                                        <h3 className="widget_title">IT SERVICES</h3>
                                        <div className="menu-all-pages-container" id="itServices">
                                            <ul className="menu">
                                                <li>
                                                    <a href="/webdevelopment">Web Development</a>
                                                </li>
                                                                                                    <li>
                                                    <a href="/gamedevelopment">Product Management</a>
                                                </li>
                                                <li>
                                                    <a href="/gamedevelopment">UI/UX Design</a>
                                                </li>
                                                <li>
                                                    <a href="/gamedevelopment">Cloud services</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        }else{            
            footerwidgetarea =  
                    <div className="widget-area" >
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <div className="widget footer-widget">

                                        <h3 className="widget_title">OENGINES GAMES LLP</h3>
                                        <div className="as-widget-about">

                                            <p className="about-text" style={{maxWidth: "inherit"}}>If you're looking to work with a team of professional techs who are dedicated, skilled, and passionate about technology, look no further. Whether you're a business seeking exceptional digital solutions or a professional eager to join a dynamic and forward-thinking team, [Your Company Name] is the place for you.</p>
                                            <div className="as-social"><a href="https://www.facebook.com/">

                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="https://www.twitter.com/">

                                                    <i className="fab fa-twitter"></i>
                                                </a><a href="https://www.linkedin.com/">

                                                    <i className="fab fa-linkedin-in"></i>
                                                </a><a href="https://www.whatsapp.com/">

                                                    <i className="fab fa-whatsapp"></i>
                                                </a> <a href="https://www.youtube.com/">

                                                    <i className="fab fa-youtube"></i>
                                                </a></div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="row justify-content-between">

                               { this.state.Job.length ? <div className="col-md-6 col-sm-4 col-xl-auto col-sm-6">
                                    <div className="widget widget_nav_menu footer-widget">
                                        <h3 className="widget_title">Hiring Team</h3>
                                        <div className="menu-all-pages-container" id="teamhiring">
                                            <ul className="menu">                                                                                                  
                                                {this.state.Job.map((job, Index) =>
                                                    <>                                
                                                        <li key={"job-"+Index}>
                                                            <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                                                        </li>
                                                    </>                                    
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div> : ""}
                                
                                {
                                    typeof header != 'undefined' && header != "" && header != null && JSON.parse(header).includes ( x => x.title == "Ready / On Demand") ?
                                        <div className="col-md-6 col-xl-auto col-sm-4">
                                            <div className="widget widget_nav_menu footer-widget">
                                                <h3 className="widget_title">On Demand / Ready Services</h3>

                                                <div className="menu-all-pages-container" id="readyservices">
                                                    <ul className="menu">
                                                        <li id="rummy_multiplayer">
                                                            <a href="/rummy_multiplayer">Rummy Multiplayer Development</a>
                                                        </li>                                             
                                                        <li id="spades_multiplayer">
                                                            <a href="/spades_multiplayer">Spades Multiplayer Development</a>
                                                        </li>
                                                        
                                                        <li id="ludo">
                                                            <a href="/ludo">Ludo Board Game Development</a>
                                                        </li>
                                                        <li id="carrom"> 
                                                            <a href="/carrom">Carrom Board Game Development</a>
                                                        </li>
                                                        <li id="bluff_card_game">
                                                            <a href="/bluff_card_game">Bluff Multiplayer Development</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    : ''
                                }
                                
                                <div className="col-md-6 col-xl-auto col-sm-4">
                                    <div className="widget widget_nav_menu footer-widget">
                                        <h3 className="widget_title">IT SERVICES</h3>
                                        <div className="menu-all-pages-container" id="it">
                                            <ul className="menu">
                                                <li id="webdevelopment">
                                                    <a href="/webdevelopment">Web Development</a>
                                                </li>
                                                <li id="gamedevelopment12">
                                                    <a href="/gamedevelopment">Product Management</a>
                                                </li>
                                                <li id="gamedevelopment1">
                                                    <a href="/gamedevelopment">UI/UX Design</a>
                                                </li>
                                                <li id="gamedevelopment">
                                                    <a href="/gamedevelopment">Cloud services</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        }
        

        if (this.state.Job.length) {
            Information =   
                <div className="col-md-6 col-sm-4 col-xl-auto col-sm-6">
                    <div className="widget widget_nav_menu footer-widget">
                        <h3 className="widget_title">Hiring Team</h3>
                        <div className="menu-all-pages-container" id="Hiring">
                            <ul className="menu">                                                                                                  
                                {this.state.Job.map((job, Index) =>
                                    <>                                
                                        <li key={Index}>
                                            <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                                        </li>
                                    </>                                    
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
        } else {
            Information = '';
        }
        
        return (
        <>
            <Helmet>               
               
                <link href="https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/css/demo.css" />
                <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                <link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet" />
                <script href="https://cdnjs.cloudflare.com/ajax/libs/jquery-circle-progress/1.2.2/circle-progress.min.js"></script>
                <script href="https://cdnjs.cloudflare.com/ajax/libs/jquery-circle-progress/1.2.2/circle-progress.js"></script>
                <script src="https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/js/TweenLite.min.js"></script>
                <script src="https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/js/rAF.js"></script>
                <script src="https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/js/EasePack.min.js"></script>
                <script src="https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js"></script>              
                <script src="../assets/js/slick-min.js"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/modernizr/2.8.3/modernizr.min.js" type="Javascript"></script>
                <script scr="https://s3-us-west-2.amazonaws.com/s.cdpn.io/72900/jquery.knob.min.js"></script>
                <script src="https://cdn.jsdelivr.net/npm/tsparticles@2.2.3/tsparticles.bundle.min.js"></script>
                <script src="../assets/js/script.js" type="Javascript"></script>
                <script></script>
            </Helmet>

                <footer className="footer-wrapper footer-layout1 ne">
                    <div className="parti-cl">
                        <div _ngcontent-mfs-c21="" className="header-lines-decoration">
                            <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                            <span _ngcontent-mfs-c21="" className="bg-blue"></span>
                            <span _ngcontent-mfs-c21="" className="bg-white-light"></span>
                            <span _ngcontent-mfs-c21="" className="bg-orange-light"></span>
                            <span _ngcontent-mfs-c21="" className="bg-red"></span>
                            <span _ngcontent-mfs-c21="" className="bg-green"></span>
                            <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                        </div>
                        <div className="footer-top">
                            <div className="logo-bg"></div>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-xl-3">
                                        <div className="footer-logo"><a href="/"><img
                                            src="assets/img/white-logo.png" alt="Traga" /></a></div>
                                    </div>
                                    <div className="col-xl-9 right-side">
                                        <div className="footer-contact-wrap">
                                            <div className="footer-contact">
                                                <div className="footer-contact_icon"><i className="fas fa-phone"></i></div>

                                                <div className="media-body"><span className="footer-contact_text">Quick Call Us:</span> <a
                                                    href="tel:+19088000393" className="footer-contact_link">(0261) - 4054978</a></div>
                                            </div>
                                            <div className="footer-contact">
                                                <div className="footer-contact_icon"><i className="fas fa-envelope"></i></div>
                                                <div className="media-body" style={{ marginLeft: "14px" }}><span className="footer-contact_text">Mail Us On:</span> <a
                                                    href="mailto:support@oengines.com" className="footer-contact_link">support@oengines.com</a>
                                                </div>
                                            </div>
                                            <div className="footer-contact">
                                                <div className="footer-contact_icon"><i className="fas fa-location-dot"></i></div>
                                                <div className="media-body" style={{ marginLeft: "14px" }}><span className="footer-contact_text">Visit Location:</span> <a
                                                    href="https://www.google.com/maps" className="footer-contact_link">436, Amby vally arcade,Utran, Surat Gujarat</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div _ngcontent-mfs-c21="" className="header-lines-decoration">
                            <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                            <span _ngcontent-mfs-c21="" className="bg-blue"></span>
                            <span _ngcontent-mfs-c21="" className="bg-white-light"></span>
                            <span _ngcontent-mfs-c21="" className="bg-orange-light"></span>
                            <span _ngcontent-mfs-c21="" className="bg-red"></span>
                            <span _ngcontent-mfs-c21="" className="bg-green"></span>
                            <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                        </div>
                        <div className="footer-tops" >
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-xl-12">
                                        <div className="newsletter-wrap">
                                            <div className="newsletter-content">

                                                <h3 className="newsletter-title">News Subscription</h3>

                                                <p className="newsletter-text">Get Latest Deals from Waker’s Inbox & Subscribe Now</p>
                                            </div>
                                            <form className="newsletter-form new-form new_form_set">
                                                <div className="form-group"><input className="form-control" type="email"
                                                    placeholder="Email Address" required="" /> <i className="fal fa-envelope"></i></div>
                                                <button type="submit" className="as-btn">Subscribe</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        
                        {footerwidgetarea}
                        
                        <div className="pb" id="pb"></div>
                        <div className="pc">
                            <div className="ol">
                                <a href="#" id="btn-back-to-top">
                                    <i className="fa-solid fa-arrow-up">
                                    </i>
                                </a>
                            </div>
                            <div className="bg" id="bg"></div>
                        </div>
                    </div>
                    <div className="copyright-wrap bg-title new-bg-title">
                        <div className="container abc">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6">
                                    <p className="copyright-text text-white">Copyright <i className="fal fa-copyright"></i> {sessionStorage.getItem("currentYear")} <a
                                        href="#"> OENGINES GAMES LLP</a></p>
                                </div>
                                <div className="col-lg-6 text-end d-none d-lg-block">
                                    <div className="footer-links">
                                        <ul className="d-flex" style={{ float: "right" }}>
                                            <li><a href="/terms">Terms & Condition</a></li>
                                            <li><a href="/career">Careers</a></li>
                                            <li><a href="/home/privacy">Privacy Policy</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-left"><img src="assets/img/shape/footer_shape_2.svg" alt="shape" /></div>
                </footer>
               


        </>
    )}
}
export default Footer;