import React, { Component } from "react";
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-awesome-slider/dist/styles.css';
import { a } from 'react-router-dom';
import axios from 'axios';
import Swal from "sweetalert2";
import Appointment from "./Appointment/Appointment";
import {BrowserRouter, Routes, Route, Form } from "react-router-dom";


class ServiceHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
            header: []
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this);
    }
    componentDidMount() {
  
        axios.get("https://localhost:3001/headers/gameCategories")
            .then(response => {                 
                this.setState({header: response.data});                
            })
            .catch(function (error) {
                console.log(error);
            })

    }
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }   
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    maxlengthnumber(evt) {
        if (evt.target.value.length === 11) {
            return false;
        }
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};
        console.log("Enter in::::::::::::");
        if (this.state.name === '' || this.state.name === null) {

            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.message === '' || this.state.message === null) {
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }else if (this.state.toDate === '' || this.state.toDate === null) {
            formErrors["toDateError"] = "Date is required.";
            this.refs.toDatefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }else{

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,
            };
            console.log("formObject::::::::::;",formObject);
            axios.get   ('https://www.oengines.com/contactus', formObject)
            .then((res) => {
                console.log("Response is:::::::::",res);
                if(res.data !== 'undefined'){
                    Swal.fire({
                        title: "Thank you!",
                        text: "Your request has been assigned successfully.",
                        icon: "success",
                    }).then(function () {
                        window.location.reload();
                    });
                }else{
                    Swal.fire({
                        title: "Oops...!",
                        text: "Oh! Something went wrong, please try again!",
                        icon: "error",
                    }).then(function () {
                        window.location.reload();
                    });
                }
               
            });
        }
            
        
        this.setState({
            name: '', 
            email: '', 
            subject: '', 
            number: '',
            message: ''
           
        })
    }
    render() {


        var GameHader;
        if(this.state.header.length){
            GameHader  = this.state.header.map((header,Index)=>                  
            <li className="text-center" key={Index}>
                <img src={header.icon} alt="wallet_image" width={"50px"}/>
                <a className="game-services" href={header.href}>{header.name}</a>
            </li>        
        )
        }else{
            GameHader = <div><h4 style={{color: "Red", fontSize: "20px"}}>No Record Found !</h4></div>;
        }

        var mobileHeader;
        if(this.state.header.length){
            mobileHeader  = this.state.header.map((header,Index)=>                  
            <li className="text-center" key={Index} style={{width: "90px"}}>
                <img src={header.icon} alt="wallet_image" className="mt-1" width={"35px"}/>
                <a className="game-services" href={header.href}>{header.name}</a>
            </li>           
        )}else{
            mobileHeader = <div><h4 style={{color: "Red", fontSize: "20px"}}>No Record Found !</h4></div>;
        }
                
        const { nameError, emailError, subjectError, numberError, toDateError, messageError } = this.state.formErrors;
        return (
        <>
                
                <div className="sidemenu-wrapper side-model-contect d-lg-block paginacontainer">
                    <div className="sidemenu-content">
                        <div className="widget woocommerce widget_shopping_cart">
                            <div className="widget_shopping_cart_content">
                                <div className="modal fade modaltwo sideservice" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                    <div className="modal-dialog new-modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header ">
                                                <h5 className="modal-title m-auto" id="exampleModalLongTitle">Appointment</h5>
                                                <button type="button" className="close mdl-cls" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: "52px", position: "absolute", left: "10px", top: "-18px" }}>&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <form onSubmit={this.onSubmit} method="post" className="contact-form ajax-contact">
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                                            <i className="fal fa-user"></i>
                                                            {nameError &&
                                                                <div className="invalid-feedback" >{nameError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email"
                                                                id="email" placeholder="Email Address" onChange={this.handleChange} />
                                                            <i className="fal fa-envelope"></i>
                                                            {emailError &&
                                                                <div className="invalid-feedback">{emailError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <select name="subject" id="subject" ref="subjectfield" className={subjectError ? "form-control is-invalid" : "form-control"} onChange={this.handleChange}>
                                                                <option disabled="disabled">Select Subject
                                                                </option>
                                                                <option defaultValue={"Web Development"}>Web Development</option>
                                                                <option defaultValue={"Brand Marketing"}>Brand Marketing</option>
                                                                <option defaultValue={"UI/UX Designing"}>UI/UX Designing</option>
                                                                <option defaultValue={"Digital Marketing"}>Digital Marketing</option>
                                                            </select>
                                                            {subjectError &&
                                                                <div className="invalid-feedback">{subjectError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input type="number" className={numberError ? "form-control is-invalid" : "form-control"}  name="number" value={this.state.number} ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off"   onChange={this.maxlengthnumber}/>
                                                            <i className="fal fa-phone"></i>
                                                            {numberError &&
                                                                <div className="invalid-feedback">{numberError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-group col-12">
                                                            <input className="form-control datetimepicker" id="datetimepicker" name="toDate" onBlur={this.endDateChange} type="text" ref="toDatefield" autoComplete="off" placeholder="Appointment Date" />
                                                            <i className="fal fa-calendar"></i>
                                                            {toDateError &&
                                                                <div className="invalid-feedback">{toDateError}</div>
                                                            }
                                                        </div>

                                                        <div className="form-group col-12">
                                                            <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                                                className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange}>
                                                            </textarea>
                                                            <i className="fal fa-comment"></i>
                                                            {messageError &&
                                                                <div className="invalid-feedback">{messageError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-btn text-xl-start text-center col-12">
                                                            <button type="submit" className="as-btn style3">Send Appointment
                                                                <i className="fa-regular fa-arrow-right ms-2"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <p className="form-messages mb-0 mt-3"></p>
                                                </form>
                                                <div className="contect-us-details">
                                                    <h4 className="text-center">Get In Touch</h4>
                                                    <p className="text-context-main">Book an Appointment now to get Started a new Journey with us.</p>
                                                    <div className="conect-item">
                                                        <i className="fas fa-map-location"></i>
                                                        <div className="contect-info">
                                                            <a href="#" className="title">436, Amby vally arcade,</a>
                                                            <p className="sub-title">Utran, Surat Gujarat</p>
                                                        </div>
                                                    </div>
                                                    <div className="conect-item">
                                                        <i className="fas fa-phone"></i>
                                                        <div className="contect-info">
                                                            <a href="#" className="title">(0261) - 4054978</a>
                                                            <p className="sub-title">Mon-Fri 9am-6pm</p>
                                                        </div>
                                                    </div>
                                                    <div className="conect-item">
                                                        <i className="fas fa-envelope"></i>
                                                        <div className="contect-info">
                                                            <a href="#" className="title">support@oengines.com</a>
                                                            <p className="sub-title">Mon-Fri 9am-6pm</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sidemenu-wrapper side-devlop d-none d-lg-block paginacontainer">
                    <div className="sidemenu-content">
                        <button className="closeButton sideMenuCls"><i className="far fa-times"></i></button>
                        <div className="widget woocommerce widget_shopping_cart">
                            <div className="widget_shopping_cart_content">
                                <ul className="my-menu woocommerce-mini-cart cart_list product_list_widget ">
                                    <li className="woocommerce-mini-cart-item mini_cart_item active">
                                        <i className="fa-solid fa-right-long"></i>
                                        <a href="#" style={{ color: "#FFFFFF" }} className="game-dev">Game Development</a>

                                        <ul className="my-menu woocommerce-mini-cart cart_list product_list_widget set-positions active">
                                            <span>Game Development</span>
                                            <div className="under-line"></div>
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/android" style={{ marginTop: "30px" }}> Android Game Development</a></li>
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/ios">iOS Game Development</a></li>
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/unity">Unity Game Development</a></li>
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/webbased">Web Based Game Development</a></li>
                                        </ul>
                                    </li>
                                    <li className="woocommerce-mini-cart-item mini_cart_item">
                                        <i className="fa-solid fa-right-long"></i>
                                        <a href="#" style={{ color: "#FFFFFF" }} className="web-dev">Ready / On Demand Development</a>
                                        <ul className="my-menu woocommerce-mini-cart cart_list product_list_widget set-positions">
                                            <span>Ready / On Demand Development</span>
                                            <div className="under-line"></div>
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/rummy_multiplayer" style={{ marginTop: "30px" }}>Rummy Multiplayer Development</a></li>                                          
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/spades_multiplayer">Spades Multiplayer Development</a></li>                                      
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/callbreak">Callbreak Multiplayer Development</a></li>
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/ludo">Ludo Board Game Development</a></li>
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/carrom">Carrom Board Game Development</a></li>
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/bluff_card_game">Bluff Multiplayer Development</a></li>
                                        </ul>
                                    </li>
                                    <li className="woocommerce-mini-cart-item mini_cart_item">
                                        <i className="fa-solid fa-right-long"></i>
                                        <a href="#" style={{ color: "#FFFFFF" }} className="web-dev">Web Development</a>
                                        <ul className="my-menu woocommerce-mini-cart cart_list product_list_widget set-positions">
                                            <span>Web Development</span>
                                            <div className="under-line"></div>
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/nodejs" style={{ marginTop: "30px" }}>Nodejs Applications Service</a></li>
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/reactjs">Reactjs Applications Service</a></li>
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/expressjs">Expressjs Applications Service</a></li>
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/flutter">Flutter Applications Service</a></li>                                           
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/php">Php Applications Service</a></li>
                                            <li className="woocommerce-mini-cart-item mini_cart_item set-box"><a href="/codelgnitor">Codelgnitor Applications Service</a></li>
                                        </ul>
                                    </li>                                  
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="popup-search-box d-none d-lg-block"><button className="searchClose"><i className="fal fa-times"></i></button>
                    <form action="#"><input type="text" placeholder="What are you looking for?" /> <button type="submit"><i
                        className="fal fa-search"></i></button></form>
                </div>
                <div className="as-menu-wrapper">
                    <div className="as-menu-area text-center"><button className="as-menu-toggle" id="btn-cls"><i className="fal fa-times"></i></button>
                        <div className="mobile-logo"><a href={"/"}><img src="assets/img/logo.png" alt="Traga" /></a>
                        </div>
                        <div className="cm-menu-inner as-mobile-menu">
                            <ul className="menu-ul clear-all main-ul-menu">
                                <li className="menu-item-has-children">
                                    <a href={"/"}>Home</a>                                   
                                </li>
                                <li className="has-child menu-item-has-childrens services">
                                    <a href="#">Services</a>
                                    <ul className="menu-ul">
                                       {mobileHeader}                                                                           
                                    </ul>
                                </li>
                                <li className="menu-item-has-childrens">
                                    <a href="/blog">Blog</a>
                                </li>
                                <li className="menu-item-has-childrens">
                                    <a href="/career">Career</a>
                                </li>
                                <li className="menu-item-has-childrens">
                                    <a href="/about">About Us</a>
                                </li>
                                <li className="menu-item-has-childrens">
                                    <a href="/contact">Contact</a>                                    
                                </li>
                                <div className="over-btn d-flex">
                                    <div className="col-auto d-lg-block">
                                        <div className="header-button">
                                            <a href="#" 
                                                className="as-btn Appointment" >Make Appointment<i
                                                    className="fas fa-arrow-right ms-1"></i></a>
                                        </div>
                                    </div> 
                                    <div className="box sky-btn">
                                        <a href="https://login.live.com/login.srf?wa=wsignin1.0&rpsnv=13&ct=1683003214&rver=7.1.6819.0&wp=MBI_SSL&wreply=https%3A%2F%2Flw.skype.com%2Flogin%2Foauth%2Fproxy%3Fclient_id%3D572381%26redirect_uri%3Dhttps%253A%252F%252Fweb.skype.com%252FAuth%252FPostHandler%253FopenPstnPage%253Dtrue%26state%3D1b2ec174-d4d2-4b7b-a6c9-7c16986c8c39&lc=1033&id=293290&mkt=en-US&psi=skype&lw=1&cobrandid=2befc4b5-19e3-46e8-8347-77317a16a5a5&client_flight=ReservedFlight33%2CReservedFlight67">
                                        <i className="fab fa-skype"></i>
                                        </a>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                <header className="as-header header-layout1">
                    <div _ngcontent-mfs-c21="" className="header-lines-decoration">
                        <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                        <span _ngcontent-mfs-c21="" className="bg-blue"></span>
                        <span _ngcontent-mfs-c21="" className="bg-white-light"></span>
                        <span _ngcontent-mfs-c21="" className="bg-orange-light"></span>
                        <span _ngcontent-mfs-c21="" className="bg-red"></span>
                        <span _ngcontent-mfs-c21="" className="bg-green"></span>
                        <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                    </div>
                    <div className="header-top">
                        <div className="container">
                            <div className="row justify-content-center align-items-center gy-2 mt-1 mb-1">
                                <div className="col-auto d-none d-lg-block">
                                    <div className="header-links">
                                        <ul>
                                            <li><i className="fas fa-map-location"></i>436, Amby vally arcade,Utran, Surat Gujarat</li>
                                            <li><i className="fas fa-phone"></i><a href="tel: 1539873657">(0261) - 4054978</a></li>
                                            <li><i className="fas fa-envelope"></i><a href="mailto:support@oengines.com">support@oengines.com</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-auto header-main">
                                    <div className="header-social"><span className="social-title">Follow Us On : </span><a
                                        href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a> 
                                        <a href="https://www.twitter.com/"><i className="fab fa-twitter"></i></a> 
                                        <a href="https://www.linkedin.com/"><i className="fab fa-linkedin-in"></i></a>
                                        <a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a> 
                                        <a href="https://www.youtube.com/"><i className="fab fa-youtube"></i></a>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sticky-wrapper">
                        <div className="menu-area">
                            <div className="container">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto">
                                        <div className="header-logo"><a href={"/"}><img src="assets/img/logo1.png" alt="Traga" /></a>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <nav className="main-menu d-none d-lg-inline-block">
                                            <ul>
                                                <li className="menu-item-has-children home"><a href={"/"} className={BrowserRouter.pathname === "/" ? "active" : ""}>Home</a>

                                                </li>
                                                <li className="menu-item-has-children servs services"><a href="#">Services <i className="fa fa-angle-down" aria-hidden="true"></i></a>
                                                    <ul className="sub-menu d-block">
                                                        <div className="">
                                                            <div className="sub-items p-4">  
                                                            <h4 className="text-center">Game Developement Service</h4>                                                                                                                             
                                                                <ul className="d-flex gap">
                                                                    {GameHader}
                                                                </ul>                                                               
                                                            </div>                                                          
                                                        </div>
                                                    </ul>
                                                </li>
                                                <li className="menu-item-has-children blog"><a href="/blog" className={BrowserRouter.pathname === "/blog" ? "active" : ""}>Blog</a>
                                                </li>
                                                <li className="menu-item-has-children career"><a href="/career" className={BrowserRouter.pathname === "/career" ? "active" : ""}>Career</a></li>
                                                <li className="menu-item-has-children about"><a href="/about" className={BrowserRouter.pathname === "/about" ? "active" : ""}>About Us</a></li>
                                                <li className="menu-item-has-children contect"><a href="/contact" className={BrowserRouter.pathname === "/contact" ? "active" : ""}>Contact</a></li>                                               
                                            </ul>
                                        </nav><button type="button" className="as-menu-toggle d-inline-block d-lg-none" id="but-icon"><i
                                            className="far fa-bars"></i></button>
                                    </div>
                                    <Appointment />
                                </div>
                            </div>
                            <div className="logo-bg"></div>
                        </div>
                    </div>
                </header>
        </>
    )}
}
export default ServiceHeader;