import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';


export default function Music() {	
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  	
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Music'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};

	const endDateChange = (e) => {
        // this.setState({ toDate: e.target.value })
    };

	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }

	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />

			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
						
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>


			<section id="hero" className="music-section hero-section division">
				<div className="container">
					<div className="row card-hero pb-100 text-start">
						<div className="col-md-12 col-lg-6 arcade-col mt-25">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Music Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt action-hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color max-conent m-auto mt-40">Our Music game development company runs independent projects, jointly creates games with the client and provides additional operational services. The expertise of our team allows us to cover all gaming platforms and develop an  amazing product that matches the customer’s vision and players preferences.</p>
								</ScrollAnimation>
							</div>
							<a href="tel:+02614054978" ><button className="theme-button mt-35">Call Now</button></a>
						</div>
						<div className="col-md-12 col-lg-6">
							<div className="music-hero-img text-center mt-30">
								<img src="../assets/img/GameDevelopment/unity3D-game-development.png" alt="img" />
							</div>
						</div>
					</div>
				</div>
			</section>
        
			<section className="music-game racing-game pt-80 pb-60">
				<div className="container">
                    <div className="row">
						<div className="col-md-5">
                            <div className="music-gif-img mt-35">
                                <img src="../assets/img/GameDevelopment/who-we-are.png" alt="img not found" />
                            </div>
                        </div>
                        <div className="action-heaing col-md-7 music-action-heaing">
                            <h2 className="text-start">Music Game</h2>
                            <div className="elementor-divider elementor-music">
                                <span className="elementor-divider-separator">
                                </span>
                            </div>
                            <p className="pt-10 mt-30 text text-start">A music video game, also commonly known as a music game, is a video game where the gameplay is meaningfully and often almost entirely oriented around the player's interactions with a musical score or individual songs. Music video games may take a variety of forms and are often grouped with puzzle games due to their common use of "rhythmically generated puzzles".</p>
                            <p className="mt-30 text">Music video games are distinct from purely audio games in that they feature visual feedback, to lead the player through
							the game's soundtrack, although eidetic music games can fall under both categories.</p>
							<ul className="music-ul">
								<h3>Features of Music Game</h3>
								<li>Concentration/Focus</li>
								<li>Listening Skills</li>
								<li>Gross Motor Skills</li>
								<li>Extra Energy Use</li>
								<li>Self-Regulation</li>
								<li>Musical Appreciation</li>
							</ul>
                        </div>                       
                    </div>
				</div>
			</section>

			<section className="element-music pb-80 pt-80">
				<div className="container">
					<div className="element-music-title">
					</div>
					<div className="row align-items-center">		
						<div className="col-xl-4">
							<div className="text-wrap">
								<div className="caption">We offer innovative digital solutions</div>
								<h2>Key Elements of Music Game</h2>								
								<div className="pt-3 pt-md-2 pt-lg-3">
									<div className="button-new">
										<span><a href="#!"><i className="fa-solid fa-right-long"></i>View All</a></span>
									</div>
								</div>
							</div>
						</div>		
						<div className="col-xl-8">
							<div className="services-box">
								<div className="row mt-50">
									<div className="col-md-6 col-xl-6 col-sm-6">
										<div className="box-wrap">
											<div className="icon-wrap">
												<img className="img-fluid entered lazyloaded" src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/mobile-app.png" data-lazy-src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/mobile-app.png" data-ll-status="loaded"/><noscript><img className="img-fluid" src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/mobile-app.png" /></noscript>
											</div>
											<div className="title">
												<h4 className="services-title"><a href="#"></a><a href="https://www.21twelveinteractive.com/custom-mobile-app-development/">Dynamics</a></h4>
												<p>Learn about the differences in volume – loud and quiet. Understand musical terms for dynamics including pianissimo,piano, forte, fortissimo, crescendo and decrescendo</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-xl-6 col-sm-6">
										<div className="box-wrap">
											<div className="icon-wrap">
												<img className="img-fluid entered lazyloaded" src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/cms.png" data-lazy-src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/cms.png" data-ll-status="loaded"/><noscript><img className="img-fluid" src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/cms.png" /></noscript>
											</div>
											<div className="title">
												<h4 className="services-title"><a href="#"></a><a href="https://www.21twelveinteractive.com/custom-cms-website-development/">Rhythm</a></h4>
												<p>Rhythm is all about the timing of sounds. Listen to some instruments and clap along. You’ll learn how to combine different sounds to create a rhythm.</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-xl-6 col-sm-6">
										<div className="box-wrap">
											<div className="icon-wrap">
												<img className="img-fluid entered lazyloaded" src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/e-commerce.png" data-lazy-src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/e-commerce.png" data-ll-status="loaded"/><noscript><img className="img-fluid" src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/e-commerce.png" /></noscript>
											</div>
											<div className="title">
												<h4 className="services-title"><a href="#"></a><a href="https://www.21twelveinteractive.com/ecommerce-development/">Pitch</a></h4>
												<p>Learn about how melodies can either be high or low. We’ll show you the difference between ascending and descending pitches using a range of instruments.</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-xl-6 col-sm-6">
										<div className="box-wrap">
											<div className="icon-wrap">
												<img className="img-fluid entered lazyloaded" src="https://www.21twelveinteractive.com/wp-content/uploads/2023/02/web-design1.png" data-lazy-src="https://www.21twelveinteractive.com/wp-content/uploads/2023/02/web-design1.png" data-ll-status="loaded"/><noscript><img className="img-fluid" src="https://www.21twelveinteractive.com/wp-content/uploads/2023/02/web-design1.png" /></noscript>
											</div>
											<div className="title">
												<h4 className="services-title"><a href="#"></a><a href="https://www.21twelveinteractive.com/designing-services/">Form</a></h4>
												<p>Musical form describes the structure in a piece of music. Sing along to Twinkle, Twinkle, Little Star and Ring-a-ring o’ Roses to learn about different musical forms including ternary form, ostinato, call and response and a canon.</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-xl-6 col-sm-6">
										<div className="box-wrap">
											<div className="icon-wrap">
												<img className="img-fluid entered lazyloaded" src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/game.png" data-lazy-src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/game.png" data-ll-status="loaded"/><noscript><img className="img-fluid" src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/game.png" /></noscript>
											</div>
											<div className="title">
												<h4 className="services-title"><a href="#"></a><a href="https://www.21twelveinteractive.com/javascript-development/">Timbre</a></h4>
												<p>How do you create different moods using music? Tone and resonance can be used to alter the timbre and transform the sound of the music.</p>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-xl-6 col-sm-6">
										<div className="box-wrap">
											<div className="icon-wrap">
												<img className="img-fluid entered lazyloaded" src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/digital-marketing.png" data-lazy-src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/digital-marketing.png" data-ll-status="loaded"/><noscript><img className="img-fluid" src="https://www.21twelveinteractive.com/wp-content/uploads/2022/09/digital-marketing.png" /></noscript>
											</div>
											<div className="title">
												<h4 className="services-title"><a href="#"></a><a href="https://www.21twelveinteractive.com/digital-marketing/">Texture</a></h4>
												<p>Music can have many layers. Learn the difference between thick and thin textures as well as the meaning of monophonic, homophonic and polyphonic textures. Understand how melody and accompaniment combine to create music with a thick texture by listening to the Australian bush ballad, Waltzing Matilda.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>	
					</div>					
				</div>
			</section>




			<section className="welcome padding-top-80 pb-60">
				<div className="container">	
					<div className="row"> 
						<div className="col-md-12 col-sm-12 col-xs-4 col-xxl-4 col-xl-4  col-lg-4 text-center margin-bottom-10">
							<div id="f1_container">
								<div id="f1_card" className="">
									<div className="front face">
										<img src="../assets/img/GameDevelopment/card-front.jpg" alt=""/>
									</div>
									<div className="back face center">
										<img src="../assets/img/GameDevelopment/card-back.jpg" alt=""/>
									</div>
								</div>
								<img src="../assets/img/GameDevelopment/card-front.jpg" style={{visibility: "hidden"}} alt=""/>
							</div>                                
						</div>

						<div className="col-md-12 col-sm-12 col-xs-8 col-xxl-8 col-xl-8 col-lg-8 mt-1">
							<div className="heading-block no-margin"> 
								<span className="margin-bottom-10 mt-30" style={{fontSize: "30px", fontWeight: "600"}}>Choose Our OEngines Games for Music Game Development</span>                                    
							</div>
							<p className="text mt-30">
								OEngines Games is one of the best Music Game Development Companies in the INDIA that is adept at designing and
								developing Musical mobile games for all platforms. Our skilled team of developers and designers' study recent market trends and create high-quality user-interface and features that capture the user’s attention.
							</p>
							<p className="text mt-30">
								We strive to build personalized games according to your requirements. With expertise in advanced technologies like AI,
								ML, we create cutting-edge game apps that are integrated with the latest technologies that drive user engagement and
								growth.
							</p>

							<div className="row margin-top-30"> 
								<div className="col-md-4 mt-15">
									<div className="oecard-icon-box ib-style-1 ib-circle ib-bordered ib-bordered-light ib-small">
										<div className="ib-icon"> <i className="far fa-diamond"></i> </div>
										<div className="ib-info">
											<h5>Musical apps for Android</h5>
										</div>
									</div>
								</div>

								<div className="col-md-4 mt-15">
									<div className="oecard-icon-box ib-style-1 ib-circle ib-bordered ib-bordered-light ib-small">
										<div className="ib-icon"> <i className="far fa-money-bill-alt" aria-hidden="true"></i> </div>
										<div className="ib-info">
											<h5>Musical Game apps for iOS</h5>
										</div>
									</div>
								</div>

								<div className="col-md-4 mt-15">
									<div className="oecard-icon-box ib-style-1 ib-circle ib-bordered ib-bordered-light ib-small">
										<div className="ib-icon"> <i className="fa fa-credit-card"></i> </div>
										<div className="ib-info">
											<h5>Musical platforms for the Web</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="hire-team pt-80 pb-80">
				<div className="container text-center">
					 <div className="hire-team-title">
						<h2>Hiring a development team shouldn't be difficult!</h2>
					 </div>
					 <p className="text mt-30">When you hire us, you'll directly work with our project manager, a team of developers and designers who are passionate about building products that people love. We understand that the details matter and we take the time to understand you & your business</p>
					 <button className="theme-button mt-35 style-2 let-project" onClick={handleShow}><a>Let’s Start Project Now</a></button>
				</div>
			</section>

			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}
			<Footer />
		</div>

	);
}
