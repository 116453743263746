import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';

export default function Word() {	
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Word'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};



	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }

	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />
			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>
			<section id="hero" className="word-section hero-section division">
				<div className="container">
					<div className="row card-heros pb-100 text-start">
						<div className="col-md-12 col-lg-12 col-xl-6 mt-80 arcade-col">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Word Game Development</h2>
								</ScrollAnimation>								
							</div>
							<div className="hero-17-txt action-hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color max-conent m-auto mt-40">OENGINES GAMES is a leading developer of the world's most popular social games that are played by millions of people around the world each day.</p>
								</ScrollAnimation>
							</div>
							<a href="tel:+02614054978"><button className="theme-button mt-50">Call Now</button></a>
						</div>						
					</div>
				</div>
			</section>
        
				<section className="chs_oeg mt-80 mb-60">
					<div className="container">
						<div className="Oeg_main">
							<div className="row">
								<div className="col-md-12">
									<div className="Oeg_text">
										<h2 className="text-center">Word Game</h2>
										<div className="elementor-divider rpg-elementor-divider">
											<span className="elementor-divider-separator">
											</span>
										</div>									
										<p className="mt-30 text">Word games are generally used as a source of entertainment but can additionally serve an 	educational purpose. Young children can enjoy playing games such as Hangman, while naturally developing important language skills like spelling. Researchers have  found that adults who regularly solved crossword puzzles, which require familiarity with a larger vocabulary, had better brain function later in life.</p>
									</div>
								</div>
							</div>
						</div>	
					</div>
				</section>
						
			<section className="service-uniq pb-100">
				<div className="container">
					<div className="service-uniq-title">
						<h2 className="text-center">Why is Our Service Unique?</h2>
					</div>
					<div className="row mt-50">
						<div className="col-md-6">
							<div className="service-uniq-box d-flex justify-content-center mt-35">
								<div className="word-icon">
									<img src="../assets/img/GameDevelopment/Seamless-Performing-Apps.png" alt="img" />
								</div>
								<div className="word-serv-text">
									<h3>Quality performance games</h3>
									<p>We are talented to build word games that require only one download time and can be played for an endless time  period even without bothering about updates.</p>
								</div>
							</div>
							<div className="service-uniq-box d-flex justify-content-center mt-35">
								<div className="word-icon">
									<img src="../assets/img/GameDevelopment/End-to-End-Support.png" alt="img" />
								</div>
								<div className="word-serv-text">
									<h3>Multi-Platform</h3>
									<p>The demand for cross-platform games increases every day. So, our primary goal is to create exciting word games that support multi-platform functionality.</p>
								</div>
							</div>
							
						</div>
						<div className="col-md-6">	
							<div className="service-uniq-box d-flex justify-content-center mt-35">
								<div className="word-icon">
									<img src="../assets/img/GameDevelopment/Detailed-Analysis.png" alt="img" />
								</div>
								<div className="word-serv-text">
									<h3>Quality coding and programming</h3>
									<p>We use well-known technology to develop a game that supports various mobile platforms. Our developers can also
									maintain and deploy advanced features.</p>
								</div>
							</div>
							<div className="service-uniq-box d-flex justify-content-center mt-35">
								<div className="word-icon">
									<img src="../assets/img/GameDevelopment/Immense-Experience.png" alt="img" />
								</div>
								<div className="word-serv-text">
									<h3>Rich user experience</h3>
									<p>It's not really hard to build such games that offer a constant look and feel across many different platforms for our professional developers and designers.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>				
			
			<section className="word-contect word-color-after mt-10 pt-70 pb-70">
				<div className="container pt-60 pb-60">
                    <div className="row">
                        <div className="hire-our-action-text racing-contect-mian text-center">
                            <h2 className="text-white">Build your own Word game with</h2>
							<h2 className="text-white">OENGINES Games Development</h2>
                           <button className="theme-button mt-30" onClick={handleShow}>Contact Us Now</button>
                        </div>
                    </div>
				</div>
			</section>

			<section className="custom_website_com_pad partner_with_best pt-80 pb-80">
				<div className="container">
					<div className="partner_with_best_sec row">
						<div className="partner_written_sec col-md-6">
							<h2 className="custom_website_heading_2 custom_website_app_heading_2">Benefits of Word Game</h2>
							<p className="custom_website__paragraph mt-35">Even without taking into account how much fun and entertaining they are, the benefits of playing word games are quite notable. Yes, they are an amusing hobby and a great way to pass time, but they are also educational and a tool for self-improvement.</p>
							<ul className="custom_website_unordered_list partner_list">
								<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2"> Increases vocabulary</p></li>
								<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2"> There is a learning process suitable for everyone</p></li>
								<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2">Improves spelling</p></li>
								<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2"> Helps train concentration skills </p></li>
								<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2"> Enhances cognitive skills</p></li>
								<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2"> Improves memory </p></li>
								<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2"> It promotes a self-improving competition</p></li>
								<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2"> It can make you happy</p></li>
								<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2"> It’s relaxing</p></li>
								<li><div className="partner_list_sec"></div><p className="custom_website__paragraph mb-2"> It’s a social bonding experience</p></li>
							</ul>
						</div>
						<div className="partner_img_sec col-md-6">
							<img decoding="async" src="https://artoonsolutions.com/wp-content/themes/artoon/images/lp/custom_website_development_page/partner_img.webp" alt="partner-img" />
						</div>
					</div>
				</div>
			</section>

			<section className="parallax ludo-game-parallax d-flex align-items-center justify-content-center mt-50">
				<div className="container custom-container">
					<div className="row">
						<div className="col-sm-12 col-md-7">
							<div className="paral-box">
								<h2 className="f-48 mb-30"><span className="ez-toc-section" id="Recognized_as_Best_Ludo_Game_Development_Company" ez-toc-data-id="#Recognized_as_Best_Ludo_Game_Development_Company"></span>OENGINES Word Game Development<span className="ez-toc-section-end"></span></h2>
								<p>We developed the Word Game that is a fun, polished word puzzle with many twists. With innovative additions to the
								original concept, challenging features and layouts, and tons of content, our Android developers built the app to keep you motivated and playing game after game.</p>
								<h3>Here are some Types of Word Game</h3>
								<ul className="ludo-list">
									<li className="mb-2">Letter arrangements</li>
									<li className="mb-2">Crosswords</li>
									<li className="mb-2">Word Search</li>
									<li className="mb-2">Wordle and Word Hustle</li>
									<li className="mb-2">Anagrams word games</li>
									<li className="mb-2">Semantic games</li>
									<li className="mb-2">Cryptogram puzzles</li>
									<li className="mb-2">Pictionary</li>
								</ul>
							</div>
						</div>
					</div>	
				</div>
			</section>
			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}
		
			<Footer />
		</div>

	);
}
