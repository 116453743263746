import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from 'react-helmet';
import Header from "./Gameheader";
import Footer from "./Footer";
import CountUp from 'react-countup';
import '../assets/css_game/style.css';
import OwlCarousel from 'react-owl-carousel';
import ScrollAnimation from 'react-animate-on-scroll';
import '../assets/css_game/dropdown-effects/fade-down.css';
import ReactCardFlip from 'react-card-flip'
import $ from 'jquery';
import config from '../config.json';
const sliderSettings = {
	dots: false,
	items: 5,
	slidestoscroll: 1,
	autoplay: true,
	interval: 5000,
	initialslide: 0,
	autoplaytimeout: 8000,
	autoplaySpeed: 2000,
	responsive: {
		0: {
			items: 2,
			nav: false
		},
		600: {
			items: 3,
			nav: false
		},
		1000: {
			items: 4,
			nav: false,
		}
	}
}
export default function Bhabhioffline() {

	const [isFlipped2, setIsFlipped2] = useState(false);
	const [gameDetails, setGame] = useState({});
	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);

		axios.get(config.GAME_DETAILS_FOR_PAGES_API+Pathname).then((res) => {
			console.log("callbreak Game Data Data is more games::::::::", res.data);
			if (res.data && res.data.length){
				setGame(res.data[0]);
			}
				
		})
	}, []);



	const handleClick = (e) => {
		if (window.innerWidth >= 769) {
			setIsFlipped2(!isFlipped2);
			$('.run-games').html(`'<div id="iframe"><iframe src="https://www.oengines.com/bgame" width="100%" height="100%" frameborder="0" targeted=_blank></iframe></div>'`);
		}
	}

	return (
		<div>
			<Helmet>
				<link id="googleFonts" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800%7CShadows+Into+Light&amp;display=swap" rel="stylesheet" type="text/css"></link>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<script src="../assets/js/script.js" type="JavaScript"></script>
			</Helmet>
			<>
				<Header />
			</>


			<section id="hero-17" className="bg-scroll rel hero-section division">
				<div className="container">
					<div className="row">
						<div className="col-md-9 col-lg-8">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Bhabhi Card Game</h2>
								</ScrollAnimation>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 col-lg-5">
							<div className="hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color">Enhance your Gaming experience with the Bhabhi Cards Game Development offered by us. Our Developers build games for both web and mobiles including all app platforms. We will provide you with a rich-featured app that will help you to gain more users. Hire the best Bhabhi Cards Game developers from our squad of tech brains and witness the best development solutions.
									</p>
								</ScrollAnimation>
								{
									gameDetails.android_link ?
										<a target="_blank" href={gameDetails.android_link} className="os-btn bg-white d-flex align-items-center">
											<div className="os-btn-ico">
												<div className="ico-50">
													<img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
												</div>
											</div>
											<div className="os-btn-txt">
												<h6 className="h6-lg">Download for Android</h6>
											</div>
										</a>
										:
										''
								}

								{
									gameDetails.ios_link ? <a target="_blank" href={gameDetails.ios_link} className="os-btn mac-os-btn bg-white d-flex align-items-center">
										<div className="os-btn-ico">
											<div className="ico-50">
												<img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
											</div>
										</div>

										<div className="os-btn-txt">
											<h6 className="h6-lg">Download for IOS</h6>
										</div>
									</a> : ''
								}
								<ul className="advantages white-color clearfix mt-30">
									<li className="first-li">
										<p>Version 2.10.074</p>
									</li>
									<li className="last-li">

									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="hero-17-img video-preview">
								<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
									<img className="img-fluid" src="../assets/img/GamesImg/bhabhi_offline_images/dashboard-04.png"
										alt="hero-image" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="content-2" className="content-2 pb-60 pt-50 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-5 col-lg-6">
							<div className="rel img-block left-column wow fadeInRight">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<img className="img-fluid" src="../assets/img/GamesImg/bhabhi_offline_images/img-10.png"
										alt="content-image" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-7 col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">
								<ScrollAnimation animateIn="animate__animated animate__fadeInright">
									<h2 className="h2-xs">Bhabhi Card Game Development Services</h2>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__animated animate__fadeInright">
									<p className="p-lg">Our Company is a leading Bhabhi Cards Game Development Company in India. Our team of Bhabhi Cards Game Developers like Designers and programmers strives to give you the best Bhabhi Cards Game software in terms of quality. Our Bhabhi Cards Game developers specifically keep clients in the loop throughout the game process to ensure that the clients are highly satisfied with the results. We use advanced technologies to create a game that supports android, iOS, Desktop & all other platforms. Our developers make sure that you and your audience receive memorable hands-on experiences. For that, our passionate gaming coders make use of the latest mobile technology.
									</p>
									<p className="p-lg">Adept Bhabhi Cards Game providers are working hard on different Bhabhi Cards Game app solutions consisting of varied options and blends of features, functionalities, modules, etc. to perfectly deliver you the best idea for Bhabhi Cards Game app development. We will provide you with a rich featured app that will help you to gain more Bhabhi Cards Game users. Set your Bhabhi Cards Game to a level higher than other Bhabhi Cards Game developers with us.
									</p>
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="features-8" className="pb-60 features-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-70">
								<ScrollAnimation animateIn="animate__animated animate__fadeInup">
									<h2 className="h2-md">Bhabhi Card Game Script</h2>
								</ScrollAnimation>
							</div>
						</div>
					</div>
					<div className="fbox-8-wrapper text-center">
						<div className="row row-cols-1 row-cols-md-3 justify-content-center">
							<div className="col">
								<div className="fbox-8 mb-40 wow fadeInUp">
									<div className=" fbox-img">
										<img className="img-fluid" src="../assets/img/GamesImg/bhabhi_offline_images/img-1.png"
											alt="feature-icon" />
									</div>
									<ScrollAnimation animateIn="animate__animated animate__fadeInup">
										<h5 className="h5-md">Desktop Version</h5>
										<p className="p-lg">This version is mainly for Windows and MacOS. You can simply
											download and install this version and good to go.
										</p>
									</ScrollAnimation>
								</div>
							</div>
							<div className="col">
								<div className="fbox-8 mb-40 wow fadeInUp">
									<div className="fbox-img">
										<img className="img-fluid change" src="../assets/img/GamesImg/bhabhi_offline_images/img-01.png"
											alt="feature-icon" />
									</div>
									<ScrollAnimation animateIn="animate__animated animate__fadeInup">
										<h5 className="h5-md">IOS Version</h5>
										<p className="p-lg">Our Bhabhi Card game software mobile version is available for
											IOS, Android and Windows app.
										</p>
									</ScrollAnimation>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="content-9" className="content-9 bg-whitesmoke-gradient pt-100 content-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-10 col-lg-8">
							<div className="section-title title-02 mb-60">
								<h2 className="h2-xs">Discover powerful features to boost your productivity</h2>
							</div>
						</div>
					</div>
					<div className="img-block">
						<div className="row">
							<div className="col-md-6">
								<div className="about-rummy">
									<ScrollAnimation animateIn="animate__animated animate__fadeInup">
										<h2 className="heading">What is Bhabhi Card Game</h2>

										<p>BHABHI Game is played in the Punjab region of India and Bangladesh. The aim of the game is to GET AWAY by playing all of one's cards. The last remaining player who fails to get away and is left holding cards is the loser. The loser is called Bhabhi. The player who holds the Ace of Spades begins by playing it face up on the table, and each of the other players must also play a card face up. Each trick begins with the player of the highest card in the suit that was led to the previous trick: this player is said to "have the power".  </p>
									</ScrollAnimation>
								</div>
							</div>
							<div className="col-md-6">
								<div className="content-9-img video-preview wow fadeInUp">
									<a className="video-popup1" href="https://www.youtube.com/embed/SZEflIVnhH8" target="_blank">
										<div className="video-btn video-btn-xl bg-red ico-90">
											<div className="video-block-wrapper"><span className="flaticon-play-button"></span></div>
										</div>
									</a>
									<img className="img-fluid" src="../assets/img/GamesImg/bhabhi_offline_images/dashboard-07.png"
										alt="video-preview" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="statistic-2" className="wide-100 statistic-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">
								<h3 className="h3-xs">More than 10,000 users around the world are already using
									Bhabhi Card actively</h3>
							</div>
						</div>
						<div className="col-sm-5 col-md-4 col-lg-3 offset-sm-1 offset-md-2 offset-lg-1">
							<div className="statistic-block wow fadeInUp">
								<h2 className="h2-title-xs statistic-number"><span className="count-element"><CountUp end={10000} duration={10} /></span>
								</h2>
								<p className="p-lg mt-20">Active downloads <br /> from the community</p>
							</div>
						</div>
						<div className="col-sm-5 col-md-4 col-lg-2">
							<div className="statistic-block wow fadeInUp">
								<h2 className="h2-title-xs statistic-number">
									<span className="count-element"><CountUp end={4} duration={5} /></span>.<span className="count-element"><CountUp end={3} duration={8} /></span>
								</h2>
								<div className="txt-block-rating ico-15 yellow-color">
									<span className="flaticon-star-1"></span>
									<span className="flaticon-star-1"></span>
									<span className="flaticon-star-1"></span>
									<span className="flaticon-star-half-empty"></span>
								</div>
								<p className="p-lg txt-400">41 Rating</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="space-bottom space-top contact-infos ">
				<div className="container">
					<div className="row text-center pt-4">
						<div className="col">
							<h2 className="word-rotator slide font-weight-bold text-8 mb-25">
								<span>Porto is&nbsp;</span>
								<span className="word-rotator-words bg-primary" style={{ padding: "0 20px 0 0" }}>
									<span className="spinny-words text-start">
										<span>incredibly</span>
										<span>especially</span>
										<span>extremely</span>
										<span>incredibly</span>
										<span>especially</span>
										<span>extremely</span>
									</span>
								</span>
								<span>&nbsp;&nbsp;beautiful and fully responsive.</span>
							</h2>
							<p className="lead appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300" style={{ animationDelay: "300ms", maxWidth: "880px", margin: "10px auto" }}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce elementum, nulla vel pellentesque consequat, ante nulla hendrerit arcu, ac tincidunt mauris lacus sed leo.
							</p>
						</div>
					</div>
					<div className="row text-center mt-50">
						<div>
							<OwlCarousel className='owl-theme' loop margin={10} {...sliderSettings} nav>
								<div>
									<img src="assets/img/language/logo-1.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-2.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-3.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-4.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-5.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-6.png" alt="img not found" />
								</div>
							</OwlCarousel>
						</div>
					</div>
					<div className="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
						<div className="home-concept mt-50">
							<div className="container-flied">

								<div className="row text-center">
									<span className="sun"></span>
									<span className="cloud"></span>
									<div className="col-lg-2 ms-lg-auto">
										<div className="process-image">
											<img src="assets/img/GamesImg/ludo_images/home-concept-item-1.png" alt="" />
											<strong>Strategy</strong>
										</div>
									</div>
									<div className="col-lg-2">
										<div className="process-image process-image-on-middle">
											<img src="assets/img/GamesImg/ludo_images/home-concept-item-2.png" alt="" />
											<strong>Planning</strong>
										</div>
									</div>
									<div className="col-lg-2">
										<div className="process-image">
											<img src="assets/img/GamesImg/ludo_images/home-concept-item-3.png" alt="" />
											<strong>Build</strong>
										</div>
									</div>
									<div className="col-lg-4 ms-lg-auto">
										<div className="project-image">
											<div id="fcSlideshow" className="fc-slideshow">
												<ul className="fc-slides hero-carousel verticalFlip">
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-1.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-2.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-3.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-1.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-2.jpg" alt="" /></a></li>
												</ul>
											</div>
											<strong className="our-work">Our Work</strong>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="content-3" className="content-3 wide-60 content-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-70">
								<h2 className="h2-md">Get Ready to Be Surprised</h2>
								<p className="p-xl">To make it easy and convenient for you to play the game, we have added various features to the game.
								</p>
							</div>
						</div>
					</div>
					<div className="top-row pb-50">
						<div className="row d-flex align-items-center">
							<div className="col-md-5 col-lg-6">
								<div className="img-block left-column wow fadeInRight">
									<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
										<img className="img-fluid" src="../assets/img/GamesImg/bhabhi_offline_images/img-01.png"
											alt="content-image" />
									</ScrollAnimation>
								</div>
							</div>
							<div className="col-md-7 col-lg-6">
								<div className="txt-block right-column wow fadeInLeft">
									<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
										<h2 className="h2-xs">Bhabhi offline Game Features:</h2>
										<p className="p-lg">Interactive UI and animations effects to Bhabhi game.
										</p>
										<div className="txt-box">
											<ul className="simple-list">
												<li className="list-item">
													<p className="p-md">Leaderboard to get competition with worldwide players with Bhabhi. Google play center is helping to find out players' positions on the leaderboard.
													</p>
												</li>
												<li className="list-item">
													<p className="p-md">Bhabhi game offers the strategy elements like trick-taking card games and luck elements of Slot games, Daily reward Roulette with Crazy graphics.</p>
												</li>
												<li>
													<p className="p-md">Bhabhi card game brings this classic 4-player trick-taking card game to Google Play, with the high-quality graphics by Oengines Games.</p>
												</li>
											</ul>
										</div>
									</ScrollAnimation>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="techno-sec">
				<div className="over-techno">
					<div className="heading">
						<h2>Our Professionals are working on the following top-notch techs</h2>
						<p>We build the nexus of talent and innovation with Optimum oengines</p>
					</div>
					<div className="row align">
						<div className="col-md-2 box">
							<div className="nodejs wow fadeInUp">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/nodejs-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box">
							<div className="javascript  wow fadeInUp">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/reactjs-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box wow fadeInUp">
							<div className="rabbit">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/mean-stack.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box wow fadeInUp">
							<div className="mongodb">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/mongodb-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box wow fadeInUp">
							<div className="redis ">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/unity-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box  wow fadeInUp">
							<div className="socket">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/angular-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="service-section py-5">
				<div className="container">
					<div className="row justify-content-center py-3">
						<div className="col-md-8 col-12 text-center">
							<h2 className="service-main-heading">Our Features</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
							<div className="icon-box wow fadeInUp">
								<img src="../assets/img/GamesImg/icon/multi-table.png" alt="" />
								<p className="service-title"><a href="#">Classic Table.</a></p>
								<p className="service-para">Play the table and win the game before your opponents and take the top in world-wide leaderboard.</p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
							<div className="icon-box  wow fadeInUp">
								<img src="../assets/img/GamesImg/icon/multilingual.png" alt="" />
								<p className="service-title"><a href="#">Multi-Languages</a></p>
								<p className="service-para">We create a Bhabhi Game app/software/website supporting different regional and native languages for a better gaming experience. </p>
							</div>
						</div>

						<div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
							<div className="icon-box  wow fadeInUp">
								<img src="../assets/img/GamesImg/icon/dailybonus.png" alt="" />
								<p className="service-title"><a href="#">Daily Bonuse</a></p>
								<p className="service-para">Play the game Regularly and get Daily Bonus Points. </p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
							<div className="icon-box  wow fadeInUp">
								<img src="../assets/img/GamesImg/icon/videobonus.png" alt="" />
								<p className="service-title"><a href="#">Video Bonus </a></p>
								<p className="service-para">You can get Video Bonus Point also. </p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
							<div className="icon-box  wow fadeInUp">
								<img src="../assets/img/GamesImg/icon/customizerummysolution.png" alt="" />
								<p className="service-title"><a href="#">Customize Bhabhi Game Solution </a></p>
								<p className="service-para">Have a particular feature in mind? We will incorporate it into your Bhabhi Game according to your exact requisites.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="content-2a" className="content-2 pb-60 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-7 col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<h2 className="h2-xs">Make it simpler with Quick Commands</h2>
									<ul className="simple-list">
										<li className="list-item">
											<p className="p-lg">Fringilla risus, luctus mauris orci auctor euismod iaculis
												luctus
												magna purus pretium ligula purus undo quaerat tempor sapien rutrum
												mauris quaerat ultrice
											</p>
										</li>
										<li className="list-item">
											<p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
										</li>
										<li className="list-item">
											<p className="p-lg">Nemo ipsam egestas volute turpis dolores undo ultrice
												aliquam
												quaerat at sodales sapien purus
											</p>
										</li>
									</ul>
								</ScrollAnimation>
							</div>
						</div>

						<div className="col-md-5 col-lg-6">
							<div className="rel img-block left-column wow fadeInRight">
								<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
									<img className="img-fluid" src="../assets/img/GamesImg/bhabhi_offline_images/img-07.png"
										alt="content-image" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="Rummy-sec">
				<div className="container">
					<div className="over-techno">
						<div className="heading">
							<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
								<h2 className="wow fadeInUp">Bhabhi Card Games Variations</h2>
								<p className="wow fadeInUp">To make your Bhabhi Card game playing experience even better,
									use this Bhabhi Card game variations and enjoy more than before-</p>
							</ScrollAnimation>
						</div>
						<div className="types">
							<div className="row">
								<div className="col-md-6 changes">
									<div className="rummy-type">
										<div className="rummy-img">
											<img src="../assets/img/GamesImg/bhabhi_offline_images/Variations/1.png" alt=""
												className="wow fadeInUp" />
										</div>
										<div className="rummy-txt">
											<h5 className="wow fadeInUp">28 Card Rummy</h5>
											<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
												<p className="wow fadeInUp">28 card rummy is a variant of 21 cards Indian
													rummy where players play for points with pre-decided coin value. One
													player who makes the first valid declaration wins the game with 0
													points and wins all the coins based on the opponent's total points.
													It is one of the most popular and fastest variants of rummy games.
												</p>
											</ScrollAnimation>
										</div>
									</div>
								</div>
								<div className="col-md-6 changes">
									<div className="rummy-type">
										<div className="rummy-img">
											<img src="../assets/img/GamesImg/bhabhi_offline_images/Variations/2.png" alt=""
												className="wow fadeInUp" />
										</div>
										<div className="rummy-txt">
											<h5 className="wow fadeInUp">29 Card Rummy</h5>
											<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
												<p className="wow fadeInUp">29 card rummy is a variant of 21 cards Indian
													rummy where players play for points with pre-decided coin value. One
													player who makes the first valid declaration wins the game with 0
													points and wins all the coins based on the opponent's total points.
													It is one of the most popular and fastest variants of rummy games.
												</p>
											</ScrollAnimation>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="faqs-2" className="pb-60 faqs-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-80">
								<h2 className="h2-md">Frequently Asked Question? </h2>

							</div>
						</div>
					</div>
					<div className="faqs-2-questions">
						<div className="row row-cols-1 row-cols-lg-2">
							<div className="col">
								<div className="questions-holder pr-15">
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">How Much Time Does It Take to Develop a Bhabhi Cards Game?</h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">How Much Time Does It Take to Develop a Bhabhi Cards Game? </p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Do you provide continued maintenance support for Bhabhi Cards Game mobile game development? </h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">Yes, we provide you with continuous maintenance support for Bhabhi Cards Game development. Even after the development, you can opt for the monthly retention plan.
											</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Will You Provide Both the Bhabhi Cards Game Website & Mobile App Development Services? </h5>
										</ScrollAnimation>
										<ul className="simple-list">
											<li className="list-item">
												<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
													<p className="p-lg">Yes, we offer a range of Bhabhi Cards Game development services including website development, mobile app development, and software development.
													</p>
												</ScrollAnimation>
											</li>
										</ul>
									</div>

								</div>
							</div>
							<div className="col">
								<div className="question wow fadeInUp">
									<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
										<h5 className="h5-md">What is the process of Bhabhi Cards Game development? </h5>
									</ScrollAnimation>
									<ul className="simple-list">
										<li className="list-item">
											<ScrollAnimation animateIn="animate__animated animate__fadeInUp">											<p className="p-lg">The process starts with the analysis of your idea and requirements. Then begins the customization and development process. After that, all the functions and functionality are added to the app. Once the app is ready, it's time for beta testing. After several tests, the app is ready to be launched.
											</p>
											</ScrollAnimation>
										</li>
									</ul>
								</div>
								<div className="question wow fadeInUp">
									<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
										<h5 className="h5-md">Do You Provide Customized Bhabhi Cards Game Development Services? </h5>
									</ScrollAnimation>
									<ul className="simple-list">
										<li className="list-item">
											<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
												<p className="p-lg">Of course, YES. We offer 100% customized gaming solutions that meet clients’ requirements and even exceed them. No matter what kind of gaming solutions you need, our team of talented professionals is capable of fulfilling your needs. </p>
											</ScrollAnimation>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12 text-center pt-25">

						</div>
					</div>
				</div>
			</section>

			<section className="variation-main">
				<div className="container">
					<div className="variation-inner">
						<div className="heading">
							<h2>Bhabhi Offline Games Screen Shot and Reviews</h2>
							<p>We provide complete and advanced Bhabhi card game development services at our Oengines Games to reach the targeted audience base.</p>
						</div>
						<OwlCarousel items={3} margin={8} autoplay={true} loop>

							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/bhabhi_offline_images/Screen_shot/1.png" alt="Img not found" />
									<h5>1. Sunny Singh</h5>
									<p className="set-height">Great👌</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/bhabhi_offline_images/Screen_shot/2.png" alt="Img not found" />
									<h5>2. Mohsin Abbas</h5>
									<p className="set-height">Good game</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/bhabhi_offline_images/Screen_shot/3.png" alt="Img not found" />
									<h5>3. Manikumar12 Manikumar12</h5>
									<p className="set-height">Superb game</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/bhabhi_offline_images/Screen_shot/4.png" alt="Img not found" />
									<h5>4. Rahul Dholiya</h5>
									<p className="set-height">real getaway card's game</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/bhabhi_offline_images/Screen_shot/5.png" alt="Img not found" />
									<h5>5. Rahul sharma</h5>
									<p className="set-height">Very good game appreciated well especially by we punjabis . This should be connected to facebook soon so that 4 people sitting at father places can relish the experience online so do consider the advice bhabhi thulla zindabaad.</p>
								</div>
							</div>
						</OwlCarousel>
					</div>
				</div>
			</section>

			<section className="Games" id="gaming">
				<ReactCardFlip isFlipped={isFlipped2} flipDirection="horizontal" style={{ height: "auto" }}>
					<div className="game-change" id="chng" onClick={handleClick} style={{ height: "auto" }}>
						<img src="../assets/img/GamesImg/bhabhi_offline_images/dashboard-07.png" alt="img not found" className="changes" />
						<img src="../assets/img/GamesImg/bhabhi_offline_images/playbutton.png" alt="" className="img-chng" />
					</div>
					<div className="run-games"></div>
				</ReactCardFlip>
			</section>

			<section id="cta-9" className="bg-01 wide-80 cta-section division">
				<div className="container">
					<div className="row justify-content-md-center">
						<div className="col col-lg-8">
							<div className="cta-9-txt text-center">
								<h2 className="h2-xs white-color">Getting Started is Fast & Easy</h2>
								<p className="p-xl white-color mb-35">Download Bhabhi Card Game on your Windows or Mac
									and get 30 days free trial.</p>
								<div className="d-flex justify-content-center gap-4">
									{
										gameDetails.android_link ?
											<a target="_blank" href={gameDetails.android_link} className="os-btn bg-white d-flex align-items-center">
												<div className="os-btn-ico">
													<div className="ico-50">
														<img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
													</div>
												</div>
												<div className="os-btn-txt">
													<h6 className="h6-lg">Download for Android</h6>
												</div>
											</a>
											:
											''
									}

									{
										gameDetails.ios_link ? <a target="_blank" href={gameDetails.ios_link} className="os-btn mac-os-btn bg-white d-flex align-items-center">
											<div className="os-btn-ico">
												<div className="ico-50">
													<img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
												</div>
											</div>

											<div className="os-btn-txt">
												<h6 className="h6-lg">Download for IOS</h6>
											</div>
										</a> : ''
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="pb" id="pb"></div>
			<div className="pc">
				<div className="ol">
					<a href="#" id="btn-back-to-top">
						<i className="fa-solid fa-arrow-up">
						</i>
					</a>
				</div>
				<div className="bg"></div>
			</div>

			<Footer />
		</div>
	);
}
