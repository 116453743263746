import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from 'react-helmet';
import Header from "./Gameheader";
import Footer from '../over_games/Footer';
import OwlCarousel from 'react-owl-carousel';
import CountUp from 'react-countup';
import '../assets/css_game/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import '../assets/css_game/dropdown-effects/fade-down.css';
import config from '../config.json';
var settings = {
	dots: false,
	slidestoshow: 3,
	slidestoscroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
	initialslide: 0,
	responsiveClass: true,
	responsive: {
		0: {
			items: 1,
			nav: true
		},
		767: {
			items: 2,
			nav: true
		},
		1000: {
			items: 3,
			nav: true,
			loop: true
		}

	}
};
const sliderSettings = {
	dots: false,
	items: 5,
	slidestoscroll: 1,
	autoplay: true,
	interval: 5000,
	initialslide: 0,
	autoplaytimeout: 8000,
	autoplaySpeed: 2000,
	responsive: {
		0: {
			items: 2,
			nav: false
		},
		600: {
			items: 3,
			nav: false
		},
		1000: {
			items: 4,
			nav: false,
		}
	}
}
export default function Newrummy() {

	const [gameDetails, setGame] = useState({});
	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		axios.get(config.GAME_DETAILS_FOR_PAGES_API+Pathname).then((res) => {
			console.log("callbreak Game Data Data is more games::::::::", res.data);
			if (res.data && res.data.length){
				setGame(res.data[0]);
			}				
		})
	}, []);

	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<link id="googleFonts" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800%7CShadows+Into+Light&amp;display=swap" rel="stylesheet" type="text/css"></link>
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			<Header />
			<section id="hero-23" className="bg-scroll new-rummy hero-section division">
				<div className="container">
					<div className="row mt-md-4">
						<div className="col-md-12 col-lg-8 arcade-col">
							<div className="hero-17-title card-hero white-color mb-30 pt-0">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl text-md-start text-lg-start">Rummy Game</h2>
								</ScrollAnimation>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6 arcade-col">
							<div className="hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color text-md-start text-lg-start">Enhance your Gaming experience with the Rummy Game
										Development offered by us. Our Developers build games for both web and mobiles
										including all app platforms. Our aim is to provide you with all the services
										that will meet your needs and requirements in a professional manner. Hire our
										Rummy Game Developers with their rich experience and skills to have the best
										Rummy Game Development services in India.
									</p>
								</ScrollAnimation>
								{
									gameDetails.android_link ?
										<a target="_blank" href={gameDetails.android_link} className="os-btn bg-white d-flex align-items-center">
											<div className="os-btn-ico">
												<div className="ico-50">
													<img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
												</div>
											</div>
											<div className="os-btn-txt">
												<h6 className="h6-lg">Download for Android</h6>
											</div>
										</a>
										:
										''
								}

								{
									gameDetails.ios_link ? <a target="_blank" href={gameDetails.ios_link} className="os-btn mac-os-btn bg-white d-flex align-items-center">
										<div className="os-btn-ico">
											<div className="ico-50">
												<img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
											</div>
										</div>

										<div className="os-btn-txt">
											<h6 className="h6-lg">Download for IOS</h6>
										</div>
									</a> : ''
								}
								<ul className="advantages white-color clearfix mt-30">
									<li className="first-li">
										<p>Version 2.10.074</p>
									</li>

								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6">
							<div className="hero-17-img video-preview">
								<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
									<img className="img-fluid" src="../assets/img/GamesImg/Rummy_images/dashboard-04.png" alt="hero-image" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
				<div className="wave-shape-bottom">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 195"><path fill="#ffffff" fillOpacity="1" d="M0,192L1440,96L1440,320L0,320Z"></path></svg>
				</div>
			</section>

			<section className="accompany-area ptb-100">
				<div className="container">
					<div className="section-title">
						<h2>Rummy Games Variations</h2>
						<p>To make your Rummy game playing experience even better, use this Rummy game variations and enjoy more than before-</p>
					</div>

					<div className="tab accompany-list-tab">
						<ul className="tabs">
							<li>
								<a href="#">
									<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(10).png" alt="" className="wow fadeInUp" />
									<span>Deals Card Rummy</span>
								</a>
							</li>
							<li>
								<a href="#">
									<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(12).png" alt="" className="wow fadeInUp" />
									<span>Pool Card Rummy</span>
								</a>
							</li>
							<li>
								<a href="#">
									<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(14).png" alt="" className="wow fadeInUp" />
									<span>13 Card Rummy</span>
								</a>
							</li>
							<li>
								<a href="#">
									<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(13).png" alt="" className="wow fadeInUp" />
									<span>21 Card Rummy</span>
								</a>
							</li>
							<li>
								<a href="#">
									<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(11).png" alt="" className="wow fadeInUp" />
									<span>27 Card Rummy</span>
								</a>
							</li>
						</ul>

						<div className="tab_content">
							<div className="tabs_item">
								<div className="row align-items-center">
									<div className="col-lg-6 col-md-6">
										<div className="accompany-image">
											<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(10).png" alt="" className="wow fadeInUp" />
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="accompany-content">
											<h2>Deals Card Rummy</h2>
											<p>Deals Rummy is another variant of the 13 card
												Rummy game & Indian Rummy where a fixed number of cards are dealt
												with each player. At the start of an online deals rummy game, all
												players are given a fixed amount of coins to play with. At the end
												of a deals rummy game, the player with the higher coins count wins
												the game.</p>
										</div>
									</div>
								</div>
							</div>

							<div className="tabs_item">
								<div className="row align-items-center">
									<div className="col-lg-6 col-md-6">
										<div className="accompany-image">
											<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(12).png" alt="" className="wow fadeInUp" />
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="accompany-content">
											<h2>Pool Card Rummy</h2>
											<p>Pool Rummy is a variant of 13 cards Indian rummy
												where players pay a fixed entry fee which forms the prize pool. A
												player is eliminated once their score reaches the maximum limit of
												points on the table eg: 101 Points Pool or 201 Points Pool.</p>
										</div>
									</div>
								</div>
							</div>

							<div className="tabs_item">
								<div className="row align-items-center">
									<div className="col-lg-6 col-md-6">
										<div className="accompany-image">
											<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(14).png" alt="" className="wow fadeInUp" />
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="accompany-content">
											<h2>13 Card Rummy</h2>
											<p>13 Card rummy is a variant of 13 cards Indian
												rummy where players play for points with pre-decided coin value. One
												player who makes the first valid declaration wins the game with 0
												points and wins all the coins based on the opponent's total points.
												It is one of the most popular and fastest variants of 2829 games.</p>
										</div>
									</div>
								</div>
							</div>

							<div className="tabs_item">
								<div className="row align-items-center">
									<div className="col-lg-6 col-md-6">
										<div className="accompany-image">
											<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(13).png" alt="" className="wow fadeInUp" />
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="accompany-content">
											<h2>21 Card Rummy</h2>
											<p>1 card rummy is a variant of 21 cards Indian
												rummy where players play for points with pre-decided coin value. One
												player who makes the first valid declaration wins the game with 0
												points and wins all the coins based on the opponent's total points.
												It is one of the most popular and fastest variants of rummy games.</p>
										</div>
									</div>
								</div>
							</div>

							<div className="tabs_item">
								<div className="row align-items-center">
									<div className="col-lg-6 col-md-6">
										<div className="accompany-image">
											<img src="../assets/img/GamesImg/Rummy_images/Variations/MicrosoftTeams-image(11).png" alt="" className="wow fadeInUp" />
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="accompany-content">
											<h2>27 Card Rummy</h2>
											<p>27 card rummy is a variant of 27 cards Indian
												rummy where players play for points with pre-decided coin value. One
												player who makes the first valid declaration wins the game with 0
												points and wins all the coins based on the opponent's total points.
												It is one of the most popular and fastest variants of 2829 games.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="ctr-area">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-6">
							<div className="ctr-content">
								<h2>More than 5,000 users around the world are already using Rummy
									actively</h2>
							</div>
						</div>

						<div className="col-lg-6 col-md-6">
							<div className="ctr-btn new-cart-btn">
								<div className="statistic-block wow fadeInUp">
									<h2 className="h2-title-xs statistic-number"><span className="count-element text-white"><CountUp end={5000} duration={10} /></span>
									</h2>
									<p className="p-lg mt-20 text-white">Active downloads <br /> from the community</p>
								</div>
								<div>
									<h2 className="h2-title-xs statistic-number text-white">
										<span className="count-element"><CountUp end={3} duration={5} /></span>.<span className="count-element "><CountUp end={3} duration={5} /></span>
									</h2>
									<p className="p-lg mt-20  txt-400 text-white">33 Rating</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="conversational-area pb-50 coversation">
				<div className="container">
					<div className="row align-items-center mt-40">
						<div className="col-lg-6">
							<div className="conversational-content">
								<h2 className="h2-xs">Rummy Game Development Services</h2>
								<div className="txt-block right-column wow fadeInLeft">

									<p className="p-lg mt-50 text-white">Our Company is a leading Rummy game Development Company in India.
										Our team of Rummy game Developers like Designers and programmers strive to give
										you the best Rummy game software in terms of quality. Our rummy game developers
										specifically keep clients in the loop throughout the game process to ensure that
										the clients are highly satisfied with the results. We use advanced technologies
										to create a game that supports android, iOS, Desktop & all other platforms. Our
										developers make sure that you and your audience receive memorable hands-on
										experiences. For that, our passionate gaming coders make use of the latest
										mobile technology.
									</p>
									<p className="p-lg text-white">Adept Rummy game providers are working hard on different Rummy game
										app solutions consisting of varied options and blends of features,
										functionalities, modules, etc. to perfectly deliver you the best idea for rummy
										game app development. We will provide you with a rich-featured app that will
										help you to gain more rummy users. Set your Rummy game to a level higher than
										other Rummy game developers with us.
									</p>
								</div>
							</div>
						</div>

						<div className="col-lg-6">
							<div className="conversational-image">
								<div className="rel img-block left-column wow fadeInRight">
									<img className="img-fluid new-img" src="../assets/img/GamesImg/Rummy_images/img-10.png" alt="content-image" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="shape">
					<img src="assets/img/shape/white-shape1.png" alt="image" />
				</div>
			</section>
			<section id="content-9" className="content-9 bg-whitesmoke-gradient pt-100 content-section division">
				<div className="container">
					<div className="img-block">
						<div className="row about-rummy">
							<h2 className="heading text-center mb-40">What is an Online Rummy Game?</h2>
							<div className="col-lg-6">
								<div className="about-rummy">
									<p>Rummy is a popular Indian card game played with sets of playing cards. It
										comes under the draw and discards card games category. It is a game of skill
										that usually requires 1 or 2 decks of cards. The rummy game’s primary
										objective is to improve your hand by dealing with sets of cards and forming
										a particular sequence or set adhering to specific rules of the game. This
										Online Rummy game is a Multiplayer card game, usually played between a group
										of 2 to 6 people where each player has to draw and discard one card, in
										turn, until the thirteen cards form a sequence in sets. A player who manages
										to build the successful sequence to card before anyone will be declared as
										the winner.</p>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="content-9-img video-preview wow fadeInUp v-change">
									<a className="video-popup1" href="https:www.youtube.com/embed/SZEflIVnhH8" height="300" width="400">
										<div className="video-btn video-btn-xl bg-red ico-90">
											<div className="video-block-wrapper"><span className="flaticon-play-button"></span></div>
										</div>
									</a>

									<img className="img-fluid" src="../assets/img/GamesImg/Rummy_images/dashboard-07.png"
										alt="video-preview" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>



			<section className="space-bottom">
				<div className="container">
					<div className="row text-center pt-4">
						<div className="col">
							<h2 className="word-rotator slide font-weight-bold text-8 mb-25">
								<span>Porto is&nbsp;</span>
								<span className="word-rotator-words bg-primary" style={{ padding: "0 20px 0 0" }}>
									<span className="spinny-words text-start">
										<span>incredibly</span>
										<span>especially</span>
										<span>extremely</span>
										<span>incredibly</span>
										<span>especially</span>
										<span>extremely</span>
									</span>
								</span>
								<span>&nbsp;&nbsp;beautiful and fully responsive.</span>
							</h2>
							<p className="lead appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300" style={{ animationDelay: "300ms", maxWidth: "880px", margin: "10px auto" }}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce elementum, nulla vel pellentesque consequat, ante nulla hendrerit arcu, ac tincidunt mauris lacus sed leo.
							</p>
						</div>
					</div>
					<div className="row text-center mt-50">
						<div>
							<OwlCarousel className='owl-theme' loop margin={10} {...sliderSettings} nav>
								<div>
									<img src="assets/img/language/logo-1.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-2.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-3.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-4.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-5.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-6.png" alt="img not found" />
								</div>
							</OwlCarousel>
						</div>
					</div>
					<div className="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
						<div className="home-concept mt-50">
							<div className="container-flied">

								<div className="row text-center">
									<span className="sun"></span>
									<span className="cloud"></span>
									<div className="col-lg-2 ms-lg-auto">
										<div className="process-image">
											<img src="assets/img/GamesImg/ludo_images/home-concept-item-1.png" alt="" />
											<strong>Strategy</strong>
										</div>
									</div>
									<div className="col-lg-2">
										<div className="process-image process-image-on-middle">
											<img src="assets/img/GamesImg/ludo_images/home-concept-item-2.png" alt="" />
											<strong>Planning</strong>
										</div>
									</div>
									<div className="col-lg-2">
										<div className="process-image">
											<img src="assets/img/GamesImg/ludo_images/home-concept-item-3.png" alt="" />
											<strong>Build</strong>
										</div>
									</div>
									<div className="col-lg-4 ms-lg-auto">
										<div className="project-image">
											<div id="fcSlideshow" className="fc-slideshow">
												<ul className="fc-slides hero-carousel verticalFlip">
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-1.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-2.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-3.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-1.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-2.jpg" alt="" /></a></li>
												</ul>
											</div>
											<strong className="our-work">Our Work</strong>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="ptb--6 overflow-hidden">
				<div className="cv-section cv-gaming-services">
					<div className="cv-gaming-banner">
						<div className="cv-gaming-banner-inner">
							<h4 className="mb-3">Rummy Games</h4>
							<p>Easier and more convenient place for you to buy or develop the rummy online game.</p>
							<div className="d-inline-block">
								<a href="#" className="">
									<button className="os-btn-ico text-white me-4 os-new-btn-ico"
										style={{ padding: "10px 10px", backgroundColor: "00000", border: "none", borderRadius: "20px", padding: "10px 23px 10px 23px" }}>
										<div className="d-flex">
											<i className="fa-brands fa-google-play text-white me-2" style={{ fontSize: "27px", lineHeight: "1.5" }}></i>
											<div className="d-grid" style={{ fontSize: "13px" }}>
												<span>Download on the</span>
												<span style={{ fontWeight: "600" }}>Google Play</span>
											</div>
										</div>
									</button>
								</a>
								<a href="#" className="">
									<button className="os-btn-ico text-white os-new-btn-ico"
										style={{ padding: "10px 10px", backgroundColor: "00000", border: "none", borderRadius: "20px", padding: "10px 23px 10px 23px" }}>
										<div className="d-flex">
											<i className="fa-brands fa-apple text-white  me-2" style={{ fontSize: "32px", lineHeight: "1.5" }}></i>
											<div className="d-grid" style={{ fontSize: "13px" }}>
												<span>Download on the</span>
												<span style={{ fontWeight: "600" }}>App Store</span>
											</div>
										</div>
									</button>
								</a>
							</div>

							<div className="cv-gaming-img cv-img-ruler">
								<div className="Demo">
									<img src="../assets/img/GamesImg/gin_rummy_images/light_effects.png" alt="not found" />
									<div className="demo-1">
										<img src="../assets/img/GamesImg/gin_rummy_images/Color-Circal.png" alt="not found" />
									</div>
									<div className="demo-3">
										<img src="../assets/img/GamesImg/gin_rummy_images/SPINfarem_.png" alt="not found" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="content-3" className="content-3 wide-60 content-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-70">
								<h2 className="h2-md">Get Ready to Be Surprised</h2>
								<p className="p-xl"> Easier and more convenient place for you to buy or develop the rummy online game.
								</p>
							</div>
						</div>
					</div>
					<div className="top-row">
						<div className="row d-flex align-items-center">
							<div className="col-md-5 col-lg-6">
								<div className="img-block left-column wow fadeInRight">
									<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
										<img className="img-fluid" src="../assets/img/GamesImg/Rummy_images/img-01.png" alt="content-image" />
									</ScrollAnimation>
								</div>
							</div>
							<div className="col-md-7 col-lg-6">
								<div className="txt-block right-column wow fadeInLeft">
									<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
										<h2 className="h2-xs">Online Rummy multiplayer Game Features</h2>

										<div className="txt-box">
											<ul className="simple-list">
												<li className="list-item ">
													<b className="p-md">Graphical - user friendly interfaces :</b>
													<span>- UI role is most important to develop the game, app, or web development, we are committed to designing the high-end assets for you. </span>

												</li>
												<li className="list-item">
													<b className="p-md">Live Game Concepts :</b>
													<span>- Our expert resources make a smarter computing engine to send real-time updates to all players of the rummy card game. </span>
												</li>
												<li>
													<b className="p-md">Security System :</b>
													<span>- We make custom encryption algorithms for every game to secure the data and transform securely players' activities on rummy card games.</span>
												</li>
												<li>
													<b className="p-md">Cross-platform :</b>
													<span>- We are working on the "write once and run anywhere" concept, we are developing Android, iOS, and Web development like rummy card game. </span>
												</li>
												<li>
													<b className="p-md">Peer to Peer Gaming Experience :</b>
													<span>- Enjoy the thrilling experience of the rummy game with your friends or other rummy card game players from around the world in online mode.</span>
												</li>
												<li>
													<b className="p-md">Peer to Peer Gaming Experience :</b>
													<span>- Our rummy game come with built-in support for multiple currencies. We provide transaction flexibility to users by incorporating both fiat and cryptocurrencies.</span>
												</li>
												<li>
													<b className="p-md">Peer to Peer Gaming Experience :</b>
													<span>- we have ready lot of variation on rummy card game like, deal, pool, 13 cards, 21 cards, 27 cards etc.</span>
												</li>
												<li>
													<b className="p-md">Peer to Peer Gaming Experience :</b>
													<span>- We are providing the every day coin bonus features to the players without any in-app purchases.</span>
												</li>
											</ul>
										</div>
									</ScrollAnimation>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="techno-sec">
				<div className="over-techno">
					<div className="heading">
						<h2>Our Professionals are working on the following top-notch techs</h2>
						<p>We build the nexus of talent and innovation with Optimum oengines</p>
					</div>
					<div className="row align">
						<div className="col-md-4 col-sm-6 col-lg-3 col-xl-2 box">
							<div className="nodejs wow fadeInUp">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/nodejs-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-lg-3 col-xl-2 box">
							<div className="javascript  wow fadeInUp">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/reactjs-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-lg-3 col-xl-2 box wow fadeInUp">
							<div className="rabbit">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/mean-stack.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-lg-3 col-xl-2 box wow fadeInUp">
							<div className="mongodb">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/mongodb-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-lg-3 col-xl-2 box wow fadeInUp">
							<div className="redis ">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/unity-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-4 col-sm-6 col-lg-3 col-xl-2 box  wow fadeInUp">
							<div className="socket">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/angular-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="content-2a" className="content-2 pb-60 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-7 col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<h2 className="h2-xs">Make it simpler with Quick Commands</h2>
									<ul className="simple-list">
										<li className="list-item">
											<p className="p-lg">Fringilla risus, luctus mauris orci auctor euismod iaculis
												luctus
												magna purus pretium ligula purus undo quaerat tempor sapien rutrum
												mauris quaerat ultrice
											</p>
										</li>
										<li className="list-item">
											<p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
										</li>
										<li className="list-item">
											<p className="p-lg">Nemo ipsam egestas volute turpis dolores undo ultrice
												aliquam
												quaerat at sodales sapien purus
											</p>
										</li>
									</ul>
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-5 col-lg-6">
							<div className="rel img-block left-column wow fadeInRight">
								<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
									<img className="img-fluid" src="../assets/img/GamesImg/Rummy_images/img-07.png" alt="content-image" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="faqs-2" className="pb-60 faqs-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-80">
								<h2 className="h2-md">Frequently Asked Question? </h2>

							</div>
						</div>
					</div>
					<div className="faqs-2-questions">
						<div className="row row-cols-1 row-cols-lg-2">
							<div className="col">
								<div className="questions-holder pr-15">
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">How Much Time Does It Take to Develop a Rummy Game? </h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">Rummy game development is a complex process, so it will take between six months and a year. </p>
										</ScrollAnimation>
									</div>

									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Do you provide continued maintenance support for Rummy mobile game development? </h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">Yes, We provide you with continuous maintenance support for rummy game development. Even after the development, you can opt for the monthly retention plan.</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Is Rummy legal in India?
											</h5>
										</ScrollAnimation>
										<ul className="simple-list">
											<li className="list-item">
												<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
													<p className="p-lg">Rummy is a skill-based game requiring a level of strategy, tactic, knowledge, and experience, so it is legal. Other luck-based games are illegal in India.
													</p>
												</ScrollAnimation>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="questions-holder pl-15">
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Can I Access Our Rummy Game Admin Panel Using Mobile
												Devices?</h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">You can access the admin panel of the hearts game on your
												PC, mobile devices seamlessly. Login credentials are required only.
											</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Where Do You Store All Data of This Rummy Game?</h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg"> The answer to this qusestion completely depends upon you. We
												prefer to store your Rummy game data on highly secured gaming servers.
												If you have any recommendations for the gaming server, then you may
												discuss them with us and we will store the data on the chosen server.
											</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md"> Will You Provide Both The Rummy Game Website & Mobile App
												Development Services?</h5>
										</ScrollAnimation>
										<ul className="simple-list">
											<li className="list-item">
												<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
													<p className="p-lg">Yes, we offer a range of Rummy game development services including website development, mobile app development, and software development.</p>
												</ScrollAnimation>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 text-center pt-25">
						</div>
					</div>
				</div>
			</section>
			<section className="management-area ptb-100 pt-30">
				<div className="container">
					<div className="section-title">
						<span>Reviews</span>
						<h2>Online Rummy Game Reviews</h2>
						<p>We provide complete and advanced Rummy game development services at our Oengines Games to reach the targeted audience base.</p>
					</div>

					<OwlCarousel items={3}
						className="owl-theme management-slider"
						loop
						autoplay={true}
						margin={0} {...settings} >

						<div className="management-item">
							<div className="image">
								<img src="../assets/img/GamesImg/Rummy_images/Screen_shot/rumm-game-7.png" alt="Img not found" />
							</div>
							<h3>1. Jason walker</h3>
							<p>We provide complete and advanced Rummy game development services at our Oengines Games to reach the targeted audience base.</p>
						</div>

						<div className="management-item">
							<div className="image">
								<img src="../assets/img/GamesImg/Rummy_images/Screen_shot/rummy-game-6.png" alt="Img not found" />
							</div>
							<h3>2. D.S</h3>
							<p>It's best game ever I played.</p>
						</div>

						<div className="management-item">
							<div className="image">
								<img src="../assets/img/GamesImg/Rummy_images/Screen_shot/rummy-game-5.png" alt="Img not found" />
							</div>
							<h3>3. Gamer roy</h3>
							<p>Superb game.... Awesome graphics and animations also</p>
						</div>

						<div className="management-item">
							<div className="image">
								<img src="../assets/img/GamesImg/Rummy_images/Screen_shot/rummy-game-4.png" alt="Img not found" />
							</div>
							<h3>4. Ali Khan</h3>
							<p>Goood....game...</p>
						</div>

						<div className="management-item">
							<div className="image">
								<img src="../assets/img/GamesImg/Rummy_images/Screen_shot/rummy-game-3.png" alt="Img not found" />
							</div>
							<h3>5. Lingraj</h3>
							<p>I am very much like this. It is so interesting to play and has a great user manual. I will recommend this to others.</p>
						</div>


					</OwlCarousel>
				</div>
			</section>

			<section id="cta-9" className="bg-01 wide-80 cta-section division">
				<div className="container">
					<div className="row justify-content-md-center">
						<div className="col col-lg-8">
							<div className="cta-9-txt text-center">
								<h2 className="h2-xs white-color">Getting Started is Fast & Easy</h2>
								<p className="p-xl white-color mb-35">Download Rummy on your Windows or Mac and get 30
									days free trial.</p>
								<div className="d-flex justify-content-center gap-4">
									{
										gameDetails.android_link ?
											<a target="_blank" href={gameDetails.android_link} className="os-btn bg-white d-flex align-items-center">
												<div className="os-btn-ico">
													<div className="ico-50">
														<img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
													</div>
												</div>
												<div className="os-btn-txt">
													<h6 className="h6-lg">Download for Android</h6>
												</div>
											</a>
											:
											''
									}

									{
										gameDetails.ios_link ? <a target="_blank" href={gameDetails.ios_link} className="os-btn mac-os-btn bg-white d-flex align-items-center">
											<div className="os-btn-ico">
												<div className="ico-50">
													<img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
												</div>
											</div>

											<div className="os-btn-txt">
												<h6 className="h6-lg">Download for IOS</h6>
											</div>
										</a> : ''
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>

	);
}
