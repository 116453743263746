import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-awesome-slider/dist/styles.css';
import axios from 'axios';
import moment from 'moment';
import Footer from "../Footer";
import Header from '../Header';
import DOMPurify from 'isomorphic-dompurify';

class BlogDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
            blog: {},
            categories: [],
            tags: []
        }
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
    }
    componentDidMount() {
  
        axios.get("https://www.oengines.com/careers")
            .then(response => {                       
                this.setState({Job: response.data});
                this.setState({Description: response.data[0].description});
                
            })
            .catch(function (error) {
                console.log(error);
        })
       
        const Blogdata = JSON.parse(localStorage.getItem('Blog'));  
        console.log("blogdetsils data is::",Blogdata);
        this.setState({blog: Blogdata});           
    }
    onClickShowdetails(job){       
        console.log("job is:::::::",job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';    
    }
    render() {

        const myStyleone = {
            backgroundImage:
                "url('../assets/img/bg/breadcumb-bg.jpg')",
            width: "100%",
            zIndex: 2,
        };
        var Information;
            if(this.state.Job.length){
            Information = this.state.Job.map((job,Index)=>       
                <>                  
                    <li>
                        <a onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>                                          
                </>
            )
            }else{
                Information = <div><h4 style={{color: "Red", fontSize: "20px"}}>No Record Found !</h4></div>;
            }
        var BlogInfo;
            console.log("this.state.blog:::::::::",this.state.blog);
            if(this.state.blog != ''){
                BlogInfo =    
                    <>            
                    <div className="as-blog blog-single has-post-thumbnail">   
                        <div className="blog-img as-carousel" data-arrows="true" data-slide-show="1" data-fade="true">                          
                            <a  > 
                                <img src={`${this.state.blog.blog_img}`} alt="Blog Image"/>
                            </a>                                                                  

                        </div>
                                
                        <div className="blog-content blogdetsils-content" >
                            <div className="blog-meta"><a className="author" ><img
                                        src="assets/img/blog/author-1-1.png" alt="avater"/> By {this.state.blog.employee_name}</a> <a
                                    ><i className="fa-light fa-calendar-days"></i>{moment(this.state.blog.createdat).format('YYYY-MM-DD')}</a> 
                            </div>
                            <h2 className="blog-title" style={{fontSize: "29px"}}><a >{this.state.blog.blogtitle}</a></h2>
                                <div className="box">
                                    <div className="content-full article " id="article" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.blog.content) }}></div> 
                                </div>                                
                                    
                        </div>
                    </div>
                    </>
                
                }else{
                    BlogInfo = <div><h4 style={{color: "Red", fontSize: "20px"}}>No Record Found !</h4></div>;
                }
        var Blogcategories;
            if(this.state.Job != ''){
            Blogcategories = 
                <>
                    <ul>
                        <li><a href="/blog">{this.state.blog.blogcategorie}</a></li>                    
                    </ul>                                                 
                </>
            }else{
                Blogcategories = <div><h4 style={{color: "Red", fontSize: "20px"}}>No Record Found !</h4></div>;
            }
            
            var Tages;
            var result = `${this.state.blog.blogtags}`;
            var arr = result.split(',');
            if(arr.length){
                console.log("ente in ",arr);
                Tages = arr.map((tag,Index)=>       
                    <>
                        <a >{tag}</a>                                                            
                    </>
                )
            }else{
                Tages = <div><h4 style={{color: "Red", fontSize: "20px"}}>No Record Found !</h4></div>;
            }
        var blogrecentpost;
        if(this.state.blog != ''){
            blogrecentpost = 
                <>
                     <div className="recent-post">
                        <div className="media-img"><a href="#!">
                            <img src={`${this.state.blog.blog_img}`} alt="Blog Image" className="rec_post"/></a></div>
                        <div className="media-body">
                            <h4 className="post-title"><a className="text-inherit"
                                    href="#!">{this.state.blog.blogtitle}</a>
                            </h4>
                            <div className="recent-post-meta"><a ><i
                                        className="fal fa-calendar-days"></i>{moment(this.state.blog.createdat).format('YYYY-MM-DD')}</a></div>
                        </div>
                    </div>    
                </>
        }
        
        return (
            <div>
                <Helmet>
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />                    
                    <script src="https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js"></script>                
                    <script src="../assets/js/script.js" type="text/Javascript"></script>                    
                </Helmet>
                {/* <div className="loader" ><span>Loading...</span></div> */}
                <Header />              
                <section className="as-blog-wrapper blog-details space-top space-extra-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-8 col-lg-7">                               
                                    {BlogInfo}                               
                            </div>
                            <div className="col-xxl-4 col-lg-5">
                                <aside className="sidebar-area">                                   
                                    <div className="widget widget_tag_cloud">
                                        <h3 className="widget_title new_widget_title">Popular Tags</h3>
                                        <div className="tagcloud">
                                            {Tages}
                                        </div>
                                    </div>
                                    <div className="widget">
                                        <h3 className="widget_title">Recent Posts</h3>
                                        <div className="recent-post-wrap">
                                           {blogrecentpost}                                           
                                        </div>
                                    </div>
                                  
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>     
                <Footer />
            </div>
        )
    }
}

export default BlogDetails;
