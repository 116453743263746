import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import Header from "./Gameheader";
import Footer from "../over_games/Footer";
import '../assets/css_game/style.css';
import CountUp from 'react-countup';
import ScrollAnimation from 'react-animate-on-scroll';
import '../assets/css_game/dropdown-effects/fade-down.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from "axios";
import config from '../config.json';

export default function Ginrummy() {

    const [gameDetails, setGame] = useState({});
    useEffect(() => {
        var str = window.location.pathname;
		let Pathname = str.substring(1);
        axios.get(config.GAME_DETAILS_FOR_PAGES_API+Pathname).then((res) => {
            if (res.data && res.data.length){
                setGame(res.data[0]);
            }                
        })
    }, []);

    return (
        <div>
            <Helmet>
                <link id="googleFonts" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800%7CShadows+Into+Light&amp;display=swap" rel="stylesheet" type="text/css"></link>
                <link rel="stylesheet" href="../assets/css_game/style.css" />
            </Helmet>
            <Header />
            <section id="hero-17" className="bg-scroll rel hero-section division">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 col-lg-8">
                            <div className="hero-17-title white-color mb-30">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
                                    <h2 className="h2-xl">Carrom Board Game </h2>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-5">
                            <div className="hero-17-txt">

                                <p className="p-xl white-color">OEngines Games LLP is a leading Carrom game development company worldwide. Our expert
                                    carrom developers are also game enthusiasts and with our latest tools and technologies, we
                                    develop the most realistic, user-friendly, Carrom board games with world leading games. Our
                                    Developers build games for both web and mobiles including all app platforms. Hire our Carrom
                                    Game Developers with their rich experience and skills to have the best Carrom Board Game
                                    Development services in India
                                </p>

                                {
                                    gameDetails.android_link ?
                                        <a target="_blank" href={gameDetails.android_link} className="os-btn bg-white d-flex align-items-center">
                                            <div className="os-btn-ico">
                                                <div className="ico-50">
                                                    <img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
                                                </div>
                                            </div>
                                            <div className="os-btn-txt">
                                                <h6 className="h6-lg">Download for Android</h6>
                                            </div>
                                        </a>
                                        :
                                        ''
                                }

                                {
                                    gameDetails.ios_link ? <a target="_blank" href={gameDetails.ios_link} className="os-btn mac-os-btn bg-white d-flex align-items-center">
                                        <div className="os-btn-ico">
                                            <div className="ico-50">
                                                <img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
                                            </div>
                                        </div>

                                        <div className="os-btn-txt">
                                            <h6 className="h6-lg">Download for IOS</h6>
                                        </div>
                                    </a> : ''
                                }
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="hero-17-img video-preview">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInRight">
                                    <img className="img-fluid" src="../assets/img/GamesImg/Carrom/img-09.png" alt="hero-image" />
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="content-2" className="content-2 pb-60 pt-50  content-section division">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-5 col-lg-6">
                            <div className="rel img-block left-column wow fadeInRight">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInleft">
                                    <img className="img-fluid" src="../assets/img/GamesImg/Carrom/img-10.png" alt="content-image" />
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-6">
                            <div className="txt-block right-column wow fadeInLeft">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInright">
                                    <h2 className="h2-xs">Carrom Board Game Development Services</h2>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInright">
                                    <p className="p-lg">Our Company is a leading Carrom Board Game Development Company in India. Our team of
                                        Carrom Board Game Developers like Designers and programmers strives to give you the best
                                        Carrom Board Game software in terms of quality. Our Carrom Board Game developers
                                        specifically keep clients in the loop throughout the game process to ensure that the clients are
                                        highly satisfied with the results. We use advanced technologies to create a game that supports
                                        android, iOS, Desktop & all other platforms. Our developers make sure that you and your
                                        audience receive memorable hands-on experiences. For that, our passionate gaming coders
                                        make use of the latest mobile technology.
                                    </p>
                                    <p className="p-lg">Adept Carrom Board game providers are working hard on different Carrom Board game app
                                        solutions consisting of varied options and blends of features, functionalities, modules, etc. to
                                        perfectly deliver you the best idea for Carrom Board game app development. We will provide
                                        you with a rich-featured app that will help you to gain more Carrom Board users. Set your
                                        Carrom Board game to a level higher than other Carrom Board game developers with us.
                                    </p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="features-8" className="pb-60 features-section division">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                            <div className="section-title title-01 mb-70">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInup">
                                    <h2 className="h2-md">--- Ability to React Quickly ---</h2>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="fbox-8-wrapper text-center">
                        <div className="row row-cols-1 row-cols-md-3 justify-content-center">
                            <div className="col">
                                <div className="fbox-8 mb-40 wow fadeInUp">
                                    <div className="fbox-img">
                                        <img className="img-fluid" src="../assets/img/GamesImg/Carrom/carrom.png" alt="feature-icon" />
                                    </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInup">
                                        <h5 className="h5-md">Desktop Version</h5>
                                        <p className="p-lg">This version is mainly for Windows and MacOS. You can simply Download and install this
                                            version and it's good to go.
                                        </p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div className="col">
                                <div className="fbox-8 mb-40 wow fadeInUp">
                                    <div className="fbox-img">
                                        <img className="img-fluid change" src="../assets/img/GamesImg/Carrom/img-01.png" alt="feature-icon" />
                                    </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInup">
                                        <h5 className="h5-md">Smartphone Version</h5>
                                        <p className="p-lg">Our Carrom Board game software mobile version is available for iOS, on Android.
                                        </p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="content-9" className="content-9 bg-whitesmoke-gradient pt-100 content-section division">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <div className="section-title title-02 mb-60">
                                <h2 className="h2-xs">Discover powerful features to boost your productivity</h2>
                            </div>
                        </div>
                    </div>
                    <div className="img-block">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="about-rummy">
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInup">
                                        <h2 className="heading">What is Carrom Board game</h2>
                                        <p>Carrom or Karrom is a tabletop game that originated from India and is the Indian version of
                                            Billiards or Pool game. The game is very popular among children and families and is played
                                            with 2 or more players. The game is very simple to understand, however, it is hard to master.
                                            The goal is to pocket carrom man and the queen to get more points. The more pieces you
                                            pocket, the more points you will earn. In the end, the person with the most points win</p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="content-9-img video-preview wow fadeInUp">
                                    <img className="img-fluid" src="../assets/img/GamesImg/Carrom/dashboard-07.png" alt="video-preview" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="statistic-2" className="wide-100 statistic-section division">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6">
                            <div className="txt-block right-column wow fadeInLeft">
                                <h3 className="h3-xs">More than 1L+ users around the world are already using Carrom actively</h3>
                            </div>
                        </div>
                        <div className="col-sm-5 col-md-4 col-lg-3 offset-sm-1 offset-md-2 offset-lg-1">
                            <div className="statistic-block wow fadeInUp">
                                <h2 className="h2-title-xs statistic-number"><span className="count-element">1L</span></h2>
                                <p className="p-lg mt-20">Active downloads <br /> from the community</p>
                            </div>
                        </div>
                        <div className="col-sm-5 col-md-4 col-lg-2">
                            <div className="statistic-block wow fadeInUp">
                                <h2 className="h2-title-xs statistic-number">
                                    <span className="count-element"><CountUp end={4} duration={2} /></span>.<span className="count-element"><CountUp end={2} duration={2} /></span>
                                </h2>
                                <div className="txt-block-rating ico-15 yellow-color">
                                    <span className="flaticon-star-1"></span>
                                    <span className="flaticon-star-1"></span>
                                    <span className="flaticon-star-1"></span>
                                    <span className="flaticon-star-half-empty"></span>
                                </div>
                                <p className="p-lg txt-400">Rating</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="content-3" className="content-3 wide-60 content-section division">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                            <div className="section-title title-01 mb-70">
                                <h2 className="h2-md">Get Ready to Be Surprised</h2>
                                <p className="p-xl">To make it easy and convenient for you to play the game, we have added various features to
                                    the game.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="top-row pb-50">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-5 col-lg-6">
                                <div className="img-block left-column wow fadeInRight">
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
                                        <img className="img-fluid" src="../assets/img/GamesImg/Carrom/img-01.png" alt="content-image" />
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div className="col-md-7 col-lg-6">
                                <div className="txt-block right-column wow fadeInLeft">
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInRight">
                                        <h2 className="h2-xs">Carrom Board Game Features</h2>
                                        <p className="p-lg">You can play against the human player using standard carrom rules
                                        </p>
                                        <div className="txt-box">
                                            <ul className="simple-list">
                                                <li className="list-item">
                                                    <p className="p-md">DISK POOL Mode allows play without king piece.

                                                    </p>
                                                </li>
                                                <li className="list-item">
                                                    <p className="p-md">Play Vs Computer and challenge the AI

                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="p-md">User can change manually Striker, Pieces and Board.

                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="techno-sec">
                <div className="over-techno">
                    <div className="heading">
                        <h2>Our Professionals are working on the following top-notch techs</h2>
                        <p>We build the nexus of talent and innovation with Optimum oengines</p>
                    </div>
                    <div className="row align">
                        <div className="col-md-2 box">
                            <div className="nodejs wow fadeInUp">
                                <ScrollAnimation animateIn="animate__animated animate__bounceInDown">
                                    <img src="../assets/img/GamesImg/Languages_images/nodejs-technology.png" alt="" />
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-2 box">
                            <div className="javascript  wow fadeInUp">
                                <ScrollAnimation animateIn="animate__animated animate__bounceInDown">
                                    <img src="../assets/img/GamesImg/Languages_images/reactjs-technology.png" alt="" />
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-2 box wow fadeInUp">
                            <div className="rabbit">
                                <ScrollAnimation animateIn="animate__animated animate__bounceInDown">
                                    <img src="../assets/img/GamesImg/Languages_images/mean-stack.png" alt="" />
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-2 box wow fadeInUp">
                            <div className="mongodb">
                                <ScrollAnimation animateIn="animate__animated animate__bounceInDown">
                                    <img src="../assets/img/GamesImg/Languages_images/mongodb-technology.png" alt="" />
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-2 box wow fadeInUp">
                            <div className="redis ">
                                <ScrollAnimation animateIn="animate__animated animate__bounceInDown">
                                    <img src="../assets/img/GamesImg/Languages_images/unity-technology.png" alt="" />
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-2 box  wow fadeInUp">
                            <div className="socket">
                                <ScrollAnimation animateIn="animate__animated animate__bounceInDown">
                                    <img src="../assets/img/GamesImg/Languages_images/angular-technology.png" alt="" />
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-section py-5">
                <div className="container">
                    <div className="row justify-content-center py-3">
                        <div className="col-md-8 col-12 text-center">
                            <h2 className="service-main-heading">Our Features</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
                            <div className="icon-box wow fadeInUp">
                                <img src="../assets/img/GamesImg/icon/multi-table.png" alt="" />
                                <p className="service-title"><a href="#">Player vs Computer</a></p>
                                <p className="service-para">You don’t need someone to play Carrom with you. You can play with the computer and you
                                    can use this to improve your skills too.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
                            <div className="icon-box  wow fadeInUp">
                                <img src="../assets/img/GamesImg/icon/multilingual.png" alt="" />
                                <p className="service-title"><a href="#">Multi-Languages</a></p>
                                <p className="service-para">We create an Carrom Board game app/software/website supporting different regional and
                                    native languages for the better gaming experience.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
                            <div className="icon-box  wow fadeInUp">
                                <img src="../assets/img/GamesImg/icon/dailybonus.png" alt="" />
                                <p className="service-title"><a href="#">Turn by Turn
                                </a></p>
                                <p className="service-para">Local mode allows you to play carrom with your friends and family on the same device. Play
                                    turn by turn and enjoy the thrill and excitement of Carrom.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
                            <div className="icon-box  wow fadeInUp">
                                <img src="../assets/img/GamesImg/icon/customizerummysolution.png" alt="" />
                                <p className="service-title"><a href="#">Customize Carrom Board Game Solution</a></p>
                                <p className="service-para">Have a particular feature in mind? We will incorporate it into your Carrom Board game
                                    according to your exact requisites</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="content-2" className="content-2 pb-60 content-section division">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-7 col-lg-6">
                            <div className="txt-block right-column wow fadeInLeft">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInleft">
                                    <h2 className="h2-xs">Make it simpler with Quick Commands</h2>
                                    <ul className="simple-list">
                                        <li className="list-item">
                                            <p className="p-lg">Fringilla risus, luctus mauris orci auctor euismod iaculis luctus
                                                magna purus pretium ligula purus undo quaerat tempor sapien rutrum mauris quaerat ultrice
                                            </p>
                                        </li>
                                        <li className="list-item">
                                            <p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
                                        </li>
                                        <li className="list-item">
                                            <p className="p-lg">Nemo ipsam egestas volute turpis dolores undo ultrice aliquam
                                                quaerat at sodales sapien purus
                                            </p>
                                        </li>
                                    </ul>
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-6">
                            <div className="rel img-block left-column wow fadeInRight">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInRight">
                                    <img className="img-fluid" src="../assets/img/GamesImg/Carrom/img-07.png" alt="content-image" />
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="Rummy-sec">
                <div className="container">
                    <div className="over-techno">
                        <div className="heading">
                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                <h2 className="wow fadeInUp">Carrom Game Variations</h2>
                                <p className="wow fadeInUp">To make your Carrom Game playing experience even better, use this Carrom game variations and enjoy more than before-</p>
                            </ScrollAnimation>
                        </div>
                        <div className="types">
                            <div className="row">
                                <div className="col-md-6 changes">
                                    <div className="rummy-type">
                                        <div className="rummy-img">
                                            <img src="../assets/img/GamesImg/gin_rummy_images/Variation/1.png" alt="" className="wow fadeInUp" />
                                        </div>
                                        <div className="rummy-txt">
                                            <h5 className="wow fadeInUp">CLASSIC CARROM</h5>
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                <p className="wow fadeInUp">
                                                    Everyone must shoot the carrom ball of their chosen color into the hole, and then they chase the red ball, also known as the "Queen", hitting the Queen and the last ball in succession will win real carrom board offline game.
                                                </p>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 changes">
                                    <div className="rummy-type">
                                        <div className="rummy-img">
                                            <img src="../assets/img/GamesImg/gin_rummy_images/Variation/2.png" alt="" className="wow fadeInUp" />
                                        </div>
                                        <div className="rummy-txt">
                                            <h5 className="wow fadeInUp">CARROM DISC POOL</h5>
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                <p className="wow fadeInUp">In this mode, you must set the correct angle. Then shoot the carrom ball into the pocket. Without the queen ball, you can win by hitting all the balls into the pocket in carrom bot board game.</p>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="faqs-2" className="pb-60 faqs-section division">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                            <div className="section-title title-01 mb-80">
                                <h2 className="h2-md">Frequently Asked Question? </h2>

                            </div>
                        </div>
                    </div>
                    <div className="faqs-2-questions">
                        <div className="row row-cols-1 row-cols-lg-2">
                            <div className="col">
                                <div className="questions-holder pr-15">
                                    <div className="question wow fadeInUp">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h5 className="h5-md">How Much Time Does It Take to Develop a Carrom Game?</h5>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p className="p-lg">Carrom Game development is a complex process, so it will take between six months and a
                                                year.
                                            </p>
                                        </ScrollAnimation>
                                    </div>
                                    <div className="question wow fadeInUp">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h5 className="h5-md">Do you provide continued maintenance support for Carrom mobile game development?</h5>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p className="p-lg">Yes, we provide you with continuous maintenance support for Carrom Game development.
                                                Even after the development, you can opt for the monthly retention plan.

                                            </p>
                                        </ScrollAnimation>
                                    </div>
                                    <div className="question wow fadeInUp">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h5 className="h5-md">What is the process of Carrom Game development?</h5>
                                        </ScrollAnimation>
                                        <ul className="simple-list">
                                            <li className="list-item">
                                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                    <p className="p-lg">The process starts with the analysis of your idea and requirements. Then begins the
                                                        customization and development process. After that, all the functions and functionality are
                                                        added to the app. Once the app is ready, it's time for beta testing. After several tests, the app
                                                        is ready to be launched.

                                                    </p>
                                                </ScrollAnimation>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="questions-holder pl-15">
                                    <div className="question wow fadeInUp">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h5 className="h5-md">What is Carrom Game is about?</h5>
                                            <p className="p-lg">Carrom board game is a board game that has a square shape of plywood or plastic shape
                                                structure. If you don't know, the carrom board game's main origin is in India, and now the
                                                game is popular worldwide.
                                            </p>
                                        </ScrollAnimation>
                                    </div>
                                    <div className="question wow fadeInUp">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h5 className="h5-md">Will You Provide Both the Carrom Game Website & Mobile App Development Services?</h5>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p className="p-lg"> Yes, we offer a range of Carrom Game development services including website development,
                                                mobile app development, and software development.
                                            </p>
                                        </ScrollAnimation>
                                    </div>
                                    <div className="question wow fadeInUp">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h5 className="h5-md">Do You Provide Customized Carrom Game Development Services?</h5>
                                        </ScrollAnimation>
                                        <ul className="simple-list">
                                            <li className="list-item">
                                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                    <p className="p-lg">Of course, YES. We offer 100% customized gaming solutions that meet clients’ requirements
                                                        and even exceed them. No matter what kind of gaming solutions you need, our team of
                                                        talented professionals is capable of fulfilling your needs.</p>
                                                </ScrollAnimation>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-center pt-25">
                        </div>
                    </div>
                </div>
            </section>

            <section id="cta-9" className="bg-01 wide-80 cta-section division">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col col-lg-8">
                            <div className="cta-9-txt text-center row justify-content-center">
                                <h2 className="h2-xs white-color">Getting Started is Fast & Easy</h2>
                                <p className="p-xl white-color mb-35">Download Carrom on your Smartphone now from google play or app store.</p>
                                <div className="d-flex justify-content-center gap-4">
                                    {
                                        gameDetails.android_link ?
                                            <a target="_blank" href={gameDetails.android_link} className="os-btn bg-white d-flex align-items-center">
                                                <div className="os-btn-ico">
                                                    <div className="ico-50">
                                                        <img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
                                                    </div>
                                                </div>
                                                <div className="os-btn-txt">
                                                    <h6 className="h6-lg">Download for Android</h6>
                                                </div>
                                            </a>
                                            :
                                            ''
                                    }

                                    {
                                        gameDetails.ios_link ? <a target="_blank" href={gameDetails.ios_link} className="os-btn mac-os-btn bg-white d-flex align-items-center">
                                            <div className="os-btn-ico">
                                                <div className="ico-50">
                                                    <img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
                                                </div>
                                            </div>

                                            <div className="os-btn-txt">
                                                <h6 className="h6-lg">Download for IOS</h6>
                                            </div>
                                        </a> : ''
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}