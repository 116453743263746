import React from "react";
import { Helmet } from 'react-helmet';
import '../../src/assets/css/app.min.css';
import '../../src/assets/css/dark.css'
import '../../src/assets/css/responsive.css';
import '../../src/assets/css/app.min.css';
import '../../src/assets/css/style.css';
import '../../src/assets/css/fontawesome.min.css';
import OwlCarousel from 'react-owl-carousel';
import 'react-awesome-slider/dist/styles.css';
import axios from 'axios';
import Header from "../Header";
import ScrollAnimation from 'react-animate-on-scroll';
import { Modal } from 'react-bootstrap';
import Swal from "sweetalert2";

class mobiledevelopment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
            isActive: false,
            sliderRef: null
        }
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this); 
    }
    componentDidMount() {

        axios.get("https://www.oengines.com/careers")
            .then(response => {
                console.log("Data is::::::::", response.data);
                this.setState({ Job: response.data });
                this.setState({ Description: response.data[0].description });

            })
            .catch(function (error) {
                console.log(error);
            })

    }
    onClickShowdetails(job) {
        console.log("job is:::::::", job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    maxlengthnumber(evt) {
        if (evt.target.value.length === 11) {
            return false;
        }   
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    handlevideopopup(e) {
        this.setState({ isActive: !this.state.isActive });
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};

        if (this.state.name === '' || this.state.name === null) {
            console.log("Nmae")
            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            console.log("mail")
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            console.log("subject")
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            console.log("number")
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.message === '' || this.state.message === null) {
            console.log("message")
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else {

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,

            };
            console.log("formObject::::::::::;", formObject);
            axios.get('http://192.168.0.103:3200/contactus', formObject)
                .then((res) => {
                    console.log("Response is:::::::::", res.data);
                    if (res.data !== 'undefined') {
                        console.log("Response is:::::::::", res.data);
                        Swal.fire({
                            title: "Thank you!",
                            text: "Your request has been assigned successfully.",
                            icon: "success",
                        }).then(function () {
                            window.location.reload();
                        });
                    } else {
                        Swal.fire({
                            title: "Oops...!",
                            text: "Oh! Something went wrong, please try again!",
                            icon: "error",
                        }).then(function () {
                            window.location.reload();
                        });
                    }

                });
        }
    }
    render() {
     
        var set = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                576: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 3,
                    nav: true,
                    loop: true
                },
                1200: {
                    items: 3,
                    nav: true,
                    loop: true
                }
            }
        };
        var setfeatures = {
            dots: false,
            slidestoshow: 5,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                576: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 3,
                    nav: true,
                    loop: true
                },
                1200: {
                    items: 4,
                    nav: true,
                    loop: true
                }
            }
        };
        var setContent = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 1,
                    nav: true
                },
                1000: {
                    items: 1,
                    nav: true,
                    loop: true
                },
                1399: {
                    items: 1,
                    nav: true,
                    loop: true
                },
                1800: {
                    items: 1,
                    nav: true,
                    loop: true
                }

            }
        }
        const myStyleone = {
            backgroundImage:
                "url('../assets/img/bg/pattern.png')",                     
            zIndex: 2            
        };
       
        const { nameError, emailError, subjectError, numberError, messageError } = this.state.formErrors;
        var Information;
        if (this.state.Job.length) {
            Information = this.state.Job.map((job, Index) =>
                <>

                    <li>
                        <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>
                </>
            )
        } else {
            Information = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }
       
        return (
            <>
                <Helmet>

                    <link href="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/css/coreui.min.css" rel="stylesheet" integrity="sha384-UkVD+zxJKGsZP3s/JuRzapi4dQrDDuEf/kHphzg8P3v8wuQ6m9RLjTkPGeFcglQU" crossorigin="anonymous" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                    <script src="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/js/coreui.bundle.min.js" integrity="sha384-n0qOYeB4ohUPebL1M9qb/hfYkTp4lvnZM6U6phkRofqsMzK29IdkBJPegsyfj/r4" crossorigin="anonymous"></script>                    
                    <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js" type="text/javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                    <script src="../assets/js/script.js" type="text/Javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                </Helmet>
                {/* <div className="loader" ><span>Loading...</span></div> */}
                <Header />
                <Modal size="lg" show={this.state.isActive} onClick={this.handleClickInsideModal} className="modal_contect video-effect-modal modalvideos" centered>
                    <Modal.Header closeButton className="modal_header p-0 border-none" style={{ border: "none" }} onHide={() => this.handlevideopopup()} >
                    </Modal.Header>
                    <Modal.Body>
                        <iframe width="960" height="415" src="https://www.youtube.com/embed/9xwazD5SyVg?autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </Modal.Body>
                </Modal>
                <div className="as-hero-wrapper hero-1" id="hero">
                  <div className="hero-img tilt-active"><img src="assets/img/hero/hero_img_1_1.png" alt="Hero Image" /></div>
                  <div className="container">
                      <div className="hero-style1">
                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                            <span className="hero-subtitle">Welcome to OEngines</span>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                          <h1 className="hero-title">Mobile Application </h1>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                          <h1 className="hero-title"><span className="text-theme fw-medium">Development</span></h1>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                          <p className="hero-text">A Complete asset will create a beautiful gaming experience. We are spending our own ideas and time to make a better user-friendly environment. We have created various reliable mobile games and computer games that who gained worldwide attentions.</p>
                        </ScrollAnimation>
                          <div className="btn-group"><a href="#" className="as-btn style3">ABOUT US<i
                              className="fa-regular fa-arrow-right ms-2"></i></a>
                              <div className="call-btn"><a href="#!"
                                  className="play-btn popup-video" onClick={(e) => this.handlevideopopup(e)}><i className="fas fa-play"></i></a>
                                  <div className="media-body">
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <a href="#!" className="btn-title popup-video">Watch Our Story</a> <span className="btn-text">Subscribe
                                          Now</span>
                                    </ScrollAnimation>
                                    </div>  
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="hero-shape1"><img src="assets/img/hero/hero_shape_1_1.svg" alt="shape" /></div>
                  <div className="hero-shape2"><img src="assets/img/hero/hero_shape_1_2.svg" alt="shape" /></div>
                  <div className="hero-shape3"><img src="assets/img/hero/hero_shape_1_3.svg" alt="shape" /></div>
              </div>
              <section className="gr-bg1 about-sec-v4 space-bottom">
                    <div className="row gy-4 justify-content-center p-4">
                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                        <h2 className="text-center text-size">Our Features</h2>
                    </ScrollAnimation>
                        <OwlCarousel items={5}
                            className="owl-theme"
                            loop
                            autoplay={true}
                            margin={2} {...setfeatures}>
                            <div className="">
                                <div className="feature-card">
                                    <div className="shape-icon"><img src="assets/img/icon/feature_card_1.svg" alt="icon" /></div>
                                    <h3 className="box-title">Code & Architecture</h3>
                                    <p className="feature-card_text">Software architecture is defined as designing parts that are hard to change later. We categorize all work as 	either “offensive” or “defensive.”</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="feature-card">
                                    <div className="shape-icon"><img src="assets/img/icon/feature_card_2.svg" alt="icon" /></div>
                                    <h3 className="box-title">Build the right</h3>
                                    <p className="feature-card_text">Finding the right talent is not easy. We help you find the talent that suits your needs, follows your processes, 	and sticks with you long term.</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="feature-card">
                                    <div className="shape-icon"><img src="assets/img/icon/feature_card_3.svg" alt="icon" /></div>
                                    <h3 className="box-title">High Scalability</h3>
                                    <p className="feature-card_text">ICustom applications are created with all parameters in mind so that they may be easily scaled up when the need arises.</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="feature-card">
                                    <div className="shape-icon"><img src="assets/img/icon/feature_card_1.svg" alt="icon" /></div>
                                    <h3 className="box-title">Push Notifications</h3>
                                    <p className="feature-card_text">Our developer gives you a built-in notifications system that will allow users to see important messages at the same time.</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="feature-card">
                                    <div className="shape-icon"><img src="assets/img/icon/feature_card_2.svg" alt="icon" /></div>
                                    <h3 className="box-title">Faster Deployment</h3>
                                    <p className="feature-card_text">You select the features that best fit your needs because they are completely customizable.</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="feature-card">
                                    <div className="shape-icon"><img src="assets/img/icon/feature_card_3.svg" alt="icon" /></div>
                                    <h3 className="box-title">Protects App Data</h3>
                                    <p className="feature-card_text">Security controls and additions which are included in the application to keep data protected and safe, as well as 	to ensure data confidentiality.</p>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                  <div className="container space-top">
                      <div className="row align-items-center">
                          <div className="col-lg-5 mb-30 mb-lg-0">
                              <div className="img-box6">
                                  <div className="img1"><img src="assets/img/normal/about_4_1.png" alt="About" /></div>
                                  <div className="shape1"><img src="assets/img/normal/about_4_2.png" alt="About" /></div>
                                  <div className="shape2"><img src="assets/img/normal/about_4_3.png" alt="About" /></div>
                                  <div className="color-animate"></div>
                              </div>
                          </div>
                          <div className="col-lg-7 text-lg-start text-center">
                              <div className="ps-xxl-5">
                                  <div className="title-area mb-35">
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <span className="sub-title">About Company</span>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h2 className="sec-title">We believe in Reliability and Perfection.</h2>
                                    </ScrollAnimation>
                                  </div>
                                  <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <p className="mt-n2 mb-25">With 12+ Years of Excellence, We are one of the Best IT Companies in Surat for Mobile App Development.</p>
                                  </ScrollAnimation>
                                  <div className="checklist style4 mb-40 list-center">
                                      <ul>
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                              <li>
                                                  <img src="assets/img/icon/check_1.svg" alt="icon" />We get utter satisfaction from providing a world-class service through our highly experienced and professional team.
                                              </li>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">                                         
                                              <li>
                                                  <img src="assets/img/icon/check_1.svg" alt="icon" />We make Complex Business easier with Top-Notch Technology & Exceptional Services.
                                              </li>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                              <li>
                                                  <img src="assets/img/icon/check_1.svg" alt="icon" />Our experts with creative brains have dealt with several clients and Help them grow
                                              </li>
                                            </ScrollAnimation>

                                      </ul>
                                  </div><a href="#" className="as-btn style3">ABOUT MORE<i
                                      className="fa-regular fa-arrow-right ms-2"></i></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <section className="gr-bg2" id="service-sec">
                  <div className="round-container gr-bg3 space">
                      <div className="container">
                          <div className="row gy-40 justify-content-between space-bottom">
                              <div className="col-6 col-lg-auto">
                                  <div className="counter-card">
                                      <div className="icons"><img src="assets/img/icon/counter_2_1.svg" alt="Icon" /></div>
                                      <div className="media-body">
                                          <h2 className="counter-card_number text-title"><span className="counter-number">986</span>+</h2>
                                          <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p className="counter-card_text text-body">Finished Project</p>
                                          </ScrollAnimation>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-6 col-lg-auto">
                                  <div className="counter-card">
                                      <div className="icons"><img src="assets/img/icon/counter_2_2.svg" alt="Icon" /></div>
                                      <div className="media-body">
                                          <h2 className="counter-card_number text-title"><span className="counter-number">896</span>+</h2>
                                          <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p className="counter-card_text text-body">Happy Clients</p>
                                          </ScrollAnimation>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-6 col-lg-auto">
                                  <div className="counter-card">
                                      <div className="icons"><img src="assets/img/icon/counter_2_3.svg" alt="Icon" /></div>
                                      <div className="media-body">
                                          <h2 className="counter-card_number text-title"><span className="counter-number">396</span>+</h2>
                                          <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p className="counter-card_text text-body">Skilled Experts</p>
                                          </ScrollAnimation>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-6 col-lg-auto">
                                  <div className="counter-card">
                                      <div className="icons"><img src="assets/img/icon/counter_2_4.svg" alt="Icon" /></div>
                                      <div className="media-body">
                                          <h2 className="counter-card_number text-title"><span className="counter-number">496</span>+</h2>
                                          <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p className="counter-card_text text-body">Honorable Awards</p>
                                          </ScrollAnimation>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="title-area text-center">
                          <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                            <span className="sub-title">SERVICES WE PROVIDE</span>
                          </ScrollAnimation>
                          <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                              <h2 className="sec-title">That Make Your Work Attractive</h2>
                          </ScrollAnimation>
                          </div>
                          <div className="row slider-shadow as-carousel" data-slide-show="3" data-lg-slide-show="2"
                              data-md-slide-show="2" data-sm-slide-show="1" data-xs-slide-show="1">
                              <OwlCarousel items={3}
                                  className="owl-theme"
                                  loop
                                  autoplay={true}
                                  margin={2} {...set}>
                                  <div className="">
                                      <div className="service-3d">
                                          <div className="service-3d_icon"><img src="assets/img/icon/service_3d_1.svg" alt="Icon" /></div>
                                          <div className="service-3d_content">
                                              <h3 className="box-title"><a href="service-details.html">PWA Developement</a></h3>
                                              <p className="service-3d_text">We provide customized Web Application Development services with the latest technology to suit your requirements that help increasing work efficiencies and reduces working costs.</p><a
                                                  href="service-details.html" className="as-btn">Read More<i
                                                      className="fas fa-arrow-right ms-2"></i></a>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="">
                                      <div className="service-3d">
                                          <div className="service-3d_icon"><img src="assets/img/icon/service_3d_2.svg" alt="Icon" /></div>
                                          <div className="service-3d_content">
                                              <h3 className="box-title"><a href="service-details.html">ios Development</a></h3>
                                              <p className="service-3d_text">Oengines Team creates successful iPhone Mobile Application that are in perfect positioning with customer expectations.</p><a
                                                  href="service-details.html" className="as-btn">Read More<i
                                                      className="fas fa-arrow-right ms-2"></i></a>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="">
                                      <div className="service-3d">
                                          <div className="service-3d_icon"><img src="assets/img/icon/service_3d_3.svg" alt="Icon" /></div>
                                          <div className="service-3d_content">
                                              <h3 className="box-title"><a href="service-details.html">Android Development</a></h3>
                                              <p className="service-3d_text">From simple to complex application, small to large scale businesses, our Android developers have the effectiveness of creating applications in android platform.</p><a
                                                  href="service-details.html" className="as-btn">Read More<i
                                                      className="fas fa-arrow-right ms-2"></i></a>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="">
                                      <div className="service-3d">
                                          <div className="service-3d_icon"><img src="assets/img/icon/service_3d_4.svg" alt="Icon" /></div>
                                          <div className="service-3d_content">
                                              <h3 className="box-title"><a href="service-details.html">Unity Development</a></h3>
                                              <p className="service-3d_text">We have been creating and engaging millions of users with our compelling and immersive Unity game solutions.</p><a
                                                  href="service-details.html" className="as-btn">Read More<i
                                                      className="fas fa-arrow-right ms-2"></i></a>
                                          </div>
                                      </div>
                                  </div>
                              </OwlCarousel>
                          </div>
                      </div>
                  </div>
              </section>
              <div className="gr-bg1">
                  <div className="container space">
                      <div className="row align-items-center flex-row-reverse">
                          <div className="col-lg-6 mb-30 mb-lg-0">
                              <div className="img-box7">
                                  <div className="img1"><img src="assets/img/normal/why_3_1.png" alt="Why" /></div>
                                  <div className="shape1"><img src="assets/img/normal/why_3_2.png" alt="About" /></div>
                                  <div className="shape2"><img src="assets/img/normal/why_3_3.png" alt="About" /></div>
                                  <div className="color-animate"></div>
                              </div>
                          </div>
                          <div className="col-lg-6 text-lg-start text-center">
                              <div className="title-area mb-35"> 
                              <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                <span className="sub-title">Why Choose Oengines</span>
                              </ScrollAnimation>
                              <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                  <h2 className="sec-title">We Focus on Making Things that Matters!</h2>
                              </ScrollAnimation>
                              </div>
                              <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                <p className="mt-n2 mb-30">Choosing the appropriate software development company may make or break your project. That’s why we at Oengines believe that we are the best choice for your software development needs.</p>
                              </ScrollAnimation>
                              <div className="two-column mb-40 list-center">
                                  <div className="checklist style2">
                                      <ul>
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                          <li><img src="assets/img/icon/check_1.svg" alt="icon" /> Experience And Expertise</li>
                                      </ScrollAnimation>    
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                          <li><img src="assets/img/icon/check_1.svg" alt="icon" /> Flexible and Customized Applications</li>
                                      </ScrollAnimation>
                                      </ul>
                                  </div>
                                  <div className="checklist style2">
                                      <ul>
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                          <li><img src="assets/img/icon/check_1.svg" alt="icon" /> Unique Service & Features</li>
                                      </ScrollAnimation>
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                          <li><img src="assets/img/icon/check_1.svg" alt="icon" /> Top-Notch Technology stack Implementation</li>
                                      </ScrollAnimation>
                                      </ul>
                                  </div>
                              </div><a href="#" className="as-btn style3">LEARN MORE<i
                                  className="fa-regular fa-arrow-right ms-2"></i></a>
                          </div>
                      </div>
                  </div>
                  <div className="shape-mockup" data-bottom="0" data-right="0">
                      <div className="particle-3" id="particle-4"></div>
                  </div>
              </div>
              <section className="gr-bg2 new-gr-bg2" >
                  <div className="round-container gr-bg4 space">
                      <div className="container">
                          <div className="row justify-content-lg-between justify-content-center align-items-center">
                              <div className="col-lg-5 mb-n2 mb-lg-0">
                                  <div className="title-area text-center text-lg-start">
                                  <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <span className="sub-title">CASE STUDY</span>
                                  </ScrollAnimation>
                                  <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                      <h2 className="sec-title">Our Portfolio / Works</h2>
                                  </ScrollAnimation>
                                  </div>
                              </div>
                              <div className="col-auto">
                                  <div className="sec-btn"><a href="#!" className="as-btn style3">VIEW ALL PORJECTS<i
                                      className="fa-regular fa-arrow-right ms-2"></i></a></div>
                              </div>
                          </div>
                          <div className="row slider-shadow as-carousel" data-slide-show="3" data-lg-slide-show="2"
                              data-md-slide-show="2" data-sm-slide-show="1" data-arrows="true" data-focuson-select="false">
                              <OwlCarousel items={3}
                                  className="owl-theme"
                                  loop
                                  autoplay={true}
                                  margin={8} {...set}>
                                  <div className="">
                                      <div className="project-box">
                                          <div className="project-img"><img src="assets/img/project/project_3_1.jpg" alt="project image" />
                                          </div>
                                          <div className="project-content" data-bg-src="assets/img/bg/project_card_bg.png">
                                              <div className="media-body">
                                                  <h3 className="box-title"><a>IT Consultency</a></h3>
                                                  <p className="project-subtitle">Technology</p>
                                              </div><a href="assets/img/project/project_3_1.jpg" className="icon-btn popup-image"><i
                                                  className="far fa-plus"></i></a>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="">
                                      <div className="project-box">
                                          <div className="project-img"><img src="assets/img/project/project_3_2.jpg" alt="project image" />
                                          </div>
                                          <div className="project-content" data-bg-src="assets/img/bg/project_card_bg.png">
                                              <div className="media-body">
                                                  <h3 className="box-title"><a>Web Development</a></h3>
                                                  <p className="project-subtitle">Technology</p>
                                              </div><a href="assets/img/project/project_3_2.jpg" className="icon-btn popup-image"><i
                                                  className="far fa-plus"></i></a>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="">
                                      <div className="project-box">
                                          <div className="project-img"><img src="assets/img/project/project_3_3.jpg" alt="project image" />
                                          </div>
                                          <div className="project-content" data-bg-src="assets/img/bg/project_card_bg.png">
                                              <div className="media-body">
                                                  <h3 className="box-title"><a>Website Design</a></h3>
                                                  <p className="project-subtitle">Technology</p>
                                              </div><a href="assets/img/project/project_3_3.jpg" className="icon-btn popup-image"><i
                                                  className="far fa-plus"></i></a>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="">
                                      <div className="project-box">
                                          <div className="project-img"><img src="assets/img/project/project_3_4.jpg" alt="project image" />
                                          </div>
                                          <div className="project-content" data-bg-src="assets/img/bg/project_card_bg.png">
                                              <div className="media-body">
                                                  <h3 className="box-title"><a>SEO Optimization</a></h3>
                                                  <p className="project-subtitle">Marketing</p>
                                              </div><a href="assets/img/project/project_3_4.jpg" className="icon-btn popup-image"><i
                                                  className="far fa-plus"></i></a>
                                          </div>
                                      </div>
                                  </div>
                              </OwlCarousel>
                          </div>
                      </div>
                      <div className="shape-mockup" data-top="0%" data-right="0%"><img src="assets/img/shape/tech_shape_6.png"
                          alt="shape" /></div>
                      <div className="shape-mockup" data-bottom="0%" data-left="0%"><img src="assets/img/shape/tech_shape_7.png"
                          alt="shape" /></div>
                  </div>
              </section>
              <section className="gr-bg1 space" id="process-sec">
                  <div className="round-container">
                      <div className="title-area text-center">
                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                        <span className="sub-title">Work Flow</span>
                      </ScrollAnimation>
                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                          <h2 className="sec-title">Mobile Application Development <span className="text-theme">Process</span></h2>
                      </ScrollAnimation>
                      </div>
                      <div className="process-card-area area">                          
                          <div className="row gy-40 justify-content-between">
                              <div className="col-md-4 col-lg-3 col-xxl-2 col-sm-6 process-card-wrap">
                                  <div className="process-card">
                                      <div className="pulse">1</div>
                                      <div className="process-card_icon"><img src="assets/img/icon/process_box_1.svg" alt="icon" />
                                      </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                      <h2 className="box-title">Requirement Gathering</h2>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                      <p className="process-card_text"> Gather requirements, Analyze, and ideate.</p>
                                    </ScrollAnimation>
                                  </div>
                              </div>
                              <div className="col-md-4 col-lg-3 col-xxl-2 col-sm-6 process-card-wrap">
                                  <div className="process-card">
                                      <div className="pulse">2</div>
                                      <div className="process-card_icon"><img src="assets/img/icon/process_box_2.svg" alt="icon" />
                                      </div>
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h2 className="box-title"> Wireframing</h2>
                                      </ScrollAnimation>
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p className="process-card_text">  Detailed sketching of the envisioned Product.</p>
                                      </ScrollAnimation>
                                  </div>
                              </div>
                              <div className="col-md-4 col-lg-3 col-xxl-2 col-sm-6 process-card-wrap">
                                  <div className="process-card">
                                      <div className="pulse">3</div>
                                      <div className="process-card_icon"><img src="assets/img/icon/process_box_3.svg" alt="icon" />
                                      </div>
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h2 className="box-title">Feasibility Assessment</h2>
                                      </ScrollAnimation>
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p className="process-card_text"> Brainstorm and evaluate the technical feasibility.</p>
                                      </ScrollAnimation>
                                  </div>
                              </div>
                              <div className="col-md-4 col-lg-3 col-xxl-2 col-sm-6 process-card-wrap">
                                  <div className="process-card">
                                      <div className="pulse">4</div>
                                      <div className="process-card_icon"><img src="assets/img/icon/process_box_4.svg" alt="icon" />
                                      </div>
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h2 className="box-title">Prototyping</h2>
                                      </ScrollAnimation>
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p className="process-card_text">Samples to test the design and enhance precision.</p>
                                      </ScrollAnimation>
                                  </div>
                              </div>
                              <div className="col-md-4 col-lg-3 col-xxl-2 col-sm-6 process-card-wrap">
                                  <div className="process-card">
                                      <div className="pulse">5</div>
                                      <div className="process-card_icon"><img src="assets/img/icon/process_box_4.svg" alt="icon" />
                                      </div>
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h2 className="box-title">Design & Development</h2>
                                      </ScrollAnimation>
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p className="process-card_text">Visualize the final product and develop the app.</p>
                                      </ScrollAnimation>
                                  </div>
                              </div>
                              <div className="col-md-4 col-lg-3 col-xxl-2 col-sm-6 process-card-wrap">
                                  <div className="process-card">
                                      <div className="pulse">6</div>
                                      <div className="process-card_icon"><img src="assets/img/icon/process_box_4.svg" alt="icon" />
                                      </div>
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <h2 className="box-title">Test & Lauch</h2>
                                      </ScrollAnimation>
                                      <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p className="process-card_text">Perform all the mandatory testing and Lauch the App.</p>
                                      </ScrollAnimation>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </section>
              <section className="gr-bg2">
                  <div className="round-container" style={myStyleone}>
                      <div className="container">
                          <div className="testi-block-area">
                              <div className="testi-block-slide as-carousel" data-slide-show="1" data-md-slide-show="1"
                                  data-dots="true" data-xl-dots="true" data-ml-dots="true" data-lg-dots="true" data-md-dots="true"
                                  data-sm-dots="true" data-xs-dots="true">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                  <h2 className="text-start mb-30">Our Clients Feedback</h2>
                                </ScrollAnimation>
                                  <OwlCarousel items={1}
                                      className="owl-theme"
                                      loop
                                      autoplay={true}
                                      margin={8} {...setContent}>
                                      <div className="col-xl-8 col-md-8">
                                          <div className="testi-block">
                                              <p className="testi-block_text">“Completely drive innovative value whereas out-of-the-box
                                                  paradigms. Interactively pursue stand-alone markets after global results. Globally
                                                  plagiarize intermandated opportunities with. Progressively leverage others
                                                  multifunctional methods.”</p>
                                              <div className="testi-block_profile">
                                                  <div className="testi-block_avater"><img src="assets/img/testimonial/testi_4_1.jpg"
                                                      alt="Avater" /></div>
                                                  <div className="media-body">
                                                      <h3 className="box-title">Md Sumon Mia</h3>
                                                      <p className="testi-block_desig">CEO at Rimasu</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-xl-8 col-md-8">
                                          <div className="testi-block">
                                              <p className="testi-block_text">“Completely drive innovative value whereas out-of-the-box
                                                  paradigms. Interactively pursue stand-alone markets after global results. Globally
                                                  plagiarize intermandated opportunities with. Progressively leverage others
                                                  multifunctional methods.”</p>
                                              <div className="testi-block_profile">
                                                  <div className="testi-block_avater"><img src="assets/img/testimonial/testi_4_2.jpg"
                                                      alt="Avater" /></div>
                                                  <div className="media-body">
                                                      <h3 className="box-title">David Farnandes</h3>
                                                      <p className="testi-block_desig">CEO at Anaton</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-xl-8 col-md-8">
                                          <div className="testi-block">
                                              <p className="testi-block_text">“Completely drive innovative value whereas out-of-the-box
                                                  paradigms. Interactively pursue stand-alone markets after global results. Globally
                                                  plagiarize intermandated opportunities with. Progressively leverage others
                                                  multifunctional methods.”</p>
                                              <div className="testi-block_profile">
                                                  <div className="testi-block_avater"><img src="assets/img/testimonial/testi_4_3.jpg"
                                                      alt="Avater" /></div>
                                                  <div className="media-body">
                                                      <h3 className="box-title">Abraham Khalil</h3>
                                                      <p className="testi-block_desig">CEO at Kormola</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-xl-8 col-md-8">
                                          <div className="testi-block">
                                              <p className="testi-block_text">“Completely drive innovative value whereas out-of-the-box
                                                  paradigms. Interactively pursue stand-alone markets after global results. Globally
                                                  plagiarize intermandated opportunities with. Progressively leverage others
                                                  multifunctional methods.”</p>
                                              <div className="testi-block_profile">
                                                  <div className="testi-block_avater"><img src="assets/img/testimonial/testi_4_4.jpg"
                                                      alt="Avater" /></div>
                                                  <div className="media-body">
                                                      <h3 className="box-title">Jackline Techie</h3>
                                                      <p className="testi-block_desig">CEO at Anatora</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </OwlCarousel>
                              </div>
                              <div className="testi-block-quote"><img src="assets/img/icon/quote_3d.svg" alt="quote" /></div>
                          </div>
                      </div>
                  </div>
              </section>
              <div className="overflow-hidden gr-bg1 space-top" id="faq-sec">                 
                  <div className="container">
                      <div className="row align-items-center justify-content-center">
                          <div className="col-xl-6 col-lg-9">
                              <div className="title-area text-center text-xl-start">
                              <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                <span className="sub-title">FAQ'S</span>
                              </ScrollAnimation>
                              <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                  <h2 className="sec-title">Mobile App Development FAQs</h2>
                              </ScrollAnimation>
                              </div>

                              <div className="accordion" id="accordionExample">
                                  <div className="accordion-item accordion-card style3">
                                      <h2 className="accordion-header" id="headingOne">                                    
                                          <button className="accordion-button" type="button" data-coreui-toggle="collapse" data-coreui-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                              Where can I get analytics help?
                                          </button>                                      
                                      </h2>
                                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-coreui-parent="#accordionExample">
                                          <div className="accordion-body">
                                              Dramatically disseminate real-time portals rather than top-line
                                              action items. Uniquely provide access to low-risk high-yield products without
                                              dynamic products. Progressively re-engineer low-risk high-yield ideas rather
                                              than emerging alignments.
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item accordion-card style3">
                                      <h2 className="accordion-header" id="headingTwo">                                    
                                          <button className="accordion-button collapsed" type="button" data-coreui-toggle="collapse" data-coreui-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                              How long should a business plan be?
                                          </button>                                      
                                      </h2>
                                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-coreui-parent="#accordionExample">
                                          <div className="accordion-body">
                                              Dramatically disseminate real-time portals rather than top-line
                                              action items. Uniquely provide access to low-risk high-yield products without
                                              dynamic products. Progressively re-engineer low-risk high-yield ideas rather
                                              than emerging alignments.
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item accordion-card style3">
                                      <h2 className="accordion-header" id="headingThree">                                    
                                          <button className="accordion-button collapsed" type="button" data-coreui-toggle="collapse" data-coreui-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                              Do I need a business plan?
                                          </button>                                      
                                      </h2>
                                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-coreui-parent="#accordionExample">
                                          <div className="accordion-body">
                                              Dramatically disseminate real-time portals rather than top-line
                                              action items. Uniquely provide acess to low-risk high-yield products without
                                              dynamic products. Progressively re-engineer low-risk high-yield ideas rather
                                              than emerging alignments.
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-6 mt-35 mt-xl-0">
                              <div className="img-box8">
                                  <div className="img1"><img src="assets/img/normal/faq_2_1.png" alt="Faq" /></div>
                                  <div className="shape1"><img src="assets/img/normal/faq_2_2.png" alt="About" /></div>
                                  <div className="shape2"><img src="assets/img/normal/faq_2_3.png" alt="About" /></div>
                                  <div className="color-animate"></div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

               <div className="bg-smokes gr-bg1 space-top space-bottom">
                    <div className="container-fluied">
                        <div className="row" style={{justifyContent: "center"}}>
                            <div className="col-xl-6 ctn-from">
                                <div className="title-area mb-35 text-center">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <span className="sub-title"><img
                                    src="../assets/img/theme-img/title_shape_2.svg" alt="shape" />contact with us!</span>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <h2 className="sec-title">Drop Us a Line</h2>
                                </ScrollAnimation>  
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <p className="sec-texts text-center">Reach Out to us from our Contact form and we will get back to you shortly.</p>
                                </ScrollAnimation>
                                </div>
                                <form  onSubmit={this.onSubmit} method="post" className="contact-forms ajax-contact mobiledev-form">
                                    <div className="row">
                                        <div className="form-group col-md-6" >
                                            <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" style={{backgroundColor: "transparent"}}/> 
                                            <i className="fal fa-user"></i>   
                                            {nameError &&
                                            <div className="invalid-feedback" >{nameError}</div>
                                            }                                                     
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} style={{backgroundColor: "transparent"}} autoComplete="Off"/> 
                                            <i className="fal fa-envelope"></i>
                                            {emailError &&
                                                <div className="invalid-feedback">{emailError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">                                        
                                            <input type="text" id="subject"  className={subjectError ? "form-control is-invalid" : "form-control"} ref="subjectfield" name="subject" placeholder="Subject" onChange={this.handleChange} style={{backgroundColor: "transparent"}} autoComplete="Off" onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()}/>               
                                            <i className="fal fa-note-sticky"></i>                              
                                            {subjectError &&
                                                <div className="invalid-feedback">{subjectError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} onKeyDown={(evt) =>   ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} name="number" value={this.state.number} ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off"   onChange={this.maxlengthnumber} style={{background: "transparent"}}/>
                                            <i className="fal fa-phone"></i>
                                            {numberError &&
                                                <div className="invalid-feedback">{numberError}</div>
                                            }
                                        </div>                                                                           
                                        <div className="form-group col-12">
                                            <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                            className={messageError ? "form-control is-invalid" : "form-control"}  placeholder="Your Message" onChange={this.handleChange} style={{backgroundColor: "transparent"}} autoComplete="Off">
                                            </textarea> 
                                            <i className="fal fa-comment"></i>
                                            {messageError &&
                                                <div className="invalid-feedback">{messageError}</div>
                                            }
                                        </div>
                                        <div className="form-btn text-center col-12 cont-btn">
                                            <button type="submit" className="as-btn style3">Send Appointment
                                                <i className="fa-regular fa-arrow-right ms-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3"></p>
                                </form>
                            </div>                         
                        </div>
                    </div>
                </div> 
                <footer className="footer-wrapper footer-layout1 ne">
                    <div className="parti-cl">
                        <div className="footer-top">
                            <div className="logo-bg"></div>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-xl-3">
                                        <div className="footer-logo"><a href="/"><img
                                            src="assets/img/white-logo.png" alt="Traga" /></a></div>
                                    </div>
                                    <div className="col-xl-9">
                                        <div className="footer-contact-wrap">
                                            <div className="footer-contact">
                                                <div className="footer-contact_icon"><i className="fas fa-phone"></i></div>

                                                <div className="media-body"><span className="footer-contact_text">Quick Call Us:</span> <a
                                                    href="tel:+19088000393" className="footer-contact_link">(0261) - 4054978</a></div>
                                            </div>
                                            <div className="footer-contact">
                                                <div className="footer-contact_icon"><i className="fas fa-envelope"></i></div>
                                                <div className="media-body" style={{ marginLeft: "14px" }}><span className="footer-contact_text">Mail Us On:</span> <a
                                                    href="mailto:support@oengines.com" className="footer-contact_link">support@oengines.com</a>
                                                </div>
                                            </div>
                                            <div className="footer-contact">
                                                <div className="footer-contact_icon"><i className="fas fa-location-dot"></i></div>
                                                <div className="media-body" style={{ marginLeft: "14px" }}><span className="footer-contact_text">Visit Location:</span> <a
                                                    href="https://www.google.com/maps" className="footer-contact_link">436, Amby vally arcade,Utran, Surat Gujarat</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div _ngcontent-mfs-c21="" className="header-lines-decoration">
                            <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                            <span _ngcontent-mfs-c21="" className="bg-blue"></span>
                            <span _ngcontent-mfs-c21="" className="bg-white-light"></span>
                            <span _ngcontent-mfs-c21="" className="bg-orange-light"></span>
                            <span _ngcontent-mfs-c21="" className="bg-red"></span>
                            <span _ngcontent-mfs-c21="" className="bg-green"></span>
                            <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                        </div>
                        <div className="footer-tops" >
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-xl-12">
                                        <div className="newsletter-wrap">
                                            <div className="newsletter-content">

                                                <h3 className="newsletter-title">News Subscription</h3>

                                                <p className="newsletter-text">Get Latest Deals from Waker’s Inbox & Subscribe Now</p>
                                            </div>
                                            <form className="newsletter-form new-form">
                                                <div className="form-group"><input className="form-control" type="email"
                                                    placeholder="Email Address" required="" /> <i className="fal fa-envelope"></i></div>
                                                <button type="submit" className="as-btn">Subscribe</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="widget-area" >
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-md-4 col-xxl-3 col-xl-4">
                                        <div className="widget footer-widget">

                                            <h3 className="widget_title">OENGINES<p className="about-text" style={{ fontSize: "14px", display: "flex" }}> Game Studio</p></h3>
                                            <div className="as-widget-about">

                                                <p className="about-text">Rapid digital transformation in enterprises across the globe is calling for more and more dedicated innovation spaces, as the technology pillars of agile, intelligence, automation, and cloud have become the foundation for an adaptive core or digital backbone for an organization.</p>
                                                <div className="as-social"><a href="https://www.facebook.com/">

                                                    <i className="fab fa-facebook-f"></i>
                                                </a><a href="https://www.twitter.com/">

                                                        <i className="fab fa-twitter"></i>
                                                    </a><a href="https://www.linkedin.com/">

                                                        <i className="fab fa-linkedin-in"></i>
                                                    </a><a href="https://www.whatsapp.com/">

                                                        <i className="fab fa-whatsapp"></i>
                                                    </a> <a href="https://www.youtube.com/">

                                                        <i className="fab fa-youtube"></i>
                                                    </a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xl-auto col-sm-6">
                                        <div className="widget widget_nav_menu footer-widget">

                                            <h3 className="widget_title">Hiring Team</h3>
                                            <div className="menu-all-pages-container">
                                                <ul className="menu">
                                                    {Information}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xl-auto col-sm-6">
                                        <div className="widget widget_nav_menu footer-widget">
                                            <h3 className="widget_title">On Demand / Ready Services</h3>

                                            <div className="menu-all-pages-container">
                                                <ul className="menu">
                                                    <li>
                                                        <a href="/rummy">Rummy Multiplayer Development</a>
                                                    </li>                                                   
                                                    <li>
                                                        <a href="/spades_multiplayer">Spades Multiplayer Development</a>
                                                    </li>

                                                    <li>
                                                        <a href="/callbreak">Callbreak Multiplayer Development</a>
                                                    </li>
                                                    <li>
                                                        <a href="/ludo">Ludo Board Game Development</a>
                                                    </li>
                                                    <li>
                                                        <a href="/carrom">Carrom Board Game Development</a>
                                                    </li>
                                                    <li>
                                                        <a href="/bluff_card_game">Bluff Multiplayer Development</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xl-auto col-sm-6">
                                        <div className="widget widget_nav_menu footer-widget">
                                            <h3 className="widget_title">IT SERVICES</h3>
                                            <div className="menu-all-pages-container">
                                                <ul className="menu">
                                                    <li>
                                                        <a href="/webdevelopment">Web Development</a>
                                                    </li>
                                                   
                                                    <li>
                                                        <a href="/gamedevelopment">Product Management</a>
                                                    </li>
                                                    <li>
                                                        <a href="/gamedevelopment">UI/UX Design</a>
                                                    </li>
                                                    <li>
                                                        <a href="/gamedevelopment">Cloud services</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pb" id="pb"></div>
                        <div className="pc">
                            <div className="ol">
                                <a href="#" id="btn-back-to-top">
                                    <i className="fa-solid fa-arrow-up">
                                    </i>
                                </a>
                            </div>
                            <div className="bg" id="bg"></div>
                        </div>
                    </div>
                    <div className="copyright-wrap bg-title new-bg-title">
                        <div className="container abc">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6">
                                    <p className="copyright-text text-white">Copyright <i className="fal fa-copyright"></i> {sessionStorage.getItem("currentYear")} <a
                                        href="#"> OENGINES GAMES LLP</a></p>
                                </div>
                                <div className="col-lg-6 text-end d-none d-lg-block">
                                    <div className="footer-links">
                                        <ul className="d-flex" style={{ float: "right" }}>
                                            <li><a href="/terms">Terms & Condition</a></li>
                                            <li><a href="/career">Careers</a></li>
                                            <li><a href="/home/privacy">Privacy Policy</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-left"><img src="assets/img/shape/footer_shape_2.svg" alt="shape" /></div>
                </footer>
            </>
        )
    }
}

export default mobiledevelopment;
