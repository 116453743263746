import React, { Component } from "react";
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-awesome-slider/dist/styles.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from "sweetalert2";
import ReactGA from "react-ga4";

class Appointment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {}
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this);
    }

    
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    maxlengthnumber(evt) {
        if (evt.target.value.length === 11) {
            return false;
        }
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    onSubmit(e) {   
        e.preventDefault();
        let formErrors = {};
        if (this.state.name === '' || this.state.name === null) {

            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.message === '' || this.state.message === null) {
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }else if (this.state.toDate === '' || this.state.toDate === null) {
            formErrors["toDateError"] = "Date is required.";
            this.refs.toDatefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }else{

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,
                
            };

            ReactGA.event({
                category: "Appointment",
                action: JSON.stringify(formObject),
            });


            
            axios.post('https://www.oengines.com/contactus', formObject)
            .then((res) => {
             
                if(res.data !== 'undefined'){
                    Swal.fire({
                        title: "Thank you!",
                        text: "Your request has been assigned successfully.",
                        icon: "success",
                    }).then(function () {
                        window.location.reload();
                    });
                }else{
                    Swal.fire({
                        title: "Oops...!",
                        text: "Oh! Something went wrong, please try again!",
                        icon: "error",
                    }).then(function () {
                        window.location.reload();
                    });
                }
               
            });
        }
            
        
        this.setState({
            name: '', 
            email: '', 
            subject: '', 
            number: '',
            message: ''
           
        })
    }
    render() {
        const { nameError, emailError, subjectError, numberError, toDateError, messageError } = this.state.formErrors;
        return (
        <>
                <div className="sidemenu-wrapper side-model-contect  d-lg-block paginacontainer">
                    <div className="sidemenu-content">
                        <div className="widget woocommerce widget_shopping_cart">
                            <div className="widget_shopping_cart_content">
                                <div className="modal fade" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header ">
                                                <h5 className="modal-title m-auto" id="exampleModalLongTitle">Appointcfghdfsment</h5>
                                                <button type="button" className="close cls-mdl" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: "52px", position: "absolute", left: "10px", top: "-14px"}}>&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <form onSubmit={this.onSubmit} method="post" className="contact-form ajax-contact">
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                                            <i className="fal fa-user"></i>
                                                            {nameError &&
                                                                <div className="invalid-feedback" >{nameError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email"
                                                                id="email" placeholder="Email Address" onChange={this.handleChange} />
                                                            <i className="fal fa-envelope"></i>
                                                            {emailError &&
                                                                <div className="invalid-feedback">{emailError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <select name="subject" id="subject" ref="subjectfield" className={subjectError ? "form-control is-invalid" : "form-control"} onChange={this.handleChange}>
                                                                <option disabled="disabled">Select Subject
                                                                </option>
                                                                <option defaultValue={"App Development"}>App Development</option>
                                                                <option defaultValue={"Game Development"}>Game Development</option>
                                                                <option defaultValue={"Web Development"}>Web Development</option>
                                                                <option defaultValue={"Brand Marketing"}>Brand Marketing</option>
                                                                <option defaultValue={"UI/UX Designing"}>UI/UX Designing</option>
                                                                <option defaultValue={"Digital Marketing"}>Digital Marketing</option>
                                                            </select>
                                                            {subjectError &&
                                                                <div className="invalid-feedback">{subjectError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input type="number" className={numberError ? "form-control is-invalid" : "form-control"}  name="number" value={this.state.number} ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off"   onChange={this.maxlengthnumber}/>
                                                            <i className="fal fa-phone"></i>
                                                            {numberError &&
                                                                <div className="invalid-feedback">{numberError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-group col-12">
                                                            <input className="form-control datetimepickerone" id="datetimepickerone" name="toDate" onBlur={this.endDateChange} type="text" ref="toDatefield" autoComplete="off" placeholder="Appointment Date" />
                                                            <i className="fal fa-calendar"></i>
                                                            {toDateError &&
                                                                <div className="invalid-feedback">{toDateError}</div>
                                                            }
                                                        </div>

                                                        <div className="form-group col-12">
                                                            <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                                                className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange}>
                                                            </textarea>
                                                            <i className="fal fa-comment"></i>
                                                            {messageError &&
                                                                <div className="invalid-feedback">{messageError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-btn text-xl-start text-center col-12">
                                                            <button type="submit" className="as-btn style3">Send Appointment
                                                                <i className="fa-regular fa-arrow-right ms-2"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <p className="form-messages mb-0 mt-3"></p>
                                                </form>
                                                <div className="contect-us-details">
                                                    <h4 className="text-center">Get In Touch</h4>
                                                    <p className="text-context-main">Book an Appointment now to get Started a new Journey with us.</p>
                                                    <div className="conect-item">
                                                        <i className="fas fa-map-location"></i>
                                                        <div className="contect-info">
                                                            <a href="#" className="title">436, Amby vally arcade,</a>
                                                            <p className="sub-title">Utran, Surat Gujarat</p>
                                                        </div>
                                                    </div>
                                                    <div className="conect-item">
                                                        <i className="fas fa-phone"></i>
                                                        <div className="contect-info">
                                                            <a href="#" className="title">(0261) - 4054978</a>
                                                            <p className="sub-title">Mon-Fri 9am-6pm</p>
                                                        </div>
                                                    </div>
                                                    <div className="conect-item">
                                                        <i className="fas fa-envelope"></i>
                                                        <div className="contect-info">
                                                            <a href="#" className="title">support@oengines.com</a>
                                                            <p className="sub-title">Mon-Fri 9am-6pm</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto d-none d-lg-block">
                    <div className="header-button">
                        <a href="#" onClick={ () => this.props.toggleSideMenu()}
                            className="as-btn Appointment" >Make Appointment<i
                                className="fas fa-arrow-right ms-1"></i></a>
                    </div>
                </div>                                                
        </>
    )}
}
export default Appointment;