import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';


export default function Roguelike() {	
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Roguelike'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};



	const handleChange = (event) => {

	};

	

	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }

	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			
			<Header />

			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>
			<section id="hero" className="roboclick-section hero-section division">
				<div className="container">
					<div className="row card-hero pb-100 text-start">
						<div className="col-md-6 col-lg-6 mt-80 arcade-col">
							<div className="hero-17-title mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Roguelike Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt action-hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl max-conent m-auto mt-40">We are the leading Roguelike game development company that specializes in designing and developing the best Roguelike games that could entertain your users using the magic of technological versatility.</p>
								</ScrollAnimation>
							</div>
							<a href="tel:+02614054978" ><button className="robolike-theme-button theme-button mt-4">Call Now</button></a>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="robo-img">
								<img src="../assets/img/GameDevelopment/web-design.png" alt="img" />
							</div>
						</div>
					</div>
				</div>
			</section>
        
			<section className="racing-game pt-80 pb-60">
				<div className="container">
                    <div className="row">
                        <div className="action-heaings col-md-12">
                            <h2 className="text-center">Roguelike Game</h2>
                            <div className="elementor-divider element-robolike">
                                <span className="elementor-divider-separator">
                                </span>
                            </div>
                            <p className="pt-10 text mt-30">Roguelike video games combine aspects of action-adventure and role-playing games in a genre that requires you to start over every time you die in the game. Roguelike video games are typically action-adventure games in which you, as the player, must restart gameplay from the beginning each time your character dies or fails to meet the main objective.</p>
                            <p className="text mt-30">Roguelike is a subgenre of role-playing video game characterized by a dungeon crawl. That means a player moves
							through a space (dungeon) to reach some objective. In roguelike games, that space is procedurally generated, usually includes turn-based gameplay, and permanent death of the player character.</p> 
                        </div>                       
                    </div>
				</div>
			</section>

			<section className="pb-60 pt-60 why_orion">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 text-center page-heading  mb-4">
							<h2 className="title robo-title">Feature of Roguelike Game</h2>							
						</div>
						<div className="col-12 col-sm-6 col-lg-4">
							<div className="card text-center apps bg-light robolike-card">
								<span>01</span>
								<h4>Random environment generation</h4>
								<p>Roguelikes feature different room layouts with randomized placements for enemies and items each time you
								play. This is usually done with procedural generation, not total randomness, to avoid unwinnable situations.</p>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-lg-4">
							<div className="card text-center apps bg-light robolike-card">
								<span>02</span>
								<h4>Permanent death</h4>
								<p>Permadeath means when you die, you lose all progress and must start over from the beginning. You don't carry
								any progress over across runs.</p>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-lg-4">
							<div className="card text-center apps bg-light robolike-card">
								<span>03</span>
								<h4>Turn-based movement on a grid</h4>
								<p>There is no real-time element in roguelikes; time usually advances when you take a step or another action. This
								allows you to take your time and consider your actions in advance. Also, roguelikes feature a grid of tiles instead of free movement.</p>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-lg-4">
							<div className="card text-center apps bg-light robolike-card">
								<span>04</span>
								<h4>Non-modal gameplay</h4>
								<p>In roguelikes, all actions take place on the same screen. There are no special screens for battles, cutscenes, or
								similar.</p>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-lg-4">
							<div className="card text-center apps bg-light robolike-card">
								<span>05</span>
								<h4>Complexity, with limited resources</h4>
								<p>The game must allow for several solutions to problems, such as letting you break down a door if you don't have
								the key to it. Roguelikes also require you to wisely utilize limited items like healing potions, so you can't survive forever with cheap strategies.</p>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-lg-4">
							<div className="card text-center apps bg-light robolike-card">
								<span>06</span>
								<h4>A focus on killing monsters</h4>
								<p>Leaning on the early definition of "hack and slash" games, roguelikes feature battling enemies as a key element.
								There are no peaceful options.</p>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-lg-4">
							<div className="card text-center apps bg-light robolike-card">
								<span>07</span>
								<h4>An emphasis on exploration and discovery</h4>
								<p>As one example, the effects of certain magical items could change in every run, requiring the player to figure out
								what a "twisted potion" does each time.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			
			<section className="tw-bg py-5 text-white pt-40 pb-40">
				<div className="container">
					<div className="row mt-2">
							<div className="col-md-6 col-xs-12 px-3 py-3 mt-80">
								<h2 className="title text-white">Why Choose OEngines for Roguelike Game Development</h2>
								<p className="mb-3 justify text-white mt-35">OEngines has an experience span of over a decade now that displays our technical expertise in various fields. One of those fields being the game industry we strive to make top-notch gaming solutions. Our Ideology is simple and robust to deliver sustainable solutions through collaborative approach and implement constant
								research practices.</p>
								<p className="text-white">⦁	Our team comprises card game developers that are top 3% talent of the industry.</p>
								<p className="text-white">⦁	OEngines as a leading Roguelike game development company follows a calculated, coordinated and
								systemized business process.</p>
								<p className="text-white">⦁	Proficient in terms of learning progressive technology and establishing clear communication between
								teams, we justify our collaborative approach.</p>
								
							</div>
							<div className="col-md-6 col-xs-12 text-center px-3 py-3">
								<img className="img-fluid" src="../assets/img/GameDevelopment/texas-holdem-poker-game-developer.svg" alt="texas holdem online "/>
							</div>
						</div>
				</div>
			</section>

			<section className="why-choose-racing pt-80 pb-60">
				<div className="container">
					<div className="why-choose-text hire-our-action-text">
						<h2 className="text-center">Why do Developers like to develop Roguelike Game</h2>	
						<ul className="mt-35">
							<li>Less development time on levels, more time on polish</li>
							<li>There tends to be less emphasis on story, so gameplay is easier to refine (though some studios take the
							opportunity to use the formula and experiment with storytelling!)</li>
							<li>The progression cycle of roguelikes is addictive, and it’s easy to keep player retention with it</li>													
						</ul>
					</div>
				</div>
			</section>

			<section className="robolike-contect pt-70 pb-70">
				<div className="container  pt-60 pb-60">
                    <div className="row">
                        <div className="hire-our-action-text racing-contect-mian text-center">
                            <h2 className="text-white">Looking to Hire an Expert Roguelike Game Developers?</h2>
                           <button className="theme-button mt-30" onClick={handleShow}>Contact Us Now</button>
                        </div>
                    </div>
				</div>
			</section>	
			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}					
            
			<Footer />
		</div>

	);
}
