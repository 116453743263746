import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from 'react-helmet';
import Header from "./Gameheader";
import Footer from '../over_games/Footer';
import OwlCarousel from 'react-owl-carousel';
import CountUp from 'react-countup';
import '../assets/css_game/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import '../assets/css_game/dropdown-effects/fade-down.css';
import config from '../config.json';


const sliderSettings = {
	dots: false,
	items: 5,
	slidestoscroll: 1,
	autoplay: true,
	interval: 5000,
	initialslide: 0,
	autoplaytimeout: 8000,
	autoplaySpeed: 2000,
	responsive: {
		0: {
			items: 2,
			nav: false
		},
		600: {
			items: 3,
			nav: false
		},
		1000: {
			items: 4,
			nav: false,
		}
	}
}
export default function G2829() {

	const [gameDetails, setGame] = useState({});
	
	useEffect(() => {				
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		axios.get(config.GAME_DETAILS_FOR_PAGES_API+Pathname).then((res) => {
			console.log("callbreak Game Data Data is more games::::::::", res.data);
			if (res.data && res.data.length){
				setGame(res.data[0]);
			}				
		})
	}, []);


	return (
		<div>
			<Helmet>		

				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<link id="googleFonts" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800%7CShadows+Into+Light&amp;display=swap" rel="stylesheet" type="text/css"></link>
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>

			</Helmet>

			<Header />

			<section id="hero-15" className="bg-scroll hero-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-7 col-lg-6">
							<div className="hero-1-txt white-color">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-md ">2829 Games</h2>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color">First time 28 and 29 together at one place, One of the
										most addictive 28 & 29 online card games by Oengines studio. Enhance your Gaming
										experience with the 2829 Multiplayer Game Development offered by us. Our
										Developers build games for both web and mobiles including all app platforms. Our
										aim is to provide you with all the services that will meet your needs and
										requirements in a professional manner. Hire our 2829 Multiplayer Game Developers
										with their rich experience and skills to have the best 2829 Game Development
										services in India.
									</p>
								</ScrollAnimation>
								{
									gameDetails.android_link ?
										<a target="_blank" href={gameDetails.android_link} className="os-btn bg-white d-flex align-items-center">
											<div className="os-btn-ico">
												<div className="ico-50">
													<img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
												</div>
											</div>
											<div className="os-btn-txt">
												<h6 className="h6-lg" style={{color: "#2f353e"}}>Download for Android</h6>
											</div>
										</a>
										:
										''
								}

								{
									gameDetails.ios_link ? <a target="_blank" href={gameDetails.ios_link} className="os-btn mac-os-btn bg-white d-flex align-items-center">
										<div className="os-btn-ico">
											<div className="ico-50">
												<img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
											</div>
										</div>

										<div className="os-btn-txt">
											<h6 className="h6-lg">Download for IOS</h6>
										</div>
									</a> : ''
								}
								<ul className="advantages white-color clearfix mt-30">
									<li className="first-li">
										<p>Version 2.10.074</p>
									</li>
									<li className="last-li">

									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5">
							<div className="hero-17-img video-preview">
								<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
									<img className="img-fluid" src="../assets/img/GamesImg/dashboard-04.png"
										alt="hero-image" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>

				<div className="wave-shape-bottom">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 130"><path fill="#ffffff" fillOpacity="1" d="M0,64L120,80C240,96,480,128,720,128C960,128,1200,96,1320,80L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
				</div>


			</section>
			<section className="accompany-area ptb-100">
				<div className="container">
					<div className="section-title">
						<h2>2829 Games Variations</h2>
						<p>To make your 2829 game playing experience even better, use this 2829 game variations and enjoy more than before-</p>
					</div>

					<div className="tab accompany-list-tab">
						<ul className="tabs">
							<li>
								<a href="#">
									<img src="../assets/img/GamesImg/28_29_images/Variations/01.png" alt="" className="wow fadeInUp" />
									<span>28 Card Rummy</span>
								</a>
							</li>
							<li>
								<a href="#">
									<img src="../assets/img/GamesImg/28_29_images/Variations/02.png" alt="" className="wow fadeInUp" />
									<span>29 Card Rummy</span>
								</a>
							</li>
						</ul>

						<div className="tab_content">
							<div className="tabs_item">
								<div className="row align-items-center">
									<div className="col-lg-6 col-md-6">
										<div className="accompany-image">
											<img src="../assets/img/GamesImg/28_29_images/Variations/01.png" alt="" className="wow fadeInUp" />
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="accompany-content">
											<h2>28 Card Rummy</h2>
											<p>28 card rummy is a variant of 21 cards Indian rummy where players play for points with pre-decided coin value. One player who makes the first valid declaration wins the game with 0 points and wins all the coins based on the opponent's total points. It is one of the most popular and fastest variants of rummy games.</p>
											<a href="#" className="accompany-btn mt-20">
												Read More
												<i className='bx bx-right-arrow-alt'></i>
											</a>
										</div>
									</div>
								</div>
							</div>

							<div className="tabs_item">
								<div className="row align-items-center">
									<div className="col-lg-6 col-md-6">
										<div className="accompany-image">
											<img src="../assets/img/GamesImg/28_29_images/Variations/02.png" alt="" className="wow fadeInUp" />
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="accompany-content">
											<h2>29 Card Rummy</h2>
											<p>29 card rummy is a variant of 21 cards Indian rummy where players play for points with pre-decided coin value. One player who makes the first valid declaration wins the game with 0 points and wins all the coins based on the opponent's total points. It is one of the most popular and fastest variants of rummy games.</p>
											<a href="#" className="accompany-btn mt-20">
												Read More
												<i className='bx bx-right-arrow-alt'></i>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="content-2" className="content-2 pb-60 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center">

						<div className="col-md-5 col-lg-6">

							<div className="rel img-block left-column wow fadeInRight">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<img className="img-fluid" src="../assets/img/GamesImg/28_29_images/img-10.png"
										alt="content-image" />
								</ScrollAnimation>
							</div>

						</div>
						<div className="col-md-7 col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">
								<ScrollAnimation animateIn="animate__animated animate__fadeInright">
									<h2 className="h2-xs">2829 Multiplayer Game Development Services</h2>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__animated animate__fadeInright">
									<p className="p-lg">Our Company is a leading 2829 Multiplayer Card game Development
										Company in India. Our team of 2829 Multiplayer Card game Developers like
										Designers and programmers strives to give you the best 2829 Multiplayer Card
										game software in terms of quality. Our 2829 Multiplayer Card game developers
										specifically keep clients in the loop throughout the game process to ensure that
										the clients are highly satisfied with the results. We use advanced technologies
										to create a game that supports android, iOS, Desktop & all other platforms. Our
										developers make sure that you and your audience receive memorable hands-on
										experiences. For that, our passionate gaming coders make use of the latest
										mobile technology.
									</p>
									<p className="p-lg">Adept 2829 Multiplayer Card game providers are working hard on
										different 2829 Multiplayer Card game app solutions consisting of varied options
										and blends of features, functionalities, modules, etc. to perfectly deliver you
										the best idea for 2829 Multiplayer Card game app development. We will provide
										you with a rich-featured app that will help you to gain 2829 more Multiplayer
										Card game users. Set your 2829 Multiplayer Card game to a level higher than
										other 2829 Multiplayer Card game developers with us.
									</p>
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="features-8" className="pb-60 features-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-70">
								<ScrollAnimation animateIn="animate__animated animate__fadeInup">
									<h2 className="h2-md">2829 Game Script</h2>
								</ScrollAnimation>
							</div>
						</div>
					</div>
					<div className="fbox-8-wrapper text-center">
						<div className="row row-cols-1 row-cols-md-3 justify-content-center">
							<div className="col">
								<div className="fbox-8 mb-40 wow fadeInUp">

									<div className="fbox-img">
										<img className="img-fluid"
											src="../assets/img/GamesImg/28_29_images/2829online.png"
											alt="feature-icon" />
									</div>
									<ScrollAnimation animateIn="animate__animated animate__fadeInup">
										<h5 className="h5-md"> Desktop Version</h5>

										<p className="p-lg">This version is mainly for Windows and MacOS. You can simply
											download and install this version and good to go.
										</p>
									</ScrollAnimation>
								</div>
							</div>
							<div className="col">
								<div className="fbox-8 mb-40 wow fadeInUp">
									<div className="fbox-img">
										<img className="img-fluid change"
											src="../assets/img/GamesImg/28_29_images/img-01.png" alt="feature-icon" />
									</div>
									<ScrollAnimation animateIn="animate__animated animate__fadeInup">
										<h5 className="h5-md">IOS Version</h5>
										<p className="p-lg">Our 2829 game software mobile version is available for IOS,
											Android and Windows app.
										</p>
									</ScrollAnimation>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="content-9" className="content-9 bg-whitesmoke-gradient pt-100 content-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-10 col-lg-8 text-black">
							<div className="section-title title-02 mb-60">
								<h2 className="h2-xs">Discover powerful features to boost your productivity</h2>
							</div>
						</div>
					</div>
					<div className="img-block">
						<div className="row">
							<div className="col-md-6">
								<div className="about-rummy">
									<ScrollAnimation animateIn="animate__animated animate__fadeInup">
										<h2 className="heading">What is an Online 2829 Game?</h2>
										<p>2829 Multiplayer Card Game is a popular South Indian Card Game, also played
											in South Asian countries. 2829 Multiplayer Card Game consists of 4 players
											with 2 players facing each other forming a team. 32 cards from a standard
											52-card deck are used to play. It is an Indian trick-taking card game for
											four players, in which the Jack and the Nine are the highest cards in every
											suit, followed by ace and ten.</p>
									</ScrollAnimation>
								</div>
							</div>
							<div className="col-md-6">
								<div className="content-9-img video-preview wow fadeInUp">

									<a className="video-popup1" href="https://www.youtube.com/embed/SZEflIVnhH8" width="500px">
										<div className="video-btn video-btn-xl bg-red ico-90">
											<div className="video-block-wrapper"><span
												className="flaticon-play-button"></span></div>
										</div>
									</a>
									<img className="img-fluid" src="../assets/img/GamesImg/28_29_images/dashboard-07.png"
										alt="video-preview" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="statistic-2" className="wide-100 statistic-section division">
				<div className="container">
					<div className="row d-flex align-items-center">

						<div className="col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">

								<h3 className="h3-xs">More than 10,000 users around the world are already using 2829
									actively</h3>

							</div>
						</div>
						<div className="col-sm-5 col-md-4 col-lg-3 offset-sm-1 offset-md-2 offset-lg-1">
							<div className="statistic-block wow fadeInUp">
								<h2 className="h2-title-xs statistic-number"><span className="count-element"><CountUp end={10000} duration={10} /></span>
								</h2>
								<p className="p-lg mt-20">Active downloads <br /> from the community</p>
							</div>
						</div>
						<div className="col-sm-5 col-md-4 col-lg-2">
							<div className="statistic-block wow fadeInUp">
								<h2 className="h2-title-xs statistic-number">
									<span className="count-element"><CountUp end={3} duration={5} /></span>.<span className="count-element"><CountUp end={4} duration={8} /></span>
								</h2>
								<div className="txt-block-rating ico-15 yellow-color">
									<span className="flaticon-star-1"></span>
									<span className="flaticon-star-1"></span>
									<span className="flaticon-star-1"></span>
									<span className="flaticon-star-half-empty"></span>
								</div>
								<p className="p-lg txt-400">34 Rating</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="space-bottom space-top contact-infos ">
				<div className="container">
					<div className="row text-center pt-4">
						<div className="col">
							<h2 className="word-rotator slide font-weight-bold text-8 mb-25">
								<span>Porto is&nbsp;</span>
								<span className="word-rotator-words bg-primary" style={{ padding: "0 20px 0 0" }}>
									<span className="spinny-words text-start">
										<span>incredibly</span>
										<span>especially</span>
										<span>extremely</span>
										<span>incredibly</span>
										<span>especially</span>
										<span>extremely</span>
									</span>
								</span>
								<span>&nbsp;&nbsp;beautiful and fully responsive.</span>
							</h2>
							<p className="lead appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300" style={{ animationDelay: "300ms", maxWidth: "880px", margin: "10px auto" }}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce elementum, nulla vel pellentesque consequat, ante nulla hendrerit arcu, ac tincidunt mauris lacus sed leo.
							</p>
						</div>
					</div>
					<div className="row text-center mt-50">
						<div>
							<OwlCarousel className='owl-theme' loop margin={10} {...sliderSettings} nav>
								<div>
									<img src="assets/img/language/logo-1.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-2.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-3.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-4.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-5.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-6.png" alt="img not found" />
								</div>
							</OwlCarousel>
						</div>
					</div>
					<div className="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
						<div className="home-concept mt-50">
							<div className="container-flied">

								<div className="row text-center">
									<span className="sun"></span>
									<span className="cloud"></span>
									<div className="col-lg-2 ms-lg-auto">
										<div className="process-image">
											<img src="assets/img/GamesImg/ludo_images/home-concept-item-1.png" alt="" />
											<strong>Strategy</strong>
										</div>
									</div>
									<div className="col-lg-2">
										<div className="process-image process-image-on-middle">
											<img src="assets/img/GamesImg/ludo_images/home-concept-item-2.png" alt="" />
											<strong>Planning</strong>
										</div>
									</div>
									<div className="col-lg-2">
										<div className="process-image">
											<img src="assets/img/GamesImg/ludo_images/home-concept-item-3.png" alt="" />
											<strong>Build</strong>
										</div>
									</div>
									<div className="col-lg-4 ms-lg-auto">
										<div className="project-image">
											<div id="fcSlideshow" className="fc-slideshow">
												<ul className="fc-slides hero-carousel verticalFlip">
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-1.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-2.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-3.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-1.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-2.jpg" alt="" /></a></li>
												</ul>
											</div>
											<strong className="our-work">Our Work</strong>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="content-3" className="content-3 wide-60 content-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-70">
								<h2 className="h2-md">Get Ready to Be Surprised</h2>
								<p className="p-xl">To make it easy and convenient for you to play the game, we have
									added various features to the game.
								</p>
							</div>
						</div>
					</div>
					<div className="top-row pb-50">
						<div className="row d-flex align-items-center">
							<div className="col-md-5 col-lg-6">
								<div className="img-block left-column wow fadeInRight">
									<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
										<img className="img-fluid" src="../assets/img/GamesImg/28_29_images/img-01.png"
											alt="content-image" />
									</ScrollAnimation>
								</div>
							</div>
							<div className="col-md-7 col-lg-6">
								<div className="txt-block right-column wow fadeInLeft">
									<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
										<h2 className="h2-xs">Online 2829 multiplayer Game Features</h2>
										<p className="p-lg">28 & 29 online multiplayer game offers the strategy elements of
											Texas Hold’em Poker card and luck elements of Slot games, Daily reward
											Roulette with Crazy graphics.
										</p>
										<div className="txt-box">
											<ul className="simple-list">
												<li className="list-item">
													<p className="p-md">Leaderboard to get competition with worldwide
														players with 28 & 29 online multiplayer game.
													</p>
												</li>
												<li className="list-item">
													<p className="p-md">Timer Bonus Get Time Based Bonus Coins in the 28 and
														29 multiplayer games and collect it.
													</p>
												</li>
												<li>
													<p className="p-md">Daily Bonus Get Daily Wheel with 28 and 29
														multiplayer games and collect the coins.
													</p>
												</li>
												<li>
													<p className="p-md">Best sound effects and easy controls.
													</p>
												</li>
												<li>
													<p className="p-md">Easily take and throw the card from the suit in our
														2829 multiplayer card game.
													</p>
												</li>
												<li>
													<p className="p-md">28,29 online multiplayer is a trick-taking card game
													</p>
												</li>
												<li>
													<p className="p-md">28 & 29 multiplayer card game brings this class
														4-player contract trick-taking card game to Google Play, with
														the high quality of Oengines Games.
													</p>
												</li>
											</ul>
										</div>
									</ScrollAnimation>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="techno-sec">
				<div className="over-techno">
					<div className="heading">
						<h2>Our Professionals are working on the following top-notch techs</h2>
						<p>We build the nexus of talent and innovation with Optimum oengines</p>
					</div>
					<div className="row align">
						<div className="col-md-2 box">
							<div className="nodejs wow fadeInUp">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/nodejs-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box">
							<div className="javascript  wow fadeInUp">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/reactjs-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box wow fadeInUp">
							<div className="rabbit">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/mean-stack.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box wow fadeInUp">
							<div className="mongodb">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/mongodb-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box wow fadeInUp">
							<div className="redis ">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/unity-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box  wow fadeInUp">
							<div className="socket">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/angular-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="platform-area plt-area game-plt-area ptb-100">
				<div className="container-fluid">
					<div className="section-title">
						<h2>Our Features</h2>
					</div>

					<div className="tab platform-list-tab">
						<div className="row align-items-center mt-50">
							<div className="col-lg-3">
								<ul className="tabs">
									<li>
										<a href="#" className="bg-f12711">
											<i className='bx bx-right-arrow-alt'></i>
											Classic Table
										</a>
									</li>
									<li>
										<a href="#" className="bg-0067ff">
											<i className='bx bx-right-arrow-alt'></i>
											Multi-Languages
										</a>
									</li>
									<li>
										<a href="#" className="bg-1b2b37">
											<i className='bx bx-right-arrow-alt'></i>
											Daily Bonuse
										</a>
									</li>
									<li>
										<a href="#" className="bg-f12711">
											<i className='bx bx-right-arrow-alt'></i>
											Customize Rummy Solution
										</a>
									</li>
								</ul>
							</div>

							<div className="col-lg-9">
								<div className="tab_content">
									<div className="tabs_item">
										<div className="row align-items-center">
											<div className="col-lg-6">
												<div className="platform-image">
													<img src="../assets/img/GamesImg/icon/multi-table.png" alt="" />
												</div>
											</div>

											<div className="col-lg-6">
												<div className="platform-content">
													<h2>Classic Table</h2>
													<p>Choose your bid with your partner and gives challenges to the opponent team with the 28 and 29 multiplayer card game</p>
												</div>
											</div>
										</div>
									</div>

									<div className="tabs_item">
										<div className="row align-items-center">
											<div className="col-lg-6">
												<div className="platform-image">
													<img src="../assets/img/GamesImg/icon/multilingual.png" alt="" />
												</div>
											</div>

											<div className="col-lg-6">
												<div className="platform-content">
													<h2>Multi-Languages</h2>
													<p>We create an Rummy Multiplayer Card game app/software/website supporting different regional and native languages for a better gaming experience.</p>
												</div>
											</div>
										</div>
									</div>

									<div className="tabs_item">
										<div className="row align-items-center">
											<div className="col-lg-6">
												<div className="platform-image">
													<img src="../assets/img/GamesImg/icon/dailybonus.png" alt="" />
												</div>
											</div>

											<div className="col-lg-6">
												<div className="platform-content">
													<h2>Daily Bonuse</h2>
													<p>Play the game Regularly and get Daily Bonus Points.</p>
												</div>
											</div>
										</div>
									</div>

									<div className="tabs_item">
										<div className="row align-items-center">
											<div className="col-lg-6">
												<div className="platform-image">
													<img src="../assets/img/GamesImg/icon/customizerummysolution.png" alt="" />
												</div>
											</div>

											<div className="col-lg-6">
												<div className="platform-content">
													<h2>Customize Rummy Solution</h2>
													<p>Have a particular feature in mind? We will incorporate it
														into your rummy game according to your exact requisites.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="content-2" className="content-2 pb-60 content-section division pt-50">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-7 col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<h2 className="h2-xs">Make it simpler with Quick Commands</h2>

									<ul className="simple-list">
										<li className="list-item">
											<p className="p-lg">Fringilla risus, luctus mauris orci auctor euismod iaculis
												luctus
												magna purus pretium ligula purus undo quaerat tempor sapien rutrum
												mauris quaerat ultrice
											</p>
										</li>
										<li className="list-item">
											<p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
										</li>
										<li className="list-item">
											<p className="p-lg">Nemo ipsam egestas volute turpis dolores undo ultrice
												aliquam
												quaerat at sodales sapien purus
											</p>
										</li>
									</ul>
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-5 col-lg-6">
							<div className="rel img-block left-column wow fadeInRight">
								<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
									<img className="img-fluid" src="../assets/img/GamesImg/28_29_images/img-07.png"
										alt="content-image" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="faqs-2" className="pb-60 faqs-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-80">
								<h2 className="h2-md">Frequently Asked Question?</h2>
							</div>
						</div>
					</div>
					<div className="faqs-2-questions">
						<div className="row row-cols-1 row-cols-lg-2">
							<div className="col">
								<div className="questions-holder pr-15">

									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">How Much Time Does it Take to Develop a 2829 Game?</h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg"> 2829 game development is a complex process, so it will take
												between six months and a year.
											</p>
										</ScrollAnimation>
									</div>

									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Will You Provide Both the 2829 Multiplayer Card game
												Website & Mobile App Development Services? </h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">Yes, we offer a range of 2829 Multiplayer Card game
												development services including website development, mobile app
												development, and software development.
											</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Do you provide continued maintenance support for 2829
												Multiplayer mobile game development? </h5>
										</ScrollAnimation>
										<ul className="simple-list">
											<li className="list-item">
												<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
													<p className="p-lg">Do you provide continued maintenance support for
														2829 Multiplayer mobile game development?
													</p>
												</ScrollAnimation>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="questions-holder pl-15">
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Is it possible to include the multi-table feature in my
												2829 Multiplayer Card game? </h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">Yes, we can include the multi-table feature in your 2829
												Multiplayer Card game. This feature would enable players to play up to
												three or more games simultaneously.
											</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Is 2829 Multiplayer Card game legal in India? </h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">2829 Multiplayer Card is a skill-based game requiring a
												level of strategy, tactic, knowledge, and experience, so it is legal.
												Other luck-based games are illegal in India.
											</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md"> Where Do You Store All Data of This 2829 Multiplayer Card
												game? </h5>
										</ScrollAnimation>

										<ul className="simple-list">
											<li className="list-item">
												<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
													<p className="p-lg">Answer: The answer to this question completely
														depends upon you. We prefer to store your 2829 Multiplayer Card
														game data on highly secured gaming servers. If you have any
														recommendations for the gaming server, then you may discuss them
														with us and we will store the data on the chosen server. </p>
												</ScrollAnimation>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 text-center pt-25">


						</div>
					</div>
				</div>
			</section>
			<section className="variation-main">
				<div className="container">
					<div className="variation-inner">
						<div className="heading">
							<h2>2829 Games Screen Shot And Reviews</h2>
							<p>We provide complete and advanced 2829 Game development services at our Oengines Games to reach the targeted audience base.</p>
						</div>
						<OwlCarousel items={3}
							className="owl-theme"
							loop autoplay={true}
							margin={8} >
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/28_29_images/Screen_shot/1.png"
										alt="Img not found" />
									<h5>1. Sandy</h5>
									<p className="set-height">Nice game...combo of 28 and 29...👍</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/28_29_images/Screen_shot/2.png"
										alt="Img not found" />
									<h5>2. UV Can</h5>
									<p className="set-height">Good game. play with my friends is very appreciate.</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/28_29_images/Screen_shot/3.png"
										alt="Img not found" />
									<h5>3. Ravinder Singh</h5>
									<p className="set-height">Excellent game with amazing Graphics.</p>
								</div>
							</div>
						</OwlCarousel>
					</div>
				</div>
			</section>
			<section className="Games" id="gaming">
				<div className="game-change" id="chng">
					<img src="../assets/img/GamesImg/bhabhi_offline_images/dashboard-07.png" alt="img not found" className="changes" />
					<img src="../assets/img/GamesImg/bhabhi_offline_images/playbutton.png" alt="" className="img-chng" />
				</div>
				<div className="run-games"></div>
			</section>
			<section id="content-2" className="bg-04 wide-80 cta-section division">
				<div className="container">
					<div className="row justify-content-md-center">
						<div className="col col-lg-8">
							<div className="cta-9-txt text-center">
								<h2 className="h2-xs white-color">Getting Started is Fast & Easy</h2>
								<p className="p-xl white-color mb-35">Download 2829 Game on your Windows or Mac and get
									30 days free trial.</p>
								<div className="d-flex justify-content-center gap-4">
									{
										gameDetails.android_link ?
											<a target="_blank" href={gameDetails.android_link} className="os-btn bg-white d-flex align-items-center">
												<div className="os-btn-ico">
													<div className="ico-50">
														<img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
													</div>
												</div>
												<div className="os-btn-txt">
													<h6 className="h6-lg">Download for Android</h6>
												</div>
											</a>
											:
											''
									}

									{
										gameDetails.ios_link ? <a target="_blank" href={gameDetails.ios_link} className="os-btn mac-os-btn bg-white d-flex align-items-center">
											<div className="os-btn-ico">
												<div className="ico-50">
													<img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
												</div>
											</div>

											<div className="os-btn-txt">
												<h6 className="h6-lg">Download for IOS</h6>
											</div>
										</a> : ''
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</div>
	);
}


