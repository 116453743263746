import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import '../../src/assets/css/app.min.css';
import axios from 'axios';
import Header from "../Header";

class career extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
        }
    }
    onClickShowdetails(job){       
        console.log("job is:::::::",job);
        localStorage.setItem("Job", JSON.stringify(job));        
        window.location.href = '/careerdetail';       
    }
    componentDidMount() {
  
        axios.get("https://www.oengines.com/careers")
            .then(response => {
                console.log("Data is::::::::",response.data);               
                this.setState({Job: response.data});
                this.setState({Description: response.data[0].description});

            })
            .catch(function (error) {
                console.log(error);
            })

    }
    render() {
        const { nameError, emailError, subjectError, numberError, toDateError, messageError } = this.state.formErrors;
        const myStyleone = {
            background: "#1f1f22",
            width: "100%",
            zIndex: 2,
        };
         var Information;
         console.log("Enter careers data:::::::::",this.state.Job)
        if(this.state.Job.length){
            Information = this.state.Job.map((job,Index)=>       
                <>
                <div className="experience-box" key={job._id}><span className="experience-box_num">{job.position > 1 ? (job.position - 1)+"+" : job.position}</span>
                    <h6 className="experience-box_title">{job.job}</h6>
                    <p className="experience-box_text">Location - {job.job_location}</p>
                    <a href="#!" onClick={() => this.onClickShowdetails(job)}>View Details</a>
                </div>

                    {/* <div className="col-md-4 col-sm-6 col-xl-4" key={job._id}>
                        <div className="project-card">
                            <div className="project-img"><img src="assets/img/project/project_1_1.jpg" alt="project image"/>
                            </div>
                            <div className="project-content-wrap">
                                <div className="project-content">
                                    <div className="box-particle" id="project-p1"></div>
                                    <h3 className="box-title"><a href="#!" onClick={() => this.onClickShowdetails(job)}>{job.job}</a></h3>
                                    <p className="project-subtitle">Technology</p><a href="#!"   className="icon-btn popup-image"onClick={() => this.onClickShowdetails(job)} ><i className="far fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </>
            )
        }else{
            Information = <div><h4 style={{color: "Red", fontSize: "20px"}}>No Record Found !</h4></div>;
        }

        return (
            <>
                <Helmet>
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" type="text/javascript" />                    
                    <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js" type="text/javascript"></script>
                    <script src="../assets/js/script.js" type="Javascript"></script>

                </Helmet>
                {/* <div className="loader" ><span>Loading...</span></div> */}
                <div className="modal fade mopen" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title m-auto" id="exampleModalLabel">QUOTE</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{fontSize: "65px", position: "absolute", right: "10px", bottom: "17px"}}>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form  onSubmit={this.onSubmit} method="post" className="contact-form ajax-contact">
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off"/> 
                                            <i className="fal fa-user"></i>   
                                            {nameError &&
                                            <div className="invalid-feedback" >{nameError}</div>
                                            }                                                     
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange}  autoComplete="Off"/> 
                                            <i className="fal fa-envelope"></i>
                                            {emailError &&
                                                <div className="invalid-feedback">{emailError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <select name="subject" id="subject" ref="subjectfield" className={subjectError ? "form-control is-invalid" : "form-control"} onChange={this.handleChange} autoComplete="Off">
                                                <option disabled="disabled">Select Subject
                                                </option>
                                                <option defaultValue={"Web Development"}>Web Development</option>
                                                <option defaultValue={"Brand Marketing"}>Brand Marketing</option>
                                                <option defaultValue={"UI/UX Designing"}>UI/UX Designing</option>
                                                <option defaultValue={"Digital Marketing"}>Digital Marketing</option>
                                            </select>                                                        
                                            {subjectError &&
                                                <div className="invalid-feedback">{subjectError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="tel" className={numberError ? "form-control is-invalid" : "form-control"} name="number" ref="numberfield" id="number" placeholder="Phone Number" onChange={this.handleChange} autoComplete="Off"  maxLength={10}/> 
                                            <i className="fal fa-phone"></i>
                                            {numberError &&
                                                <div className="invalid-feedback">{numberError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-12">
                                            <input className="form-control datetimepicker" id="datetimepicker" name="toDate" onBlur={this.endDateChange} type="text" ref="toDatefield" autoComplete="off" placeholder="Appointment Date"/>
                                            <i className="fal fa-calendar"></i>
                                            {toDateError &&
                                                <div className="invalid-feedback">{toDateError}</div>
                                            }
                                        </div>
                                        
                                        <div className="form-group col-12">
                                            <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                            className={messageError ? "form-control is-invalid" : "form-control"}  placeholder="Your Message" onChange={this.handleChange} autoComplete="Off">
                                            </textarea> 
                                            <i className="fal fa-comment"></i>
                                            {messageError &&
                                                <div className="invalid-feedback">{messageError}</div>
                                            }
                                        </div>
                                        <div className="form-btn text-xl-start text-center col-12">
                                            <button type="submit" className="as-btn style3">Send QUOTE
                                                <i className="fa-regular fa-arrow-right ms-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3"></p>
                                </form>
                            </div>                     
                        </div>
                    </div>
                </div>
                <div className="modal fade modelopencontect fade-scale" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
                                <button type="button" className="close cls-animate" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{fontSize: "65px", position: "absolute", right: "10px", bottom: "17px"}}>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form  onSubmit={this.onSubmit} method="post" className="contact-form ajax-contact">
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off"/> 
                                            <i className="fal fa-user"></i>   
                                            {nameError &&
                                            <div className="invalid-feedback" >{nameError}</div>
                                            }                                                     
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange}  autoComplete="Off"/> 
                                            <i className="fal fa-envelope"></i>
                                            {emailError &&
                                                <div className="invalid-feedback">{emailError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <select name="subject" id="subject" ref="subjectfield" className={subjectError ? "form-control is-invalid" : "form-control"} onChange={this.handleChange}>
                                                <option disabled="disabled">Select Subject
                                                </option>
                                                <option defaultValue={"Web Development"}>Web Development</option>
                                                <option defaultValue={"Brand Marketing"}>Brand Marketing</option>
                                                <option defaultValue={"UI/UX Designing"}>UI/UX Designing</option>
                                                <option defaultValue={"Digital Marketing"}>Digital Marketing</option>
                                            </select>
                                            {subjectError &&
                                                <div className="invalid-feedback">{subjectError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="tel" className={numberError ? "form-control is-invalid" : "form-control"} name="number" ref="numberfield" id="number" placeholder="Phone Number" onChange={this.handleChange} autoComplete="Off"/> 
                                            <i className="fal fa-phone"></i>
                                            {numberError &&
                                                <div className="invalid-feedback">{numberError}</div>
                                            }
                                        </div>                                                                             
                                        <div className="form-group col-12">
                                            <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                            className={messageError ? "form-control is-invalid" : "form-control"}  placeholder="Your Message" onChange={this.handleChange} autoComplete="Off">
                                            </textarea> 
                                            <i className="fal fa-comment"></i>
                                            {messageError &&
                                                <div className="invalid-feedback">{messageError}</div>
                                            }
                                        </div>
                                        <div className="form-btn text-xl-start text-center col-12">
                                            <button type="submit" className="as-btn style3">Send Contect Details
                                                <i className="fa-regular fa-arrow-right ms-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3"></p>
                                </form>
                            </div>                     
                        </div>
                    </div>
                </div>
               <Header />
                <div className="breadcumb-wrapper" style={myStyleone}>
                    <div className="container">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">Shine bright with us</h1>
                            <ul className="breadcumb-menu">
                                <li>We believe work should be fun, meaningful, and challenging. <br></br>Our mission is to help teams succeed by encouraging the respectful and intentional use of time, and that mission speaks to everything we do as a company.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <section className="space">
                    <div className="container">
                        <div className="row gy-4 spc_career">
                            <div class="experience-box-wrap">
                            {Information}                            
                            </div>
                            
                        </div>
                    </div>
                    <div className="shape-mockup" data-top="0%" data-right="0%"><img src="assets/img/shape/tech_shape_1.png"
                        alt="shape"/></div>
                    <div className="shape-mockup" data-bottom="0%" data-left="0%" style={{left: "0"}}><img src="assets/img/shape/tech_shape_2.png"
                        alt="shape"/></div>
                </section>

                

                <footer className="footer-wrapper footer-layout1">
                    <div className="pb" id="pb"></div>
                    <div className="pc">
                        <div className="ol">
                            <a href="#" id="btn-back-to-top">
                                <i className="fa-solid fa-arrow-up">
                                </i>
                            </a>
                        </div>
                        <div className="bg" id="bg"></div>
                    </div>
                    <div className="copyright-wrap bg-title">
                        <div className="container abc">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6"> 
                                        <p className="copyright-text text-white">Copyright <i className="fal fa-copyright"></i> {sessionStorage.getItem("currentYear")} <a
                                        href="#"> OENGINES GAMES LLP</a></p>
                                </div>
                                <div className="col-lg-6 text-end d-none d-lg-block">
                                    <div className="footer-links">
                                        <ul className="d-flex" style={{ float: "right" }}>
                                            <li><a href="/terms">Terms & Condition</a></li>
                                            <li><a href="/career">Careers</a></li>
                                            <li><a href="/home/privacy">Privacy Policy</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-left"><img src="assets/img/shape/footer_shape_2.svg" alt="shape"/></div>
                </footer>
               

            </>
        )
    }
}

export default career;

