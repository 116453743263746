import React from "react";
import { Helmet } from 'react-helmet';
import '../../src/assets/css/style.css';
import '../../src/assets/css/fontawesome.min.css';
import OwlCarousel from 'react-owl-carousel';
import 'react-awesome-slider/dist/styles.css';
import '../../src/assets/css/responsive.css';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import Header from "../Header";
import ScrollAnimation from 'react-animate-on-scroll';
import Swal from "sweetalert2";
import moment from 'moment';
import DOMPurify from 'isomorphic-dompurify';
import Footer from "../Footer";

class WebDevelopment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            blog: [],
            toDate: '',
            formErrors: {},
            isActive: false,
        }
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this);
    }
    componentDidMount() {

        axios.get("https://www.oengines.com/careers")
            .then(response => {
                console.log("Data is::::::::", response.data);
                this.setState({ Job: response.data });
                this.setState({ Description: response.data[0].description });

            })
            .catch(function (error) {
                console.log(error);
            })


            axios.get("https://localhost:3001/employee-posts")
            .then(response => {
                console.log("blogData is::::::::",response.data);                               
                this.setState({blog: response.data});                  
            })
            .catch(function (error) {
                console.log(error);
            })


    }
    onClickShowdetails(job) {
        console.log("job is:::::::", job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    handlevideopopup(e) {
        this.setState({ isActive: !this.state.isActive });
    }
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    maxlengthnumber(evt) {
        if (evt.target.value.length === 11) {
            return false;
        }
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    onClickblogdetails(blog){      
        console.log("blog is:::::::",blog);
        localStorage.setItem("Blog", JSON.stringify(blog));
        window.location.href = '/blogdetails';          
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};

        if (this.state.name === '' || this.state.name === null) {
            console.log("Nmae")
            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            console.log("mail")
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            console.log("subject")
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            console.log("number")
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.message === '' || this.state.message === null) {
            console.log("message")
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else {

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: 'WebDevelopment' + '-' + this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,

            };
            console.log("formObject::::::::::;", formObject);
            axios.post("https://www.oengines.com/contactus", formObject)
                .then((res) => {
                    console.log("Response is:::::::::", res);
                    if (res.data !== 'undefined') {
                        Swal.fire({
                            title: "Thank you!",
                            text: "Your request has been assigned successfully.",
                            icon: "success",
                        }).then(function () {
                            window.location.reload();
                        });
                    } else {
                        Swal.fire({
                            title: "Oops...!",
                            text: "Oh! Something went wrong, please try again!",
                            icon: "error",
                        }).then(function () {
                            window.location.reload();
                        });
                    }

                });
        }
    }

    render() {
        var settings = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 3,
                    nav: true,
                    loop: true
                }

            }
        };      
        var setweb = {
            dots: false,
            slidestoshow: 3,
            slidestoscroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            initialslide: 0,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                576: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1000: {
                    items: 3,
                    nav: true,
                    loop: true
                },
                1200: {
                    items: 4,
                    nav: true,
                    loop: true
                }
            }
        };

        var Information;
        if (this.state.Job.length) {
            Information = this.state.Job.map((job, Index) =>
                <>
                    <li>
                        <a onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>
                </>
            )
        } else {
            Information = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }
        var blogcarousel;
        var localcategories = localStorage.getItem("catdata");        
        if(this.state.blog.length){                         
            blogcarousel = 
            <OwlCarousel className='owl-theme' loop margin={10} {...settings} animationdelay="3s" nav>
                {this.state.blog.map((blog, index) => {
                    if (blog.blogcategorie === localcategories) {

                        return <>
                            <div className="as-blog blog-single has-post-thumbnail" style={{ height: "95%" }}>
                                <div className="blog-img as-carousel" data-arrows="true" data-slide-show="1" data-fade="true">
                                    <a>
                                        <img src={`${blog.blog_img}`} alt="Blog Image" />
                                    </a>
                                </div>

                                <div className="blog-content" >
                                    <div className="blog-meta"><a className="author"><img
                                        src="assets/img/blog/author-1-1.png" alt="avater" /> By {blog.employee_name}</a> <a
                                        ><i className="fa-light fa-calendar-days"></i>{moment(blog.createdat).format('YYYY-MM-DD')}</a>
                                    </div>
                                    <h2 className="blog-title"><a >{blog.blogtitle}</a></h2>
                                    <div className="box" id="section">
                                        <div className="content-full article" id="article" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog.sub_title) }}></div>
                                    </div>
                                    <a href="#!" onClick={() => this.onClickblogdetails(blog)} className="line-btn">Read More</a>
                                </div>
                            </div>

                        </>

                    } else {
                        return null;
                    }
                }
                )}
            </OwlCarousel>
            }else{
                blogcarousel = <div><h4 style={{color: "Red", fontSize: "20px"}}>No Record Found !</h4></div>;
            }

        const { nameError, emailError, subjectError, numberError, messageError } = this.state.formErrors;
        return (
            <>
                <Helmet>

                    <link href="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/css/coreui.min.css" rel="stylesheet" integrity="sha384-UkVD+zxJKGsZP3s/JuRzapi4dQrDDuEf/kHphzg8P3v8wuQ6m9RLjTkPGeFcglQU" crossorigin="anonymous" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                    <script src="https://cdn.jsdelivr.net/npm/@coreui/coreui@4.2.0/dist/js/coreui.bundle.min.js" integrity="sha384-n0qOYeB4ohUPebL1M9qb/hfYkTp4lvnZM6U6phkRofqsMzK29IdkBJPegsyfj/r4" crossorigin="anonymous"></script>                    
                    <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js" type="text/javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                    <script src="../assets/js/script.js" type="text/Javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                </Helmet>
                {/* <div className="loader" ><span>Loading...</span></div> */}
                <Header />
                <Modal size="lg" show={this.state.isActive} onClick={this.handleClickInsideModal} className="modal_contect video-effect-modal modalvideos" centered>
                    <Modal.Header closeButton className="modal_header p-0 border-none" style={{ border: "none" }} onHide={() => this.handlevideopopup()} >
                    </Modal.Header>
                    <Modal.Body>
                        <iframe width="960" height="415" src="https://www.youtube.com/embed/9xwazD5SyVg?autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </Modal.Body>
                </Modal>
                <div className="as-hero-wrapper hero-3" id="hero">
                    <div className="hero-img"><img src="assets/img/hero/hero_img_3_1.png" alt="Hero Image" />
                        <div className="shape-blur"></div>
                    </div>
                    <div className="hero-img-phone"><img src="assets/img/hero/hero_img_3_phone.png" alt="Hero Image" />
                        <div className="shape-blur"></div>
                    </div>
                    <div className="container">
                        <div className="hero-style3">
                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                <span className="hero-subtitle">Creative Web Designers</span>
                            </ScrollAnimation>
                            <h1 className="hero-title">Best Web Development Company</h1>
                            <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                <p className="hero-text">We're a diversified product engineering firm dedicated to crafting top-tier digital solutions. At Oengines, we hold trust, collaboration, and surpassing expectations in high regard, going the extra mile to ensure utmost excellence. We firmly believe that our commitment extends beyond app delivery.
                                </p>
                            </ScrollAnimation>
                            <div className="btn-group"><a href="#ctn-form" className="as-btn style3">CONTACT US<i
                                className="fa-regular fa-arrow-right ms-2"></i></a>
                               
                            </div>
                        </div>
                    </div>
                    
                    <div className="hero-shape1"><img src="assets/img/hero/hero_shape_3_1.png" alt="shape" /></div>
                </div>
                <section className="about-area pt-100">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-6 pl-0">
                                <div className="about-image">
                                    <img src="assets/img/about/about1.jpg" alt="image" />
                                    <img src="assets/img/about/about2.jpg" alt="image" />

                                    <div className="shape">
                                        <img src="assets/img/about/shape2.png" alt="shape" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="about-content">
                                    <span>About US</span>
                                    <h2>Transforming innovative ideas into reality</h2>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p>Web application over the game developments for solving complex business problems with ingenious, our team of professionals are developing websites and admin control system with high-end ready or custom designs.</p>
                                        <p>(We merge creativity and technology to help brands grow in an age of digital transformation)</p>
                                    </ScrollAnimation>
                                    <div className="about-text">
                                        <div className="iconss">
                                            <img src="assets/img/about/goal.png" alt="image" />
                                        </div>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h3>Our Mission</h3>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p>Our mission is to provide you the smart tech that is simpler, but significant in to the world.</p>
                                        </ScrollAnimation>
                                    </div>

                                    <div className="about-text">
                                        <div className="iconss">
                                            <img src="assets/img/about/history.png" alt="image" />
                                        </div>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h3>Our Story</h3>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p>A successful Oengines software application development company since 2017, providing a full range of website and mobile app development, games development, digital marketing, and other IT services and solutions globally.</p>
                                        </ScrollAnimation>
                                    </div>

                                    <div className="signature">
                                        <img src="assets/img/about/signature.png" alt="image" />
                                        <h3>Ankur Rupapara</h3>
                                        <p>Founder of OENGINES GAMES LLP</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="about-shape">
                        <img src="assets/img/about/shape1.png" alt="image" />
                    </div>
                </section>
                <section className="apps-areas pb-100">
                    <div className="container-fluid">
                        <div className="section-title-three">
                            <div className="row align-items-center">
                                <div className="col-12">
                                    <div className="title">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h2 className="text-center">Web Development Process Cycle</h2>
                                        </ScrollAnimation>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-auto pt-80 web-prc-img" style={{ margin: "0 auto" }}>
                                    <img src="assets/img/bg/web_process.png" alt="img not found" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="space pb-0 pt-0" id="service-sec">
                    <div className="container">
                        <div className="row justify-content-lg-between justify-content-center align-items-center">
                            <div className="col-lg-6 col-sm-9 pe-xl-5">
                                <div className="title-area text-center text-lg-start">
                                    <span className="sub-title"><img
                                        src="assets/img/theme-img/title_shape_2.svg" alt="shape" />WHAT WE DO</span>
                                    <h2 className="sec-title">We Provide Exclusive Service For <span className="text-theme fw-normal">Your Web</span></h2>
                                </div>
                            </div>
                            <div className="col-auto">
                              
                            </div>
                        </div>
                        <div className="row slider-shadow as-carousel all-dev" data-slide-show="4" data-lg-slide-show="3" data-md-slide-show="2"
                            data-sm-slide-show="2" data-xs-slide-show="1">
                            <OwlCarousel items={4}
                                className="owl-theme"
                                loop
                                autoplay={true}
                                margin={2} {...setweb}>
                                <div className="col-md-6 col-lg-4 col-xl-3">
                                    <div className="service-box">
                                        <div className="service-box_img"><img src="assets/img/service/service_box_1.jpg" className="serv-img" alt="Icon" /></div>
                                        <div className="service-box_content">
                                            <div className="service-box_icon"><img src="assets/img/icon/service_box_1.svg" alt="Icon" /></div>
                                            <h3 className="box-title"><a>Web Development</a></h3>
                                            <p className="service-box_text">We develop website & web pages that are optimized for the speed. Our websites load faster, run smoother & perform better.</p>
                                            
                                            <div className="bg-shape"><img src="assets/img/bg/service_box_bg.png" alt="bg" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-xl-3">
                                    <div className="service-box">
                                        <div className="service-box_img"><img src="assets/img/service/service_box_2.jpg" className="serv-img" alt="Icon" /></div>
                                        <div className="service-box_content">
                                            <div className="service-box_icon"><img src="assets/img/icon/service_box_2.svg" alt="Icon" /></div>
                                            <h3 className="box-title"><a>Software Development</a></h3>
                                            <p className="service-box_text">Our custom software development services helps your business to manage efficiently so you can focus on your core work.</p>
                                           
                                            <div className="bg-shape"><img src="assets/img/bg/service_box_bg.png" alt="bg" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-xl-3">
                                    <div className="service-box">
                                        <div className="service-box_img"><img src="assets/img/service/service_box_3.jpg" className="serv-img" alt="Icon" /></div>
                                        <div className="service-box_content">
                                            <div className="service-box_icon"><img src="assets/img/icon/service_box_3.svg" alt="Icon" /></div>
                                            <h3 className="box-title"><a>UI/UX Design</a></h3>
                                            <p className="service-box_text">Our designer's team has creative logo design ideas and corporate designs and custom logos that are original and help to build the winning brand.</p>
                                        
                                            <div className="bg-shape"><img src="assets/img/bg/service_box_bg.png" alt="bg" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-xl-3">
                                    <div className="service-box">
                                        <div className="service-box_img"><img src="assets/img/service/service_box_4.jpg" className="serv-img" alt="Icon" /></div>
                                        <div className="service-box_content">
                                            <div className="service-box_icon"><img src="assets/img/icon/service_box_4.svg" alt="Icon" /></div>
                                            <h3 className="box-title"><a>Digital Marketing</a></h3>
                                            <p className="service-box_text">It is a strategy that uses multiple channels to attract, engage and convert customers online.</p>
                                           
                                            <div className="bg-shape"><img src="assets/img/bg/service_box_bg.png" alt="bg" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-xl-3">
                                    <div className="service-box">
                                        <div className="service-box_img"><img src="assets/img/service/service_box_5.jpg" className="serv-img" alt="Icon" /></div>
                                        <div className="service-box_content">
                                            <div className="service-box_icon"><img src="assets/img/icon/service_box_5.svg" alt="Icon" /></div>
                                            <h3 className="box-title"><a>Search Engine Optimization</a></h3>
                                            <p className="service-box_text">Optimize Website, Rank Better, More Traffic, And Increase in Sales. SEO does it all. We offer SEO services at very best prices.</p>
                                           
                                            <div className="bg-shape"><img src="assets/img/bg/service_box_bg.png" alt="bg" /></div>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </section>

                <section className="features-area pb-100 pt-80">
                    <div className="container">
                        <div className="section-title-two">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className="title">
                                        <span>Our Work</span>
                                        <h2>What makes Us unique!</h2>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="text">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p>At Oengines, we pride ourselves on offering unique advantages that set us apart in the field of web development. Here’s why clients choose us:</p>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
                                    <div className="features-content">
                                        <div className="image">
                                            <img src="../assets/img/icon/sales.png" alt="image" />
                                        </div>
                                        <h3>Tailored Solutions</h3>
                                        <p>We believe in personalized service. Unlike one-size-fits-all solutions, we take the time to understand your business, goals, and challenges. This allows us to create customized web solutions that perfectly align with your brand identity and meet your specific objectives.</p>
                                    </div>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
                                    <div className="features-content">
                                        <div className="image">
                                            <img src="../assets/img/icon/calculator.png" alt="image" />
                                        </div>
                                        <h3>Creative Design Excellence</h3>
                                        <p>We prioritize aesthetics without compromising functionality, ensuring that your website not only looks impressive but also performs flawlessly.</p>
                                    </div>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
                                    <div className="features-content">
                                        <div className="image">
                                            <img src="../assets/img/icon/team.png" alt="image" />
                                        </div>
                                        <h3>Comprehensive Support and Maintenance</h3>
                                        <p>Whether you need updates, enhancements, or technical assistance, our dedicated support team is always here to help.</p>
                                    </div>
                                </ScrollAnimation>
                            </div>

                            <div className="col-lg-6">
                                <div className="features-image">
                                    <img src="assets/img/about/features.png" alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="overflow-hidden bg-smoke space">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 p-3">
                                <div className="title-area mb-35 text-center text-xl-start">
                                    <span className="sub-title"><img
                                        src="assets/img/theme-img/title_shape_2.svg" alt="shape" />Why Choose US</span>
                                    <h2 className="sec-title">We are creating high-end qualities for your <span
                                        className="text-theme fw-normal">next-generation businesses.</span></h2>

                                </div>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <p className="mt-n2 mb-25 text-center text-xl-start">At Oengines, we specialize in crafting high-end web solutions tailored for next-generation businesses. Our commitment to excellence and innovation ensures that your digital presence stands out in the competitive online landscape.</p>
                                </ScrollAnimation>
                                <div className="pe-xxl-4 mt-50">
                                    <div className="skill-feature">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h3 className="skill-feature_title">Industry Experience</h3>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p>With over {sessionStorage.getItem("yearOfExp")} years of industry experience.</p>
                                        </ScrollAnimation>
                                    </div>
                                    <div className="skill-feature">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <h3 className="skill-feature_title">Brilliant Team</h3>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p>An extensive experience team.</p>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 mt-40 mt-xl-0">
                                <div className="video-box1"><img className="tilt-active" src="assets/img/normal/video_shape_1.png"
                                    alt="Video" /> <a href="#!"
                                        className="play-btn popup-video" onClick={(e) => this.handlevideopopup(e)}><i className="fas fa-play"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-mockup" data-top="0%" data-left="0%"><img src="assets/img/shape/tech_shape_3.png" alt="shape" />
                    </div>
                    <div className="shape-mockup" data-bottom="0%" data-right="0%"><img src="assets/img/shape/tech_shape_4.png"
                        alt="shape" /></div>
                </div>

                
                <section className="apps-area pb-100">
                    <div className="container">
                        <div className="section-title-two">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className="title">
                                        <span>Apps We Use</span>
                                        <h2>Integrated With The Business Apps</h2>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="text">
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <p>Spend less time managing your software, and more time managing your business. Oengines was built to integrate with other apps.</p>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ul className="app-list">
                            <li className="youtube">
                                <a>
                                    <img src="assets/img/apps/1.png" alt="image" />
                                </a>
                            </li>
                            <li className="sound">
                                <a>
                                    <img src="assets/img/apps/2.png" alt="image" />
                                </a>
                            </li>
                            <li className="youtube">
                                <a>
                                    <img src="assets/img/apps/3.png" alt="image" />
                                </a>
                            </li>
                            <li>
                                <a>
                                    <img src="assets/img/apps/4.png" alt="image" />
                                </a>
                            </li>
                            <li className="youtube">
                                <a>
                                    <img src="assets/img/apps/5.png" alt="image" />
                                </a>
                            </li>
                            <li className="call">
                                <a>
                                    <img src="assets/img/apps/6.png" alt="image" />
                                </a>
                            </li>
                            <li>
                                <a>
                                    <img src="assets/img/apps/7.png" alt="image" />
                                </a>
                            </li>
                            <li className="twitter">
                                <a>
                                    <img src="assets/img/apps/8.png" alt="image" />
                                </a>
                            </li>
                            <li>
                                <a>
                                    <img src="assets/img/apps/9.png" alt="image" />
                                </a>
                            </li>
                            <li>
                                <a>
                                    <img src="assets/img/apps/10.png" alt="image" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="overview-area ptb-100" id="ctn-form">
                    <div className="container">
                        <div className="row">
                            <div className="col-auto">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="overview-image">
                                            <img src="assets/img/overview/image2.png" alt="image" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="overview-content">
                                            <h2>Hire our Dedicated Web Developement Resources.</h2>
                                            <p>Are you ready to join a dynamic tech team where creativity meets cutting-edge technology? Hire now.</p>
                                        </div>
                                        <form onSubmit={this.onSubmit} method="post" className="contact-forms ajax-contact pt-25">
                                            <div className="row">
                                                <div className="form-group col-md-6" >
                                                    <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                                    <i className="fal fa-user"></i>
                                                    {nameError &&
                                                        <div className="invalid-feedback" >{nameError}</div>
                                                    }
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} autoComplete="Off" />
                                                    <i className="fal fa-envelope"></i>
                                                    {emailError &&
                                                        <div className="invalid-feedback">{emailError}</div>
                                                    }
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="text" id="subject" className={subjectError ? "form-control is-invalid" : "form-control"} ref="subjectfield" name="subject" placeholder="Subject" onChange={this.handleChange} autoComplete="Off"  />
                                                    <i className="fal fa-note-sticky"></i>
                                                    {subjectError &&
                                                        <div className="invalid-feedback">{subjectError}</div>
                                                    }
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="number" className={numberError ? "form-control is-invalid" : "form-control"}  name="number" value={this.state.number} ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off" onChange={this.maxlengthnumber} />
                                                    <i className="fal fa-phone"></i>
                                                    {numberError &&
                                                        <div className="invalid-feedback">{numberError}</div>
                                                    }
                                                </div>
                                                <div className="form-group col-12">
                                                    <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                                        className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange} autoComplete="Off">
                                                    </textarea>
                                                    <i className="fal fa-comment"></i>
                                                    {messageError &&
                                                        <div className="invalid-feedback">{messageError}</div>
                                                    }
                                                </div>
                                                <div className="form-btn text-xl-start text-center col-12 cont-btn ps-3">
                                                    <button type="submit" className="as-btn style3"> CONTACT US</button>
                                                </div>
                                            </div>
                                            <p className="form-messages mb-0 mt-3"></p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="bg-smoke overflow-hidden space" id="faq-sec">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-9">
                                <div className="title-area text-center text-xl-start">
                                    <span className="sub-title"><img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />What we are</span>
                                    <h2 className="sec-title">ABOUT US <span className="text-theme fw-normal">- CONNECT</span></h2>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p>At Oengines, we are more than just a tech team. We are a collective of innovators, creators, and problem solvers dedicated to transforming ideas into reality. Our passion for technology is matched only by our commitment to pushing boundaries and redefining what's possible.</p>
                                    </ScrollAnimation>
                                </div>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item accordion-card style3">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-coreui-toggle="collapse" data-coreui-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            What does Oengines do?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-coreui-parent="#accordionExample">
                                            <div className="accordion-body">
                                            Oengines is a leader in developing games on websites. We specialize in creating casino games for the web and are dedicated to solving complex problems and making good logic.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordion-card style3">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-coreui-toggle="collapse" data-coreui-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            What sets Oengines apart from others?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-coreui-parent="#accordionExample">
                                            <div className="accordion-body">
                                            We stand out for our commitment to innovation and to development. We prioritize Innovation, Design, Quality & Accessibility, ensuring that every project reflects our dedication to excellence.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordion-card style3">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-coreui-toggle="collapse" data-coreui-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            What kind of projects does Oengines work on?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-coreui-parent="#accordionExample">
                                            <div className="accordion-body">
                                            We tackle a diverse range of projects, from Casual to Real Gambling casino games. Our team thrives on solving complex challenges and pushing the boundaries of technology.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordion-card style3">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button collapsed" type="button" data-coreui-toggle="collapse" data-coreui-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            How does Oengines support career growth and development?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-coreui-parent="#accordionExample">
                                            <div className="accordion-body">
                                            We believe in nurturing talent from the ground up. Freshers are not just welcomed; they are valued for their fresh perspectives and enthusiasm.you’ll have access to web & game professional development opportunities, training programs, mentorship, etc. We encourage continuous learning and skill enhancement.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordion-card style3">
                                        <h2 className="accordion-header" id="headingFive">
                                            <button className="accordion-button collapsed" type="button" data-coreui-toggle="collapse" data-coreui-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            How can I contribute to Oengines’s success?
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-coreui-parent="#accordionExample">
                                            <div className="accordion-body">
                                            By joining our team, you’ll have the opportunity to make a real impact. We value initiative, creativity, and a passion for web, game and for designing. Your ideas and contributions will help shape the future of Oengines.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-xl-6 mt-35 mt-xl-0">
                                <div className="faq-img tilt-active"><img src="assets/img/normal/faq_1_1.png" alt="Faq" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-mockup jump" data-bottom="0%" data-left="0%"><img src="assets/img/shape/tech_shape_5.png"
                        alt="shape" /></div>
                </div>
                <section className="position-relative space">
                    <div className="as-bg-img" data-bg-src="assets/img/bg/cta_bg_2.jpg"><img src="assets/img/bg/bg_overlay_1.png"
                        alt="overlay" /></div>
                    <div className="container z-index-common">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-7 col-md-9 text-center">
                                <div className="title-area mb-35">
                                    <span className="sub-title"><img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />CONTACT US</span>
                                    <h2 className="sec-title text-white">We can’t wait to show you</h2>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p className="text-white">how awesome we are at website design.</p>
                                    </ScrollAnimation>
                                </div>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <a href="/contact" className="as-btn">Get In Touch</a>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </section>
                {this.state.blog.length ?             
                
                <section className="bg-top-right overflow-hidden space evry-upd" id="blog-sec" data-bg-src="assets/img/bg/blog_bg_1.png">
                    <div className="container">
                        <div className="title-area text-center">
                            <span className="sub-title"><img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />NEWS & ARTICLES</span>
                            <h2 className="sec-title">Get Every Single Update <span className="text-theme fw-normal">Blog</span></h2>
                        </div>
                        <div className="row slider-shadow as-carousel edit-carousel" data-slide-show="3" data-lg-slide-show="2" data-md-slide-show="2"
                            data-sm-slide-show="1" data-arrows="true">
                            {blogcarousel}
                        </div>

                    </div>
                    <div className="shape-mockup" data-bottom="0" data-left="0">
                        <div className="particle-2 small" id="particle-4"></div>
                    </div>
                </section>
                : '' }

                <Footer />
               

            </>
        )
    }
}

export default WebDevelopment;

