import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';

export default function Strategy() {	
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Strategy'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};

	

	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }

	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			
			<Header />
			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>
			<section id="hero" className="strategy-section hero-section division">
				<div className="container">
					<div className="row card-hero pb-100 text-start">
						<div className="col-md-6 col-lg-6 mt-80 arcade-col">
							<div className="hero-17-title mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Strategy Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt action-hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl max-conent m-auto mt-40">We are the Strategy game development studio developing games for several platforms with a team experienced in handling projects across various genres. Equipped with industry-leading features, OEngines Studios creates quality games in a short time for its clients.</p>
								</ScrollAnimation>
							</div>							
							<a href="tel:+02614054978"className="theme-button " ><button className="theme-button mt-4 mt-50 style-2">Call Now</button></a>
						</div>
						<div className="col-md-6 float-right">
							<div className="strategy-hero-img">
								<img src="../assets/img/GameDevelopment/blockchain-development.png" alt="img" />
							</div>
						</div>
					</div>
				</div>
			</section>
        
			<section className="racing-game pt-80 pb-60">
				<div className="container">                    
					<div className="action-heaing-quiz">
						<h2 className="text-center">Strategy Game</h2>
						<div className="elementor-divider elementor-divider stretegy-divider" style={{left: "42%"}}>
							<span className="elementor-divider-separator">
							</span>
						</div>
						<p className="pt-10 text-start text mt-30">A strategy game or strategic game is a game (e.g., a board game) in which the players' uncoerced, and often autonomous, decision-making skills have a high significance in determining the outcome. Almost all strategy games require internal decision tree-style thinking, and typically very high situational awareness.</p>
						<p className="text mt-30">Your game will rely on strategy, not luck in determining the course of the game. This means that players’ decision-
						making skills have a high significance in determining the outcome of the game. Luck-based elements can be unfair if overly used—one player might roll a string of high numbers and another player may roll a series of low numbers, so victory may not be based on how good the players were. If players have the ability to choose the actions they want to take, then victory results from those better decisions and skilled players have a much better experience.</p>
					</div>                                        
				</div>	
			</section>

			<section className="serviced-industries-section common-section">
				<div className="container">
					<h2 className="service-para-title text-center">Features of Strategy Game</h2>
					<div className="custom-saperator"></div>
					<div className="custom-saperator"></div>
					<div className="serviced-indus-wrap">
						<div className="row d-flex-wrap">
							<div className="col-md-6 col-sm-7 serviced-indus-itembox">
								<div className="serviced-indus-imgbox">
									<img className="" src="https://www.yudiz.com/wp-content/uploads/2023/03/game-indus.svg" data-lzy_src="https://www.yudiz.com/wp-content/uploads/2023/03/game-indus.svg" alt="industry-website"/>
								</div>
							</div>
							<div className="col-md-6 serviced-indus-itembox">
								<div className="serviced-indus-box">
									<h5>Some Benefits of Playing Strategy Game</h5>
									<p className="mt-30 text">Playing certain video games can have multiple benefits. Research has shown that experience of playing video
									   games can improve cognitive development such as greater sensitivity to contrasts, better eye-to-hand coordination and superior memory. Action real-time strategy video games such as Age of Empires, World of Warcraft, and Total War are played by millions. These games, which can be won through strategic planning,
									   selective attention, sensorimotor skills, and teamwork place considerable demands on the brain.</p> 
									<div className="custom-saperator"></div>
									<div className="service-ind-acc-list d-flex">
										<ul className="ind-list-point">
											<li>Decision Making Skill</li>
											<li>Strategy games relieve stress</li>
											<li>Strategy games allow one to exercise one’s mind.</li>
											<li>Improve Teamwork</li>		
											<li>Memory</li>									
										</ul>
										<ul className="ind-list-point">										
											<li>Self-Analysis</li>
											<li>Resourcefulness</li>
											<li>Perspective</li>
											<li>Problem Solving</li>
											<li>Focus</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="new-section-area float-area graybg-none scroll-box-area">
				<div className="container">
					<div className="row">
						<div className="new-main-hedding text-center">
							<h2>Key Elements of Strategy Game</h2>							
						</div>
					</div>
					<div className="row">
						<div className="col-sm-6 col-md-6 col-xl-4">
							<div className="color-box-text">
								<span>Players succeed (or lose) based on strategic decisions, not luck.</span>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-xl-4">
							<div className="color-box-text darkgray-border">
								<span>Players have equal knowledge to play; no trivia.</span>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-xl-4">
							<div className="color-box-text blue-border">
								<span>Play is based on multiple decisions a person could make on each turn with possible advantages and
								disadvantages each time.</span>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-xl-4">
							<div className="color-box-text yellow-border">
								<span>Players can plan strategies that will take multiple turns to complete fully.</span>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-xl-4">
							<div className="color-box-text green-border">
								<span>Players can replay the game many times and have a different experience each time.</span>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-xl-4">
							<div className="color-box-text red-border">
								<span>Winning (and losing) is specific and achievable</span>
							</div>
						</div>						
					</div>
				</div>
			</section>

			<section className="stretagy-process pt-80 pb-80">
				<div className="container">
					<div className="prc-title">
						<h2 className="text-center mb-20">Game Strategy Development Process</h2>
						<p className="text-center ">The process of strategy game development is very similar to developing a game in other genres. The basic steps are the
						same.</p>
					</div>
					<div className="prc-box">
						<div className="row ml-30 mr-30">
							<div className="col-md-3 col-sm-3 col-lg-3 col-xl-3 xol-xxl-3 col-12 pt-80 pr-40">
								<div className="prc-one">
									<img src="../assets/img/GameDevelopment/AdvantagesIcon01-removebg-preview.png" alt="prc-1" />
								</div>
							</div>
							<div className="col-md-9 col-sm-9 col-lg-9 col-xl-9 xol-xxl-9 col-12">
								<div className="prc-text">
									<h4>The Concept</h4>
									<p>This includes the idea of the game, its setting, the characters you want to use, the plot, and anything related to world-building. You could create your concept fully based on your own tastes or on the most popular trends. This is also the stage when you need to decide which platform (iOS, Android, PC, etc.) to focus on, and who constitutes your target audience.</p>
								</div>								
							</div>
						</div>
						
					</div>
					<div className="prc-box">
						<div className="row ml-30 mr-30">
							<div className="col-md-3 col-sm-3 col-lg-3 col-xl-3 xol-xxl-3 col-12 pt-80 pr-40">
								<div className="prc-two">
									<img src="../assets/img/GameDevelopment/AdvantagesIcon02-removebg-preview.png" alt="prc-2" />
								</div>
							</div>
							<div className="col-md-9 col-sm-9 col-lg-9 col-xl-9 xol-xxl-9 col-12">
								<div className="prc-text">
									<h4>The Prototype</h4>
									<p>This is a “light” version of the game created to test the core mechanics before you set to working on the final product. The purpose of the prototype stage is to reveal possible bugs, evaluate your target audience’s reaction, and in general make sure that serious issues do not emerge later. Don’t try to skip this step by going straight to the full version: in the long run, it is more profitable to test early.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="prc-box">
						<div className="row ml-30 mr-30">
							<div className="col-md-3 col-sm-3 col-lg-3 col-xl-3 xol-xxl-3 col-12 pt-80 pr-40">
								<div className="prc-three">
									<img src="../assets/img/GameDevelopment/AdvantagesIcon03-removebg-preview.png" alt="prc-3" />
								</div>
							</div>
							<div className="col-md-9 col-sm-9 col-lg-9 col-xl-9 xol-xxl-9 col-12">
								<div className="prc-text">
									<h4>The Testing</h4>
									<p>When you are creating a game, you need to have a target audience in mind. Now you are going to see its reaction. We have a detailed guide on testing your game prototype here – check it out.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="prc-box">
						<div className="row ml-30 mr-30">
							<div className="col-md-3 col-sm-3 col-lg-3 col-xl-3 xol-xxl-3 col-12 pt-80 pr-40">
								<div className="prc-four">
									<img src="../assets/img/GameDevelopment/AdvantagesIcon04-removebg-preview.png" alt="prc-4" />
								</div>
							</div>
							<div className="col-md-9 col-sm-9 col-lg-9 col-xl-9 xol-xxl-9 col-12">
								<div className="prc-text">
									<h4>The Release</h4>
									<p>After all the necessary corrections have been made, the final product is developed and can be released to the public on the platform of your choice.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

	
			<section className="strategy-contect pb-50">
				<div className="container  pt-60 pb-60">
                    <div className="row starategy-box">
                        <div className="hire-our-action-text racing-contect-mian text-center">
                            <h2 className="">Looking to Hire a Strategy Game Developer?</h2>
                           <button className="theme-button mt-30 style-3" onClick={handleShow}>Contact Us Now</button>
                        </div>
                    </div>
				</div>
			</section>

           
			<section className="service-list-info-section common-section">
				<div className="container"> <div className="service-content-para">
					<div className="row mt-30 text">
						<div className="col-md-6">
							<h3 className="service-para-title text-start">Why Choose OEngines as your Strategy Game Developer ?</h3>
						</div>
						<div className="col-md-6 para-info-box">
							<p className="">We provide a handful of online game apps and software keeping quality and security in mind. Our aim is to reach the
								maximum number of users and provide greater gaming and playing experience with Mobile Game Development. Explore all the options and play to create a better chance of winning.</p> 
						</div>
					</div>
				</div>
					<div className="service-list-content">
						<div className="row d-flex-wrap mt-50">
							<div className="col-md-4 col-xl-3 col-sm-6 service-item-bx mb-40">
								<div className="service-item-wrp" style={{background: "#f1faf5"}}>
									<div className="img-wrp" style={{background: "#00b06f"}}>
										<img src="https://www.yudiz.com/wp-content/uploads/2023/03/unity-game-development.svg" className="" data-lzy_src="https://www.yudiz.com/wp-content/uploads/2023/03/unity-game-development.svg" alt="Service Icon" />
									</div>
									<div className="service-item-des">
										<h5 style={{color: "#00b06f", borderColor: "rgba(0,176,111,0.24)"}}>Best Game Studio</h5>
										<p style={{color: "#436d5e"}}>Get a variety of Android and iOS games with our team of experts and experience the stunning graphics with a highly polished interface in any Game Software. We use the latest technologies to help you provide the best solution.</p>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-xl-3 col-sm-6 service-item-bx mb-40">
								<div className="service-item-wrp" style={{background: "#f0f3fc"}}>
									<div className="img-wrp" style={{background: "#0d7de7"}}>
										<img src="https://www.yudiz.com/wp-content/uploads/2023/03/ios-game-develop.svg" className="" data-lzy_src="https://www.yudiz.com/wp-content/uploads/2023/03/ios-game-develop.svg" alt="Service Icon" />
									</div>
									<div className="service-item-des">
										<h5 style={{color: "#0d7de7", borderColor: "rgba(13,125,231,0.24)"}}>24x7 Technical Support</h5>
										<p style={{color: "#5d7b97"}}>Get your problems an instant solution with our 24x7 technical support.</p>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-xl-3 col-sm-6 service-item-bx mb-40">
								<div className="service-item-wrp" style={{background: "#f7f0fb"}}>
									<div className="img-wrp" style={{background: "#7e2ae5"}}>
										<img src="https://www.yudiz.com/wp-content/uploads/2023/03/html-game-ic.svg" className="" data-lzy_src="https://www.yudiz.com/wp-content/uploads/2023/03/html-game-ic.svg" alt="Service Icon" />
									</div>
									<div className="service-item-des">
										<h5 style={{color: "#7e2ae5", borderColor: "rgba(126,42,229,0.24)"}}>Expert Game Programmers</h5>
										<p style={{color: "#856ba4"}}>We have a team of expert ultimate Strategy game software providers and game programmers holding years of rich experience. Their hard work brings video games to life.</p>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-xl-3 col-sm-6 service-item-bx mb-40">
								<div className="service-item-wrp" style={{background: "#fff4f1"}}>
									<div className="img-wrp" style={{background: "#ff573c"}}>
										<img src="https://www.yudiz.com/wp-content/uploads/2023/03/game-2d-3d.svg" className="" data-lzy_src="https://www.yudiz.com/wp-content/uploads/2023/03/game-2d-3d.svg" alt="Service Icon" />
									</div>
									<div className="service-item-des">
										<h5 style={{color: "#ff573c", borderColor: "rgba(255,87,60,0.24)"}}>Smart Solutions</h5>
										<p style={{color: "#b58d86"}}>We strive to provide a smart service solution at the time of cutting-edge technology. Our cost-effective solutions help in saving time and effort.</p>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-xl-3 col-sm-6 service-item-bx mb-40">
								<div className="service-item-wrp" style={{background: "#fff8e5"}}>
									<div className="img-wrp" style={{background: "#ffb800"}}>
										<img src="https://www.yudiz.com/wp-content/uploads/2023/03/android-app.svg" className="" data-lzy_src="https://www.yudiz.com/wp-content/uploads/2023/03/android-app.svg" alt="Service Icon" />
									</div>
									<div className="service-item-des">
										<h5 style={{color: "#ffb800", borderColor: "rgba(255,184,0,0.24)"}}>Seamless Communication</h5>
										<p style={{color: "#b09c7d"}}>We promote seamless communication to give users greater experience and aim to provide more personalized services.</p>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-xl-3 col-sm-6 service-item-bx mb-40">
								<div className="service-item-wrp" style={{background: "#effafb"}}>
									<div className="img-wrp" style={{background: "#00b7ca"}}>
										<img src="https://www.yudiz.com/wp-content/uploads/2023/03/custom-game.svg" className="" data-lzy_src="https://www.yudiz.com/wp-content/uploads/2023/03/custom-game.svg" alt="Service Icon" />
									</div>
									<div className="service-item-des">
										<h5 style={{color: "#00b7ca", borderColor: "rgba(0,183,202,0.24)"}}>Flexible and Customized Solutions</h5>
										<p style={{color: "#4d797d"}}>We collaborate with our clients, from time to time, to help them achieve their desired result. Our flexible solutions are designed to suit all your specific needs with the utmost flexibility.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}
       
			<Footer />
		</div>

	);
}
