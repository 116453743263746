import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';


export default function Puzzle() {
	
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Puzzle'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};


	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }

	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
						
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>
			<Header />
				<section id="hero" className="bg-scroll-card hero-section division">
					<div className="container">
						<div className="row card-her pt-100 pb-100">
							<div className="col-md-6 col-lg-6 arcade-col">
								<div className="hero-17-title white-color mb-30">
									<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
										<h2 className="h2-xl">Puzzle Game Development</h2>
									</ScrollAnimation>
								</div>
								<div className="hero-17-txt">
									<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
										<p className="p-xl white-color max-conent">Develop Your Puzzle Mobile Game With OENGINES Innovative Ideas
										</p>
									</ScrollAnimation>
								</div>
								<a href="tel:+02614054978" ><button className="theme-button mt-4">Call Now</button></a>
							</div>
							<div className="col-md-6 col-lg-6">
								<div className="hero-17-img video-preview">
									<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
										<img className="img-fluid" src="../assets/img/GamesImg/Rummy_images/dashboard-04.png" alt="hero-image" />
									</ScrollAnimation>
								</div>
							</div>
						</div>
					</div>			
				</section>
				<section className="chs_oeg mt-80 mb-60">
					<div className="container">
						<div className="Oeg_main">
							<div className="row">								
								<div className="col-md-12">
									<div className="Oeg_text">
										<h2 className="text-center">Puzzle Game</h2>
										<div className="elementor-divider">
											<span className="elementor-divider-separator">
											</span>
										</div>
										<p className="mt-30 text">Creating a puzzle game is a creative and technical art form. All it takes is time, a willingness to create something unique and a passion for innovation. To create a puzzle game, one must have an idea or a concept for a game. Minimalism and simplicity are what grab the user’s attention in most puzzle games. Focus on the unique genre of game that emphasizes solving puzzles. In this technology-driven society, it boosts brain power while improving learning ability in an interactive way. With the advancement of technology, puzzle game development has a huge market with great revenue potential.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			
				<section className="puzzle_game_feature">
					<div className="container">
						<h2 className="title_underline">Puzzle Game Development Features</h2>
						<div className="game_feature_list row">
							<div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
								<div className="puzzle-feature_card mt-35">
									<div className="puzzle_game_icon"><span className="uno_feature_icon single_player"></span></div>
									<h3>Tutorial/Practice </h3>
									<p>Tutorials and practice sessions will show players how to play</p>
								</div>								
							</div>
							<div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
								<div className="puzzle-feature_card mt-35">
									<div className="puzzle_game_icon"><span className="uno_feature_icon two_v_two"></span></div>
									<h3>Play With Friends</h3>
									<p>Bring all your friends together by playing through sharing an invite</p>
								</div>								
							</div>
							<div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
								<div className="puzzle-feature_card mt-35">
									<div className="puzzle_game_icon"><span className="uno_feature_icon custom"></span></div>
									<h3>Matchmaking </h3>
									<p>A fast and reliable technique by which players can be matched</p>
								</div>
							</div>
							<div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
								<div className="puzzle-feature_card mt-35">
									<div className="puzzle_game_icon"><span className="uno_feature_icon play_v_bot"></span></div>
									<h3>Events/Tournaments</h3>
									<p>Regular events and tournaments increase player engagement </p>
								</div>								
							</div>
							<div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
								<div className="puzzle-feature_card mt-35">
									<div className="puzzle_game_icon"><span className="uno_feature_icon tutorial"></span></div>
									<h3>In-App Purchases </h3>
									<p>Earn from your app, offer in-game currency or boosts with real money</p>
								</div>								
							</div>
							<div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
								<div className="puzzle-feature_card mt-35">
									<div className="puzzle_game_icon"><span className="uno_feature_icon rules"></span></div>
									<h3>Chat Facility</h3>
									<p>Let users keep in contact with each other with messages or voice messages</p>
								</div>							
							</div>
						</div>
					</div>
				</section>
				<section className="why-choose-puzzle pt-100 pb-100">
					<div className="container">
						<div className="row">
							<div className="col-md-12 col-lg-6 col-xl-6">
								<div className="why-puzzle-text pt-100">
									<h2 className="text-white">Why Choose Us?</h2>
									<p className="text-white">With cost-effective approaches, we help our customers to turn
									their innovative ideas into games.</p>
									<p className="text-white">OEngines offers a complete range of gaming solutions for various platforms and devices. Our large team of game
									developers and designers offers the most comprehensive solutions that guarantee the best results for your game.</p>
								</div>	
							</div>
							<div className="col-md-12 col-lg-6 col-xl-6">
								<div className="row">
									<div className="col-md-6 mt-20">
										<div className="grd-border">
											<h4 className="text-white">Experienced Developers</h4>											
											<p className="text-white">Working for 6+ years in Puzzle Game App development.</p>
										</div>
									</div>
									<div className="col-md-6 mt-20">
										<div className="grd-border">											
											<h4 className="text-white">Seamless Communication</h4>										
											<p className="text-white">Regular tracking of project status is conveyed to the client.</p>										
										</div>
									</div>
									<div className="col-md-6 mt-20">
										<div className="grd-border">											
											<h4 className="text-white">On-time Delivery</h4>										
											<p className="text-white">We deliver projects within a deadline with the best efficiency.</p>										
										</div>
									</div>
									<div className="col-md-6 mt-20">
										<div className="grd-border">											
											<h4 className="text-white">Affordable Services</h4>										
											<p className="text-white">The most affordable and budget-friendly rates for Puzzle games.</p>										
										</div>
									</div>									
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="puzzle_expert_mode pt-80 pb-80">
					<div className="container">
						<h2 className="title_underline">What Can You Expect?</h2>
						<span className="text_uno">Include These UNO Modes</span>
						<p className="mode_content">Puzzle games are fun, but you can make them more fun by including other modes to keep players entertained as they can switch around and play different modes. You can include these different types of modes in your Puzzle game.</p>
						<div className="game_mode_list row">
							<div className="col-md-6 col-lg-4 col-xl-3">
								<div className="game_mode">
									<div className="mode_game_icon"><span className="uno_feature_icon play_app"></span></div>
									<div className="mode_game_content">
										<h4>Play vs. App Logic </h4>
										<p>Choose to skip matchmaking by playing against the app’s logic </p>
									</div>
								</div>								
							</div>
							<div className="col-md-6 col-lg-4 col-xl-3">
								<div className="game_mode">
									<div className="mode_game_icon"><span className="uno_feature_icon one_v_one"></span></div>
									<div className="mode_game_content">
										<h4>1 vs. 1 Mode</h4>
										<p>Make it a fair competition by letting two players play at the same time to test their addition and coordination skills</p>
									</div>
								</div>								
							</div>
							<div className="col-md-6 col-lg-4 col-xl-3">
								<div className="game_mode">
									<div className="mode_game_icon"><span className="uno_feature_icon Multiplayer"></span></div>
									<div className="mode_game_content">
										<h4>Multiplayer Mode</h4>
										<p>This mode lets players play with other players around the room or within the same room</p>
									</div>
								</div>							
							</div>
							<div className="col-md-6 col-lg-4 col-xl-3">
								<div className="game_mode">
									<div className="mode_game_icon"><span className="uno_feature_icon offline"></span></div>
									<div className="mode_game_content">
										<h4>Offline Mode</h4>
										<p>Provide players with the option to play offline when there isn’t an internet connection</p>
									</div>
								</div>								
							</div>
						</div>
					</div>
				</section>
				<section className="puzzel-contect-details pt-50 pb-50">
					<div className="container">
						<div className="row">
							<div className="col-md-6 board-span mt-65">
								<span className="text-white">Contact Us</span>
								<h2 className="text-white mt-35">WANT TO HIRE OUR PUZZLE GAME DEVELOPERS?</h2>
								<button className="theme-button mt-40" onClick={handleShow}>GET IN TOUCH</button>
							</div>
							<div className="col-md-6">
								<div className="chess-img">
									<img src="../assets/img/GameDevelopment/sec-7-img.png" alt="img"/>
								</div>
							</div>
						</div>
					</div>
				</section>
				{
					Gamedata.length && (

						<section className="team-sec space new-space-bg">
							<div className="containers z-index-common">
								<div className="title-area text-center">
									<h2 className="sec-title">Checkout Some Games on Store.</h2>
								</div>
								<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
									{Gamedata.map((gamedata, index) => {
										return (
											<>
												<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
													<div className="as-team team-card">
														<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
														<div className="team-content team-content-remove new-con-box row d-flex">
															<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
															<div className="applink d-flex ml-2 justify-content-between m-auto row">
																{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
																}
																{gamedata.ios_link === '' ? '' :
																	<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
																}
															</div>
														</div>
													</div>
												</div>
											</>
										);
									})}
								</OwlCarousel>
							</div>
							<div className="shape-mockup" data-bottom="0" data-left="0">
								<div className="particle-2" id="particle-2"></div>
							</div>
						</section>
					)
				}
			
			<Footer />
		</div>

	);
}
