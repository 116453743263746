import React, { Component } from 'react';
import {BrowserRouter, Routes, Route, Form } from "react-router-dom";
import ReactGA from "react-ga4";
import App from "../App";
import Service from '../service';
import WebDevelopment from  '../Services/WebDevelopment';
import GameDevelopment from '../Services/GameDevelopment';
import MobileDevelopment from '../Services/MobileDevelopment';
import ServiceDetails from '../Services/Service_details';
import About from '../about.js';
import Contact from '../contect.js';
import NewServices from '../New_services/Service_details';
import Career from '../Career/career';
import CareerDetail from '../Career/CareerDetail';
import Bhabhioffline from '../over_games/Bhabhiofline';
import Bluffofline from '../over_games/bluffofline';
import Callbreak from '../over_games/callbreak';
import Ginrummy from '../over_games/Ginrummy';
import Hazari from  '../over_games/Hazari';
import Kachuful from '../over_games/kachuful';
import Rummy500 from  '../over_games/rummy500';
import Rummy from '../over_games/rummy';
import Ludo from  '../over_games/ludo';
import Solitaire from '../over_games/solitaire';
import G2829 from '../over_games/2829';
import SpadesMultiplayer  from '../over_games/SpadesMultiplayer';
import Tank from '../over_games/TunkMultiplayer'; 
import Carrom from '../over_games/Carrom';
import Blog from '../Blog/Blog';
import Privacy from '../privacypolicy.js';
import Terms from '../Termsandcondition';
import BlogDetails from '../Blog/BlogDetails';
import Nodepage from '../Services/Nodejs';
import Reactpage from '../Services/React';
import Expresspage from '../Services/Express';
import Flutterpage from '../Services/Flutter';
import PHPpage from '../Services/Php';
import Codelgnitorpage from '../Services/Codelgnitor';
import Unitypage from '../Services/Game_development/unity';
import Androidpage from '../Services/Game_development/Android';
import Webbased from '../Services/Game_development/WebBasedGamedevelopment';
import IOSPage from '../Services/Game_development/IOS';
import Newrummy from '../over_games/new_rummy';
import CardGame from '../Services/Game_development/CardGame';
import Casino from '../Services/Game_development/Casino';
import Board from '../Services/Game_development/Board';
import Puzzle from '../Services/Game_development/Puzzle/puzzle';
import Arcarde from '../Services/Game_development/Arcarde/Arcarde';
import Action from '../Services/Game_development/Action/Action';
import Adventure from '../Services/Game_development/Adventure/Adventure';
import Racing from '../Services/Game_development/Racing/Racing';
import Casual from '../Services/Game_development/Casual/Casual';
import Educational from '../Services/Game_development/Educational/Educational';
import Horror from '../Services/Game_development/Horror/Horror';  
import Music from '../Services/Game_development/Music/Music.js';
import Quiz from '../Services/Game_development/Quiz/Quiz';
import Roboclick from '../Services/Game_development/Roguclikc/Roguclikc';
import RPG from '../Services/Game_development/RPG/Rpg';
import Sendbox from '../Services/Game_development/Sandbox/Sandbox';
import Simulator from '../Services/Game_development/Simulator/Simulator';
import Sport from '../Services/Game_development/Sport/Sport';
import Strategy from '../Services/Game_development/Strategy/Strategy';
import Word from '../Services/Game_development/Word/Word';
import Mobiledevelopmentold from '../Services/Old_mobiledevelopment';

class AppRouter extends React.Component {
   
  componentDidMount() {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Page" });
  }

    render() { 

      return (  
        <BrowserRouter>    
          
          <Routes>

            <Route exact path="/" element={<App />} />
            <Route path='/29card' element={<G2829/>} />
            <Route path="/Service" element={<Service />} />
            <Route path="/mobiledevelopment" element={<MobileDevelopment />} />
            <Route path="/old_mobiledevelopment" element={<Mobiledevelopmentold />} />
            <Route path="/webdevelopment" element={<WebDevelopment />} />
            <Route path="/gamedevelopment" element={<GameDevelopment />} />
            <Route path="/ServiceDetails" element={<ServiceDetails />} />
            <Route path="/unity" element={<Unitypage />} />
            <Route path="/android" element={<Androidpage />} />
            <Route path="/webbased" element={<Webbased />} />
            <Route path="/ios" element={<IOSPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />            
            <Route path="/blogdetails" element={<BlogDetails />} />
            <Route path="/Newservice" element={<NewServices />} />          
            <Route path="/career" element={<Career />} />            
            <Route path="/careerdetail" element={<CareerDetail />} />
            <Route exact path="/home/privacy" element={<Privacy />}  />
            <Route path="/terms" element={<Terms />} />
            <Route path="/nodejs" element={<Nodepage />} />
            <Route path="/reactjs" element={<Reactpage />} />
            <Route path="/expressjs" element={<Expresspage />} />
            <Route path="/flutter" element={<Flutterpage />} />
            <Route path="/php" element={<PHPpage />} />
            <Route path="/codelgnitor" element={<Codelgnitorpage />} />            
            <Route path="/bhabhi_card_game" element={<Bhabhioffline />} />
            <Route path="/bluff_card_game" element={<Bluffofline />} />
            <Route path="/callbreak" element={<Callbreak />} />
            <Route path="/gin_rummy" element={<Ginrummy />} />
            <Route path="/hazari_card_game" element={<Hazari />} />
            <Route path="/kachufulJudgement" element={<Kachuful />} />
            <Route path="/rummy500" element={<Rummy500 />} />
            <Route path="/rummy" element={<Rummy />} />
            <Route path="/rummy_multiplayer" element={<Newrummy />} />
            <Route path="/ludo" element={<Ludo />} />
            <Route path="/solitaire" element={<Solitaire />} /> 
            <Route path="/spades_multiplayer" element={<SpadesMultiplayer />} /> 
            <Route path="/tonk" element={<Tank />} /> 
            <Route path="/carrom" element={<Carrom />} /> 
            <Route path="/Card" element={<CardGame />} />
            <Route path="/Casino" element={<Casino />} /> 
            <Route path="/Board" element={<Board />} /> 
            <Route path="/Puzzle" element={<Puzzle />} /> 
            <Route path="/Arcarde" element={<Arcarde />} /> 
            <Route path="/Action" element={<Action />} /> 
            <Route path="/Adventure" element={<Adventure />} /> 
            <Route path="/Racing" element={<Racing />} /> 
            <Route path="/Casual" element={<Casual/>} />
            <Route path="/Educational" element={<Educational/>} />
            <Route path="/Horror" element={<Horror/>} />
            <Route path="/Music" element={<Music/>} />
            <Route path="/Quiz" element={<Quiz/>} />
            <Route path="/Roguelike" element={<Roboclick/>} />
            <Route path="/Rpg" element={<RPG/>} />
            <Route path="/sendbox" element={<Sendbox/>} />
            <Route path="/Simulator" element={<Simulator/>} />
            <Route path="/Sport" element={<Sport/>} />
            <Route path="/Strategy" element={<Strategy/>} />
            <Route path="/Word" element={<Word/>} />
          </Routes>

    </BrowserRouter>
      )
    }
  }

export default AppRouter;
