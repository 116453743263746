import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Gameheader";
import Footer from "../over_games/Footer";
import { Helmet } from 'react-helmet';
import '../assets/css_game/style.css';
import CountUp from 'react-countup';
import ScrollAnimation from 'react-animate-on-scroll';
import '../assets/css_game/dropdown-effects/fade-down.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import config from '../config.json';
const sliderSettings = {
	dots: false,
	items: 5,
	slidestoscroll: 1,
	autoplay: true,
	interval: 5000,
	initialslide: 0,
	autoplaytimeout: 8000,
	autoplaySpeed: 2000,
	responsive: {
		0: {
			items: 2,
			nav: false
		},
		600: {
			items: 3,
			nav: false
		},
		1000: {
			items: 4,
			nav: false,
		}
	}
}

export default function Kachuful() {

	const [gameDetails, setGame] = useState({});
	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		axios.get(config.GAME_DETAILS_FOR_PAGES_API+Pathname).then((res) => {
			console.log("callbreak Game Data Data is more games::::::::", res.data);
			if (res.data && res.data.length){
				setGame(res.data[0]);
			}				
		})
	}, []);

	return (
		<div>
			<Helmet>
				<link id="googleFonts" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800%7CShadows+Into+Light&amp;display=swap" rel="stylesheet" type="text/css"></link>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
			</Helmet>
			<Header />
			<section id="hero-17" className="bg-scroll rel hero-section division">
				<div className="container">
					<div className="row">
						<div className="col-md-9 col-lg-8">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Kachuful Card Game </h2>
								</ScrollAnimation>
							</div>
						</div>
					</div>
					<div className="row">

						<div className="col-md-6 col-lg-5">
							<div className="hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<p className="p-xl white-color">Enhance your Gaming experience with the Kachuful Card
										Game Development offered by us. Our Developers build games for both web and
										mobiles including all app platforms. Our aim is to provide you with all the
										services that will meet your needs and requirements in a professional manner.
										Hire our Kachuful Card Game Developers with their rich experience and skills
										to have the best Kachuful Card Game Development services in India.
									</p>
								</ScrollAnimation>
								{
									gameDetails.android_link ?
										<a target="_blank" href={gameDetails.android_link} className="os-btn bg-white d-flex align-items-center">
											<div className="os-btn-ico">
												<div className="ico-50">
													<img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
												</div>
											</div>
											<div className="os-btn-txt">
												<h6 className="h6-lg">Download for Android</h6>
											</div>
										</a>
										:
										''
								}

								{
									gameDetails.ios_link ? <a target="_blank" href={gameDetails.ios_link} className="os-btn mac-os-btn bg-white d-flex align-items-center">
										<div className="os-btn-ico">
											<div className="ico-50">
												<img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
											</div>
										</div>

										<div className="os-btn-txt">
											<h6 className="h6-lg">Download for IOS</h6>
										</div>
									</a> : ''
								}
								<ul className="advantages white-color clearfix mt-30">
									<li className="first-li">
										<p>Version 2.10.074</p>
									</li>

								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="hero-17-img video-preview">
								<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
									<img className="img-fluid"
										src="../assets/img/GamesImg/Kachuful_ofline_images/dashboard-04.png"
										alt="hero-image" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="content-2" className="content-2 pb-60 pt-50 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-5 col-lg-6">
							<div className="rel img-block left-column wow fadeInRight">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<img className="img-fluid" src="../assets/img/GamesImg/Kachuful_ofline_images/img-10.png"
										alt="content-image" />
								</ScrollAnimation>
							</div>

						</div>
						<div className="col-md-7 col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">
								<ScrollAnimation animateIn="animate__animated animate__fadeInright">
									<h2 className="h2-xs">Kachuful Card Game Development Services</h2>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__animated animate__fadeInright">
									<p className="p-lg">Our Company is a leading Kachuful Card Game Development Company
										in India. Our team of Kachuful Card Game Developers like Designers and
										programmers strives to give you the best Kachuful Card Game software in terms
										of quality. Our Kachuful Card Game developers specifically keep clients in
										the loop throughout the game process to ensure that the clients are highly
										satisfied with the results. We use advanced technologies to create a game that
										supports android, iOS, Desktop & all other platforms. Our developers make sure
										that you and your audience receive memorable hands-on experiences. For that, our
										passionate gaming coders make use of the latest mobile technology.
									</p>

									<p className="p-lg">Adept Kachuful Card Game providers are working hard on different
										Kachuful Card Game app solutions consisting of varied options and blends of
										features, functionalities, modules, etc. to perfectly deliver you the best idea
										for Kachuful Card Game app development. We will provide you with a
										rich-featured app that will help you to gain more Kachuful Card Game users.
										Set your Kachuful Card Game to a level higher than other Kachuful Card
										Game developers with us.
									</p>
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="features-8" className="pb-60 features-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-70">
								<ScrollAnimation animateIn="animate__animated animate__fadeInup">
									<h2 className="h2-md">Kachuful Card Game Script</h2>
								</ScrollAnimation>
							</div>
						</div>
					</div>
					<div className="fbox-8-wrapper text-center">
						<div className="row row-cols-1 row-cols-md-3 justify-content-center">
							<div className="col">
								<div className="fbox-8 mb-40 wow fadeInUp">
									<div className="fbox-img">
										<img className="img-fluid"
											src="../assets/img/GamesImg/Kachuful_ofline_images/kachufuloffline.png"
											alt="feature-icon" />
									</div>
									<ScrollAnimation animateIn="animate__animated animate__fadeInup">
										<h5 className="h5-md">Desktop Version</h5>
										<p className="p-lg">This version is mainly for Windows and MacOS. You can simply
											download and install this version and good to go.
										</p>
									</ScrollAnimation>
								</div>
							</div>
							<div className="col">
								<div className="fbox-8 mb-40 wow fadeInUp">

									<div className="fbox-img">
										<img className="img-fluid change"
											src="../assets/img/GamesImg/Kachuful_ofline_images/img-01.png"
											alt="feature-icon" />
									</div>
									<ScrollAnimation animateIn="animate__animated animate__fadeInup">
										<h5 className="h5-md">IOS Version</h5>
										<p className="p-lg">Our kachuful offline game software mobile version is available
											for IOS, Android and Windows app.
										</p>
									</ScrollAnimation>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="content-9" className="content-9 bg-whitesmoke-gradient pt-100 content-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-10 col-lg-8">
							<div className="section-title title-02 mb-60">
								<h2 className="h2-xs">Discover powerful features to boost your productivity</h2>
							</div>
						</div>
					</div>
					<div className="img-block">
						<div className="row">
							<div className="col-md-6">
								<div className="about-rummy">
									<ScrollAnimation animateIn="animate__animated animate__fadeInup">
										<h2 className="heading">What is Kachuful Card Game?</h2>
									</ScrollAnimation>
									<p>The game is played like any other trick-taking game starting with the player
										left of the dealer. The tricks for each player are counted at the end and
										each player that successfully takes the exact number of tricks that he
										declared receives ten plus the number of tricks declared/taken.</p>
								</div>

							</div>
							<div className="col-md-6">
								<div className="content-9-img video-preview wow fadeInUp">
									<a className="video-popup1" href="https://www.youtube.com/embed/SZEflIVnhH8">
										<div className="video-btn video-btn-xl bg-red ico-90">
											<div className="video-block-wrapper"><span
												className="flaticon-play-button"></span></div>
										</div>
									</a>
									<img className="img-fluid"
										src="../assets/img/GamesImg/Kachuful_ofline_images/dashboard-07.png"
										alt="video-preview" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="statistic-2" className="wide-100 statistic-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">
								<h3 className="h3-xs">More than 5,000 users around the world are already using Kachuful
									offline actively</h3>
							</div>
						</div>
						<div className="col-sm-5 col-md-4 col-lg-3 offset-sm-1 offset-md-2 offset-lg-1">
							<div className="statistic-block wow fadeInUp">
								<h2 className="h2-title-xs statistic-number"><span className="count-element"><CountUp end={5000} duration={10} /></span>
								</h2>
								<p className="p-lg mt-20">Active downloads <br /> from the community</p>
							</div>
						</div>
						<div className="col-sm-5 col-md-4 col-lg-2">
							<div className="statistic-block wow fadeInUp">
								<h2 className="h2-title-xs statistic-number">
									<span className="count-element"><CountUp end={3} duration={5} /></span>.<span className="count-element"><CountUp end={3} duration={5} /></span>
								</h2>
								<div className="txt-block-rating ico-15 yellow-color">
									<span className="flaticon-star-1"></span>
									<span className="flaticon-star-1"></span>
									<span className="flaticon-star-1"></span>
									<span className="flaticon-star-half-empty"></span>
								</div>

								<p className="p-lg txt-400">33 Rating</p>
							</div>
						</div>


					</div>
				</div>
			</section>

			<section className="space-bottom space-top contact-infos ">
				<div className="container">
					<div className="row text-center pt-4">
						<div className="col">
							<h2 className="word-rotator slide font-weight-bold text-8 mb-25">
								<span>Porto is&nbsp;</span>
								<span className="word-rotator-words bg-primary" style={{ padding: "0 20px 0 0" }}>
									<span className="spinny-words text-start">
										<span>incredibly</span>
										<span>especially</span>
										<span>extremely</span>
										<span>incredibly</span>
										<span>especially</span>
										<span>extremely</span>
									</span>
								</span>
								<span>&nbsp;&nbsp;beautiful and fully responsive.</span>
							</h2>
							<p className="lead appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300" style={{ animationDelay: "300ms", maxWidth: "880px", margin: "10px auto" }}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce elementum, nulla vel pellentesque consequat, ante nulla hendrerit arcu, ac tincidunt mauris lacus sed leo.
							</p>
						</div>
					</div>
					<div className="row text-center mt-50">
						<div>
							<OwlCarousel className='owl-theme' loop margin={10} {...sliderSettings} nav>
								<div>
									<img src="assets/img/language/logo-1.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-2.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-3.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-4.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-5.png" alt="img not found" />
								</div>
								<div>
									<img src="assets/img/language/logo-6.png" alt="img not found" />
								</div>
							</OwlCarousel>
						</div>
					</div>
					<div className="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
						<div className="home-concept mt-50">
							<div className="container-flied">

								<div className="row text-center">
									<span className="sun"></span>
									<span className="cloud"></span>
									<div className="col-lg-2 ms-lg-auto">
										<div className="process-image">
											<img src="assets/img/GamesImg/ludo_images/home-concept-item-1.png" alt="" />
											<strong>Strategy</strong>
										</div>
									</div>
									<div className="col-lg-2">
										<div className="process-image process-image-on-middle">
											<img src="assets/img/GamesImg/ludo_images/home-concept-item-2.png" alt="" />
											<strong>Planning</strong>
										</div>
									</div>
									<div className="col-lg-2">
										<div className="process-image">
											<img src="assets/img/GamesImg/ludo_images/home-concept-item-3.png" alt="" />
											<strong>Build</strong>
										</div>
									</div>
									<div className="col-lg-4 ms-lg-auto">
										<div className="project-image">
											<div id="fcSlideshow" className="fc-slideshow">
												<ul className="fc-slides hero-carousel verticalFlip">
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-1.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-2.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-3.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-1.jpg" alt="" /></a></li>
													<li><a href="#!" aria-label=""><img className="img-fluid" src="assets/img/GamesImg/ludo_images/project-home-2.jpg" alt="" /></a></li>
												</ul>
											</div>
											<strong className="our-work">Our Work</strong>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="content-3" className="content-3 wide-60 content-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-70">
								<h2 className="h2-md">Get Ready to Be Surprised</h2>
								<p className="p-xl">To make it easy and convenient for you to play the game, we have
									added various features to the game.
								</p>
							</div>
						</div>
					</div>
					<div className="top-row pb-50">
						<div className="row d-flex align-items-center">
							<div className="col-md-5 col-lg-6">
								<div className="img-block left-column wow fadeInRight">
									<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
										<img className="img-fluid"
											src="../assets/img/GamesImg/Kachuful_ofline_images/img-01.png"
											alt="content-image" />
									</ScrollAnimation>
								</div>
							</div>
							<div className="col-md-7 col-lg-6">
								<div className="txt-block right-column wow fadeInLeft">
									<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
										<h2 className="h2-xs">Kachuful Card Game Features</h2>
										<p className="p-lg">Leaderboard to get competition with worldwide players with
											kachuful offline. Google play center is helping to find out players position
											in the leader board.
										</p>
										<div className="txt-box">
											<ul className="simple-list">
												<li className="list-item">
													<p className="p-md">Quests on a Weekly basis are available with existing
														deals to get an extra bonus with the Kachuful game.
													</p>
												</li>
												<li className="list-item">
													<p className="p-md">Easily take and throw the card from suit in our
														kachuful offline card game.
													</p>
												</li>
												<li>
													<p className="p-md">Kachuful game offers the strategy elements like
														trick-tacking card games and luck elements of Slot games, Daily
														reward Roulette with Crazy graphics.
													</p>
												</li>
											</ul>
										</div>
									</ScrollAnimation>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="techno-sec">
				<div className="over-techno">
					<div className="heading">
						<h2>Our Professionals are working on the following top-notch techs</h2>
						<p>We build the nexus of talent and innovation with Optimum oengines</p>
					</div>
					<div className="row align">
						<div className="col-md-2 box">
							<div className="nodejs wow fadeInUp">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/nodejs-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box">
							<div className="javascript  wow fadeInUp">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/reactjs-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box wow fadeInUp">
							<div className="rabbit">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/mean-stack.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box wow fadeInUp">
							<div className="mongodb">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/mongodb-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box wow fadeInUp">
							<div className="redis ">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/unity-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
						<div className="col-md-2 box  wow fadeInUp">
							<div className="socket">
								<ScrollAnimation animateIn="animate__animated animate__bounceInDown">
									<img src="../assets/img/GamesImg/Languages_images/angular-technology.png" alt="" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>

			</section>
			<section className="service-section py-5">
				<div className="container">
					<div className="row justify-content-center py-3">
						<div className="col-md-8 col-12 text-center">
							<h2 className="service-main-heading">Our Features</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
							<div className="icon-box  wow fadeInUp">
								<img src="../assets/img/GamesImg/icon/multilingual.png" alt="" />
								<p className="service-title"><a href="#">Multi-Languages</a></p>
								<p className="service-para">We created Kachuful Card Game app/software/website
									supporting different regional and native languages for the better gaming
									experience.</p>
							</div>
						</div>

						<div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
							<div className="icon-box  wow fadeInUp">
								<img src="../assets/img/GamesImg/icon/dailybonus.png" alt="" />
								<p className="service-title"><a href="#">Daily Bonuse</a></p>
								<p className="service-para">Play the game Regularly and get Daily Bonus Points.</p>
							</div>
						</div>

						<div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
							<div className="icon-box  wow fadeInUp">
								<img src="../assets/img/GamesImg/icon/videobonus.png" alt="" />
								<p className="service-title"><a href="#">Video Bonuse</a></p>
								<p className="service-para">You can get Video Bonus Point also.</p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6 col-12 mt-4 mt-md-0">
							<div className="icon-box  wow fadeInUp">
								<img src="../assets/img/GamesImg/icon/customizerummysolution.png" alt="" />
								<p className="service-title"><a href="#">Customize Kachuful Card Game Solution </a>
								</p>
								<p className="service-para">Have a particular feature in mind? We will incorporate it
									into your Kachuful Card Game according to your exact requisites.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="content-2a" className="content-2 pb-60 pt-50 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-7 col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<h2 className="h2-xs">Make it simpler with Quick Commands</h2>
									<ul className="simple-list">

										<li className="list-item">
											<p className="p-lg">Fringilla risus, luctus mauris orci auctor euismod iaculis
												luctus
												magna purus pretium ligula purus undo quaerat tempor sapien rutrum
												mauris quaerat ultrice
											</p>
										</li>

										<li className="list-item">
											<p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
										</li>
									</ul>
								</ScrollAnimation>
							</div>
						</div>

						<div className="col-md-5 col-lg-6">
							<div className="rel img-block left-column wow fadeInRight">
								<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
									<img className="img-fluid" src="../assets/img/GamesImg/Kachuful_ofline_images/img-07.png"
										alt="content-image" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="Rummy-sec">
				<div className="container">
					<div className="over-techno">
						<div className="heading">
							<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
								<h2 className="wow fadeInUp">Kachuful Card Games Variations</h2>
								<p className="wow fadeInUp">To make your Kachuful Card game playing experience even
									better, use this Kachuful Card game variations and enjoy more than before-</p>
							</ScrollAnimation>
						</div>
						<div className="types">
							<div className="row">
								<div className="col-md-4 changes">
									<div className="rummy-type">
										<div className="rummy-img">
											<img src="../assets/img/GamesImg/Kachuful_ofline_images/Variations/1.png"
												alt="" className="wow fadeInUp" />
										</div>
										<div className="rummy-txt">
											<h5 className="wow fadeInUp">Deals Card Rummy</h5>
											<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
												<p className="wow fadeInUp">Deals Rummy is another variant of the 13 card
													Rummy game & Indian Rummy where a fixed number of cards are dealt
													with each player. At the start of an online deals rummy game, all
													players are given a fixed amount of coins to play with. At the end
													of a deals rummy game, the player with the higher coins count wins
													the game.</p>
											</ScrollAnimation>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="faqs-2" className="pb-60 faqs-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-80">
								<h2 className="h2-md">Frequently Asked Question?</h2>
							</div>
						</div>
					</div>
					<div className="faqs-2-questions">
						<div className="row row-cols-1 row-cols-lg-2">
							<div className="col">
								<div className="questions-holder pr-15">
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">How Much Time Does It Take to Develop a Kachuful Card
												Game?</h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">Kachuful Card Game development is a complex process, so
												it will take between six months and a year.
											</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Do you provide continued maintenance support for Kachuful
												offline mobile game development?</h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">Yes, we provide you with continuous maintenance support for
												Kachuful Card Game development. Even after the development, you can
												opt for the monthly retention plan.
											</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">What is the process of Kachuful Card Game development?
											</h5>
										</ScrollAnimation>
										<ul className="simple-list">
											<li className="list-item">
												<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
													<p className="p-lg">The process starts with the analysis of your idea
														and requirements. Then begins the customization and development
														process. After that, all the functions and functionality are
														added to the app. Once the app is ready, it's time for beta
														testing. After several tests, the app is ready to be launched.
													</p>
												</ScrollAnimation>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="questions-holder pl-15">
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Will You Provide Both the Kachuful Card Game Website &
												Mobile App Development Services?</h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg">Yes, we offer a range of Kachuful Card Game development
												services including website development, mobile app development, and
												software development.
											</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Where Do You Store All Data of This Kachuful Card Game?
											</h5>
										</ScrollAnimation>
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<p className="p-lg"> The answer to this question completely depends upon you. We
												prefer to store your kachuful offline game data on highly secured gaming
												servers. If you have any recommendations for the gaming server, then you
												may discuss them with us and we will store the data on the chosen
												server.
											</p>
										</ScrollAnimation>
									</div>
									<div className="question wow fadeInUp">
										<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
											<h5 className="h5-md">Do You Provide Customized Kachuful Card Game
												Development Services?</h5>
										</ScrollAnimation>
										<ul className="simple-list">
											<li className="list-item">
												<ScrollAnimation animateIn="animate__animated animate__fadeInUp">
													<p className="p-lg">Of course, YES. We offer 100% customized gaming
														solutions that meet clients’ requirements and even exceed them.
														No matter what kind of gaming solutions you need, our team of
														talented professionals is capable of fulfilling your needs.</p>
												</ScrollAnimation>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 text-center pt-25">
						</div>
					</div>
				</div>
			</section>
			<section className="variation-main">
				<div className="container">
					<div className="variation-inner">
						<div className="heading">
							<h2>Kachuful Card Games Screen Shot And Reviews</h2>
							<p>We provide complete and advanced Kachuful card game development services at our Oengines Games to reach the targeted audience base.</p>
						</div>
						<OwlCarousel items={3}
							className="owl-theme"
							loop
							autoplay={true}
							margin={8} >
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/Kachuful_ofline_images/Screen_shot/1.png"
										alt="Img not found" />
									<h5>1. Nizar Dayal</h5>
									<p className="set-height">Nice kachuful game...</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/Kachuful_ofline_images/Screen_shot/2.png"
										alt="Img not found" />
									<h5>2. Eleah Perry</h5>
									<p className="set-height">well done guys, graphics / animations / effects / challenging in alll 101 out of 100 👍👌</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/Kachuful_ofline_images/Screen_shot/3.png"
										alt="Img not found" />
									<h5>3. BMW NISSAN</h5>
									<p className="set-height">Just a fun game not to many ads</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/Kachuful_ofline_images/Screen_shot/4.png"
										alt="Img not found" />
									<h5>4. Demuriel Thompson</h5>
									<p className="set-height">I enjoy this game offline</p>
								</div>
							</div>
							<div className="item">
								<div className="sc-shot">
									<img src="../assets/img/GamesImg/Kachuful_ofline_images/Screen_shot/5.png"
										alt="Img not found" />
									<h5>5. Priya shah</h5>
									<p className="set-height">It is easy to play</p>
								</div>
							</div>
						</OwlCarousel>
					</div>
				</div>
			</section>

			<section id="cta-9" className="bg-01 wide-80 cta-section division">
				<div className="container">
					<div className="row justify-content-md-center">
						<div className="col col-lg-8">
							<div className="cta-9-txt text-center">
								<h2 className="h2-xs white-color">Getting Started is Fast & Easy</h2>
								<p className="p-xl white-color mb-35">Download kachuful offline on your Windows or Mac
									and get 30 days free trial.</p>
								<div className="d-flex justify-content-center gap-4">
									{
										gameDetails.android_link ?
											<a target="_blank" href={gameDetails.android_link} className="os-btn bg-white d-flex align-items-center">
												<div className="os-btn-ico">
													<div className="ico-50">
														<img src="../assets/img/GamesImg/palystore.jpeg" alt="os-icon" />
													</div>
												</div>
												<div className="os-btn-txt">
													<h6 className="h6-lg">Download for Android</h6>
												</div>
											</a>
											:
											''
									}

									{
										gameDetails.ios_link ? <a target="_blank" href={gameDetails.ios_link} className="os-btn mac-os-btn bg-white d-flex align-items-center">
											<div className="os-btn-ico">
												<div className="ico-50">
													<img src="../assets/img/GamesImg/app_store1.png" alt="os-icon" />
												</div>
											</div>

											<div className="os-btn-txt">
												<h6 className="h6-lg">Download for IOS</h6>
											</div>
										</a> : ''
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>

	);

}