import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';

export default function Simulator() {
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
	const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Simulator'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};

	

	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }

	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />
			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>
			<section id="hero" className="simulator-section hero-section division">
				<div className="container">
					<div className="row card-her pt-100 simulator-hero pb-100 text-start">
						<div className="col-md-12 col-lg-12 col-xl-6 arcade-col">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Simulator Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt action-hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color max-conent m-auto mt-40">The business simulation game for born leaders! This is an exciting and complex economic game that develops entrepreneurial thinking and business skills. Free scenario, multiplayer economy, realistic company management and global competition. Find your idea and market strategy, become a real tycoon!</p>
								</ScrollAnimation>
							</div>
							<a href="tel:+02614054978" ><button className="theme-button mt-4">Call Now</button></a>
						</div>						
					</div>
				</div>
			</section>
    			
			
			<section className="simulatore-details pt-80 pb-80">
				<div className="container">
					 <div className="row">
						<div className="col-md-6">
							<div className="row">
								<div className="col-md-3">
									<h2>Simulator Game</h2>
								</div>
								<div className="col-md-9">
									<div className="simulation-image">
										<img src="../assets/img/GameDevelopment/web-development-compnay-in-california.jpg" alt="img not found" />
									</div>
								</div>
							</div>
						</div>					
						<div className="col-md-6 p-5">
							<div className="row mt-50">
								<div className="col-md-6">
									<div className="benifites">
										<h4>Benefits of Simulation Game</h4>
										<ul className="brnifit-ul">
											<li>Simplify Difficult Concepts</li>
											<li>Impossible Experience</li>
											<li>Emotional and Intuitive Experience</li>
											<li>Oversimplification</li>
										</ul>
									</div>
								</div>
								<div className="col-md-6">
									<div className="types-simulation">
										<h4>Types of Simulation</h4>
										<ul className="brnifit-ul">
											<li>Construction & Management</li>
											<li>Business & Management</li>
											<li>Sports Simulation</li>
											<li>Life Simulation</li>
											<li>Vehicle Simulation</li>
											<li>General Simulation</li>
										</ul>
									</div>
								</div>
							</div>
						</div>						
					 </div>
					<div className="fpage-desc">
						<p className="text-white">Simulation games are a genre of games that are designed to mimic activities you’d see in the real world. The purpose of the game may be to teach you something. For esxample, you could learn how to fish. Others simulation games take on operating a business such as a farm or a theme park.
						</p>
					</div>
				</div>
			</section>

			<div className="hero-box bg-gradient-1 font-inverse">
				<div className="container">
					<h2 className="hero-heading wow fadeInDown animated animated text-white text-center" data-wow-duration="0.6s">OEngines Simulator Game Development</h2>
					<p className="hero-text wow bounceInUp animated animated text-white text-center mt-30 text" data-wow-duration="0.9s" data-wow-delay="0.2s">
					Simulation is a replica of the operation of any real-world process over time whereas visualization is a process or a
					technique of creating visual representation or videos in order to portray a message. OEngines Games delivers the most
					cutting-edge simulation and visualization services in the industry. Our solutions are top notch and have addressed the
					needs of our clients very well. Today, simulation and visualization has been adopted by all types of industries such as
					healthcare, training and education, automobile and gaming and we are keenly following the trends of these
					technologies.
					</p>
				</div>
				<div className="hero-pattern pattern-bg-2"></div>
			</div>

			<section className="best-game-idea pt-80 pb-100">
				<div className="container">
				<h2 className="text-center mb-50">We are known for our best game development ideas</h2>
					<div className="row">
						<div className="col-md-6">							
							<h3>Our Expertise</h3>
							<ul>							
								<li>Game viral factors</li>
								<li>Game marketing</li>
								<li>Support 24x7</li>
								<li>100% Code Guarantee</li>
								<li>Game monetization plans</li>                            
							</ul>
						</div>
						<div className="col-md-6">							
							<h3>Flexible Engagement Model</h3>
							<ul>							
								<li>Access to the whole team during development</li>
								<li>Manual Testing, Load testing and Security testing</li>
								<li>Source Control/Continuous Integration and Deployment</li>
								<li>Hire Mobile Game Developers/Designers/Animators on an hourly basis too.</li>								
							</ul>
						</div>
					</div>
				</div>		
			</section>

            <section className="simulator-contect pt-70 pb-70">
				<div className="container  pt-60 pb-60">
                    <div className="row">
                        <div className="hire-our-action-text racing-contect-mian text-center">
                            <h2 className="text-white">Get in Touch with Us to Create Great Simulator Games</h2>
                           <button className="theme-button mt-30" onClick={handleShow}>Contact Us Now</button>
                        </div>
                    </div>
				</div>
			</section>
			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}
			<Footer />
		</div>

	);
}
