import React, { useState, useEffect, Component } from "react";
import { Helmet } from 'react-helmet';
import Header from '../../../GameServiceHeader';
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import config from '../../../config.json';
import OwlCarousel from 'react-owl-carousel';

export default function Arcarde() {	
	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
    const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  

	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Arcarde'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};

	const maxlengthnumber = (e) => {
		console.log("Enter in maxLength:::::")
        if (e.target.value.length === 11) {
            return false;
        }        
    }
	return (

		<div>		
			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />

			<Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>

			<section id="hero" className="bg-scroll-card arcarde-bg hero-section division arcarde-sec">
				<div className="container">
					<div className="row card-hero pb-100">
						<div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6 arcade-col">
							<div className="hero-17-title  white-color mb-30 pt-25">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Arcade Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color max-conent">Drive maximum engagement for your gaming business through our unrivalled and top of the line arcade game development solutions and services.</p>
								</ScrollAnimation>
							</div>
							<a href="tel:+02614054978" ><button className="theme-button mt-4">Call Now</button></a>
						</div>
						<div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6">
							<div className="hero-17-img arcade-hero video-preview">
								<ScrollAnimation animateIn="animate__animated animate__fadeInRight">
									<img className="img-fluid" src="../assets/img/GameDevelopment/Arcarde/icon-new.png" alt="hero-image" />
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="chs_oeg mt-80 mb-60">
				<div className="container">
					<div className="Oeg_main">
						<div className="row">
							<div className="col-md-12">
								<div className="Oeg_text">
									<h2 className="text-center">Arcade Game</h2>
									<div className="elementor-divider ">
										<span className="elementor-divider-separator">
										</span>
									</div>
									<p className="mt-30 text-center">A usually coin-operated game designed for play at an amusement arcade.</p>
									<p className="mt-30 text">An arcade game is a game machine typically found in public places like malls, restaurants and amusement arcades, and is usually coin operated. Arcade games are usually video games, pinball machines or electromechanical games. Arcade games often have short levels, which rapidly increase in difficulty with simple and intuitive controls. Game players are essentially renting the game for as long as the game avatar is alive. For this business model to be profitable, the difficulty of the game must be high enough to make the players reach a game-over state and engaging or addictive enough to keep the players playing. Nowadays, the arcade is clearly dead in most parts of the world as what was once amazing technology is available on your cell phone.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="arcd-features pt-100">
				<div className="container">
					<div className="game-features">
						<div className="page-heading text-center text-white arc-text-max">
							<h2 className="text-white">Features of Our Arcade Game Development</h2>
						</div>
						<div className="row">
							<div className="col-lg-4 col-md-6 mt-20">
								<div className="item">
									<span className="icons">
										<img src="../assets/img/GameDevelopment/Arcarde/icons/1.png" alt="" className="" width="70" />
									</span>
									<h5 className="mt-20">Engaging Game Graphics</h5>
									<p>We cover both 2D and 3D arcade games to offer amazing and real gaming experiences to your gamers. Our game
										developers and designer consider creating such gaming assets with realism to retain the engagement for the game</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 mt-20">
								<div className="item">
									<span className="icons">
										<img src="../assets/img/GameDevelopment/Arcarde/icons/2.png" alt="" className="" width="70" />
									</span>
									<h5 className="mt-20">Leadership Board</h5>
									<p>To retain the engaging factor in the game, our arcade game developers have introduced leadership boards. This enables the player to record the maximum engagement and highest scores.</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 mt-20">
								<div className="item">
									<span className="icons">
										<img src="../assets/img/GameDevelopment/Arcarde/icons/3.png" alt="" className="" width="70" />
									</span>
									<h5 className="mt-20">Achievement Badges</h5>
									<p>This feature of arcade games also retains the engaging factor for the game similar to the leadership board. It motivates the player to play more games by unlocking these small challenges.</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 mt-20">
								<div className="item">
									<span className="icons">
										<img src="../assets/img/GameDevelopment/Arcarde/icons/4.png" alt="Rummy Tournment" title="Rummy Tournment" className="" width="70" />
									</span>
									<h5 className="mt-20">Exciting Game Assets</h5>
									<p>At OEngines, we include 2d and 3d gaming graphics, game characters, and offline asset storage with excellent video and audio capabilities to render the best gaming experience.</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 mt-20">
								<div className="item">
									<span className="icons">
										<img src="../assets/img/GameDevelopment/Arcarde/icons/5.png" alt="" className="" width="70" />
									</span>
									<h5 className="mt-20">Game Specific Sounds & Animations</h5>
									<p>Our game developers and sound engineers include unique game sounds and animations to the arcade games. This
										ensures to offer a real-time gaming experience to your users.</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 mt-20">
								<div className="item">
									<span className="icons">
										<img src="../assets/img/GameDevelopment/Arcarde/icons/6.png" alt="" className="" width="70" />
									</span>
									<h5 className="mt-20">Wide Range of Arcade Games</h5>
									<p>Our game developers include a wide range of arcade game solutions. The most famous arcade games the casual games and knock down games for mobile devices.</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 mt-20">
								<div className="item">
									<span className="icons">
										<img src="../assets/img/GameDevelopment/Arcarde/icons/7.png" alt="" className="" width="70" />
									</span>
									<h5 className="mt-20">Seamless and Secure Payment Options</h5>
									<p>We ensure to implement secure payment services by integrating a reliable payment gateway. It supports the payment with both fiat and cryptocurrencies in our arcade game development solutions.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="bottom-shape"></div>
			</section>

			<section className="rummy-gaame-dev-3 pt-70 pb-70">
				<div className="layers hide-tab">
					<div className="layer-1 aos-init aos-animate" data-aos="fade-right"><img src="../assets/img/GameDevelopment/Arcarde/remorte.png" alt="Rummy game Developers" title="Rummy game Developers" width="75%" className="" /></div>
				</div>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-9 col-md-12 offset-lg-3 aos-init aos-animate" data-aos="fade-left">
							<div className="page-heading">
								<span>Arcade Variations</span>
								<h2 className="pt-15">Variations of Our Arcade Games Development</h2>
							</div>
							
							<div className="d-flex">
								<ul>
									<li>Action Games</li>
									<li>Flight Games</li>
									<li>Pinball Machines Games</li>
									<li>Electro-Mechanical Games</li>
								</ul>
								<ul className="ml-50">
									<li>Music Games</li>
									<li>Casual Games</li>
									<li>Beaten Up Games</li>
									<li>Fighting Games</li>
								</ul>
							</div>								
						</div>
					</div>
				</div>
			</section>

			<section className="arcade-service-sec pt-60 pb-60">
				<div className="container">
					<div className="row mt-40">
						<div className="col-md-6">
							<div className="arcade-service-text">
								<h2 className="text-white">Specialized Arcade Game Development Services</h2>
								<p className="text-white">We are a dedicated team of professionals that bring life into your gaming ideas cost-effectively by employing
									cutting-edge technology and efficient strategies. We ensure that our clients receive maximum audience engagement
									from their respective business ventures.</p>
								<p className="text-white">From information collecting to testing, we methodically prepare every step of the arcade game creation
									process to attract more players by combining all consumer components into one gorgeous game.</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="arcade-service-img">
								<img src="../assets/img/GameDevelopment/Arcarde/mobile.png" alt="Img" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="pb-60 pt-60 why_orion">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 text-center page-heading mb-4">
							<h2 className="title robolike">Why Choose OEngines for Arcade Game Development</h2>
							<p className="mt-25">OEngines, an arcade game development company, works hard to produce the greatest games. Therefore, your
								innovative ideas will turn into the best custom-made arcade game development solutions.</p>
						</div>
						<div className="col-12 col-sm-6 col-lg-4">
							<div className="card text-center apps bg-light">
								<span>01</span>
								<h4>Experienced Team</h4>
								<p>At OEngines, our outstanding team of arcade game developers have more than 6+ years of experience. We have
									provided several mobile arcade game solutions across the globe with rich customer satisfaction.</p>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-lg-4">
							<div className="card text-center apps bg-light">
								<span>02</span>
								<h4>Game Portability</h4>
								<p>Our game developers provide the perfect arcade game solutions that support multiple gaming platforms without
									disrupting the gaming experience.</p>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-lg-4">
							<div className="card text-center apps bg-light">
								<span>03</span>
								<h4>Prompt Support</h4>
								<p>At OEngines , our team of designers, developers, testers and marketing experts is committed to supporting your arcade gaming app solution with 24/7 customer support.</p>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-lg-4">
							<div className="card text-center apps bg-light">
								<span>04</span>
								<h4>Cost-Effective</h4>
								<p>We have rich experience while working on several mobile game development solutions with modern technologies to
									deliver cost-effective solutions for arcade games.</p>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-lg-4">
							<div className="card text-center apps bg-light">
								<span>05</span>
								<h4>Regular Update</h4>
								<p>We have rich experience while working on several mobile game development solutions with modern technologies to
									deliver cost-effective solutions for arcade games.</p>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-lg-4">
							<div className="card text-center apps bg-light">
								<span>06</span>
								<h4>Ready-to-launch Games</h4>
								<p>We have several ready-to-launch arcade games that you can buy from us and mark your entry into the gaming world at once. Our ready-made games encompass all engaging features and important game assets.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="work-section position-relative">
				
				<span className="icon-2 sprite-bg"></span>
				<span className="icon-3 sprite-bg"></span>
				<span className="icon-4 sprite-bg d-md-block d-none"></span>
				<span className="icon-5 sprite-bg"></span>
				<span className="icon-6 sprite-bg d-md-block d-none"></span>
				<div className="container">					
					<div className="row align-items-center">
						
						<div className="col-md-12 board-span text-center">
							<span className="text-white">Contact Us</span>
							<h2 className="text-white mt-35">WANT TO HIRE OUR ARCADE GAME DEVELOPERS?</h2>
							<button className="theme-button mt-40" onClick={handleShow}>GET IN TOUCH</button>
						</div>
					</div>
				</div>
			</section>		
			
			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}
				
			<Footer />
		</div>

	);
}
