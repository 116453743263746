import React, { Component } from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import Appointment from "./Appointment/Appointment";
import { BrowserRouter, json } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReactGA from "react-ga4";
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            header: [],
            formErrors: {},
            showSubMenu: false,
            activeIndex: null,
            sideMenu: false,
            mobileMenu: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this);
    }

    componentDidMount() {

        axios.get("/headers/Main")
        .then(response => {
            this.setState({ header: response.data });
            // sessionStorage.setItem("header", JSON.stringify(response.data));
        })
        .catch(function (error) {
            console.log(error);
        })
        
        /* var header = sessionStorage.getItem("header");
        console.log("header ::::::::::::: ", header);
        if(typeof header == 'undefined' || header == "" || header == null || header.includes("<html>")){
            axios.get("https://localhost:3001/headers/Main")
            .then(response => {
                this.setState({ header: response.data });
                sessionStorage.setItem("header", JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            })
        }else{
            try{
                this.setState({ header: JSON.parse(header) });    
            }catch(e){
                console.log("error ::::::: ", e);
            }
            
        } */
        

    }

    toggleMobileMenu = () => {
        this.setState((prevState) => ({
            mobileMenu: !prevState.mobileMenu,
          }));

       
    }

    toggleSideMenu = () => {
        this.setState((prevState) => ({
            sideMenu: !prevState.sideMenu,
          }));
    }

    toggleSubMenu = (index) => {
        this.setState((prevState) => ({
          showSubMenu: !prevState.showSubMenu,
          activeIndex: index,
        }));
    };
    

    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    setStartDate(date){
        this.setState({ toDate: date })
    }
    
    maxlengthnumber(evt) {
        if (evt.target.value.length > 15) {
            return false;
        }
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};
        
        if (this.state.name === '' || this.state.name === null) {

            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.message === '' || this.state.message === null) {
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else if (this.state.toDate === '' || this.state.toDate === null) {
            formErrors["toDateError"] = "Date is required.";
            this.refs.toDatefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else {

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,
            };

            ReactGA.event({
                category: "Appointment",
                action: JSON.stringify(formObject),
            });

            axios.post("https://www.oengines.com/contactus", formObject)
                .then((res) => {
                    if (res.data !== 'undefined') {
                        Swal.fire({
                            title: "Thank you!",
                            text: "Your request has been assigned successfully.",
                            icon: "success",
                        }).then(function () {
                            window.location.reload();
                        });
                    } else {
                        Swal.fire({
                            title: "Oops...!",
                            text: "Oh! Something went wrong, please try again!",
                            icon: "error",
                        }).then(function () {
                            window.location.reload();
                        });
                    }

                });
        }


        this.setState({
            name: '',
            email: '',
            subject: '',
            number: '',
            message: '',
            toDate: ''

        })
    }
    render() {
        var headerData;
        if (this.state.header.length) {
            headerData = this.state.header.map((header, Index) =>
                <div className="sub-items p-4" key={Index}>
                    <h4>{header.title}</h4>
                    <ul className="" key={Index}>
                        {
                            header.items.map((item, i) =>
                                <li key={i}><a href={item.href}>{item.name}</a></li>
                            )
                        }
                    </ul>

                </div>
            )
        } else {
            headerData = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }

        const { nameError, emailError, subjectError, numberError, toDateError, messageError } = this.state.formErrors;
        return (
            <>

                <div className={this.state.sideMenu ? "show sidemenu-wrapper side-model-contect d-lg-block paginacontainer" : "sidemenu-wrapper side-model-contect d-lg-block paginacontainer"}>
                    <div className="sidemenu-content">
                        <div className="widget woocommerce widget_shopping_cart">
                            <div className="widget_shopping_cart_content">
                                <div className={this.state.sideMenu ? "modal fade modaltwo show" : "modal fade modaltwo"} id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                    <div className="modal-dialog new-modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header ">
                                                <h5 className="modal-title m-auto" id="exampleModalLongTitle">Appointment</h5>
                                                <button type="button" className="close mdl-cls" data-dismiss="modal" aria-label="Close" onClick={ () => this.toggleSideMenu()}>
                                                    <span aria-hidden="true" style={{ fontSize: "52px", position: "absolute", left: "10px", top: "-14px"  }}>&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <form onSubmit={this.onSubmit} method="post" className="contact-form ajax-contact">
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" value={this.state.name} placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                                            <i className="fal fa-user"></i>
                                                            {nameError &&
                                                                <div className="invalid-feedback" >{nameError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" value={this.state.email} name="email"
                                                                id="email" placeholder="Email Address" onChange={this.handleChange} />
                                                            <i className="fal fa-envelope"></i>
                                                            {emailError &&
                                                                <div className="invalid-feedback">{emailError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <select name="subject" id="subject" ref="subjectfield" value={this.state.subject} className={subjectError ? "form-control is-invalid" : "form-control"} onChange={this.handleChange}>
                                                                <option value="">Select Subject
                                                                </option>
                                                                <option value="App Development">App Development</option>
                                                                <option value="Game Development">Game Development</option>
                                                                <option value="Web Development">Web Development</option>
                                                                <option value="Brand Marketing">Brand Marketing</option>
                                                                <option value="UI/UX Designing">UI/UX Designing</option>
                                                                <option value="Digital Marketing">Digital Marketing</option>
                                                            </select>
                                                            {subjectError &&
                                                                <div className="invalid-feedback">{subjectError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} name="number" value={this.state.number} ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off" onChange={this.maxlengthnumber} />
                                                            <i className="fal fa-phone"></i>
                                                            {numberError &&
                                                                <div className="invalid-feedback">{numberError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-group col-12">
                                                            <DatePicker className="form-control" placeholderText="Select Date" selected={this.state.toDate} value={this.state.toDate} onChange={(date) => this.setStartDate(date)}/>
                                                            {toDateError &&
                                                                <div className="invalid-feedback">{toDateError}</div>
                                                            }
                                                        </div>

                                                        <div className="form-group col-12">
                                                            <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                                                className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" value={this.state.message} onChange={this.handleChange}>
                                                            </textarea>
                                                            <i className="fal fa-comment"></i>
                                                            {messageError &&
                                                                <div className="invalid-feedback">{messageError}</div>
                                                            }
                                                        </div>
                                                        <div className="form-btn text-xl-start text-center col-12">
                                                            <button type="submit" className="as-btn style3">Send Appointment
                                                                <i className="fa-regular fa-arrow-right ms-2"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <p className="form-messages mb-0 mt-3"></p>
                                                </form>
                                                <div className="contect-us-details">
                                                    <h4 className="text-center">Get In Touch</h4>
                                                    <p className="text-context-main">Book an Appointment now to get Started a new Journey with us.</p>
                                                    <div className="conect-item">
                                                        <i className="fas fa-map-location"></i>
                                                        <div className="contect-info">
                                                            <a href="#" className="title">436, Amby vally arcade,</a>
                                                            <p className="sub-title">Utran, Surat Gujarat</p>
                                                        </div>
                                                    </div>
                                                    <div className="conect-item">
                                                        <i className="fas fa-phone"></i>
                                                        <div className="contect-info">
                                                            <a href="#" className="title">(0261) - 4054978</a>
                                                            <p className="sub-title">Mon-Fri 9am-6pm</p>
                                                        </div>
                                                    </div>  
                                                    <div className="conect-item">
                                                        <i className="fas fa-envelope"></i>
                                                        <div className="contect-info">
                                                            <a href="#" className="title">support@oengines.com</a>
                                                            <p className="sub-title">Mon-Fri 9am-6pm</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={this.state.mobileMenu ? "as-menu-wrapper as-body-visible" : "as-menu-wrapper"}>
                    <div className="as-menu-area text-center"><button className="as-menu-toggle" id="btn-cls" onClick={ () => this.toggleMobileMenu()} ><i className="fal fa-times"></i></button>
                        <div className="mobile-logo"><a href={"/"}><img src="/assets/img/logo.png" alt="Traga" /></a>
                        </div>
                        <div className="cm-menu-inner as-mobile-menu">
                            <ul className="menu-ul clear-all main-ul-menu">
                                <li className="menu-item-has-children">
                                    <a href={"/"}>Home</a>
                                </li>
                                {
                                    this.state.header.length ?

                                        <li className="has-child menu-item-has-childrens services d-lg-none d-md-block">
                                            <a href="#" className="menu-item-has-children">Services</a>
                                            <ul className="menu-ul sub-menu" style={{ flexWrap: "inherit", justifyContent: "center", gap: "34px" }}>
                                            {this.state.header.map((header, index) => (
                                                    <li className="has-child menu-item-has-children" key={index} onClick={() => this.toggleSubMenu(index)}>
                                                        <a href="#" className="menu-item-has-children">{header.title}</a>                
                                                        <div className={`child-menu-btn fa fa-angle-right ${this.state.activeIndex === index && this.state.showSubMenu ? 'child-menu-btn fa fa-angle-right fa-minus' : ''}`}></div>
                                                        {this.state.showSubMenu && this.state.activeIndex === index && (
                                                            <ul className="menu-ul sub-menu d-block" key={index} >                                                   
                                                            {
                                                                header.items.map((item, i) =>(
                                                                    <li className="menu-item-has-childrens" key={i}>
                                                                        <a href={item.href}>{item.name}</a>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                        )}                                 
                                                        
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>

                                    :

                                    ''
                                }
                                
                                {/* <li className="menu-item-has-childrens">
                                    <a href="/blog">Blog</a>
                                </li> */}
                                <li className="menu-item-has-childrens">
                                    <a href="/career">Career</a>
                                </li>
                                <li className="menu-item-has-childrens">
                                    <a href="/about">About Us</a>
                                </li>
                                <li className="menu-item-has-childrens">
                                    <a href="/contact">Contact</a>
                                </li>
                                <div className="over-btn d-flex">
                                    <div className="col-auto d-lg-block">
                                        <div className="header-button">
                                            <a href="#" onClick={() => {
                                                this.toggleSideMenu();
                                                if(this.state.mobileMenu){
                                                    this.toggleMobileMenu();
                                                }
                                            }}
                                                className="as-btn style3 Appointment" style={{padding: "18px 11px"}} >Make Appointment<i
                                                    className="fas fa-arrow-right ms-1"></i></a>
                                        </div>
                                    </div>
                                    <div className="box sky-btn">
                                        <a href="https://join.skype.com/invite/ietjVjcoI5eb">
                                            <i className="fab fa-skype"></i>
                                        </a>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                <header className="as-header header-layout1">
                    <div _ngcontent-mfs-c21="" className="header-lines-decoration">
                        <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                        <span _ngcontent-mfs-c21="" className="bg-blue"></span>
                        <span _ngcontent-mfs-c21="" className="bg-white-light"></span>
                        <span _ngcontent-mfs-c21="" className="bg-orange-light"></span>
                        <span _ngcontent-mfs-c21="" className="bg-red"></span>
                        <span _ngcontent-mfs-c21="" className="bg-green"></span>
                        <span _ngcontent-mfs-c21="" className="bg-secondary-color"></span>
                    </div>
                    <div className="header-top">
                        <div className="container">
                            <div className="row justify-content-center align-items-center gy-2 mt-1 mb-1">
                                <div className="col-auto d-none d-lg-block">
                                    <div className="header-links">
                                        <ul>
                                            <li><i className="fas fa-map-location"></i>436, Amby vally arcade,Utran, Surat Gujarat</li>
                                            <li><i className="fas fa-phone"></i><a href="tel: 1539873657">(0261) - 4054978</a></li>
                                            <li><i className="fas fa-envelope"></i><a href="mailto:support@oengines.com">support@oengines.com</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-auto header-main">
                                    <div className="header-social"><span className="social-title">Follow Us On : </span><a
                                        href="https://www.facebook.com/oe.studio091"><i className="fab fa-facebook-f"></i></a> <a
                                                href="https://www.linkedin.com/company/oenginesgames"><i className="fab fa-linkedin-in"></i></a> <a
                                                    href="https://www.youtube.com/@oenginesstudio337"><i className="fab fa-youtube"></i></a>
                                                    <a
                                                    href="https://play.google.com/store/apps/dev?id=7618782448745223948"><i className="fab fa-android"></i></a>
                                                    <a
                                                    href="https://apps.apple.com/us/developer/oengines-games-llp/id1565939904?see-all=i-phonei-pad-apps"><i className="fab fa-apple"></i></a>
                                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sticky-wrapper"> 
                        <div className="menu-area">
                            <div className="container">
                                <div className="row align-items-center justify-content-between"> 
                                    <div className="col-auto">
                                        <div className="header-logo"><a href={"/"}><img src="/assets/img/logo1.png" alt="Traga" /></a>
                                        </div>
                                    </div> 
                                    <div className="col-auto">
                                        <nav className="main-menu d-none d-lg-inline-block">
                                            <ul>
                                                <li className="menu-item-has-children home"><a href={"/"} className={BrowserRouter.pathname === "/" ? "active" : ""}>Home</a>
                                                </li>
                                                <li className="menu-item-has-children servs services"><a href="#">Services <i className="fa fa-angle-down" aria-hidden="true"></i></a>
                                                    <ul className="sub-menu">
                                                        <div className="d-flex justify-conetnt-center">                                                            
                                                            {headerData}                                                            
                                                        </div>
                                                    </ul>
                                                </li>
                                                {/* <li className="menu-item-has-children blog"><a href="/blog" className={BrowserRouter.pathname === "/blog" ? "active" : ""}>Blog</a>
                                                </li> */}
                                                <li className="menu-item-has-children career"><a href="/career" className={BrowserRouter.pathname === "/career" ? "active" : ""}>Career</a></li>
                                                <li className="menu-item-has-children about"><a href="/about" className={BrowserRouter.pathname === "/about" ? "active" : ""}>About Us</a></li>
                                                <li className="menu-item-has-children contect"><a href="/contact" className={BrowserRouter.pathname === "/contact" ? "active" : ""}>Contact</a></li>                                             
                                            </ul>
                                        </nav><button type="button" className="as-menu-toggle d-inline-block d-lg-none" id="but-icon" onClick={ () => this.toggleMobileMenu()}><i
                                            className="far fa-bars"></i></button>
                                    </div>
                                    <Appointment toggleSideMenu={ this.toggleSideMenu }/>
                                </div>
                            </div>
                            <div className="logo-bg"></div>
                        </div>
                    </div>
                </header>
            </>
        )
    }
}
export default Header;