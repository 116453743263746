import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';
import { Helmet } from 'react-helmet';
import Header from "../../../GameServiceHeader";
import Footer from '../../../over_games/Footer';
import '../../Game_development/Puzzle/puzzstyle.css';
import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';

export default function Sport() {
    const [show, setShow] = useState(false);
    const [Gamedata, setGameData] = useState([]);

	useEffect(() => {
        var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);
    const handleClose = () =>{
		setShow(false);
	};

	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};  
  
	
	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee  =  formObject['subject'];
		formObject['subject'] = 'Sport'+'-'+TotalEmployee; 
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
		.then((res) => {
			console.log("Response is:::::::::", res);
			if (res.data !== 'undefined') {
				Swal.fire({
					title: "Thank you!",
					text: "Your request has been assigned successfully.",
					icon: "success",
				}).then(function () {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: "Oops...!",
					text: "Oh! Something went wrong, please try again!",
					icon: "error",
				}).then(function () {
					window.location.reload();
				});
			}

		}); 
	};

	const handleChange = (event) => {

	};

	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
        if (evt.target.value.length === 11) {
            return false;
        }        
    }

	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
			</Helmet>
            {/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />
            <Modal className="modal_contect" centered show={show} >
				<Modal.Header  closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">
							
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off"/>
								<i className="fal fa-user"></i>							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>						
							</div>							
							<div className="form-group form-float col-md-6 col-sm-6">                                        
								<input type="text" id="subject"  className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />               
								<i className="fal fa-note-sticky"></i>                              							
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">							
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange= {maxlengthnumber}/>
								<i className="fal fa-phone"></i>					
							</div>
							<div className="form-group form-float col-12">							
								<textarea name="message" id="message" cols="30" rows="3" 
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>						
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>							
						</div>
					</form>	
				</Modal.Body>
			</Modal>
			<section id="hero" className="sport-section hero-section division">
				<div className="container">
					<div className="row card-hero pb-100 text-start">
						<div className="col-md-11 col-xl-6 mt-80 m-3">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Sports Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt action-hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color m-auto mt-40">Get feature-rich mobility solutions for sports. We are a topnotch sports app development company with 6 + years of Experience, who will make your dream app live.</p>
								</ScrollAnimation>
							</div>
							<a href="tel:+02614054978" ><button className="theme-button mt-4">Call Now</button></a>
						</div>
					</div>
				</div>
			</section>
            <section className="sport_Game-parts pb-90 pt-90">
				<div className="container">
					<p className="text-center text-white text">OEngines is a leading sports app & website app development company. Our sports app developers create
					eye-catching, feature-rich apps. Here are some of the benefits of choosing OEngines for your Sports game App.</p>
					<div className="row mt-60 ">
						 <div className="col-md-4">
							<div className="banner-content-box line">
                                <img src="./assets/img/GameDevelopment/best-tool.png" alt="img not found" />
                                <h3 className="text-white">Best Tool</h3>
                                <p className="text-white">Looking to bring your high-quality Scalable apps in real life.</p>
							</div>
						 </div>
						 <div className="col-md-4">
                            <div className="banner-content-box line">
                                <img src="./assets/img/GameDevelopment/experience-team.png" alt="img not found" />	
                                <h3 className="text-white">Experienced Team</h3>
                                <p className="text-white">Reimagine your business with our mobile app development services.</p>			
                            </div>			
						 </div>
						 <div className="col-md-4">
                            <div className="banner-content-box">
                                <img src="./assets/img/GameDevelopment/multiple-platform.png" alt="img not found" />	
                                <h3 className="text-white">Multiple Platform</h3>
                                <p className="text-white">Building custom solutions across Android, iOS, and Native platform.</p>
                            </div>
						 </div> 
					</div>
				</div>
			</section>
            
            <section className="section why-ios mt-lg-5 w-100 float-left position-relative blockchain-technology py-4 p-md-5">
                <div className="circle-yellow"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xl-7 col-lg-12 p-3">
                            <div className="">
                                <div className="w-100 float-left section-title mb-0">
                                    <p className="title-para">Why Gaming App?</p>
                                    <h2 className="mb-3 text-start">
                                        Get Ready to Embrace the Success with Stunning Sports Apps
                                    </h2>
                                </div>
                                <p className="w-100 float-left">
                                    Sports and gaming apps are perfect for an immersive and thrilling experience. Millions of people around the world love to play games on their smartphones.
                                </p>
                                <p className="w-100 float-left">
                                    Having a gaming app can take your business to a whole new level. With it, you can attract users of all ages. What’s more, gaming apps are not just for entertainment but can also be used for educational purposes.
                                </p>
                                <p className="w-100 float-left">
                                    So, if you are thinking of building a gaming app, then OEngines is the perfect development company for you.
                                </p>
                                <p className="w-100 float-left"><b>Top Sports Software Solutions</b></p>
                                <div className="d-flex w-100 float-left flex flex-wrap flex-lg-nowrap justify-content-md-around">
                                    <div className="d-flex">
                                        <img src="https://www.codeaegis.com/public/user/images/icons/sports-how-work1.png" alt="icon" className="img-fluid icon" />
                                        <p className="mb-0">Live Sports Streaming App</p>
                                    </div>
                                    <div className="d-flex">
                                        <img src="https://www.codeaegis.com/public/user/images/icons/sports-how-work2.png" alt="icon" className="img-fluid icon" />
                                        <p className="mb-0">On-Demand Sports Coach App</p>
                                    </div>
                                </div>
                                <div className="d-flex w-100 float-left flex flex-wrap flex-lg-nowrap justify-content-md-around">
                                    <div className="d-flex">
                                        <img src="https://www.codeaegis.com/public/user/images/icons/sports-how-work2.png" alt="icon" className="img-fluid icon" />
                                        <p className="mb-0">Sports Betting App</p>
                                    </div>
                                    <div className="d-flex">
                                        <img src="https://www.codeaegis.com/public/user/images/icons/sports-how-work4.png" alt="icon" className="img-fluid icon" />
                                        <p className="mb-0">Sports Gambling App</p>
                                    </div>
                                </div>
                                <a onClick={handleShow} style={{cursor: "pointer"}} className="btn-primary start-project mt-2 mt-md-5" data-toggle="modal" data-target="#startproject">
                                    <span>Start a Project</span><i className="bx bx-right-arrow-alt"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-12 col-xl-5 col-lg-12 d-none d-xl-block">
                            <div className="w-100 float-left" style={{position: "relative"}}>
                                <div className="image">
                                    <img className="img-fluid shape-7" src="https://www.codeaegis.com/public/user/images/icons/shape-7.png" alt="image" />
                                    <img className="img-fluid img1" src="https://www.codeaegis.com/public/user/images/custom-images/sports-how-works1.png" alt="image" />
                                    <img className="img-fluid img2" src="https://www.codeaegis.com/public/user/images/custom-images/sports-how-works2.png" alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="capermint-app-dev pt-80 pb-80">
                <div className="container">
                    <div className="capermint-app-dev-content row">
                        <h2><b>Why OEngines for Sports App Development?</b></h2>
                        <p>Our fantasy sports developers use top-notched technology to create the most advanced and innovative fantasy sports
                        solutions. From the planning stage to the launch, we will provide effective results in all the stages. Here are some advantages of choosing OEngines Games as your sports app development company.</p>

                        <div className="col-md-6 mt-60">
                            <div className="dev-feature">
                                <div className="dev-feature-box">
                                    <div className="dev-feature-img">
                                        <b>
                                            <img decoding="async" src="./assets/img/GameDevelopment/multiplayer.png" alt="Multiplayer Game/App Development Specialist" title="Multiplayer Game/App Development Specialist" />
                                        </b>
                                    </div>
                                    <div className="dev-feature-content">
                                        <h4><b>Multiplayer Game/App Development Specialist</b></h4>
                                        <p className="text-white">Our developers have years of experience in developing chartbusters and astonishing multiplayer mobile games/apps.</p></div>
                                </div>
                                
                            </div>
                            
                            <div className="dev-feature-box">
                                <div className="dev-feature-img">
                                    <b>
                                        <img decoding="async" src="./assets/img/GameDevelopment/seamless.png" alt="Seamless gaming experience" title="Seamless gaming experience" />
                                    </b>
                                </div>
                                <div className="dev-feature-content">
                                    <h4><b>Supports cross-platform</b></h4>
                                    <p className="text-white">Your game should get full exposure, and therefore, we develop games that support cross-platform functionality.</p>
                                </div>
                            </div>
                            <div className="dev-feature-box">
                                <div className="dev-feature-img">
                                    <b>
                                        <img decoding="async" src="./assets/img/GameDevelopment/attractive.png" alt="Attractive UI and UX" title="Attractive UI and UX" />
                                    </b>
                                </div>
                                <div className="dev-feature-content">
                                    <h4><b>Seamless gaming experience</b></h4>
                                    <p className="text-white">Our games are designed to work seamlessly and effortlessly across all devices and platforms.</p>
                                </div>
                            </div>
                            <div className="dev-feature-box">
                                <div className="dev-feature-img">
                                    <b>
                                        <img decoding="async" src="./assets/img/GameDevelopment/expert.png" alt="Expert 2D and 3D Game Developers" title="Expert 2D and 3D Game Developers" />
                                    </b>
                                </div>
                                <div className="dev-feature-content">
                                    <h4>
                                        <b>Attractive UI and UX</b>
                                    </h4>
                                    <p className="text-white">   
                                    To provide the best gaming experience, we develop the most attractive UI and UX that feel amazing and user-friendly at the same time.
                                    </p>
                                </div>
                            </div>
                            <div className="dev-feature-box">
                                <div className="dev-feature-img overflow-hidden">
                                    <b>
                                        <img decoding="async" src="./assets/img/GameDevelopment/experienced.png" alt="Experienced Real Money Game Developers" title="" />
                                    </b>
                                </div>
                                <div className="dev-feature-content">
                                    <h4>
                                        <b>Expert 2D and 3D Game Developers</b>
                                    </h4>
                                    <p className="text-white">
                                        We have a team of expert 2D and 3D game developers who are experienced in developing the best 2D and 3D games.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="dev-img col-md-6 mt-90 text-center"><b>
                            <img decoding="async" src="../assets/img/GameDevelopment/capermint-sport-app-dev.png" alt=""/>
                        </b>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sports-geners">
                <div className="container">
                    <div className="sports-geners-content">
                        <h2>Some Glimpse of Sports Game Development</h2>                   
                        <div className="sports-geners-boxes row">
                            <div className="col-md-4 col-sm-6 col-lg-4 mt-20">
                                <div className="genres-box cl-1">
                                    <img decoding="async" src="../assets/img/GameDevelopment/fantasy-cricket-app-img.png" alt="Fantasy Cricket App" title="Fantasy Cricket App" />
                                    <h3>Fantasy Cricket App</h3>
                                    <p>We have an expert team of Fantasy Cricket app developers who are effective in creating interactive Fantasy cricket apps that offer rich features at affordable rates.</p>
                                </div>                               
                            </div>
                            <div className="col-md-4 col-sm-6 col-lg-4 mt-20">
                                <div className="genres-box cl-2">
                                    <img decoding="async" src="../assets/img/GameDevelopment/fantasy-football-app-img.png" alt="Fantasy Football App" title="Fantasy Football App" />
                                    <h3>Fantasy Football App</h3>
                                    <p>We offer out-of-the-league Fantasy Football App development solutions that provide the most thrilling Fantasy Football experience to the users.</p>
                                </div>                               
                            </div>
                            <div className="col-md-4 col-sm-6 col-lg-4 mt-20">
                                <div className="genres-box cl-3">
                                    <img decoding="async" src="../assets/img/GameDevelopment/fantasy-hockey-app-img.png" alt="Fantasy Hockey App" title="Fantasy Hockey App" />
                                    <h3>Fantasy Hockey App</h3>
                                    <p>Get the highest-performing Fantasy Hockey app development solutions that covers all the major Hockey Leagues, and are armed with a powerful backend to provide smooth gameplay.</p>
                                </div>                              
                            </div>                           
                        </div>
                    </div>
                </div>
            </section>

            {
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}

			<Footer />
		</div>

	);
}
