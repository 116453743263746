import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import Header from '../../../GameServiceHeader';
import Footer from '../../../over_games/Footer';
// import '../../Game_development/Puzzle/puzzstyle.css';
// import '../../../assets/css/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Modal } from 'react-bootstrap';
import axios from "axios";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel';
import config from '../../../config.json';


export default function Action() {

	const [show, setShow] = useState(false);
	const [Gamedata, setGameData] = useState([]);

	useEffect(() => {

		var str = window.location.pathname;
		let Pathname = str.substring(1);
		
		axios.get(config.GAME_CATEGORIES_GAMEDATA_API+Pathname).then((res) => {
			console.log("Get Action GAme Data 1111111:::::::::::;", res.data);
		    if (res.data && res.data.length){
		        setGameData(res.data);
		    }                
		})
				
	}, []);


	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => {
		console.log("enter in modal::::::::");
		setShow(true);
	};

	const onSubmit = (event) => {
		event.preventDefault();
		console.log("Submites Sucsssfully");
		const data = new FormData(event.target);
		var formObject = Object.fromEntries(data.entries());
		let TotalEmployee = formObject['subject'];
		formObject['subject'] = 'Action' + '-' + TotalEmployee;
		console.log("Submites Sucsssfully data is::::::::::::", formObject);

		axios.post("https://www.oengines.com/contactus", formObject)
			.then((res) => {
				console.log("Response is:::::::::", res);
				if (res.data !== 'undefined') {
					Swal.fire({
						title: "Thank you!",
						text: "Your request has been assigned successfully.",
						icon: "success",
					}).then(function () {
						window.location.reload();
					});
				} else {
					Swal.fire({
						title: "Oops...!",
						text: "Oh! Something went wrong, please try again!",
						icon: "error",
					}).then(function () {
						window.location.reload();
					});
				}

			});
	};

	const handleChange = (event) => {

	};

	const maxlengthnumber = (evt) => {
		console.log("Enter in maxLength:::::")
		if (evt.target.value.length === 11) {
			return false;
		}
	}
	return (

		<div>

			<Helmet>
				<link rel="stylesheet" href="../assets/css_game/style.css" />
				<link rel="stylesheet" href="../assets/css_game/responsive.css" />
			</Helmet>
			{/* <div className="loader" ><span>Loading...</span></div> */}
			<Header />
			<Modal className="modal_contect" centered show={show} >
				<Modal.Header closeButton onClick={handleClose}>
					<h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={onSubmit} className="contact-form ajax-contact">
						<div className="row">

							<div className="form-group form-float col-md-6 col-sm-6">
								<input type="text" name="name" placeholder="Your Name" onChange={handleChange} id="name" className="form-control" autoComplete="Off" />
								<i className="fal fa-user"></i>
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">
								<input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-envelope"></i>
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">
								<input type="text" id="subject" className="form-control" name="subject" placeholder="Subject" onChange={handleChange} autoComplete="Off" />
								<i className="fal fa-note-sticky"></i>
							</div>
							<div className="form-group form-float col-md-6 col-sm-6">
								<input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange={maxlengthnumber} />
								<i className="fal fa-phone"></i>
							</div>
							<div className="form-group form-float col-12">
								<textarea name="message" id="message" cols="30" rows="3"
									className="form-control" placeholder="Your Message" onChange={handleChange} autoComplete="Off">
								</textarea>
								<i className="fal fa-comment"></i>
							</div>
							<div className="form-group form-float col-12">
								<button type="submit" className="as-btn style3 send-qty">Send Details
									<i className="fa-regular fa-arrow-right ms-2"></i>
								</button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			<section id="hero" className="hero-action hero-section division action-hero-bfr">

				<div className="container">
					<div className="row card-hero pb-100 text-center">
						<div className="col-md-12 col-lg-12 mt-80">
							<div className="hero-17-title white-color mb-30">
								<ScrollAnimation animateIn="animate__animated animate__fadeInLeft">
									<h2 className="h2-xl">Action Game Development</h2>
								</ScrollAnimation>
							</div>
							<div className="hero-17-txt action-hero-17-txt">
								<ScrollAnimation animateIn="animate__animated animate__fadeInleft">
									<p className="p-xl white-color max-conent m-auto mt-40">Oengines is one of the leading action games development companies that takes pride in creating captivating and intriguing action games which are easy to learn but hard to master while targeting diverse age groups and demographics.</p>
								</ScrollAnimation>
							</div>
							<a href="tel:+02614054978" ><button className="theme-button mt-50">Call Now</button></a>
						</div>
					</div>
				</div>
			</section>
			<section className="action-game pt-80 pb-80">
				<div className="container">
					<div className="action-heaing">
						<h2 className="text-center">Action Game</h2>
						<div className="elementor-divider action-elementor-divider">
							<span className="elementor-divider-separator">
							</span>
						</div>
						<p className="text-center mt-30 text">Games in Action genre are large and varied, they encompass many sub-genres like Action/Adventure, Racing/Driving,
							RPG, Strategy/Tactics etc. offering gamers different style of gameplay, platforms and sub-genres to choose from with majority of the challenges being physical tests of skills. Action games are games which involve fighting, guns, explosions, and saving the world. They comprise many sub-genres such as Adventure, Shooter games, Rhythm games, and so on.</p>
					</div>
				</div>
			</section>

			<section className="action-benifit pt-100 mt-100 pb-100">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6 arcade-col">
							<div className="action-benifite-man">
								<img src="../assets/img/GameDevelopment/Action/character.png" alt="img" />
							</div>
						</div>
						<div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6 arcade-col action-beni-man">
							<div className="action-benifit-text">
								<h2 className="text-white">Benefits of Playing Action Games</h2>
								<div className="action-benifites-main">
									<div className="right-img d-flex mt-3">
										<img src="../assets/img/GameDevelopment/green-check-icon.png" alt="img-not found" />
										<h4 className="text-white">Improved problem-solving skills</h4>
									</div>
									<div className="right-img d-flex mt-3">
										<img src="../assets/img/GameDevelopment/green-check-icon.png" alt="img-not found" />
										<h4 className="text-white">Enhanced hand-to-eye coordination</h4>
									</div>
									<div className="right-img d-flex mt-3">
										<img src="../assets/img/GameDevelopment/green-check-icon.png" alt="img-not found" />
										<h4 className="text-white">Greater multi-tasking ability</h4>
									</div>
									<div className="right-img d-flex mt-3">
										<img src="../assets/img/GameDevelopment/green-check-icon.png" alt="img-not found" />
										<h4 className="text-white">Promotes teamwork and prosocial behavior</h4>
									</div>
									<div className="right-img d-flex mt-3">
										<img src="../assets/img/GameDevelopment/green-check-icon.png" alt="img-not found" />
										<h4 className="text-white">Better Eyesight</h4>
									</div>
									<div className="right-img d-flex mt-3">
										<img src="../assets/img/GameDevelopment/green-check-icon.png" alt="img-not found" />
										<h4 className="text-white">Greater multi-tasking ability</h4>
									</div>
									<div className="right-img d-flex mt-3">
										<img src="../assets/img/GameDevelopment/green-check-icon.png" alt="img-not found" />
										<h4 className="text-white">Enhanced prosocial behaviors</h4>
									</div>
									<div className="right-img d-flex mt-3">
										<img src="../assets/img/GameDevelopment/green-check-icon.png" alt="img-not found" />
										<h4 className="text-white">And other benefits.</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="choose_poker" className="why-poker">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6">
							<div className="poker-choose-desc ">
								<h2>Oengines Action Game Development Company</h2>
								<p>
									At Multi-Programming Solutions, we have all the experience and expertise necessary to turn your ideas into amazing and super-fabulous action games. Oengines has displayed their expertise in the development of the following genres of action games.
								</p>
								<ul>
									<h3>Benefits of Hiring Oengines for Action Game Development</h3>
									<li>We have experienced app developers for multiple platforms</li>
									<li>We have years of great experience</li>
									<li>Our experts understand the project and deliver it timely</li>
									<li>Once the project gets ready, we make sure to test in on different platforms</li>
									<li>We work 24*7 for our clients</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6">
							<div className="choose-img mt-25"><picture> <img decoding="async" title="poker game developer" src="../assets/img/GameDevelopment/Action/new_charecter.png" alt="poker game developer" /></picture></div>
						</div>
					</div>
				</div>
			</section>

			<section className="Action-Company">
				<div className="container">
					<div className="row cpmy-main">
						<div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6">
							<div className="cpmy-mian-text">
								<h2 className="text-white">HOW WE DO IT?</h2>
								<p className="text-white">Through our experience in providing action games development services to our global clients, we understand important
									challenges which need to be properly addressed to give players realistic gaming experience, like:</p>
								<ul>
									<li className="text-white">Switchable and Easy Player Camera View between First Person and Third Person View</li>
									<li className="text-white">Details Character Animation of action, reaction, pose based on the player view</li>
									<li className="text-white">Weapon and Combat System</li>
									<li className="text-white">Depth of view of Scene objects</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6">
							<div className="we-do-img">
								<img src="../assets/img/GameDevelopment/stunning-right.png" alt="IMG NOT FOUND" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="hire-our-action mt-80 pb-60">
				<div className="container  pt-60 pb-60">
					<div className="hire-our-action-text">
						<h2 className="text-white text-center">WANT TO HIRE OUR ACTION GAME DEVELOPERS?</h2>
						<p className="text-white text-center mt-30">Get an Action Game Development by our team of experts</p>
						<button className="theme-button mt-30" onClick={handleShow}>Contact Us Now</button>
					</div>
				</div>
			</section>


			{
				Gamedata.length && (

					<section className="team-sec space new-space-bg">
						<div className="containers z-index-common">
							<div className="title-area text-center">
								<h2 className="sec-title">Checkout Some Games on Store.</h2>
							</div>
							<OwlCarousel className="owl-theme" loop items={5}  margin={10} nav>
								{Gamedata.map((gamedata, index) => {
									return (
										<>
											<div className="col-sm-6 col-lg-4 col-xl-3 mb-20">
												<div className="as-team team-card">
													<div className="team-img"><img src={`http://moregames.oengines.com/images/icons/${gamedata.icon}`} alt="Team" /></div>
													<div className="team-content team-content-remove new-con-box row d-flex">
														<h3 className="box-title col-auto"><a >{gamedata.game_name}</a></h3>
														<div className="applink d-flex ml-2 justify-content-between m-auto row">
															{gamedata.android_link === '' ? '' : <button className="border-none"><a href={`${gamedata.android_link}`} target="_blank"><img src="assets/img/icon/play_store.png" alt="img not found" className="playstore" /></a></button>
															}
															{gamedata.ios_link === '' ? '' :
																<button className="border-none"><a href={`${gamedata.ios_link}`} target="_blank"><img src="assets/img/icon/app_store.png" alt="img not found" className="appstore" /></a></button>
															}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</OwlCarousel>
						</div>
						<div className="shape-mockup" data-bottom="0" data-left="0">
							<div className="particle-2" id="particle-2"></div>
						</div>
					</section>
				)
			}

			<Footer />
		</div>

	);
}
