import React, { Component } from "react";
import { Helmet } from 'react-helmet';
// import '../assets/css_game/App.css';
import '../assets/css_game/style.css';
import '../assets/css_game/dropdown-effects/fade-down.css';

export default function Footer(){
    return( 
        <>
         <div className="pb" id="pb"></div>
        <div className="pc">
            <div className="ol">
                <a href="#" id="btn-back-to-top">
                    <i className="fa-solid fa-arrow-up">
                    </i>
                </a>
            </div>
            <div className="bg"></div>
        </div>
                        
        <footer id="footer-1" className="footer division" style={{paddingTop: "20px", paddingBottom: "0", backgroundColor: "#000", color: "FFFFFFF"}}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="text-center text-white">&copy; {sessionStorage.getItem("currentYear")} OENGINES GAMES LLP</p>
                    </div>
                 </div> 
            </div>
        </footer>
        </>
);
    }