import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-awesome-slider/dist/styles.css';
import axios from 'axios';
import moment from 'moment';
import Footer from "../Footer";
import Header from '../Header';
import DOMPurify from 'isomorphic-dompurify';
import { Tab, Tabs, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            formErrors: {},
            blog: [],
            categories: [],
            tags: []
        }
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
        this.onClickblogdetails = this.onClickblogdetails.bind(this);
    }
    async componentDidMount() {
        this.onshowcategories();
        axios.get("https://www.oengines.com/careers")
            .then(response => {
                this.setState({ Job: response.data });
                this.setState({ Description: response.data[0].description });
            })
            .catch(function (error) {
                console.log(error);
            })
        localStorage.setItem("catdata", 'Game Development');

        try {
            const response = await fetch("https://localhost:3001/employee-posts");
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            console.log("Data is:::::::::::;;;", data);
            this.setState({ blog: data });
        } catch (error) {
            console.error("Error fetching data:", error);
        }

        axios.get("https://localhost:3001/blogsCategories")
            .then(response => {
                this.setState({ categories: response.data });
            })
            .catch(function (error) {
                console.log(error);
            })



    }
    onClickShowdetails(job) {
        console.log("job is:::::::", job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }
    onClickblogdetails(blog) {
        console.log("blog is:::::::", blog);
        localStorage.setItem("Blog", JSON.stringify(blog));
        window.location.href = '/blogdetails';
    }

    onshowcategories(categoriesName) {
        var catdata = categoriesName;
        localStorage.setItem("catdata", catdata);
    }
    render() {


        const myStyleone = {
            backgroundImage:
                "url('../assets/img/bg/breadcumb-bg.jpg')",
            width: "100%",
            zIndex: 2,
        };
        const data = [{ "_id": "Game Development" }, { "_id": "Nodejs" }];

        var Information;
        if (this.state.Job.length) {
            Information = this.state.Job.map((job) =>
                <>
                    <li key={job.index}>
                        <a onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>
                </>
            )
        } else {
            Information = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }

        var BlogInfo;
        var localcategories = localStorage.getItem("catdata");
        if (this.state.blog.length) {
            BlogInfo = this.state.blog.map((blog, index) => {
                if (blog.blogcategorie === localcategories) {

                    return <>
                        <div className="as-blog blog-single has-post-thumbnail col-md-10 col-lg-5 col-sm-10">
                            <div className="blog-img as-carousel" data-arrows="true" data-slide-show="1" data-fade="true">
                                <a>
                                    <img src={`${blog.blog_img}`} alt="Blog Image" />
                                </a>
                            </div>

                            <div className="blog-content" >
                                <div className="blog-meta"><a className="author"><img
                                    src="assets/img/blog/author-1-1.png" alt="avater" /> By {blog.employee_name}</a> <a
                                    ><i className="fa-light fa-calendar-days"></i>{moment(blog.createdat).format('YYYY-MM-DD')}</a>
                                </div>
                                <h2 className="blog-title"><a >{blog.blogtitle}</a></h2>
                                <div className="box" id="section">
                                    <div className="content-full article" id="article" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog.sub_title) }}></div>
                                </div>
                                <a href="#!" onClick={() => this.onClickblogdetails(blog)} className="line-btn">Read More</a>
                            </div>
                        </div>

                    </>

                } else {
                    return null;
                }
            }
            )

        } else {
            BlogInfo = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }

        var Blogcategories;
        if (this.state.Job.length) {
            Blogcategories = this.state.categories.map((categories, Index) =>
                <>
                    <ul>
                        <li><a href="#!" onClick={(e) => this.onshowcategories(categories._id)}>{categories._id}</a></li>
                    </ul>
                </>
            )
        } else {
            Blogcategories = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }


        var blogrecentpost;
        var localcategories = localStorage.getItem("catdata");
        if (this.state.blog.length) {
            <div className="widget">
                <h3 className="widget_title">Recent Posts</h3>
                <div className="recent-post-wrap">
                    {blogrecentpost = this.state.blog.map((blog, index) => {
                        if (blog.blogcategorie == localcategories) {
                            return <>
                                <div className="recent-post">
                                    <div className="media-img"><a href="#!" onClick={() => this.onClickblogdetails(blog)}><img
                                        src={`${blog.blog_img}`} alt="Blog Image" /></a></div>
                                    <div className="media-body">
                                        <h4 className="post-title"><a className="text-inherit"
                                            href="#!" onClick={() => this.onClickblogdetails(blog)}>{blog.blogtitle}</a>
                                        </h4>
                                        <div className="recent-post-meta"><a><i
                                            className="fal fa-calendar-days"></i>{moment(blog.createdat).format('YYYY-MM-DD')}</a></div>
                                    </div>
                                </div>
                            </>

                        } else {
                            return false;
                        }
                    }
                    )}
                </div>
            </div>
        } else {
            blogrecentpost = '';
        }

        var getItem = localStorage.getItem("catdata");
        this.setState({ newcatdata: getItem });

        return (
            <div>
                <Helmet>
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                    <script src="https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js"></script>
                    <script src="../public/assets/js/script.js" type="text/Javascript"></script>
                </Helmet>
                {/* <div className="loader" ><span>Loading...</span></div> */}
                <Header />
                <div className="breadcumb-wrapper" style={myStyleone}>
                    <div className="container">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">Blog Post</h1>
                            <ul className="breadcumb-menu">
                                <li><a href={"/"}>Home</a></li>
                                <li>Blog Post</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <section className="as-blog-wrapper space-top space-extra-bottom">
                    <div className="container">
                        <div className="row">
                            <Tabs className="d-lg-flex d-md-block revers">
                                <div className="col-xxl-4 col-lg-5 col-md-12">
                                    <aside className="sidebar-area">
                                        {this.state.categories.length ?
                                            <div className="widget widget_categories">
                                                <h3 className="widget_title">Categories</h3>
                                                <TabList>
                                                    <ul>
                                                        {this.state.categories.map((categories, Index) =>
                                                            <Tab key={Index}>
                                                                <a name="name" onClick={(e) => this.onshowcategories(categories._id)}>{categories._id}</a>
                                                            </Tab>
                                                        )}
                                                    </ul>
                                                </TabList>
                                            </div>
                                            : ''}

                                        {blogrecentpost}

                                    </aside>
                                </div>
                                <div className="col-xxl-8 col-lg-7 col-md-12 blg-details">
                                    <div className="row">
                                        {BlogInfo}
                                    </div>
                                </div>
                            </Tabs>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        )
    }
}

export default Blog;
